import {
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Button,
  ButtonGroup,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';
import SendIcon from '@mui/icons-material/Send';
import InfoIcon from '@mui/icons-material/Info';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  useGetExternalAccountsQuery,
  useGetFinancialAccountTransactionQuery,
  useGetProjectFinancialAccountQuery,
} from '../../app/api/project-financial-account-slice';
import ProjectFinancialAccountSetup from '../../components/ProjectFinancialAccountSetup';
import './FinancesFinancialAccountTab.scss';
import FinancesFinancialAccountSensitiveInformationModal from './FinancesFinancialAccountSensitiveInformationModal';
import FinancialAccountTransactionsList from './FinancialAccountTransactionsList';
import FinancialAccountOutboundTransferDialog from './FinancialAccountOutboundTransferDialog';
import FinancialAccountTransactionDetailDialog from './FinancialAccountTransactionDetailDialog';
import FinancialAccountTransferChoiceDialog from './FinancialAccountTransferChoiceDialog';
import FinancialAccountOutboundPaymentDialog from './FinancialAccountOutboundPaymentDialog';

interface FinancesFinancialAccountTabProps {
  projectId: string;
}

function FinancesFinancialAccountTab({
  projectId,
}: FinancesFinancialAccountTabProps) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const transactionId = searchParams.get('transaction');
  const getTransaction = useGetFinancialAccountTransactionQuery(
    { projectId, transactionId },
    { skip: !transactionId }
  );

  const getProjectFinancialAccountQuery =
    useGetProjectFinancialAccountQuery(projectId);

  const getExternalAccountsQuery = useGetExternalAccountsQuery(projectId);

  const [transferChoiceDialogOpen, setTransferChoiceDialogOpen] =
    useState(false);

  const [outboundTransferDialogOpen, setOutboundTransferDialogOpen] =
    useState(false);

  const [outboundPaymentDialogOpen, setOutboundPaymentDialogOpen] =
    useState(false);

  const mainContent = () => {
    const [sensitiveInfoModalOpen, setSensitiveInfoModalOpen] = useState(false);
    if (getProjectFinancialAccountQuery.isLoading) {
      return null;
    }

    if (getProjectFinancialAccountQuery.isError) {
      // Note: When switching projects, there is an opportunity for the new data fetch to fail (no financial account) and the previous data fetch (has financial account) to be preserved
      // An errored RTK query fetch does not nullify the previous data object
      return null;
    }

    if (
      getProjectFinancialAccountQuery.data &&
      getProjectFinancialAccountQuery.data.enabled
    ) {
      const financialAccount = getProjectFinancialAccountQuery.data;
      const availableBalance = financialAccount.cashBalance! / 100;
      const currentBalance =
        (financialAccount.cashBalance! +
          financialAccount.inboundPendingBalance! +
          financialAccount.outboundPendingBalance!) /
        100;
      const showCurrentBalance = currentBalance !== availableBalance;
      return (
        <>
          <FinancesFinancialAccountSensitiveInformationModal
            open={sensitiveInfoModalOpen}
            onClose={() => setSensitiveInfoModalOpen(false)}
            accountNumber={financialAccount.accountNumber!}
            routingNumber={financialAccount.routingNumber!}
          />
          <div className="financial-account-header">
            <div className="financial-account-header-heading">
              <Typography variant="h6">
                Financial Account ••••{financialAccount.accountNumberLast4}
              </Typography>
            </div>
            <div className="financial-account-header-body">
              <Typography variant="h2">
                {Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(availableBalance)}
              </Typography>
              {showCurrentBalance && (
                <div>
                  <Typography variant="caption">
                    Current Balance:{' '}
                    {Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(currentBalance)}
                  </Typography>
                  <Tooltip title="Current balance includes pending transactions.">
                    <IconButton size="small">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
            <ButtonGroup
              variant="outlined"
              aria-label="financial account navigation"
              color="secondary"
            >
              <Button
                onClick={() => setTransferChoiceDialogOpen(true)}
                disabled={
                  getExternalAccountsQuery.isLoading ||
                  !getExternalAccountsQuery.data ||
                  getExternalAccountsQuery.data!.length === 0 ||
                  financialAccount.cashBalance === 0
                }
                className="financial-account-header-button"
              >
                <SendIcon />
                <div>Pay & Transfer</div>
              </Button>
              <Button
                onClick={() => setSensitiveInfoModalOpen(true)}
                className="financial-account-header-button"
              >
                <VisibilityIcon />
                <div>Account Details</div>
              </Button>
              <Button
                onClick={() =>
                  navigate(
                    `/settings/project/${projectId}?tab=financial-account`
                  )
                }
                className="financial-account-header-button"
              >
                <SettingsIcon />
                <div>Settings</div>
              </Button>
            </ButtonGroup>
          </div>
          <FinancialAccountTransactionsList projectId={projectId!} />
          <FinancialAccountOutboundTransferDialog
            open={outboundTransferDialogOpen}
            cashBalance={financialAccount.cashBalance || 0}
            onClose={() => setOutboundTransferDialogOpen(false)}
            projectId={projectId!}
          />
          <FinancialAccountOutboundPaymentDialog
            open={outboundPaymentDialogOpen}
            cashBalance={financialAccount.cashBalance || 0}
            onClose={() => setOutboundPaymentDialogOpen(false)}
            projectId={projectId!}
          />
          <FinancialAccountTransferChoiceDialog
            open={transferChoiceDialogOpen}
            onClose={() => setTransferChoiceDialogOpen(false)}
            onChoiceSelected={(choice) => {
              setTransferChoiceDialogOpen(false);
              if (choice === 'external_account') {
                setOutboundTransferDialogOpen(true);
              }
              if (choice === 'user') {
                setOutboundPaymentDialogOpen(true);
              }
            }}
          />
        </>
      );
    }
    return null;
  };

  return (
    <Stack spacing={2} height="100%">
      <ProjectFinancialAccountSetup projectId={projectId!} hideEditButton />
      {mainContent()}
      <FinancialAccountTransactionDetailDialog
        open={!!transactionId}
        transaction={getTransaction.data}
        isSuccess={getTransaction.isSuccess}
        isError={getTransaction.isError}
        isLoading={getTransaction.isLoading}
        error={getTransaction.error}
        onClose={() => {
          searchParams.delete('transaction');
          setSearchParams(searchParams);
        }}
      />
    </Stack>
  );
}

export default FinancesFinancialAccountTab;
