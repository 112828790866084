import { Chip, Stack } from '@mui/material';
import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { EventSummary } from '../app/api/events-api-slice';
import { LOCATION_TBD, getCityAndState } from '../app/helpers/location-helpers';
import { useGetProjectsQuery } from '../app/api/projects-api-slice';
import DateAvatar from './DateAvatar';
import './EventCard.scss';
import GreenRoomCard from './GreenRoomCard';
import Labels from './Labels/Labels';
import EventStatusChip from './EventStatusChip';

interface EventCardProps {
  event: EventSummary;
  onClick: () => void;
}

function EventCard({ event, onClick }: EventCardProps) {
  const projects = useGetProjectsQuery();
  const project = projects.data?.find((p) => p.id === event.projectId);

  const attendanceSummaryExists = () =>
    Object.values(event.attendanceSummary).find((value) => value > 0);

  const getRowOfLabels = () =>
    event?.labels?.length ? (
      <Labels labelIds={event.labels} projectId={event.projectId} />
    ) : null;

  if (project) {
    return (
      <GreenRoomCard
        onClick={onClick}
        key={project.id}
        subtitle={
          <>
            <span>
              {getCityAndState(event?.locationCity, event?.locationState)}
            </span>
            <div>{project.name}</div>
            <Stack spacing={1.5} sx={{ marginTop: '8px' }}>
              {attendanceSummaryExists() && (
                <div className="event-attendance-summary">
                  {event.attendanceSummary.pending > 0 && (
                    <div className="event-attendance-summary-chip">
                      <Chip
                        icon={<AccessTimeIcon />}
                        label={event.attendanceSummary.pending}
                        size="small"
                      />
                    </div>
                  )}
                  {event.attendanceSummary.declined > 0 && (
                    <div className="event-attendance-summary-chip">
                      <Chip
                        icon={<CancelIcon color="error" />}
                        label={event.attendanceSummary.declined}
                        sx={{ color: 'error.main' }}
                        size="small"
                      />
                    </div>
                  )}
                  {event.attendanceSummary.attending > 0 && (
                    <div className="event-attendance-summary-chip">
                      <Chip
                        icon={<CheckCircleIcon color="success" />}
                        label={event.attendanceSummary.attending}
                        sx={{ color: 'success.main' }}
                        size="small"
                      />
                    </div>
                  )}
                </div>
              )}
              {getRowOfLabels()}
            </Stack>
          </>
        }
        title={event?.locationName || LOCATION_TBD}
        left={
          <DateAvatar
            displayDate={new Date(event.startTime)}
            timeZone={event!.locationTimeZone}
            status={event.status}
          />
        }
        right={<EventStatusChip status={event.status} />}
      />
    );
  }

  return null;
}

export default EventCard;
