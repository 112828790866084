import React from 'react';
import { Stack } from '@mui/material';
import KeyValue from '../../components/KeyValue';
import Subheading from '../../components/Text/Subheading';

function KeyValues() {
  return (
    <>
      <Stack spacing={2}>
        <KeyValue
          label="Primary Key"
          value={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Value</span>
              <span>Value</span>
            </div>
          }
        />
        <KeyValue
          label="Primary Key"
          value={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Value</span>
              <span>Value</span>
            </div>
          }
        />
      </Stack>
      <Stack spacing={2} sx={{ marginTop: '24px' }}>
        <KeyValue
          secondary
          label="Secondary Key"
          value={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Value</span>
              <span>Value</span>
            </div>
          }
        />
        <KeyValue
          secondary
          label="Secondary Key"
          value={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Value</span>
              <span>Value</span>
            </div>
          }
        />
      </Stack>
      <Stack spacing={2} sx={{ marginTop: '24px' }}>
        <Subheading>Changing the width of a key column</Subheading>
        <KeyValue
          secondary
          label="A key with default keyWidth"
          value={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Value</span>
              <span>Value</span>
            </div>
          }
        />
        <KeyValue
          secondary
          keyWidth="200px"
          label="A key with increased keyWidth"
          value={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Value</span>
              <span>Value</span>
            </div>
          }
        />
      </Stack>
      <Stack spacing={2} sx={{ marginTop: '24px' }}>
        <Subheading>
          Changing the row alignment with centerVertically
        </Subheading>
        <KeyValue
          secondary
          label="Default alignment"
          value={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Value</span>
              <span>Value</span>
              <span>Value</span>
              <span>Value</span>
            </div>
          }
        />
        <KeyValue
          secondary
          centerVertically
          label="Center alignment"
          value={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Value</span>
              <span>Value</span>
              <span>Value</span>
              <span>Value</span>
            </div>
          }
        />
      </Stack>
    </>
  );
}

export default KeyValues;
