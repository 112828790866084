/* eslint-disable no-param-reassign */
import React from 'react';
import { configureStore, createAction, createReducer } from '@reduxjs/toolkit';
import emptySplitApi from './api/empty-api-slice';

const googleAnalyticsLogger = () => (next: any) => (action: any) => {
  try {
    if (action.type === 'api/executeQuery/fulfilled') {
      window.gtag('event', `api_query_${action.meta.arg.endpointName}`, {
        url: action.meta.baseQueryMeta.request.url,
      });
    }
    if (action.type === 'api/executeMutation/fulfilled') {
      window.gtag('event', `api_mutation_${action.meta.arg.endpointName}`, {
        url: action.meta.baseQueryMeta.request.url,
      });
    }
  } catch {
    //
  }
  return next(action);
};

const intercomLogger = () => (next: any) => (action: any) => {
  try {
    if (action.type === 'api/executeMutation/fulfilled') {
      const endpointName = action.meta.arg.endpointName.replace(
        /([a-z])([A-Z])/g,
        '$1_$2'
      );
      window.Intercom('trackEvent', endpointName);
    }
  } catch {
    //
  }
  return next(action);
};

export interface AudioFile {
  name: string;
  path: string;
  actions?: React.ReactNode | null;
}

interface AppState {
  audioFile: AudioFile | null;
}

const initialState: AppState = {
  audioFile: null,
};

export const setAudioFile = createAction<AudioFile | null>('app/setAudioFile');

export const store = configureStore({
  reducer: {
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    app: createReducer(initialState, (builder) => {
      builder.addCase(setAudioFile, (state, action) => {
        state.audioFile = action.payload;
      });
    }),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(emptySplitApi.middleware)
      .concat(googleAnalyticsLogger)
      .concat(intercomLogger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
