import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2';
import {
  useCreateLabelMutation,
  useDeleteLabelMutation,
  useUpdateLabelMutation,
  useGetLabelsForAllProjectsQuery,
  LABEL_COLORS,
} from '../app/api/labels-api-slice';
import GreenRoomButton from '../components/GreenRoomButton';
import ServerErrorDisplay from '../components/ServerErrorDisplay';
import './CreateEditProjectLabelDialog.scss';

export interface NewLabel {
  id?: null;
  name?: null;
  color?: null;
  projectId: string;
}

export interface ExistingLabel {
  id: string;
  name: string;
  color: typeof LABEL_COLORS[number];
  projectId: string;
}

type NewOrExistingLabel = NewLabel | ExistingLabel;

// Add missing default attributes to new labels
export const addDefaultsToLabel = (newOrExistingLabel: NewOrExistingLabel) =>
  ({
    id: newOrExistingLabel.id,
    name: newOrExistingLabel.name || '',
    color: newOrExistingLabel.color || LABEL_COLORS[0],
    projectId: newOrExistingLabel.projectId,
  } as ExistingLabel);

export interface CreateEditProjectLabelDialogProps {
  label: NewOrExistingLabel;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose: (id: string | null) => void;
  labelNamesOnProject: string[] | undefined;
}

function CreateEditProjectLabelDialog({
  label,
  open,
  onClose,
  labelNamesOnProject,
}: CreateEditProjectLabelDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Fetch data to allow user to create new from existing project labels
  const getLabelsForAllProjects = useGetLabelsForAllProjectsQuery();

  // We only care to provide autocomplete for labels not associated with this project already
  const labelsInOtherProjects =
    getLabelsForAllProjects?.data?.filter(
      (p: ExistingLabel) =>
        p.projectId !== label?.projectId &&
        !labelNamesOnProject?.includes(p.name)
    ) || [];

  // Keep track of when an autocomplete option is highlighted in order to gate form submission on enter
  const [optionHighlighted, setOptionHighlighted] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [updatedLabel, setUpdatedLabel] = useState<ExistingLabel>(
    addDefaultsToLabel(label)
  );
  useEffect(() => {
    setUpdatedLabel(addDefaultsToLabel(label));
  }, [label]);
  const [createLabelMutation, createLabelMutationStatus] =
    useCreateLabelMutation();
  const [updateLabelMutation, updateLabelMutationStatus] =
    useUpdateLabelMutation();
  const [deleteLabelMutation, deleteLabelMutationStatus] =
    useDeleteLabelMutation();

  useEffect(() => {
    if (createLabelMutationStatus.isSuccess) {
      onClose(createLabelMutationStatus.data);
    } else if (updateLabelMutationStatus.isSuccess) {
      onClose(updateLabelMutationStatus.data);
    } else if (deleteLabelMutationStatus.isSuccess) {
      onClose(null);
    } else if (
      createLabelMutationStatus.isError ||
      updateLabelMutationStatus.isError ||
      deleteLabelMutationStatus.isError
    ) {
      const error =
        createLabelMutationStatus.error ||
        updateLabelMutationStatus.error ||
        deleteLabelMutationStatus.error;
      const errorDisplay = (
        <ServerErrorDisplay
          error={error!}
          errorMessage="Unable to save changes."
        />
      );
      enqueueSnackbar(errorDisplay, {
        variant: 'error',
      });
    }
  }, [
    createLabelMutationStatus,
    updateLabelMutationStatus,
    deleteLabelMutationStatus,
  ]);

  const mode = updatedLabel.id ? 'Edit' : 'Add';

  const handleSave = () => {
    if (updatedLabel.id) {
      updateLabelMutation({
        id: updatedLabel.id!,
        body: { name: updatedLabel.name, color: updatedLabel.color },
      });
    } else {
      createLabelMutation({
        name: updatedLabel.name,
        color: updatedLabel.color,
        projectId: updatedLabel.projectId,
      });
    }
  };

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle>{mode} Project Label</DialogTitle>
      <DialogContent>
        <div className="edit-label-content">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                options={labelsInOtherProjects.map(
                  (p: ExistingLabel) => p?.name
                )}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    required
                    label="Name"
                    onChange={(e) =>
                      setUpdatedLabel({ ...updatedLabel, name: e.target.value })
                    }
                    onKeyDown={(e) => {
                      // If an item in the autocomplete dropdown is highlighted, the enter key is used to select the option
                      // If an item in the autocomplete dropdown is not highlighted, the enter key is used to submit the form
                      if (!optionHighlighted && e.key === 'Enter') {
                        handleSave();
                      }
                    }}
                    helperText="Re-usable category tag like performance set-up, tours, or geographic areas."
                  />
                )}
                onChange={(e, value, reason) => {
                  if (reason === 'selectOption') {
                    setUpdatedLabel({
                      ...updatedLabel,
                      name: value!,
                      color: labelsInOtherProjects?.find(
                        (p) => p.name === value
                      )?.color!,
                    });
                  }
                }}
                fullWidth
                value={updatedLabel.name || ''}
                onHighlightChange={(e) => e && setOptionHighlighted(true)}
                onClose={() => setOptionHighlighted(false)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl required>
                <InputLabel id="color-select">Color</InputLabel>
                <Select
                  required
                  labelId="color-select"
                  label="Color"
                  value={updatedLabel.color}
                  onChange={(e) =>
                    setUpdatedLabel({
                      ...updatedLabel,
                      color: e.target.value,
                    } as ExistingLabel)
                  }
                  renderValue={(color) => <CircleIcon sx={{ color }} />}
                >
                  {LABEL_COLORS.map((color) => (
                    <MenuItem key={color} value={color}>
                      <CircleIcon sx={{ color }} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        {mode === 'Edit' && (
          <GreenRoomButton
            type="destroy"
            onClick={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: "This will remove the label from all tagged events. You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it',
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteLabelMutation(updatedLabel.id!);
                }
              });
            }}
          >
            Delete
          </GreenRoomButton>
        )}
        <GreenRoomButton
          type="cancel"
          onClick={() => {
            onClose(null);
          }}
        >
          Cancel
        </GreenRoomButton>
        <GreenRoomButton
          type="accept"
          onClick={handleSave}
          disabled={!updatedLabel.name}
        >
          Save
        </GreenRoomButton>
      </DialogActions>
    </Dialog>
  );
}

CreateEditProjectLabelDialog.defaultProps = {};

export default CreateEditProjectLabelDialog;
