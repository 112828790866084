import React from 'react';
import {
  Dialog,
  Stack,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import GreenRoomDialogHeader from './GreenRoomDialogHeader';
import './GreenRoomDialog.scss';

interface GreenRoomDialogProps {
  open: boolean;
  title: string | React.ReactNode;
  onClose: () => void;
  onCloseDisabled?: boolean | null;
  onBack?: () => void | null;
  children?: React.ReactNode | null;
  actions?: React.ReactNode | null;
  fullscreen?: boolean;
  fullWidth?: boolean;
}

/// //////////
// This component appears as a dialog on desktop but a drawer on mobile.

// From an ingestion perspective, there is one important detail to know in order for the Drawer slide to work:
// 1. `<GreenRoomDialog>` needs to remain mounted in the parent, and only toggled on/off using the `open` prop

// NOTE: In order for the MUI Drawer to slide in and out, we must rely on its default behavior which is to always render the component and let the `open` prop determine its visibility
// Conversely, the MUI Dialog by default mounts and unmounts as the `open` prop is toggled
// In order for us to be able to treat the Drawer and Dialog the same as they relate to the DOM, we do not toggle the `open` prop on the Dialog directly, but keep `open` set to true and toggle visibility instead
function GreenRoomDialog({
  open,
  title,
  onClose,
  onCloseDisabled,
  onBack,
  children,
  actions,
  fullscreen,
  fullWidth,
}: GreenRoomDialogProps) {
  /// //////////
  // Responsiveness
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  /// //////////
  // Dialog/Drawer body
  const body = (
    <div className="greenroom-dialog">
      <GreenRoomDialogHeader
        title={title}
        onClose={onClose}
        onCloseDisabled={onCloseDisabled}
        onBack={onBack}
      />
      <Stack spacing={2} sx={{ paddingTop: '16px', overflow: 'auto' }}>
        {children}
        {actions && <div className="greenroom-dialog-actions">{actions}</div>}
      </Stack>
    </div>
  );

  /// //////////
  // Drawer container
  if (mobile && !fullscreen) {
    return (
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        onOpen={() => {}}
      >
        {body}
      </SwipeableDrawer>
    );
  }

  /// //////////
  // Dialog container
  return (
    <Dialog
      open
      onClose={onClose}
      sx={{ visibility: (open && 'visible') || 'hidden' }}
      fullScreen={fullscreen}
      fullWidth={fullWidth}
    >
      {body}
    </Dialog>
  );
}

GreenRoomDialog.defaultProps = {
  onCloseDisabled: false,
  children: null,
  actions: null,
  onBack: null,
  fullscreen: false,
  fullWidth: false,
};

export default GreenRoomDialog;
