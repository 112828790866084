import emptySplitApi from './empty-api-slice';

export interface Location {
  googlePlacesId: 'string';
  name: 'string';
  timeZone: 'string';
  streetAddress: 'string';
  city: 'string';
  state: 'string';
  zipCode: 'string';
}

export const locationsApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocationsForAllProjects: builder.query<Location[], void>({
      query: () => `/locations`,
    }),
  }),
});

export const { useGetLocationsForAllProjectsQuery } = locationsApiSlice;
