import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SvgIconComponent } from '@mui/icons-material';
import { Tabs, Tab, Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';
import './StatefulTabs.scss';
import BetaChip from '../BetaChip';

export interface TabProps {
  label: string | null;
  icon: React.ReactElement<any, SvgIconComponent>;
  value: string | null;
  sensitive?: boolean;
  beta?: boolean;
}

interface StatefulTabsProps {
  tabs: TabProps[];
  showSensitiveInformation?: boolean;
  onlyPreserveTheseParamsOnTabChange?: string[]; // If null, all params are preserved on tab change
}

// This is an enhanced Material UI Tabs component that:
// 1) Maintains tab state in the URL
// 2) Fixes invalid URL searchparams
// 3) Hides all tab labels except the active one when viewed on mobile
// 4) Defines basic styling on Tabs and Tab components

// Future enhancements:
// 1. Support multiple StatefulTabs on a given page. Currently, all StatefulTabs use the same searchParam of `tab`.
function StatefulTabs({
  tabs,
  showSensitiveInformation,
  onlyPreserveTheseParamsOnTabChange,
}: StatefulTabsProps) {
  const smallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const STATEFUL_TAB_PARAM_NAME = 'tab';

  const possibleTabValues = tabs.map((tab) => tab.value!);
  const defaultTab = tabs[0].value!;

  const activeTab = searchParams.get(STATEFUL_TAB_PARAM_NAME) || defaultTab;

  useEffect(() => {
    if (
      !searchParams.has(STATEFUL_TAB_PARAM_NAME) ||
      !possibleTabValues.includes(activeTab)
    ) {
      searchParams.set(STATEFUL_TAB_PARAM_NAME, defaultTab);
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams]);

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        variant="scrollable"
        allowScrollButtonsMobile
        scrollButtons="auto"
        value={activeTab}
        onChange={(_, value) => {
          const paramsToDelete: string[] = [];

          // If the component is provided an array for onlyPreserveTheseParamsOnTabChange, let's wipe all params not included
          if (onlyPreserveTheseParamsOnTabChange) {
            searchParams.forEach((val, key) => {
              if (
                !onlyPreserveTheseParamsOnTabChange.includes(key) &&
                key !== STATEFUL_TAB_PARAM_NAME
              )
                paramsToDelete.push(key);
            });
          }

          paramsToDelete.forEach((param) => searchParams.delete(param));

          searchParams.set(STATEFUL_TAB_PARAM_NAME, value);
          setSearchParams(searchParams);
        }}
        sx={{ height: '58px', width: '100%' }}
        className="stateful-tabs"
      >
        {tabs.map((tab) => {
          if (tab.sensitive && !showSensitiveInformation) return null;

          const betaLabel = tab.beta ? <BetaChip /> : null;
          const label =
            smallScreen && activeTab !== tab.value ? null : (
              <>
                {tab.label} {betaLabel}
              </>
            );

          return (
            <Tab
              label={label}
              icon={tab.icon}
              value={tab.value}
              iconPosition="start"
              key={tab.value}
              className="stateful-tabs-tab"
            />
          );
        })}
      </Tabs>
    </Box>
  );
}

export function getSelectedTabLabel(
  tabs: TabProps[],
  selectedTabValue: string
) {
  const selectedTab = tabs.find((tab) => tab.value === selectedTabValue);
  return selectedTab?.label || '';
}

StatefulTabs.defaultProps = {
  showSensitiveInformation: false,
  onlyPreserveTheseParamsOnTabChange: null,
};

export default StatefulTabs;
