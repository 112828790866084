export default function getHumanReadable(size: number) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  let i = 0;
  let currentSize = size;
  while (currentSize >= 1000) {
    currentSize /= 1000;
    i += 1;
  }
  return `${currentSize.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })} ${units[i]}`;
}
