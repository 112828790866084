import React, { useEffect, useState } from 'react';
import {
  createTheme,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import Div100vh from 'react-div-100vh';
import './App.scss';
import { SnackbarProvider } from 'notistack';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { UAParser } from 'ua-parser-js';
import * as SentryBrowser from '@sentry/browser';
import NavbarSide from './NavbarSide';
import Events from './pages/Events';
import SettingsPage from './pages/SettingsPage';
import Loading from './components/Loading';
import NavbarBottom from './NavbarBottom';
import { sec } from './app/security';
import EditEventPage from './pages/EditEventPage';
import { useGetUserQuery } from './app/api/user-api-slice';
import userMissingInfo from './app/userHelpers';
import OnboardUser from './pages/OnboardUser';
import { useGetProjectsQuery } from './app/api/projects-api-slice';
import ProjectSettingsPage from './pages/ProjectSettingsPage';
import ViewEventPage from './pages/ViewEventPage';
import { useGetFeatureFlagsQuery } from './app/api/feature-flags-slice';
import FinancesPage from './pages/finances/FinancesPage';
import NotFoundPage from './components/NotFoundPage';
import UnsupportedBrowserDialog from './components/UnsupportedBrowserDialog';
import GreenRoomButton from './components/GreenRoomButton';
import ComponentLibrary from './pages/ComponentLibrary/Home';
import getEnvironment from './app/helpers/environment-helpers';
import OutagePage from './OutagePage';
import HandleRedirectPage from './pages/HandleRedirectPage';
import AudioPlayer from './components/AudioPlayer';

function App() {
  /// //////////
  // Because Google authentication fails (403) in Instagram browsers, and we don't want users using the app on TikTok, check for invalid user agent metadata
  const [invalidBrowser, setInvalidBrowser] = useState<boolean>(false);
  const parser = new UAParser(navigator.userAgent);
  const { ua: userAgent, browser } = parser.getResult();
  const { name: browserName } = browser;

  // ua-parser-js supports detecting the Instagram browser and should maintain this well as it's a ubiquitous package
  // TODO: ua-parser-js does not support detecting TikTok browser; Check again in the future to see if they do: https://github.com/faisalman/ua-parser-js/blob/ec5a864abb4b30c5143c3f870d73d807697cf610/src/ua-parser.js#L221
  // Example TikTok useragent as of 01/04/2023: "TikTok 16.0.16 rv:103005 (iPhone; iOS 11.1.4; en_EN) Cronet"
  if (
    !invalidBrowser &&
    (browserName === 'Instagram' || userAgent.includes('TikTok'))
  ) {
    setInvalidBrowser(true);
  }

  /// //////////
  // Material UI Theme overrides
  const theme = createTheme({
    palette: {
      primary: {
        main: '#35B44C',
        light: '#6FE77A',
        dark: '#00831E',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#1a1c19',
        light: '#797c77',
        dark: '#000000',
        contrastText: '#ffffff',
      },
    },
    typography: {
      fontFamily: '"Urbanist"',
      body2: {
        opacity: 0.6,
      },
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: '#d32f2f',
            '&$error': {
              color: '#d32f2f',
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      },
    },
  });

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  LicenseInfo.setLicenseKey(
    '854fb66d41b5991f93907d2b3bc2aef6Tz02OTU5OCxFPTE3MTk1MjY0MjU2MjEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  );

  /// //////////
  // Auth0 intialization
  const {
    error,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    isLoading,
    user,
    logout,
  } = useAuth0();

  sec.setAccessTokenSilently(getAccessTokenSilently);

  React.useEffect(() => {
    if (isAuthenticated || !userAgent) {
      return;
    }
    async function checkUser() {
      if (!isLoading && !user && !error && !invalidBrowser) {
        const urlParams = new URLSearchParams(window.location.search);
        const signup = urlParams.get('signup');
        let options: RedirectLoginOptions | undefined;
        if (signup !== null) {
          options = { screen_hint: 'signup' };
        }
        loginWithRedirect(options);
      }
    }
    checkUser();
  }, [isLoading]);

  /// //////////
  // Data fetching
  const getUser = useGetUserQuery(undefined, { skip: !isAuthenticated });
  const getProjects = useGetProjectsQuery(undefined, {
    skip: !isAuthenticated || !getUser.isSuccess,
  });

  const getFeatureFlags = useGetFeatureFlagsQuery(undefined, {
    skip: !isAuthenticated || !getUser.isSuccess,
  });

  useEffect(() => {
    if (
      getUser.data &&
      user &&
      (getFeatureFlags.isSuccess || getFeatureFlags.isError)
    ) {
      window.gtag('config', 'G-78SSHJK7S5', {
        user_id: getUser.data.id,
      });

      window.Canny('identify', {
        appID: '62545b911f61092df15bbb69',
        user: {
          email: user?.email,
          name: `${getUser.data?.preferredName} ${getUser.data?.legalLastName}`,
          id: getUser.data?.id,

          avatarURL: user?.picture,
        },
      });

      SentryBrowser.setUser({
        id: getUser.data?.id,
        email: getUser.data?.email,
      });

      window.Intercom('boot', {
        app_id: 'z6pxe354',
        email: getUser.data?.email,
        name: `${getUser.data?.preferredName} ${getUser.data?.legalLastName}`,
        user_id: getUser.data?.id,
        phone: getUser.data?.phoneNumber,
        avatar: {
          type: 'avatar',
          image_url: user.picture,
        },
        user_hash: getUser.data?.intercomHash,
        created_at: getUser.data?.createdAt,
        'Onboarding Group': getFeatureFlags.data?.onboardingGroup ?? null,
        Environment: getEnvironment(),
      });
    }
  }, [getUser.data, user, getFeatureFlags]);

  /// //////////
  // Content rendering
  const appContent = () => {
    if (invalidBrowser) {
      return <UnsupportedBrowserDialog fullscreen={isSmallScreen} />;
    }

    if (error) {
      let errorMessage = error.message;
      let errorTitle = 'Error Occurred';
      let errorActionLabel = 'Try Again';
      let errorAction = () => {
        logout({ returnTo: window.location.origin });
      };

      if (error.message === '[verification]') {
        try {
          window.gtag('event', 'account_created');
        } catch {
          //
        }
        errorTitle = 'Welcome to Green Room!';
        errorMessage =
          'Before you continue, please click on the verification link we sent to your email.';
        errorActionLabel = 'Continue';
        errorAction = () => {
          loginWithRedirect();
        };
      }
      return (
        <Dialog open>
          <DialogTitle>{errorTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{errorMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <GreenRoomButton type="accept" onClick={errorAction}>
              {errorActionLabel}
            </GreenRoomButton>
          </DialogActions>
        </Dialog>
      );
    }

    if (getUser.isError) {
      return <OutagePage />;
    }

    if (
      !isAuthenticated ||
      !userAgent ||
      getUser.isLoading ||
      getProjects.isLoading ||
      getFeatureFlags.isLoading
    ) {
      return <Loading />;
    }

    if (getUser.data && userMissingInfo(getUser.data)) {
      try {
        if (user?.sub!.indexOf('google-oauth2') !== -1) {
          window.gtag('event', 'account_created');
        }
        window.gtag('event', 'email_verified');
      } catch {
        //
      }
      return (
        <div className="App">
          <div className="App-content">
            <OnboardUser onboardingComplete={() => {}} />
          </div>
        </div>
      );
    }

    const isNotProduction = !window.location.origin.includes('greenroom.app');

    return (
      <Div100vh>
        <AudioPlayer />
        <div className="App">
          {!isSmallScreen && <NavbarSide />}
          <div className="App-main-container">
            <div className="App-content">
              <Routes>
                <Route path="/" element={<Navigate to="events" replace />} />
                <Route path="events" element={<Events />} />
                <Route
                  path="events/create"
                  element={<EditEventPage mode="create" />}
                />
                <Route path="events/:id" element={<ViewEventPage />} />
                <Route
                  path="events/:id/edit"
                  element={<EditEventPage mode="edit" />}
                />
                {getFeatureFlags.data?.financesEnabled && (
                  <Route path="finances" element={<FinancesPage />} />
                )}
                <Route path="settings" element={<SettingsPage />} />
                <Route
                  path="settings/project/:id"
                  element={<ProjectSettingsPage />}
                />
                {isNotProduction && (
                  <Route path="components" element={<ComponentLibrary />} />
                )}
                <Route path="redirect" element={<HandleRedirectPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </div>
            {isSmallScreen && (
              <div className="App-navbar">
                <NavbarBottom />
              </div>
            )}
          </div>
        </div>
      </Div100vh>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        dense
      >
        {appContent()}
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
