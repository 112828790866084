import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { EditEvent } from '../app/models/edit-event';
import EditEventTransactionCard from '../components/EditEventTransactionCard';
import { Person } from '../app/api/persons-api-slice';
import { EventRoleType } from '../app/api/event-role-types-api-slice';
import {
  getCardTitle,
  getTransactor,
} from '../app/helpers/transaction-helpers';
import GreenRoomDialog from '../components/Dialog/GreenRoomDialog';
import GreenRoomButton from '../components/GreenRoomButton';
import GreenRoomStack from '../components/GreenRoomStack';
import NoEventFinancesBlankslate from '../components/Blankslates/NoEventFinancesBlankslate';
import { Transaction } from '../app/api/events-api-slice';
import './EventTransactionCards.scss';
import EventTransactionCard from './finances/EventTransactionCard';
import { PaymentType } from '../app/api/payment-types-api-slice';

interface EventTransactionCardsProps {
  event: EditEvent;
  roles: EventRoleType[] | undefined;
  paymentTypes: PaymentType[] | undefined;
  persons: Person[] | undefined;
  // eslint-disable-next-line no-unused-vars
  onCreateOrUpdate?: (event: EditEvent) => void;
}

function EventTransactionCards({
  event,
  roles,
  paymentTypes,
  persons,
  onCreateOrUpdate,
}: EventTransactionCardsProps) {
  const { sensitiveInformation } = event;
  const { transactions } = sensitiveInformation;

  const newTransaction = {
    id: uuid(),
    type: 'Expense',
    transactor: '',
    amount: 0,
    description: '',
    isPaid: false,
    paidDate: null,
    eventPersonId: null,
    paymentTypeId: null,
    excludeFromReports: false,
  } as Transaction;

  const [selectedTransaction, setSelectedTransaction] =
    useState<Transaction | null>(null);

  const onClearSelected = () => {
    setSelectedTransaction(null);
  };

  const onDelete = () => {
    const newEventTransactions =
      transactions?.filter((t) => t.id !== selectedTransaction?.id) || [];
    if (onCreateOrUpdate) {
      onCreateOrUpdate({
        ...event,
        sensitiveInformation: {
          ...sensitiveInformation,
          transactions: newEventTransactions,
        },
      });
      onClearSelected();
    }
  };

  const onSave = () => {
    try {
      window.Intercom('trackEvent', 'event_transaction_saved_client_side');
    } catch {
      // Ignore
    }
    const newTransactions = [...transactions];
    const selectedTransactionIndex = newTransactions.findIndex(
      (transaction) => transaction.id === selectedTransaction?.id
    );
    if (selectedTransaction) {
      if (selectedTransactionIndex < 0) {
        newTransactions.push(selectedTransaction);
      } else if (selectedTransactionIndex >= 0) {
        newTransactions[selectedTransactionIndex] = selectedTransaction;
      }
    }

    if (onCreateOrUpdate) {
      onCreateOrUpdate({
        ...event,
        sensitiveInformation: {
          ...sensitiveInformation,
          transactions: newTransactions,
        },
      });
      onClearSelected();
    }
  };

  /// //////////
  // Dialog actions
  const isEditing = () =>
    transactions.find(
      (transaction) => transaction.id === selectedTransaction?.id
    );

  const getEditActions = () => (
    <>
      <GreenRoomButton type="destroy" onClick={onDelete}>
        Delete
      </GreenRoomButton>
      <GreenRoomButton type="edit" onClick={onSave}>
        Update
      </GreenRoomButton>
    </>
  );

  const getAddActions = () => (
    <>
      <GreenRoomButton type="cancel" onClick={onClearSelected}>
        Cancel
      </GreenRoomButton>
      <GreenRoomButton type="create" onClick={onSave}>
        Add
      </GreenRoomButton>
    </>
  );

  // TODO: Duplicated on EventRoleCards
  let blankslateActions = null;
  if (!roles || !persons) {
    blankslateActions = (
      <b>To begin adding, select a project on the info tab.</b>
    );
  }

  /// //////////
  // Return main content
  return (
    <Stack spacing={2}>
      {!transactions.length && (
        <NoEventFinancesBlankslate actions={blankslateActions} />
      )}
      {!!transactions.length && (
        <GreenRoomStack>
          {transactions.map((transaction) => (
            <EventTransactionCard
              key={transaction.id}
              transaction={transaction}
              paymentTypes={paymentTypes}
              onClick={() => setSelectedTransaction(transaction)}
              title={getCardTitle(
                getTransactor({
                  event,
                  transaction,
                  teamMembers: persons,
                  roles,
                }),
                transaction.type
              )}
            />
          ))}
        </GreenRoomStack>
      )}
      {onCreateOrUpdate && roles && persons && (
        <div className="event-role-type-select-and-add-button">
          <GreenRoomButton
            type="add"
            onClick={() => setSelectedTransaction(newTransaction)}
            className="gr-edit-event-edit-event-add-transaction-button"
          >
            Add Transaction
          </GreenRoomButton>
        </div>
      )}
      <GreenRoomDialog
        open={!!selectedTransaction?.id}
        title={isEditing() ? 'Edit Transaction' : 'Add Transaction'}
        onClose={onClearSelected}
        actions={isEditing() ? getEditActions() : getAddActions()}
      >
        <EditEventTransactionCard
          key={selectedTransaction?.id}
          transaction={selectedTransaction || newTransaction}
          transactor={getTransactor({
            event,
            transaction: selectedTransaction,
            teamMembers: persons,
            roles,
          })}
          projectId={event.projectId}
          paymentTypes={paymentTypes}
          onEditEventTransaction={setSelectedTransaction}
        />
      </GreenRoomDialog>
    </Stack>
  );
}

EventTransactionCards.defaultProps = {
  onCreateOrUpdate: null,
};

export default EventTransactionCards;
