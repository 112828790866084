/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  IconButton,
  Skeleton,
  Typography,
  Stack,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Swal from 'sweetalert2/dist/sweetalert2';
import { sec } from '../app/security';
import configData from '../config.json';
import GreenRoomButton from './GreenRoomButton';
import GreenRoomStack from './GreenRoomStack';
import {
  useCreateExternalAccountMutation,
  useDeleteExternalAccountMutation,
  useGetExternalAccountsQuery,
} from '../app/api/project-financial-account-slice';
import './ProjectFinancialAccountExternalAccounts.scss';
import GreenRoomCard from './GreenRoomCard';
import Subheading from './Text/Subheading';

interface ProjectFinancialAccountExternalAccountProps {
  projectId: string;
}

interface CreateExternalAccountSessionResponse {
  clientSecret: string;
}

interface FinancialConnectionsSession {
  accounts: any[];
}

function getInitialsForBankName(bankName: string) {
  return bankName
    .toUpperCase()
    .split(' ')
    .map((word) => word[0])
    .join('')
    .substring(0, 2);
}

function ProjectFinancialAccountExternalAccounts({
  projectId,
}: ProjectFinancialAccountExternalAccountProps) {
  const stripe = useStripe();
  const elements = useElements();

  const getExternalAccounts = useGetExternalAccountsQuery(projectId);
  const [createExternalAccountMutation, createExternalAccountMutationStatus] =
    useCreateExternalAccountMutation();
  const [deleteExternalAccountMutation, deleteExternalAccountMutationStatus] =
    useDeleteExternalAccountMutation();

  const [setupInProgress, setSetupInProgress] = useState(false);

  function showAddingExternalAccountError() {
    Swal.fire({
      icon: 'error',
      title: 'Error Occurred',
      text: 'There was a problem adding your external account. Please try again later. If the problem persists, please contact us at support@greenroom.app',
    });
  }

  async function setupFinancialConnection() {
    setSetupInProgress(true);
    const token = await sec.getAccessTokenSilently()();
    fetch(
      `${configData.apiDomain}/projects/${projectId}/financial_account/external_accounts/session`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ redirectUrl: window.location.href }),
      }
    )
      .then(
        async (response) =>
          (await response.json()) as CreateExternalAccountSessionResponse
      )
      .then(async (response) => {
        const financialConnectionsSessionResult =
          await stripe?.collectFinancialConnectionsAccounts({
            clientSecret: response.clientSecret,
          });
        if (financialConnectionsSessionResult) {
          createExternalAccountMutation({
            projectId,
            payload: {
              financialConnectionSessionId:
                financialConnectionsSessionResult.financialConnectionsSession
                  ?.id!,
            },
          });
        } else {
          setSetupInProgress(false);
        }
      })
      .catch(() => {
        showAddingExternalAccountError();
        setSetupInProgress(false);
      });
  }

  useEffect(() => {
    if (createExternalAccountMutationStatus.isError) {
      const error =
        createExternalAccountMutationStatus.error as FetchBaseQueryError;
      if (error.status !== 404) {
        showAddingExternalAccountError();
      }
    }
    setSetupInProgress(false);
  }, [
    createExternalAccountMutationStatus.isSuccess,
    createExternalAccountMutationStatus.isError,
  ]);

  return (
    <>
      <Subheading>External Accounts</Subheading>
      <Stack spacing={2}>
        {getExternalAccounts.isLoading && (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={48}
            sx={{ marginTop: '8px', marginBottom: '8px' }}
          />
        )}
        {getExternalAccounts.isSuccess && (
          <GreenRoomStack>
            {getExternalAccounts.data?.map((externalAccount) => (
              <GreenRoomCard
                key={externalAccount.id}
                title={externalAccount.bankName}
                subtitle={`Account Number ••••${externalAccount.last4}`}
                left={
                  <Avatar>
                    {getInitialsForBankName(externalAccount.bankName)}
                  </Avatar>
                }
                onRemove={() => {
                  Swal.fire({
                    title: `Remove ${externalAccount.bankName} (${externalAccount.last4})?`,
                    text: `Are you sure you want to remove this account?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, remove!',
                    cancelButtonText: 'No, keep it',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteExternalAccountMutation({
                        projectId,
                        id: externalAccount.id,
                      });
                    }
                  });
                }}
              />
            ))}
          </GreenRoomStack>
        )}
        <GreenRoomButton
          type="add"
          onClick={async () => setupFinancialConnection()}
          disabled={setupInProgress}
        >
          Add External Account
        </GreenRoomButton>
      </Stack>
    </>
  );
}

export default ProjectFinancialAccountExternalAccounts;
