import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import {
  Project,
  useCreateProjectMutation,
} from '../app/api/projects-api-slice';
import GreenRoomButton from '../components/GreenRoomButton';
import ProjectInfoEdit from '../components/ProjectInfoEdit';

export interface OnboardingPageCreateProjectProps {
  onBack: () => void;
  // eslint-disable-next-line no-unused-vars
  onNext: (project: Project) => void;
  nextLabel?: string;
}

function OnboardingPageCreateProject({
  onBack,
  onNext,
  nextLabel,
}: OnboardingPageCreateProjectProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [project, setProject] = useState({ name: '' } as Project);
  const [createProjectMutation, createProjectMutationStatus] =
    useCreateProjectMutation();

  useEffect(() => {
    if (createProjectMutationStatus.isSuccess) {
      onNext({ ...project, id: createProjectMutationStatus.data });
    } else if (createProjectMutationStatus.isError) {
      enqueueSnackbar('There was a problem saving your info.', {
        variant: 'error',
      });
    }
  }, [createProjectMutationStatus]);

  return (
    <>
      <DialogTitle>What&apos;s your project&apos;s name?</DialogTitle>
      <DialogContent>
        <div>
          A project is a group of team members and the roles they fill in order
          to perform. You’ll need to create at least one project to create
          events.
        </div>
        <div className="onboarding-content">
          <ProjectInfoEdit project={project} onChange={(p) => setProject(p)} />
        </div>
      </DialogContent>
      <DialogActions>
        <GreenRoomButton
          type="cancel"
          onClick={() => {
            onBack();
          }}
        >
          Cancel
        </GreenRoomButton>
        <GreenRoomButton
          type="accept"
          onClick={() => {
            createProjectMutation({
              name: project.name,
            });
          }}
        >
          {nextLabel}
        </GreenRoomButton>
      </DialogActions>
    </>
  );
}

OnboardingPageCreateProject.defaultProps = {
  nextLabel: 'Next',
};

export default OnboardingPageCreateProject;
