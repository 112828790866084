import moment from 'moment';
import {
  Event,
  EventPerson,
  Transaction,
  EventLink,
  EventStatus,
} from '../api/events-api-slice';

export interface EditSensitiveInformation {
  transactions: Transaction[];
  sendEmail: boolean;
  sendICS: boolean;
  privateNotes: string | null;
}

export interface EditEvent {
  id: string | null;
  projectId: string | null;
  startTime: string | null;
  endTime: string | null;
  arrivalTime: string | null;
  arrivalNotes: string | null;
  departureTime: string | null;
  departureNotes: string | null;
  soundCheckTime: string | null;
  soundCheckNotes: string | null;
  generalNotes: string | null;
  locationContactName: string | null;
  locationContactEmail: string | null;
  locationContactPhone: string | null;
  sensitiveInformation: EditSensitiveInformation;
  eventPersons: EventPerson[];
  links: EventLink[];
  labels: string[] | null;
  status: EventStatus;
  files: string[];
}

function convertToLocalTime(
  date: string | null | undefined,
  timezone: string
): string | null {
  if (!date) {
    return null;
  }
  return moment.tz(date, timezone).format('YYYY-MM-DDTHH:mm:ss');
}

export function ToEditEvent(event: Event | undefined) {
  if (!event) {
    return undefined;
  }
  return {
    id: event.id,
    projectId: event.projectId,
    startTime: convertToLocalTime(event.startTime, event.locationTimeZone),
    endTime: convertToLocalTime(event.endTime, event.locationTimeZone),
    locationContactName: event.locationContactName,
    locationContactEmail: event.locationContactEmail,
    locationContactPhone: event.locationContactPhone,
    arrivalTime: convertToLocalTime(event.arrivalTime, event.locationTimeZone),
    arrivalNotes: event.arrivalNotes || '',
    departureTime: convertToLocalTime(
      event.departureTime,
      event.locationTimeZone
    ),
    departureNotes: event.departureNotes || '',
    soundCheckTime: convertToLocalTime(
      event.soundCheckTime,
      event.locationTimeZone
    ),
    soundCheckNotes: event.soundCheckNotes || '',
    generalNotes: event.generalNotes || '',
    sensitiveInformation: event.sensitiveInformation
      ? {
          transactions: event.sensitiveInformation!.transactions,
          sendEmail: event.sensitiveInformation!.sendEmail,
          sendICS: event.sensitiveInformation!.sendICS,
          privateNotes: event.sensitiveInformation!.privateNotes,
        }
      : null,
    eventPersons: event.eventPersons.map((ep) => ({
      id: ep.id,
      personId: ep.personId,
      eventRoleTypeId: ep.eventRoleTypeId,
      isAttending: ep.isAttending,
      sendEmail: ep.sendEmail,
      sendText: ep.sendText,
    })),
    links: event.links,
    labels: event.labels,
    status: event.status,
    files: event.files.map((f) => f.id),
  } as EditEvent;
}

export interface TeamMember {
  id?: string | null;
  name: string;
  projectId?: string;
  inputValue?: string;
  defaultSendEmail?: boolean;
  defaultSendText?: boolean;
}

export interface Role {
  id?: string | null;
  name: string;
  projectId?: string;
  inputValue?: string;
}
