import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  TextField,
  Autocomplete,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2';
import {
  useCreatePersonMutation,
  useDeletePersonMutation,
  useUpdatePersonMutation,
  useGetPersonsForAllProjectsQuery,
} from '../app/api/persons-api-slice';
import GreenRoomButton from '../components/GreenRoomButton';
import ServerErrorDisplay from '../components/ServerErrorDisplay';
import './CreateEditProjectMemberDialog.scss';
import Subheading from '../components/Text/Subheading';
import EditPhoneNumber from '../components/EditPhoneNumber';
import { EditPerson, getSMSDisabled } from '../app/helpers/contact-helpers';
import GreenRoomTooltip from '../components/GreenRoomTooltip';
import SMSStatus from '../components/SMSStatus';
import SMSHelperText from '../components/SMSHelperText';
import EmailHelperText from '../components/EmailHelperText';
import { useGetProjectEntitlementsQuery } from '../app/api/projects-api-slice';
import PremiumFeatureChip from '../components/PremiumFeatureChip';

export interface CreateEditProjectMemberDialogProps {
  person: EditPerson;
  open: boolean;
  fullScreen: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose: (id: string | null) => void;
}

// TODO: We are currently deduping the autocomplete options list by name only. In the future, we should probably dedupe by name and email/phone number.
// The only downfall to the current system is that if there were two "Phil"'s across projects, only one would appear in the dropdown, and when selected, the email that is autopopulated would belong to Phil A without Phil B's email being offered

function CreateEditProjectMemberDialog({
  person,
  open,
  fullScreen,
  onClose,
}: CreateEditProjectMemberDialogProps) {
  const getProjectEntitlements = useGetProjectEntitlementsQuery(
    person.projectId
  );

  // Fetch data to allow user to create new from existing project members
  const getPersonsForAllProjects = useGetPersonsForAllProjectsQuery();

  // Keep track of when an autocomplete option is highlighted in order to gate form submission on enter
  const [optionHighlighted, setOptionHighlighted] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  // Get dropdown options
  const getUniquePersonsInOtherProjects = () => {
    const dedupedRolesinAllProjects =
      getPersonsForAllProjects?.data?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.name === value.name)
      ) || [];
    const personsInThisProject =
      getPersonsForAllProjects?.data?.filter(
        (p: EditPerson) => p.projectId === person?.projectId
      ) || [];

    // We want to provide autocomplete for members not associated with this project already
    return dedupedRolesinAllProjects?.filter(
      (a) => !personsInThisProject.some((b) => a.name === b.name)
    );
  };

  // Selected person state management
  const [editPerson, setEditPerson] = useState(person);
  useEffect(() => {
    if (person.id !== editPerson.id) {
      setEditPerson(person);
    } else if (person) {
      setEditPerson({
        ...editPerson,
        phoneNumberStatus: person.phoneNumberStatus,
        phoneNumberOptInLastRequestedAt: person.phoneNumberOptInLastRequestedAt,
      });
    }
  }, [person]);

  const [uniquePersonsInOtherProjects, setUniquePersonsInOtherProjects] =
    useState(getUniquePersonsInOtherProjects());

  useEffect(() => {
    setUniquePersonsInOtherProjects(getUniquePersonsInOtherProjects());
  }, [editPerson]);

  // Create, update, delete
  const [createPersonMutation, createPersonMutationStatus] =
    useCreatePersonMutation();
  const [updatePersonMutation, updatePersonMutationStatus] =
    useUpdatePersonMutation();
  const [deletePersonMutation, deletePersonMutationStatus] =
    useDeletePersonMutation();

  useEffect(() => {
    if (createPersonMutationStatus.isSuccess) {
      onClose(createPersonMutationStatus.data);
    } else if (updatePersonMutationStatus.isSuccess) {
      onClose(updatePersonMutationStatus.data);
    } else if (deletePersonMutationStatus.isSuccess) {
      onClose(null);
    } else if (
      createPersonMutationStatus.isError ||
      updatePersonMutationStatus.isError ||
      deletePersonMutationStatus.isError
    ) {
      const error =
        createPersonMutationStatus.error ||
        updatePersonMutationStatus.error ||
        deletePersonMutationStatus.error;
      const errorDisplay = (
        <ServerErrorDisplay
          error={error!}
          errorMessage="Unable to save changes."
        />
      );
      enqueueSnackbar(errorDisplay, {
        variant: 'error',
      });
    }
  }, [
    createPersonMutationStatus,
    updatePersonMutationStatus,
    deletePersonMutationStatus,
  ]);

  const mode = person.id ? 'Edit' : 'Add';

  const handleSave = () => {
    if (editPerson.id) {
      updatePersonMutation({
        id: editPerson.id!,
        body: {
          name: editPerson.name,
          email: editPerson.email,
          phoneNumber: editPerson.phoneNumber,
          defaultSendEmail: editPerson.defaultSendEmail,
          defaultSendText: editPerson.defaultSendText,
        },
      });
    } else {
      createPersonMutation({
        name: editPerson.name,
        email: editPerson.email,
        phoneNumber: editPerson.phoneNumber,
        projectId: editPerson.projectId,
        defaultSendEmail: editPerson.defaultSendEmail,
        defaultSendText: editPerson.defaultSendText,
      });
    }
  };

  const newPhoneNumber =
    !!person.phoneNumber && person.phoneNumber !== editPerson.phoneNumber;

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle>{mode} Project Member</DialogTitle>
      <DialogContent>
        <div className="edit-person-content">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Subheading>Contact Information</Subheading>
              <Autocomplete
                freeSolo
                options={
                  (person.id && []) ||
                  uniquePersonsInOtherProjects.map((p: EditPerson) => p?.name)
                }
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    required
                    label="Name"
                    onChange={(e) =>
                      setEditPerson({ ...editPerson, name: e.target.value })
                    }
                    onKeyDown={(e) => {
                      // If an item in the autocomplete dropdown is highlighted, the enter key is used to select the option
                      // If an item in the autocomplete dropdown is not highlighted, the enter key is used to submit the form
                      if (!optionHighlighted && e.key === 'Enter') {
                        handleSave();
                      }
                    }}
                  />
                )}
                onChange={(e, value, reason) => {
                  const newContact = uniquePersonsInOtherProjects?.find(
                    (p) => p.name === value
                  );

                  if (reason === 'selectOption') {
                    setEditPerson({
                      ...newContact,
                      id: null,
                      name: newContact?.name || '',
                      email: newContact?.email || null,
                      phoneNumber: newContact?.phoneNumber || null,
                      projectId: person.projectId,
                      defaultSendEmail: newContact?.defaultSendEmail || false,
                      defaultSendText: newContact?.defaultSendText || false,
                      phoneNumberStatus: newContact?.phoneNumberStatus || null,
                      phoneNumberOptInLastRequestedAt:
                        newContact?.phoneNumberOptInLastRequestedAt || null,
                    });
                  }
                }}
                fullWidth
                value={editPerson.name || ''}
                onHighlightChange={(e) => e && setOptionHighlighted(true)}
                onClose={() => setOptionHighlighted(false)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                fullWidth
                value={editPerson.email || ''}
                onChange={(e) =>
                  setEditPerson({ ...editPerson, email: e.target.value })
                }
                onKeyUp={(e) => {
                  // Enter key submits form
                  if (e.key === 'Enter') {
                    handleSave();
                  }
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <EditPhoneNumber
                label="Phone"
                value={editPerson.phoneNumber || ''}
                onChange={(newValue) => {
                  setEditPerson({
                    ...editPerson,
                    phoneNumber: newValue,
                  });
                }}
                disabled={!getProjectEntitlements.data?.sms}
              />

              {!getProjectEntitlements.data?.sms && (
                <PremiumFeatureChip
                  size="small"
                  projectId={person.projectId}
                  margin="top"
                />
              )}

              {getProjectEntitlements.data?.sms && (
                <div className="create-edit-project-member-dialogue-tooltip-wrapper">
                  <SMSStatus
                    editPerson={editPerson}
                    newPhoneNumber={newPhoneNumber}
                  />
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              <Subheading>Default Notification Settings</Subheading>
              <div className="create-edit-project-member-dialogue-tooltip-wrapper">
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(_, checked) => {
                        setEditPerson({
                          ...editPerson,
                          defaultSendEmail: checked,
                        });
                      }}
                      checked={editPerson.defaultSendEmail}
                      disabled={!editPerson.email}
                    />
                  }
                  label="Send email updates by default"
                />
                <GreenRoomTooltip title="This person will have email updates selected on events, by default. You can unselect them for these notifications on every update." />
              </div>
              <EmailHelperText editPerson={editPerson} />
            </Grid>

            <Grid item xs={12}>
              <div className="create-edit-project-member-dialogue-tooltip-wrapper">
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(_, checked) => {
                        setEditPerson({
                          ...editPerson,
                          defaultSendText: checked,
                        });
                      }}
                      checked={editPerson.defaultSendText}
                      disabled={getSMSDisabled(
                        editPerson,
                        editPerson.defaultSendText,
                        newPhoneNumber,
                        !!getProjectEntitlements.data?.sms
                      )}
                    />
                  }
                  label="Send text updates by default"
                />
                {!getProjectEntitlements.data?.sms && (
                  <PremiumFeatureChip
                    size="small"
                    projectId={person.projectId}
                  />
                )}
                {getProjectEntitlements.data?.sms && (
                  <GreenRoomTooltip title="This person will have SMS updates selected on events, by default. You can unselect them for these notifications on every update." />
                )}
              </div>
              {getProjectEntitlements.data?.sms && (
                <SMSHelperText
                  editPerson={editPerson}
                  newPhoneNumber={newPhoneNumber}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        {mode === 'Edit' && (
          <GreenRoomButton
            type="destroy"
            onClick={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete them!',
                cancelButtonText: 'No, keep them',
              }).then((result) => {
                if (result.isConfirmed) {
                  deletePersonMutation(editPerson.id!);
                }
              });
            }}
          >
            Delete
          </GreenRoomButton>
        )}
        <GreenRoomButton
          type="cancel"
          onClick={() => {
            onClose(null);
          }}
        >
          Cancel
        </GreenRoomButton>
        <GreenRoomButton
          type="accept"
          onClick={handleSave}
          disabled={!editPerson.name}
        >
          Save
        </GreenRoomButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateEditProjectMemberDialog;
