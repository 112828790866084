import emptySplitApi from './empty-api-slice';
import { EventSummary } from './events-api-slice';

interface ProjectTransactionsSummary {
  income: number;
  expenses: number;
  pendingIncome: number;
  pendingExpenses: number;
}

interface ProjectTransactionsSummaryRequest {
  projectId: string;
  from?: string;
  to?: string;
  includeTransactionsWithoutDates?: boolean; // TODO: integrate this into UI
}

export interface ProjectTransaction {
  id: string;
  event?: EventSummary;
  type: 'Income' | 'Expense';
  transactor: string | null;
  transactorPersonId?: string | null;
  description: string;
  amount: number;
  isPaid: boolean;
  paidDate: string | null;
  paymentTypeId: string | null;
  excludeFromReports: boolean;
  stripeTransactionId: string | null;
}

interface GetProjectTransactionsResponse {
  totalCount: number;
  transactions: ProjectTransaction[];
}

export interface GetProjectTransactionsRequest {
  projectId: string;
  eventId?: string;
  from?: string | null;
  to?: string | null;
  includeTransactionsWithoutDates?: boolean;
  offset: number;
  limit: number;
  sort?: 'Date' | 'Amount';
  sortDirection?: 'Desc' | 'Asc';
  type?: 'any' | 'Expense' | 'Income';
  isPaid?: 'any' | 'true' | 'false';
  paymentTypeIds?: string | null;
}

function getParameters(request: GetProjectTransactionsRequest) {
  const optionalParams = [
    { eventId: request.eventId },
    { sort: request.sort },
    { sortDirection: request.sortDirection },
    { from: request.from },
    { to: request.to },
    { type: request.type },
    { isPaid: request.isPaid },
    { paymentTypeIds: request.paymentTypeIds },
  ];

  let url = `projectId=${request.projectId}&includeTransactionsWithoutDates=false`;
  // Append optional params to url
  optionalParams.forEach((param) => {
    const key = Object.keys(param)[0];
    const value = Object.values(param)[0];

    if (value) {
      url += `&${key}=${value}`;
    }
  });

  return url;
}

export const projectsApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjectTransactionsSummary: builder.query<
      ProjectTransactionsSummary,
      ProjectTransactionsSummaryRequest
    >({
      query: ({
        projectId,
        from,
        to,
        includeTransactionsWithoutDates = false,
      }) =>
        `/transactions/summary?projectId=${projectId}&from=${from}&to=${to}&includeTransactionsWithoutDates=${includeTransactionsWithoutDates}`,
    }),
    getProjectTransactions: builder.query<
      GetProjectTransactionsResponse,
      GetProjectTransactionsRequest
    >({
      query: (request) => {
        let url = `/transactions?`;

        url += getParameters(request);
        // Append required params to url
        url += `&offset=${request.offset}&limit=${request.limit}`;
        return url;
      },
    }),
    exportProjectTransactions: builder.mutation<
      void,
      GetProjectTransactionsRequest
    >({
      query: (request) => {
        let url = `/transactions/export?`;

        url += getParameters(request);
        return { url, method: 'POST' };
      },
    }),
  }),
});

export const {
  useGetProjectTransactionsSummaryQuery,
  useGetProjectTransactionsQuery,
  useExportProjectTransactionsMutation,
} = projectsApiSlice;
