import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ListIcon from '@mui/icons-material/List';
import { useSearchParams } from 'react-router-dom';
import Blankslate from '../../components/Blankslate';
import NoProjectTransactionsBlankslate from '../../components/Blankslates/NoProjectTransactionsBlankslate';
import StatefulTabs, {
  getSelectedTabLabel,
} from '../../components/Tabs/StatefulTabs';
import TabContent from '../../components/Tabs/TabContent';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import GreenRoomButton from '../../components/GreenRoomButton';
import getProjectCrumb from '../../app/helpers/get-project-crumb';
import useGetAuthorizedProjectsQuery from '../../app/hooks/use-get-authorized-projects-query';
import FinancesFinancialAccountTab from './FinancesFinancialAccountTab';
import OnboardProject from '../OnboardProject';
import FinancesHome from './FinancesHome';
import { useGetFeatureFlagsQuery } from '../../app/api/feature-flags-slice';
import { ReactComponent as Armchair } from '../../img/placeholders/armchair.svg';
import { useGetProjectQuery } from '../../app/api/projects-api-slice';
import setTitle from '../../app/helpers/document-helpers';
import FinancesCards from './FinancesCards';
import FinancesTransactions from './FinancesTransactions';
import { useGetPaymentTypesQuery } from '../../app/api/payment-types-api-slice';
import { useGetProjectFinancialAccountQuery } from '../../app/api/project-financial-account-slice';

function FinancesPage() {
  /// //////////
  // Navigation
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const projectId = searchParams.get('project');
  const [projectOnboarding, setProjectOnboarding] = useState(false);

  const { user } = useAuth0();

  const tabs = [];

  const getFeatureFlags = useGetFeatureFlagsQuery();

  const getProject = useGetProjectQuery(projectId!, { skip: !projectId });

  /// //////////
  // Get authorized projects
  const { data: projects = [], isLoading: projectsIsLoading } =
    useGetAuthorizedProjectsQuery();

  const getProjectFinancialAccountQuery = useGetProjectFinancialAccountQuery(
    projectId!,
    { skip: !projectId }
  );

  /// //////////
  // Fetch payment types
  const getPaymentTypes = useGetPaymentTypesQuery(
    {
      projectId: projectId!,
    },
    { skip: !projectId }
  );

  /// //////////
  // Set project
  useEffect(() => {
    if (
      !projectId &&
      user &&
      (projects.length || getFeatureFlags.data?.personalProjectEnabled)
    ) {
      const lastProjectId = localStorage.getItem(`${user?.sub}_lastProjectId`);
      searchParams.set(
        'project',
        lastProjectId || projects[0]?.id || 'personal'
      );
      setSearchParams(searchParams, { replace: true });
    }
    if (projectId) {
      localStorage.setItem(`${user?.sub}_lastProjectId`, projectId);
    }
  }, [projectId, projectOnboarding]);

  if (
    projectsIsLoading ||
    getProject.isFetching ||
    getFeatureFlags.isFetching ||
    getPaymentTypes.isFetching ||
    getProjectFinancialAccountQuery.isFetching
  )
    return <Loading />;

  const showFinancialAccountTabs =
    getFeatureFlags.data?.financialAccountsEnabled &&
    getProject.data?.role === 'Owner' &&
    getProjectFinancialAccountQuery.isSuccess &&
    getProjectFinancialAccountQuery.data.enabled;

  /// //////////
  // Set tabs
  if (showFinancialAccountTabs) {
    tabs.push({
      label: 'Account',
      value: 'account',
      icon: <AccountBalanceIcon />,
      beta: true,
    });
  }

  if (projectId !== 'personal') {
    tabs.push(
      {
        label: 'Dashboard',
        value: 'dashboard',
        icon: <AutoAwesomeMosaicIcon />,
      },
      {
        label: 'Transactions',
        value: 'transactions',
        icon: <ListIcon />,
      }
    );
  }

  if (showFinancialAccountTabs) {
    tabs.push({
      label: 'Cards',
      value: 'cards',
      icon: <CreditCardIcon />,
      beta: true,
    });
  }

  /// //////////
  // Get breadcrumbs
  const getBreacrumbs = () => {
    const onChangeProjectCrumb = (id: any) => {
      if (id) {
        searchParams.set('project', id);
        setSearchParams(searchParams, { replace: true });
      }
    };

    const projectCrumb = getProjectCrumb(
      projectId,
      projects,
      onChangeProjectCrumb,
      '/finances',
      getFeatureFlags.data?.personalProjectEnabled === true
    );

    const crumbs = [
      {
        name: 'Finances',
      },
    ];

    if (projectCrumb) crumbs.push(...[projectCrumb]);

    return <Breadcrumbs crumbs={crumbs} />;
  };

  let mainContent;

  /// //////////
  // Render loading state
  if (projectsIsLoading) {
    mainContent = <Loading />;
  } else if (
    !projects.length &&
    !getFeatureFlags.data?.personalProjectEnabled
  ) {
    if (tab === 'account') {
      mainContent = (
        <Blankslate
          title="No project exists"
          svg={<Armchair />}
          actions={
            <GreenRoomButton
              type="create"
              onClick={() => setProjectOnboarding(true)}
            >
              Create New Project
            </GreenRoomButton>
          }
        >
          <span>
            Projects are groups of people of any size that perform. You can open
            a <b>Financial Account</b> for projects here to accept payments,
            issue and manage business expense cards, and report on transactions.
            Create a project to get started!
          </span>
        </Blankslate>
      );
    } else {
      mainContent = (
        <Blankslate
          title="No project exists"
          svg={<Armchair />}
          actions={
            <GreenRoomButton
              type="create"
              onClick={() => setProjectOnboarding(true)}
            >
              Create New Project
            </GreenRoomButton>
          }
        >
          <span>
            To get started tracking finances in Green Room, create a project. A
            project is a group of people of any size that perform.{' '}
            <a
              href="https://help.greenroom.app/how-do-i-track-my-finances-in-green-room"
              target="_blank"
              rel="noreferrer"
            >
              Learn more here
            </a>
            .
          </span>
        </Blankslate>
      );
    }
  } else if (projectId) {
    /// //////////
    // Render main content
    if (tab === 'dashboard')
      mainContent = <FinancesHome projectId={projectId} />;
    if (tab === 'account')
      mainContent = <FinancesFinancialAccountTab projectId={projectId!} />;
    if (tab === 'transactions') mainContent = null;
    if (tab === 'cards') mainContent = <FinancesCards projectId={projectId} />;
    if (tab === 'transactions') {
      mainContent = (
        <FinancesTransactions
          projectId={projectId}
          paymentTypes={getPaymentTypes.data}
          blankslate={<NoProjectTransactionsBlankslate />}
        />
      );
    }
  }

  setTitle('Finances', getSelectedTabLabel(tabs, tab!));

  return (
    <>
      {getBreacrumbs()}
      {tabs && (
        <StatefulTabs
          tabs={tabs}
          onlyPreserveTheseParamsOnTabChange={['project']}
        />
      )}
      <TabContent>{mainContent}</TabContent>
      {projectOnboarding && (
        <OnboardProject
          onboardingComplete={() => setProjectOnboarding(false)}
        />
      )}
    </>
  );
}

export default FinancesPage;
