import React, { useEffect } from 'react';
import { Avatar, Button, useMediaQuery, useTheme } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSnackbar } from 'notistack';
import { useAuth0 } from '@auth0/auth0-react';
import Swal from 'sweetalert2/dist/sweetalert2';
import {
  useDeleteUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
} from '../app/api/user-api-slice';
import Loading from '../components/Loading';
import UserInfoEdit from '../components/UserInfoEdit';
import Subheading from '../components/Text/Subheading';
import TabContent from '../components/Tabs/TabContent';
import GreenRoomButton from '../components/GreenRoomButton';
import ServerErrorDisplay from '../components/ServerErrorDisplay';
import GreenRoomCard from '../components/GreenRoomCard';

function SettingsPageUserInfo() {
  const { enqueueSnackbar } = useSnackbar();

  const auth0 = useAuth0();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getUser = useGetUserQuery();
  const [updateUserMutation, updateUserMutationStatus] =
    useUpdateUserMutation();
  const [deleteUserMutation, deleteUserMutationStatus] =
    useDeleteUserMutation();

  useEffect(() => {
    if (updateUserMutationStatus.isSuccess) {
      enqueueSnackbar('Your info has been saved!', { variant: 'success' });
    } else if (updateUserMutationStatus.isError) {
      enqueueSnackbar('There was a problem saving your info.', {
        variant: 'error',
      });
    }
  }, [updateUserMutationStatus]);

  useEffect(() => {
    if (deleteUserMutationStatus.isSuccess) {
      Swal.fire('Deleted!', 'Your account has been deleted.', 'success').then(
        () => auth0.logout()
      );
    } else if (deleteUserMutationStatus.isError) {
      const errorDisplay = (
        <ServerErrorDisplay
          error={deleteUserMutationStatus.error}
          errorMessage="There was a problem deleting your account."
        />
      );
      enqueueSnackbar(errorDisplay, {
        variant: 'error',
      });
    }
  });

  const [user, setUser] = React.useState(getUser.data);

  if (getUser.isLoading) {
    return <Loading />;
  }

  if (getUser.isError) {
    return (
      <div>There was a problem getting account info. Please try again.</div>
    );
  }

  const handleDeleteAccount = () => {
    Swal.fire({
      title: 'Delete Account?',
      html:
        '<p>This will delete your user account. All projects and associated data will be deleted.</p>' +
        '<p> <b>This action cannot be undone.</b> </p>' +
        "<p>Type 'DELETE' below to confirm and proceed.</p>",
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Delete',
      icon: 'warning',
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value === 'DELETE') {
          deleteUserMutation();
        } else {
          Swal.fire('Canceled', 'Your account has not been deleted.', 'error');
        }
      }
    });
  };

  const submissionDisabled =
    !user?.legalFirstName || !user?.legalLastName || !user?.preferredName;

  return (
    <TabContent
      actions={
        <>
          <GreenRoomButton
            type="accept"
            onClick={() =>
              updateUserMutation({
                user: {
                  legalFirstName: user?.legalFirstName!,
                  legalLastName: user?.legalLastName!,
                  preferredName: user?.preferredName!,
                  phoneNumber: user?.phoneNumber!,
                  zipCode: user?.zipCode!,
                  timeZone: user?.timeZone || null,
                  betaAccess: user?.betaAccess || false,
                },
                initialSignup: false,
              })
            }
            disabled={submissionDisabled}
          >
            Save Changes
          </GreenRoomButton>
          <GreenRoomButton type="destroy" onClick={handleDeleteAccount}>
            Delete Account
          </GreenRoomButton>
        </>
      }
    >
      {isSmallScreen && (
        <div style={{ marginBottom: '12px' }}>
          <GreenRoomCard
            left={<Avatar src={auth0.user?.picture} sx={{ height: '100%' }} />}
            title="Logged in as"
          >
            <div>
              <div>{user?.email}</div>
              <Button startIcon={<LogoutIcon />} onClick={() => auth0.logout()}>
                Log Out
              </Button>
            </div>
          </GreenRoomCard>
        </div>
      )}
      <Subheading>Personal Info</Subheading>
      <UserInfoEdit user={user!} onChange={(u) => setUser(u)} />
    </TabContent>
  );
}

export default SettingsPageUserInfo;
