import React from 'react';
import './Text.scss';

interface SubheadingProps {
  children?: React.ReactNode | null;
  className?: string | null;
}

function Subheading({ children, className }: SubheadingProps) {
  return <h3 className={`green-room-subheading ${className}`}>{children}</h3>;
}

Subheading.defaultProps = {
  children: null,
  className: '',
};

export default Subheading;
