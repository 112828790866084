import React from 'react';
import { Stack } from '@mui/material';
import { EventLink } from '../app/api/events-api-slice';
import NoEventLinksBlankSlate from '../components/Blankslates/NoEventLinksBlankslate';
import Subheading from '../components/Text/Subheading';

interface ViewEventPageResourcesTabProps {
  links?: EventLink[];
}

function ViewEventPageLinksTab({ links }: ViewEventPageResourcesTabProps) {
  return (
    (!links?.length && <NoEventLinksBlankSlate />) || (
      <>
        <Subheading>Links</Subheading>
        <Stack spacing={2}>
          {links?.map((link) => (
            <a key={link.id} href={link.url} target="_blank" rel="noreferrer">
              {link.label || link.url}
            </a>
          ))}
        </Stack>
      </>
    )
  );
}

ViewEventPageLinksTab.defaultProps = {
  links: [],
};

export default ViewEventPageLinksTab;
