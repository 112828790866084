import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import './OnboardingPage.scss';
import OnboardingPageUserInfo from './OnboardingPageUserInfo';

export interface OnboardUserProps {
  onboardingComplete: () => void;
}

function OnboardUser({ onboardingComplete }: OnboardUserProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open fullScreen={fullScreen}>
      <OnboardingPageUserInfo onSave={onboardingComplete} />
    </Dialog>
  );
}

export default OnboardUser;
