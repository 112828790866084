import React from 'react';
import { Stack, Divider } from '@mui/material';

import { useGetProjectFinancialAccountQuery } from '../app/api/project-financial-account-slice';
import TabContent from './Tabs/TabContent';
import ProjectFinancialAccountExternalAccounts from './ProjectFinancialAccountExternalAccounts';
import ProjectFinancialAccountInfo from './ProjectFinancialAccountInfo';
import ProjectFinancialAccountSetup from './ProjectFinancialAccountSetup';

export interface SetupProjectFinancialAccountResponse {
  redirectUrl: string;
}

interface ProjectAdminsProps {
  projectId: string;
}

function ProjectAdmins({ projectId }: ProjectAdminsProps) {
  const getProjectFinancialAccountQuery =
    useGetProjectFinancialAccountQuery(projectId);

  return (
    <TabContent>
      <Stack spacing={3} sx={{ height: '100%' }}>
        {getProjectFinancialAccountQuery.isSuccess &&
          getProjectFinancialAccountQuery.data.enabled && (
            <div>
              <ProjectFinancialAccountInfo projectId={projectId} />
            </div>
          )}
        <ProjectFinancialAccountSetup projectId={projectId} />
        {/* TODO: Consider creating GreenRoomDivider component */}
        <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.12)', width: '100%' }} />
        {getProjectFinancialAccountQuery.isSuccess &&
          getProjectFinancialAccountQuery.data.enabled && (
            <div>
              <ProjectFinancialAccountExternalAccounts projectId={projectId} />
            </div>
          )}
      </Stack>
    </TabContent>
  );
}

export default ProjectAdmins;
