import { Button, IconButton, List, ListItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import ProjectFileSelectionDialog from '../../components/ProjectFileSelectionDialog';
import ProjectSelect from '../../components/ProjectSelect';
import {
  GreenRoomFile,
  useLazyGetFileQuery,
} from '../../app/api/files-api-slice';

function FileUploadExample() {
  const [projectId, setProjectId] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<GreenRoomFile[]>([]);

  const [getFile] = useLazyGetFileQuery();

  useEffect(() => {
    if (selectedFileIds.length > 0) {
      Promise.all(selectedFileIds.map((id) => getFile(id).unwrap())).then(
        (files) => setSelectedFiles(files)
      );
    } else {
      setSelectedFiles([]);
    }
  }, [selectedFileIds]);

  useEffect(() => {
    if (projectId) {
      setSelectedFileIds([]);
      setSelectedFiles([]);
    }
  }, [projectId]);

  return (
    <>
      <ProjectSelect id={projectId} onChange={setProjectId} />
      <Button disabled={!projectId} onClick={() => setOpen(true)}>
        Open Example Dialog
      </Button>
      <ProjectFileSelectionDialog
        open={open}
        selectedFileIds={selectedFileIds}
        onSelectionChange={setSelectedFileIds}
        onClose={() => setOpen(false)}
        projectId={projectId!}
      />
      <div>
        <Typography variant="h6">Selected Files</Typography>
        <List>
          {selectedFiles.map((file) => (
            <ListItem key={file.id}>
              <Typography>{file.name}</Typography>
              <IconButton color="primary" href={file.url} download>
                <DownloadIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </div>
    </>
  );
}

export default FileUploadExample;
