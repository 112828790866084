import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Button, Stack } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useGetEventRoleTypesQuery } from '../app/api/event-role-types-api-slice';
import { useGetEventQuery } from '../app/api/events-api-slice';
import { useGetPersonsQuery } from '../app/api/persons-api-slice';
import { useGetProjectQuery } from '../app/api/projects-api-slice';
import { useGetPaymentTypesQuery } from '../app/api/payment-types-api-slice';
import { LOCATION_TBD, getCityAndState } from '../app/helpers/location-helpers';
import ErrorPage from '../components/ErrorPage';
import Loading from '../components/Loading';
import configData from '../config.json';
import EventTabs, {
  EVENT_TABS,
  EVENT_TAB_VALUES,
} from '../components/Tabs/EventTabs';
import './ViewEventPage.scss';
import setTitle from '../app/helpers/document-helpers';
import { getSelectedTabLabel } from '../components/Tabs/StatefulTabs';
import TabContent from '../components/Tabs/TabContent';
import GreenRoomButton from '../components/GreenRoomButton';
import Breadcrumbs from '../components/Breadcrumbs';
import NoEventFinancesBlankslate from '../components/Blankslates/NoEventFinancesBlankslate';
import FinancesTransactions from './finances/FinancesTransactions';
import KeyValue from '../components/KeyValue';
import ViewEventPageLinksTab from './ViewEventPageLinksTab';
import Labels from '../components/Labels/Labels';
import {
  useGetBandsintownConfigurationQuery,
  useGetEventBandsintownQuery,
} from '../app/api/bandsintown-api-slice';
import EventRoleCards from '../components/EventRoleCards';
import EventStatusChip from '../components/EventStatusChip';
import { useGetFeatureFlagsQuery } from '../app/api/feature-flags-slice';
import ViewEventPageFilesTab from './ViewEventPageFilesTab';
import { ToEditEvent } from '../app/models/edit-event';

function ViewEventPage() {
  const getFeatureFlags = useGetFeatureFlagsQuery();
  const { id } = useParams();
  const getEvent = useGetEventQuery(id!);
  const getProject = useGetProjectQuery(getEvent.data?.projectId!, {
    skip: !getEvent.data?.projectId,
  });
  const getRoles = useGetEventRoleTypesQuery(
    {
      projectId: getEvent.data?.projectId!,
    },
    { skip: !getEvent.data?.projectId }
  );
  const getPersons = useGetPersonsQuery(
    {
      projectId: getEvent.data?.projectId!,
    },
    { skip: !getEvent.data?.projectId }
  );

  /// //////////
  // Fetch payment types
  const getPaymentTypes = useGetPaymentTypesQuery(
    {
      projectId: getEvent.data?.projectId!,
    },
    { skip: !getEvent.data?.projectId }
  );

  /// //////////
  // Bandsintown integration
  const bandsintownConfiguration = useGetBandsintownConfigurationQuery(
    getEvent.data?.projectId!,
    { skip: !getEvent.data?.projectId }
  );
  const bandsintownEvent = useGetEventBandsintownQuery(id!);

  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const navigate = useNavigate();

  if (
    getEvent.isFetching ||
    getProject.isFetching ||
    getRoles.isFetching ||
    getPersons.isFetching ||
    getPaymentTypes.isFetching
  ) {
    return <Loading />;
  }

  if (getEvent.isError) {
    return <ErrorPage error={getEvent.error} />;
  }

  const map =
    getEvent.data?.locationGooglePlacesId &&
    `https://www.google.com/maps/embed/v1/place?key=${configData.googleApiKey}&q=place_id:${getEvent.data?.locationGooglePlacesId}`;

  const showSensitiveInformation =
    getProject.data?.role === 'Owner' || getProject.data?.role === 'Admin';

  const heading = getEvent.data?.locationName || LOCATION_TBD;
  const showDate = moment
    .tz(getEvent.data?.startTime, getEvent.data?.locationTimeZone!)
    .format('ll');
  setTitle(
    `${getProject.data?.name} in ${getCityAndState(
      getEvent.data?.locationCity,
      getEvent.data?.locationState
    )} - ${showDate}`,
    getSelectedTabLabel(EVENT_TABS, tab!)
  );

  /// //////////
  // Format timestamps

  function getLocalizedTimestamp(value: string | null | undefined) {
    return moment.tz(value, getEvent.data?.locationTimeZone!);
  }

  function getEventTime(value: string | null | undefined) {
    return value ? getLocalizedTimestamp(value)?.format('LT') : null;
  }

  function getShowStartAndEndTime() {
    const startTime = getEventTime(getEvent.data?.startTime);
    const endTime = getLocalizedTimestamp(getEvent.data?.endTime!).format(
      'LT z'
    );

    return `${startTime}–${endTime}`;
  }

  function getShowDate() {
    return getLocalizedTimestamp(getEvent.data?.startTime)
      .toDate()
      .toLocaleDateString(undefined, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
  }

  /// //////////
  // Render event metadata

  function getKeyValues(label: string, values: any[]) {
    const nonNullValue = values.filter((value) => value);

    let emptyValue;

    // If there is no value, and user can edit, provide button to do so
    if (showSensitiveInformation) {
      emptyValue = (
        <GreenRoomButton type="add" subtle to={`/events/${id}/edit?tab=${tab}`}>
          {`Add ${label}`}
        </GreenRoomButton>
      );
    }

    const codeBlockOrButton =
      (nonNullValue.length &&
        values.map(
          (value) =>
            (value && <span key={`${label}${Math.random()}`}>{value}</span>) ||
            null
        )) ||
      emptyValue;

    return <KeyValue label={label} value={codeBlockOrButton} />;
  }

  const hasLocationMetadata =
    getEvent.data?.locationStreetAddress ||
    getEvent.data?.locationCity ||
    getEvent.data?.locationState ||
    getEvent.data?.locationZipCode;

  const getAddressButton = () =>
    (hasLocationMetadata && (
      <GreenRoomButton
        type="copy"
        subtle
        copyText={`${
          getEvent.data?.locationStreetAddress
            ? `${getEvent.data?.locationStreetAddress}, `
            : ''
        }${getCityAndState(
          getEvent.data?.locationCity,
          getEvent.data?.locationState
        )} ${
          getEvent.data?.locationZipCode ? getEvent.data?.locationZipCode : ''
        }`}
        sx={{ textTransform: 'none', marginTop: '4px' }}
      >
        <span style={{ textAlign: 'left' }}>
          {getEvent.data?.locationStreetAddress}
        </span>
        <span style={{ textAlign: 'left' }}>
          {getCityAndState(
            getEvent.data?.locationCity,
            getEvent.data?.locationState
          )}
        </span>
        <span style={{ textAlign: 'left' }}>
          {getEvent.data?.locationZipCode}
        </span>
      </GreenRoomButton>
    )) || (
      <GreenRoomButton
        sx={{ marginTop: '4px' }}
        type="add"
        subtle
        to={`/events/${id}/edit?tab=${tab}`}
      >
        {getEvent.data?.locationName ? 'Add Exact Location' : 'Add Location'}
      </GreenRoomButton>
    );

  return (
    <>
      <Breadcrumbs
        crumbs={[
          {
            name: 'Events',
            to: '/events',
          },
          {
            name: getProject.data?.name || '',
          },
          { name: heading },
        ]}
      />
      <EventTabs
        showSensitiveInformation={showSensitiveInformation}
        filesEnabled={
          !!getFeatureFlags.currentData?.filesEnabled ||
          !!getEvent.data?.files.length
        }
      />
      <TabContent
        actions={
          showSensitiveInformation && (
            <>
              <GreenRoomButton type="edit" to={`/events/${id}/edit?tab=${tab}`}>
                Edit Event
              </GreenRoomButton>
              <Button
                startIcon={<ContentCopyIcon />}
                onClick={() => {
                  navigate(`./../create?duplicateFrom=${id}`);
                }}
              >
                Duplicate
              </Button>
            </>
          )
        }
        className={
          (tab === EVENT_TAB_VALUES.FINANCES &&
            'view-event-tab-content-no-top-padding') ||
          ''
        }
      >
        {tab === EVENT_TAB_VALUES.INFO && (
          <Stack spacing={3}>
            {getKeyValues('Status', [
              <EventStatusChip status={getEvent.data?.status!} />,
            ])}
            {getKeyValues('Showtime', [
              getShowDate(),
              getShowStartAndEndTime(),
            ])}
            {getKeyValues('Location', [
              getEvent.data?.locationName,
              getAddressButton(),
            ])}
            {getKeyValues('Contact', [
              getEvent.data?.locationContactName,
              getEvent.data?.locationContactPhone ? (
                <a
                  href={`tel:${getEvent.data?.locationContactPhone}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getEvent.data?.locationContactPhone}
                </a>
              ) : null,
              getEvent.data?.locationContactEmail ? (
                <a
                  href={`mailto:${getEvent.data?.locationContactEmail}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getEvent.data?.locationContactEmail}
                </a>
              ) : null,
            ])}
            {getKeyValues(
              'Labels',
              getEvent.data?.labels?.length
                ? [
                    <Labels
                      labelIds={getEvent.data?.labels}
                      projectId={getEvent.data?.projectId}
                    />,
                  ]
                : []
            )}
            {getKeyValues('General Notes', [getEvent.data?.generalNotes])}
            {getEvent.data?.sensitiveInformation?.privateNotes &&
              getKeyValues('Private Notes', [
                getEvent.data?.sensitiveInformation?.privateNotes,
              ])}
            {bandsintownEvent.isSuccess && (
              <KeyValue
                label="Bandsintown"
                value={
                  <div>
                    {showSensitiveInformation &&
                      bandsintownConfiguration.isSuccess && (
                        <Button
                          href={`https://artists.bandsintown.com/artists/${bandsintownConfiguration.data?.artist?.id}/events/${bandsintownEvent.data?.id}?version=single-page`}
                          target="_blank"
                          rel="noreferrer"
                          variant="outlined"
                          className="green-room-button"
                          color="secondary"
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                      )}
                    <Button
                      href={`https://bandsintown.com/e/${bandsintownEvent.data?.id}`}
                      target="_blank"
                      rel="noreferrer"
                      variant="outlined"
                      className="green-room-button"
                      style={{ marginLeft: '8px' }}
                      color="secondary"
                      startIcon={<VisibilityIcon />}
                    >
                      View
                    </Button>
                  </div>
                }
              />
            )}
            {map && (
              <iframe
                title="map"
                width="100%"
                height="450"
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={map}
              />
            )}
          </Stack>
        )}
        {tab === EVENT_TAB_VALUES.TIMES && (
          <Stack spacing={3}>
            {getKeyValues('Showtime', [
              getShowDate(),
              getShowStartAndEndTime(),
            ])}
            {getKeyValues('Load-In', [
              getEventTime(getEvent.data?.arrivalTime),
              getEvent.data?.arrivalNotes,
            ])}
            {getKeyValues('Sound Check', [
              getEventTime(getEvent.data?.soundCheckTime),
              getEvent.data?.soundCheckNotes,
            ])}
            {getKeyValues('Load-Out', [
              getEventTime(getEvent.data?.departureTime),
              getEvent.data?.departureNotes,
            ])}
          </Stack>
        )}
        {tab === EVENT_TAB_VALUES.ROLES &&
          getEvent.data &&
          getRoles.data &&
          getPersons.data && (
            <EventRoleCards
              event={ToEditEvent(getEvent.data)!}
              roles={getRoles.data}
              persons={getPersons.data}
              paymentTypes={getPaymentTypes.data}
              showSensitiveInformation={showSensitiveInformation}
            />
          )}
        {tab === EVENT_TAB_VALUES.FINANCES && (
          <FinancesTransactions
            projectId={getEvent.data?.projectId!}
            paymentTypes={getPaymentTypes.data}
            eventId={id}
            blankslate={<NoEventFinancesBlankslate />}
          />
        )}
        {tab === EVENT_TAB_VALUES.LINKS && (
          <ViewEventPageLinksTab links={getEvent.data?.links} />
        )}
        {tab === EVENT_TAB_VALUES.FILES && (
          <ViewEventPageFilesTab files={getEvent.data?.files} />
        )}
      </TabContent>
    </>
  );
}

export default ViewEventPage;
