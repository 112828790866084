import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VariantType, useSnackbar } from 'notistack';
import Loading from '../components/Loading';
import { useGetProjectsQuery } from '../app/api/projects-api-slice';

interface RedirectParms {
  to: string;
  snackbarMessage?: string;
  snackbarVariant?: VariantType;
}

function HandleRedirectPage() {
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const snackbar = useSnackbar();

  const getProjects = useGetProjectsQuery();

  const destination = urlParams.get('to');

  const [redirectLocation, setRedirectLocation] =
    useState<RedirectParms | null>(null);

  useEffect(() => {
    if (redirectLocation) {
      if (redirectLocation.snackbarMessage) {
        snackbar.enqueueSnackbar(redirectLocation.snackbarMessage, {
          variant: redirectLocation.snackbarVariant,
        });
      }
      navigate(redirectLocation?.to);
    }
  }, [redirectLocation]);

  useEffect(() => {
    let handled = false;
    if (destination === 'projectSubscriptionSettings') {
      const lastProjectSubscriptionSettingsVisited = localStorage.getItem(
        'lastProjectSubscriptionSettingsVisited'
      );
      if (lastProjectSubscriptionSettingsVisited) {
        handled = true;
        setRedirectLocation({
          to: `/settings/project/${lastProjectSubscriptionSettingsVisited}?tab=subscription`,
        });
      }
    }
    if (destination === 'projectMembers') {
      handled = true;
      if (getProjects.data) {
        const adminedProject = getProjects.data.find(
          (p) => p.role === 'Admin' || p.role === 'Owner'
        );
        if (adminedProject) {
          setRedirectLocation({
            to: `/settings/project/${adminedProject.id}?tab=members`,
          });
        } else {
          setRedirectLocation({
            to: '/settings?tab=projects&createDialog',
            snackbarMessage: 'Create a project to manage members.',
            snackbarVariant: 'info',
          });
        }
      }
    }
    if (destination === 'projectRoles') {
      handled = true;
      if (getProjects.data) {
        const adminedProject = getProjects.data.find(
          (p) => p.role === 'Admin' || p.role === 'Owner'
        );
        if (adminedProject) {
          setRedirectLocation({
            to: `/settings/project/${adminedProject.id}?tab=roles`,
          });
        } else {
          setRedirectLocation({
            to: '/settings?tab=projects&createDialog',
            snackbarMessage: 'Create a project to manage roles.',
            snackbarVariant: 'info',
          });
        }
      }
    }
    if (destination === 'projectInfo') {
      handled = true;
      if (getProjects.data) {
        const adminedProject = getProjects.data.find(
          (p) => p.role === 'Admin' || p.role === 'Owner'
        );
        if (adminedProject) {
          setRedirectLocation({
            to: `/settings/project/${adminedProject.id}?tab=project`,
          });
        } else {
          setRedirectLocation({
            to: '/settings',
          });
        }
      }
    }
    if (!handled) {
      setRedirectLocation({ to: '/' });
    }
  }, [getProjects.data]);

  return <Loading />;
}

export default HandleRedirectPage;
