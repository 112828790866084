import {
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import UserInfoEdit from '../components/UserInfoEdit';
import {
  UpdateUserWithReferrer,
  useGetUserQuery,
  User,
  useUpdateUserMutation,
} from '../app/api/user-api-slice';
import Loading from '../components/Loading';
import GreenRoomButton from '../components/GreenRoomButton';
import userMissingInfo from '../app/userHelpers';

export interface OnboardingPageUserInfoProps {
  onSave: () => void;
}

interface ReferrerOption {
  id: string;
  name: string;
  details?: string;
}

const referrerOptions: ReferrerOption[] = [
  { id: 'google-ad', name: 'Google Ad' },
  {
    id: 'google',
    name: 'Google Search Result',
    details: 'What were you searching for?',
  },
  { id: 'instagram-ad', name: 'Instagram Ad' },
  { id: 'instagram', name: 'Instagram Post' },
  { id: 'facebook-ad', name: 'Facebook Ad' },
  { id: 'facebook', name: 'Facebook Post' },
  { id: 'twitter-ad', name: 'Twitter Ad' },
  { id: 'twitter', name: 'Twitter Post' },
  { id: 'reddit-ad', name: 'Reddit Ad' },
  { id: 'reddit-post', name: 'Reddit Post', details: 'Which Reddit post?' },
  { id: 'tiktok', name: 'TikTok' },
  { id: 'linkedin', name: 'LinkedIn' },
  { id: 'pr', name: 'Press/PR' },
  { id: 'blog', name: 'Blog post', details: 'Which blog?' },
  {
    id: 'word-of-mouth',
    name: 'Referral / Word of mouth',
    details: 'Who referred you?',
  },
  { id: 'podcast', name: 'Podcast', details: 'Which podcast?' },
  { id: 'payment-by-venue', name: `I'm getting paid by a venue` },
  { id: 'payment-by-band', name: `I'm getting paid by a band` },
  { id: 'other', name: 'Other', details: 'Please specify' },
];

function OnboardingPageUserInfo({ onSave }: OnboardingPageUserInfoProps) {
  const { enqueueSnackbar } = useSnackbar();

  const getUser = useGetUserQuery();
  const [updateUserMutation, updateUserMutationStatus] =
    useUpdateUserMutation();
  useEffect(() => {
    if (updateUserMutationStatus.isSuccess) {
      try {
        window.gtag('event', 'onboarding_complete');
        window.Intercom('trackEvent', 'onboarding_complete');
        window.gtag('event', 'conversion', {
          send_to: 'AW-10877801174/7i_LCPTn95gYENal-MIo',
        });
      } catch {
        //
      }
      onSave();
    } else if (updateUserMutationStatus.isError) {
      enqueueSnackbar('There was a problem saving your info.', {
        variant: 'error',
      });
    }
  }, [updateUserMutationStatus]);
  const [user, setUser] = React.useState<User | null>(null);

  useEffect(() => {
    if (getUser.data) {
      setUser({
        ...getUser.data,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    }
  }, [getUser]);

  const [referrer, setReferrer] = React.useState<string | null>(null);
  const [referrerDetails, setReferrerDetails] = React.useState<string | null>(
    null
  );

  if (getUser.isLoading || user == null) {
    return <Loading />;
  }

  return (
    <>
      <DialogTitle>Welcome to Green Room!</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          We just need a few details to get started.
        </Typography>
        <div className="onboarding-content">
          <UserInfoEdit
            user={user!}
            onChange={setUser}
            hideAdditionalSettings
          />
        </div>
        <div className="onboarding-content">
          <FormControl fullWidth required>
            <InputLabel id="referrer-label">
              How did you hear about us?
            </InputLabel>
            <Select
              labelId="referrer-label"
              label="How did you hear about us?"
              id="referrer"
              required
              value={referrer || ''}
              onChange={(e) => setReferrer(e.target.value)}
            >
              {referrerOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {referrer !== null &&
          referrerOptions.find((o) => o.id === referrer)?.details && (
            <div className="onboarding-content">
              <TextField
                label={`${
                  referrerOptions.find((o) => o.id === referrer)?.details || ''
                } (optional)`}
                fullWidth
                multiline
                minRows={3}
                value={referrerDetails || ''}
                onChange={(e) => setReferrerDetails(e.target.value)}
              />
            </div>
          )}
      </DialogContent>
      <DialogActions>
        <GreenRoomButton
          type="accept"
          onClick={() => {
            updateUserMutation({
              user: {
                legalFirstName: user?.legalFirstName!,
                legalLastName: user?.legalLastName!,
                preferredName: user?.preferredName!,
                phoneNumber: user?.phoneNumber!,
                zipCode: user?.zipCode!,
                referrer,
                referrerDetails,
                timeZone: user.timeZone,
              } as UpdateUserWithReferrer,
              initialSignup: true,
            });
          }}
          disabled={
            userMissingInfo(user!) ||
            referrer === null ||
            updateUserMutationStatus.isLoading
          }
        >
          Save
        </GreenRoomButton>
      </DialogActions>
    </>
  );
}

export default OnboardingPageUserInfo;
