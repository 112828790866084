import { Stack } from '@mui/material';
import React from 'react';
import Subheading from './Text/Subheading';
import './Blankslate.scss';
import Heading from './Text/Heading';

interface BlankslateProps {
  heading?: string | null;
  title?: string | null;
  children: React.ReactNode | string;
  actions?: React.ReactNode | null;
  svg?: React.ReactNode | null;
}

function Blankslate({
  heading,
  title,
  children,
  actions,
  svg,
}: BlankslateProps) {
  return (
    <div className="green-room-blankslate-wrapper">
      <Stack spacing={2} textAlign="center" className="green-room-blankslate">
        {heading && <Heading>{heading}</Heading>}
        {svg}
        {title && (
          <Subheading className="green-room-blankslate-title">
            {title}
          </Subheading>
        )}
        <span>{children}</span>
        {actions && (
          <div className="green-room-blankslate-actions">{actions}</div>
        )}
      </Stack>
    </div>
  );
}

Blankslate.defaultProps = {
  heading: null,
  title: null,
  actions: null,
  svg: null,
};

export default Blankslate;
