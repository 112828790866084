import React from 'react';
import { Button } from '@mui/material';
import { useAudioPlayer } from '../../components/AudioPlayer';

function AudioPlayerExample() {
  const { setAudioFile } = useAudioPlayer();
  return (
    <Button
      onClick={() =>
        setAudioFile({
          name: 'Kalimba.mp3',
          path: 'https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3',
        })
      }
    >
      Start
    </Button>
  );
}

export default AudioPlayerExample;
