import React from 'react';
import { Box, Chip } from '@mui/material';
import { useGetLabelsQuery } from '../../app/api/labels-api-slice';

interface LabelsProps {
  labelIds: string[];
  projectId: string | null;
}

function Labels({ labelIds, projectId }: LabelsProps) {
  const getLabels = useGetLabelsQuery(
    { projectId: projectId! },
    { skip: !projectId }
  );

  if (!projectId) return null;

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {labelIds?.map((labelId: string) => (
        <Chip
          sx={{
            background: getLabels?.data?.find(
              (projectLabel) => projectLabel.id === labelId
            )?.color,
          }}
          key={labelId}
          label={
            getLabels?.data?.find((projectLabel) => projectLabel.id === labelId)
              ?.name
          }
        />
      )) || []}
    </Box>
  );
}

export default Labels;
