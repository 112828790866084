import React, { useState, useEffect } from 'react';
import { Typography, Stack, Chip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { v4 as uuid } from 'uuid';
import { getStringValueForIsAttending } from '../app/helpers/event-helpers';
import GreenRoomCard from './GreenRoomCard';
import GreenRoomStack from './GreenRoomStack';
import NoEventRolesBlankslate from './Blankslates/NoEventRolesBlankslate';
import { EventPerson, Transaction } from '../app/api/events-api-slice';
import { Person } from '../app/api/persons-api-slice';
import { EventRoleType } from '../app/api/event-role-types-api-slice';
import GreenRoomDialog from './Dialog/GreenRoomDialog';
import GreenRoomButton from './GreenRoomButton';
import EditEventRoleCard from './EditEventRoleCard';
import { EditEvent } from '../app/models/edit-event';
import { PaymentType } from '../app/api/payment-types-api-slice';
import { getPaymentInfo } from '../app/helpers/transaction-helpers';

interface EventRoleCardsProps {
  event: EditEvent;
  roles: EventRoleType[] | undefined;
  persons: Person[] | undefined;
  paymentTypes: PaymentType[] | undefined;
  showSensitiveInformation: boolean;
  // eslint-disable-next-line no-unused-vars
  onCreateOrUpdate?: (event: EditEvent) => void;
}

function EventRoleCards({
  event,
  roles,
  persons,
  paymentTypes,
  showSensitiveInformation,
  onCreateOrUpdate,
}: EventRoleCardsProps) {
  const newEventPerson = {
    id: uuid(),
    eventRoleTypeId: null,
    personId: null,
    isAttending: null,
    sendEmail: false,
    sendText: false,
  };

  const [selectedTransaction, setSelectedTransaction] =
    useState<Transaction | null>(null);

  const [selectedPerson, setSelectedPerson] = useState<EventPerson | null>(
    null
  );

  const [previousSelectedPerson, setPreviousSelectedPerson] =
    useState<EventPerson | null>(null);

  useEffect(() => {
    const newPersonSelected =
      selectedPerson?.id && selectedPerson?.id !== previousSelectedPerson?.id;

    if (
      newPersonSelected &&
      selectedPerson?.id !== selectedTransaction?.eventPersonId
    ) {
      const newSelectedTransaction =
        event.sensitiveInformation.transactions.find(
          (t) => t.eventPersonId === selectedPerson?.id
        ) || null;

      setSelectedTransaction(newSelectedTransaction);
    }

    setPreviousSelectedPerson(selectedPerson);
  }, [selectedPerson]);

  const onClearSelected = () => {
    setSelectedPerson(null);
    setSelectedTransaction(null);
  };

  const onDelete = () => {
    const newEventPersons = event.eventPersons?.filter(
      (eventPerson) => eventPerson.id !== selectedPerson?.id
    );
    const newEventTransactions =
      event.sensitiveInformation?.transactions?.filter(
        (t) => t.eventPersonId !== selectedPerson?.id
      ) || [];

    if (onCreateOrUpdate) {
      onCreateOrUpdate({
        ...event,
        eventPersons: newEventPersons,
        sensitiveInformation: {
          ...event.sensitiveInformation,
          transactions: newEventTransactions,
        },
      });

      onClearSelected();
    }
  };

  const onSave = () => {
    const newEventPersons = [...event.eventPersons];
    const selectedPersonIndex = newEventPersons.findIndex(
      (newPerson) => newPerson.id === selectedPerson?.id
    );

    if (selectedPerson) {
      if (selectedPersonIndex < 0) {
        newEventPersons.push(selectedPerson);
      } else if (selectedPersonIndex >= 0) {
        newEventPersons[selectedPersonIndex] = selectedPerson;
      }
    }

    let newTransactions = [...event.sensitiveInformation.transactions];
    const selectedTransactionIndex = newTransactions.findIndex(
      (newTransaction) => newTransaction.id === selectedTransaction?.id
    );
    const existingTransaction = event.sensitiveInformation?.transactions.find(
      (t) => t.eventPersonId === selectedPerson?.id
    );

    if (
      selectedTransaction?.id &&
      existingTransaction?.id &&
      selectedTransaction?.id !== existingTransaction?.id
    ) {
      // If there is new transaction data, and the user turned paid role off and then on again, creating a new id
      newTransactions = newTransactions.filter(
        (transaction) => transaction.id !== existingTransaction.id
      );
    }

    if (selectedTransaction) {
      // If there is new transaction data, and the id is the same, add or update
      if (selectedTransactionIndex < 0) {
        newTransactions.push(selectedTransaction);
      } else if (selectedTransactionIndex >= 0) {
        newTransactions[selectedTransactionIndex] = selectedTransaction;
      }
    } else if (existingTransaction) {
      // If the user turned the paid transaction toggle off, and it was previoudly on
      newTransactions = newTransactions.filter(
        (transaction) => transaction.id !== existingTransaction.id
      );
    }

    if (onCreateOrUpdate) {
      onCreateOrUpdate({
        ...event,
        eventPersons: newEventPersons,
        sensitiveInformation: {
          ...event.sensitiveInformation,
          transactions: newTransactions,
        },
      });

      onClearSelected();
    }
  };

  /// //////////
  // Dialog actions
  const isEditing = () =>
    event.eventPersons.find(
      (eventPerson) => eventPerson.id === selectedPerson?.id
    );

  const getEditActions = () => (
    <>
      <GreenRoomButton
        type="destroy"
        onClick={onDelete}
        className="gr-edit-event-role-delete"
      >
        Delete
      </GreenRoomButton>
      <GreenRoomButton
        type="edit"
        onClick={onSave}
        disabled={!selectedPerson?.eventRoleTypeId}
        className="gr-edit-event-role-update"
      >
        Update
      </GreenRoomButton>
    </>
  );

  const getAddActions = () => (
    <>
      <GreenRoomButton
        type="cancel"
        onClick={onClearSelected}
        className="gr-edit-event-role-cancel"
      >
        Cancel
      </GreenRoomButton>
      <GreenRoomButton
        type="create"
        onClick={onSave}
        disabled={!selectedPerson?.eventRoleTypeId}
        className="gr-edit-event-role-add"
      >
        Add
      </GreenRoomButton>
    </>
  );

  // TODO: Duplicated on EventTransactionCardsProps
  let blankslateActions = null;
  if (!roles || !persons) {
    blankslateActions = (
      <b>To begin adding, select a project on the info tab.</b>
    );
  }

  /// //////////
  // Render main content
  return (
    <>
      <Stack spacing={2}>
        {!!event?.eventPersons.length && (
          <GreenRoomStack>
            {event?.eventPersons?.map((eventPerson) => {
              /// //////////
              // Find data objects associated with each eventPerson
              const role = roles?.find(
                (r) => r.id === eventPerson.eventRoleTypeId
              );
              const person = persons?.find(
                (p) => p.id === eventPerson.personId
              );
              const transaction = event.sensitiveInformation?.transactions.find(
                (t) => t.eventPersonId === eventPerson.id
              );

              /// //////////
              // Build card layout JSX
              const getCardTitle = () => {
                if (person?.name) {
                  return `${person.name} (${role?.name})`;
                }

                return `(${role?.name})`;
              };

              /// //////////
              // Build transaction JSX
              const transactionAmount = transaction && transaction.amount >= 0;

              let transactionAmountAndDate = 'Not a paid role';

              if (transactionAmount) {
                transactionAmountAndDate = getPaymentInfo(
                  paymentTypes,
                  transaction
                );
              }

              /// //////////
              // Build attendance JSX
              const getAttendanceIcon = () => {
                // Unconfirmed
                if (eventPerson.isAttending === null) return <AccessTimeIcon />;

                // Confirmed yes
                if (eventPerson.isAttending === true)
                  return <CheckCircleIcon color="success" />;

                // Confirmed no
                return <CancelIcon color="error" />;
              };

              /// //////////
              // Return Card
              return (
                <GreenRoomCard
                  key={eventPerson.id}
                  title={getCardTitle()}
                  subtitle={
                    (person?.email && !onCreateOrUpdate && (
                      <a href={`mailto:${person.email}`}>{person.email}</a>
                    )) ||
                    person?.email
                  }
                  right={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: '8px',
                      }}
                    >
                      {getAttendanceIcon()}
                      <div>
                        <Typography
                          variant="subtitle2"
                          style={{ marginLeft: '4px' }}
                        >
                          {getStringValueForIsAttending(
                            eventPerson.isAttending
                          )}
                        </Typography>
                      </div>
                    </div>
                  }
                  onClick={
                    onCreateOrUpdate
                      ? () => setSelectedPerson(eventPerson)
                      : undefined
                  }
                >
                  {!!showSensitiveInformation && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="subtitle2">
                        {transactionAmountAndDate}
                      </Typography>
                    </div>
                  )}
                  {showSensitiveInformation && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '8px',
                      }}
                    >
                      <div>
                        <Chip
                          icon={
                            eventPerson.sendEmail ? (
                              <NotificationsIcon />
                            ) : (
                              <NotificationsOffIcon />
                            )
                          }
                          label="Email"
                          color={eventPerson.sendEmail ? 'primary' : 'default'}
                        />
                      </div>

                      <div style={{ marginLeft: '8px' }}>
                        <Chip
                          icon={
                            eventPerson.sendText ? (
                              <NotificationsIcon />
                            ) : (
                              <NotificationsOffIcon />
                            )
                          }
                          label="Text"
                          color={eventPerson.sendText ? 'primary' : 'default'}
                        />
                      </div>
                    </div>
                  )}
                </GreenRoomCard>
              );
            })}
          </GreenRoomStack>
        )}
        {!event?.eventPersons.length && (
          <NoEventRolesBlankslate actions={blankslateActions}>
            Project roles are positions that need to be filled for an event. For
            example: lead guitar, lighting, drums. Add them here and assign them
            to team members to keep track of who has done what.
          </NoEventRolesBlankslate>
        )}
        {onCreateOrUpdate && roles && persons && (
          <div className="event-role-type-select-and-add-button">
            <GreenRoomButton
              type="add"
              onClick={() => setSelectedPerson(newEventPerson)}
              className="gr-edit-event-add-role-button"
            >
              Add Role
            </GreenRoomButton>
          </div>
        )}
      </Stack>
      {/* /// //////////
      // Return Dialog; Open dialog when adding or editing */}
      <GreenRoomDialog
        open={!!selectedPerson?.id}
        title={isEditing() ? 'Edit Role' : 'Add Role'}
        onClose={onClearSelected}
        actions={isEditing() ? getEditActions() : getAddActions()}
      >
        <EditEventRoleCard
          editEvent={event as EditEvent}
          roles={roles}
          persons={persons}
          paymentTypes={paymentTypes}
          eventPerson={selectedPerson}
          onEditEventPerson={setSelectedPerson}
          eventTransaction={selectedTransaction}
          onEditEventTransaction={setSelectedTransaction}
        />
      </GreenRoomDialog>
    </>
  );
}

EventRoleCards.defaultProps = {
  onCreateOrUpdate: null,
};

export default EventRoleCards;
