import React, { useState } from 'react';
import { IconButton, List, ListItem, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoEventFilesBlankSlate from '../components/Blankslates/NoEventFilesBlankslate';
import Subheading from '../components/Text/Subheading';
import { GreenRoomFile } from '../app/api/files-api-slice';
import { isAudioFile, isImage, isPdf } from '../app/helpers/file-helpers';
import { useAudioPlayer } from '../components/AudioPlayer';
import PdfViewer from '../components/PdfViewer';
import ImageViewer from '../components/ImageViewer';

interface ViewEventPageResourcesTabProps {
  files?: GreenRoomFile[];
}

interface CurrentFile {
  name: string;
  path: string;
  open: boolean;
}

function ViewEventPageFilesTab({ files }: ViewEventPageResourcesTabProps) {
  const { setAudioFile } = useAudioPlayer();
  const initialCurrentFile = {
    name: '',
    path: '',
    open: false,
  };
  const [currentPdf, setCurrentPdf] = useState<CurrentFile>(initialCurrentFile);
  const [currentImage, setCurrentImage] =
    useState<CurrentFile>(initialCurrentFile);
  return (
    (!files?.length && <NoEventFilesBlankSlate />) || (
      <>
        <Subheading>Files</Subheading>
        <List>
          {files?.map((file) => (
            <ListItem key={file.id}>
              <Typography>{file.name}</Typography>
              <IconButton color="primary" href={file.url} download>
                <DownloadIcon />
              </IconButton>
              {isAudioFile(file) && (
                <IconButton
                  color="primary"
                  onClick={() =>
                    setAudioFile({ name: file.name, path: file.url })
                  }
                >
                  <PlayCircleIcon />
                </IconButton>
              )}
              {isPdf(file) && (
                <IconButton
                  color="primary"
                  onClick={() =>
                    setCurrentPdf({
                      name: file.name,
                      path: file.url,
                      open: true,
                    })
                  }
                >
                  <VisibilityIcon />{' '}
                </IconButton>
              )}
              {isImage(file) && (
                <IconButton
                  color="primary"
                  onClick={() =>
                    setCurrentImage({
                      name: file.name,
                      path: file.url,
                      open: true,
                    })
                  }
                >
                  <VisibilityIcon />{' '}
                </IconButton>
              )}
            </ListItem>
          ))}
        </List>
        <PdfViewer
          name={currentPdf.name}
          url={currentPdf.path}
          open={currentPdf.open}
          onClose={() => setCurrentPdf({ ...currentPdf, open: false })}
        />
        <ImageViewer
          name={currentImage.name}
          url={currentImage.path}
          open={currentImage.open}
          onClose={() => setCurrentImage({ ...currentImage, open: false })}
        />
      </>
    )
  );
}

ViewEventPageFilesTab.defaultProps = {
  files: [],
};

export default ViewEventPageFilesTab;
