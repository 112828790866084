import React from 'react';
import GreenRoomDialog from '../../components/Dialog/GreenRoomDialog';
import GreenRoomButton from '../../components/GreenRoomButton';
import KeyValue from '../../components/KeyValue';

interface FinancesFinancialAccountSensitiveInformationModalProps {
  open: boolean;
  onClose: () => void;
  accountNumber: string;
  routingNumber: string;
}

function FinancesFinancialAccountSensitiveInformationModal({
  open,
  onClose,
  accountNumber,
  routingNumber,
}: FinancesFinancialAccountSensitiveInformationModalProps) {
  return (
    <GreenRoomDialog open={open} title="Account Information" onClose={onClose}>
      <KeyValue
        secondary
        keyWidth="120px"
        centerVertically
        label="Account Number"
        value={
          <GreenRoomButton type="copy" copyText={accountNumber || undefined}>
            {accountNumber}
          </GreenRoomButton>
        }
      />
      <KeyValue
        secondary
        keyWidth="120px"
        centerVertically
        label="Routing Number"
        value={
          <GreenRoomButton type="copy" copyText={routingNumber || undefined}>
            {routingNumber}
          </GreenRoomButton>
        }
      />
    </GreenRoomDialog>
  );
}

export default FinancesFinancialAccountSensitiveInformationModal;
