import { Avatar, useTheme } from '@mui/material';
import React from 'react';
import { EventStatus } from '../app/api/events-api-slice';

interface DateAvatarProps {
  displayDate: Date;
  timeZone: string;
  status: EventStatus;
}

function DateAvatar({ displayDate, timeZone, status }: DateAvatarProps) {
  const theme = useTheme();

  const currentDate = new Date();
  let color = '#FFF';
  let backgroundColor = 'secondary.light';
  let borderWidth = 'unset';
  let borderStyle = 'unset';
  let borderColor = 'unset';
  if (status === 'Draft') {
    backgroundColor = '#FFF';
    color = 'secondary.dark';
    borderWidth = 'medium';
    borderStyle = 'dotted';
    borderColor = theme.palette.secondary.dark.toString();
  } else if (currentDate <= displayDate) {
    if (status === 'Confirmed') {
      backgroundColor = 'primary.main';
    } else if (status === 'Hold') {
      backgroundColor = '#FFF';
      color = 'primary.dark';
      borderWidth = 'medium';
      borderStyle = 'dotted';
      borderColor = theme.palette.primary.dark.toString();
    }
  }

  return (
    <Avatar
      variant="rounded"
      className="date-avatar"
      sx={{
        width: '60px',
        height: '60px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px',
        display: 'flex',
        flex: 'none',
        flexDirection: 'column',
        order: '0',
        flexGrow: '0',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '16px',
        textAlign: 'center',
        backgroundColor,
        color,
        borderWidth,
        borderStyle,
        borderColor,
      }}
    >
      <span>
        {displayDate
          .toLocaleString(navigator.language, {
            weekday: 'short',
            timeZone,
          })
          .toUpperCase()}
      </span>
      <span>
        {displayDate
          .toLocaleString(navigator.language, {
            month: 'short',
            day: 'numeric',
            timeZone,
          })
          .toUpperCase()}
      </span>
      <span>
        {displayDate
          .toLocaleTimeString(navigator.language, {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
            timeZone,
          })
          .replaceAll(':00', '')}
      </span>
    </Avatar>
  );
}

export default DateAvatar;
