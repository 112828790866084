import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import './ProjectFileSelectionDialog.scss';
import ProjectFileUpload from './ProjectFileUpload';
import ProjectFileListing from './ProjectFileListing';

interface ProjectFileSelectionDialogProps {
  open: boolean;
  selectedFileIds: string[];
  // eslint-disable-next-line no-unused-vars
  onSelectionChange: (fileIds: string[]) => void;
  onClose: () => void;
  projectId: string;
}

const UPLOAD_TAB = 0;
const SELECT_TAB = 1;

function ProjectFileSelectionDialog({
  open,
  selectedFileIds,
  onSelectionChange,
  onClose,
  // eslint-disable-next-line no-unused-vars
  projectId,
}: ProjectFileSelectionDialogProps) {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isUploading, setIsUploading] = useState<boolean | null>(null);

  const [currentSelectedIds, setCurrentSelectedIds] = useState<string[]>([]);
  useEffect(() => {
    setCurrentSelectedIds(selectedFileIds);
  }, [selectedFileIds]);

  function close(fileIds: string[], wait = false) {
    const timeout = wait ? 750 : 0;
    setTimeout(() => {
      setCurrentSelectedIds(fileIds);
      onSelectionChange(fileIds);
      onClose();
    }, timeout);
  }

  useEffect(() => {
    if (open) {
      setIsUploading(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => close(selectedFileIds)}
      fullScreen={isMobile}
      fullWidth
      maxWidth="xl"
    >
      {isUploading === null && (
        <DialogTitle>
          <Tabs value={tabValue} onChange={(_, v) => setTabValue(v)}>
            <Tab
              icon={<UploadFileIcon />}
              label="Upload New"
              value={UPLOAD_TAB}
            />
            <Tab
              icon={<FolderIcon />}
              label="Select Existing"
              value={SELECT_TAB}
            />
          </Tabs>
        </DialogTitle>
      )}
      {tabValue === UPLOAD_TAB && (
        <ProjectFileUpload
          isUploading={isUploading}
          setIsUploading={setIsUploading}
          onComplete={(files) =>
            close(
              currentSelectedIds.concat(files?.map(({ id }) => id!) ?? []),
              true
            )
          }
          projectId={projectId}
        />
      )}
      {tabValue === SELECT_TAB && (
        <>
          <DialogContent>
            <div style={{ overflow: 'hidden', height: '100%', width: '100%' }}>
              <ProjectFileListing
                projectId={projectId}
                selectedFileIds={currentSelectedIds}
                onSelectionChange={setCurrentSelectedIds}
                height={
                  isMobile ? 'calc(100vh - 200px)' : 'calc(100vh - 300px)'
                }
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => close(selectedFileIds)}>Cancel</Button>
            <Button
              variant="contained"
              onClick={() => close(currentSelectedIds)}
            >
              Select
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default ProjectFileSelectionDialog;
