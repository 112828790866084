import React, { useState, useEffect } from 'react';
import {
  Chip,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  SelectChangeEvent,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  useGetPersonsForAllProjectsQuery,
  Person,
} from '../../../app/api/persons-api-slice';
import { useGetProjectsQuery } from '../../../app/api/projects-api-slice';
import {
  useGetLocationsForAllProjectsQuery,
  Location,
} from '../../../app/api/locations-api-slice';
import GreenRoomDialog from '../../Dialog/GreenRoomDialog';
import GreenRoomButton from '../../GreenRoomButton';
import './index.scss';
import {
  isSomeSelected,
  isAllSelected,
  isSomeButNotAllSelected,
  SELECT_MENU_PROPS,
} from '../../Selects/select-helpers';
import {
  useGetLabelsForAllProjectsQuery,
  Label,
} from '../../../app/api/labels-api-slice';
import { PaymentType } from '../../../app/api/payment-types-api-slice';

/// //////////
// TODO: There is a lot of refactoring that can be made below so there are fewer places to touch when adding new filters
// NOTE: Be wary of `EXCEPTION TO PATTERN FOR LABELS` annotations below when refactoring
// NOTE: Be wary of `EXCEPTION TO PATTERN FOR MEMBERS` annotations below when refactoring

/// //////////
// Dedupe filter helpers
export const getAllUniqueNames = (list: Label[] | Person[] | PaymentType[]) => {
  const allNames = list?.map((listItem) => listItem.name);
  const allUniqueNames = allNames?.filter(
    (value: string, index: number, array: string[]) =>
      array.indexOf(value) === index
  );

  return allUniqueNames;
};

/// //////////
// Constants: Projects
export const PROJECTS_PARAM_NAME = 'projectIds';
const PROJECTS_LABEL = 'Projects';
const PROJECTS_GROUP = 'projects';

/// //////////
// Constants: Members
export const MEMBERS_PARAM_NAME = 'personIds';
const MEMBERS_LABEL = 'Members';
const MEMBERS_GROUP = 'members';

/// //////////
// Constants: Venue
export const VENUES_PARAM_NAME = 'locationGooglePlacesIds';
const VENUES_LABEL = 'Venues';
const VENUES_GROUP = 'venues';

/// //////////
// Constants: Event Status
export const EVENT_STATUSES_PARAM_NAME = 'eventStatuses';
const EVENT_STATUSES_LABEL = 'Event Status';
const EVENT_STATUSES_GROUP = 'event statuses';
const EVENT_STATUSES_DRAFT = 'Draft';
const EVENT_STATUSES_HOLD = 'Hold';
const EVENT_STATUSES_CONFIRMED = 'Confirmed';
const EVENT_STATUSES_CANCELLED = 'Cancelled';
export const EVENT_STATUSES_OPTIONS = [
  {
    value: EVENT_STATUSES_DRAFT,
    label: EVENT_STATUSES_DRAFT,
  },
  {
    value: EVENT_STATUSES_HOLD,
    label: EVENT_STATUSES_HOLD,
  },
  {
    value: EVENT_STATUSES_CONFIRMED,
    label: EVENT_STATUSES_CONFIRMED,
  },
  {
    value: EVENT_STATUSES_CANCELLED,
    label: EVENT_STATUSES_CANCELLED,
  },
] as const;

/// //////////
// Constants: Invite statuses
const INVITE_STATUSES_LABEL = 'RSVPs';
const INVITE_STATUSES_GROUP = 'RSVP statuses';
export const INVITE_STATUSES_PARAM_NAME = 'inviteStatuses';
const INVITE_STATUSES_VALUE_HAS_PENDING = 'pending';
const INVITE_STATUSES_VALUE_HAS_ATTENDING = 'attending';
const INVITE_STATUSES_VALUE_HAS_DECLINED = 'declined';
export const INVITE_STATUSES_OPTIONS = [
  {
    value: INVITE_STATUSES_VALUE_HAS_PENDING,
    label: 'Pending',
  },
  {
    value: INVITE_STATUSES_VALUE_HAS_ATTENDING,
    label: 'Attending',
  },
  {
    value: INVITE_STATUSES_VALUE_HAS_DECLINED,
    label: 'Declined',
  },
] as const;

/// //////////
// Constants: Labels
export const LABELS_PARAM_NAME = 'labelIds';
const LABELS_LABEL = 'Labels';
const LABELS_GROUP = 'labels';

/// //////////
// Types
type FilterEventParamName =
  | typeof PROJECTS_PARAM_NAME
  | typeof MEMBERS_PARAM_NAME
  | typeof VENUES_PARAM_NAME
  | typeof EVENT_STATUSES_PARAM_NAME
  | typeof INVITE_STATUSES_PARAM_NAME
  | typeof LABELS_PARAM_NAME;
type FilterEventLabel =
  | typeof PROJECTS_LABEL
  | typeof MEMBERS_LABEL
  | typeof VENUES_LABEL
  | typeof EVENT_STATUSES_LABEL
  | typeof INVITE_STATUSES_LABEL
  | typeof LABELS_LABEL;
type FilterEventGroup =
  | typeof PROJECTS_GROUP
  | typeof MEMBERS_GROUP
  | typeof VENUES_GROUP
  | typeof EVENT_STATUSES_GROUP
  | typeof INVITE_STATUSES_GROUP
  | typeof LABELS_GROUP;

export interface FilterEventsRequestArgs {
  projectIds?: string;
  personIds?: string;
  locationGooglePlacesIds?: string;
  eventStatuses?: string;
  inviteStatuses?: string;
  labelIds?: string;
}

function FilterEvents() {
  /// //////////
  // Navigation
  const [searchParams, setSearchParams] = useSearchParams();

  /// //////////
  // Projects fetch
  const getProjects = useGetProjectsQuery();

  const PROJECTS_OPTIONS = getProjects.data?.map((project) => ({
    value: project.id,
    label: `${project.name}`,
  }));

  /// //////////
  // Members fetch
  const getMembers = useGetPersonsForAllProjectsQuery();

  // EXCEPTION TO PATTERN FOR MEMBERS: Merge fetched members across projects by name. When merged, combine ids into comma-delineated string
  const allUniqueMemberNames = getAllUniqueNames(getMembers.data || []);
  const MEMBERS_OPTIONS = allUniqueMemberNames?.map((memberName: string) => {
    // EXCEPTION TO PATTERN FOR MEMBERS: Only show members for projects user is admin or owner of
    const projectsWithOwnerOrAdminRole = getProjects.data?.filter(
      (project) => project.role === 'Owner' || project.role === 'Admin'
    );
    const idsForProjectsWithOwnerOrAdminRole =
      projectsWithOwnerOrAdminRole?.map((project) => project.id);
    const membersForProjectsWithOwnerOrAdminRole = getMembers.data?.filter(
      (member) => idsForProjectsWithOwnerOrAdminRole?.includes(member.projectId)
    );

    const allMembersWithSameName =
      membersForProjectsWithOwnerOrAdminRole?.filter(
        (member) => member.name === memberName
      );

    return {
      value: allMembersWithSameName?.map((member) => member.id).join(',')!,
      label: memberName,
    };
  });

  /// //////////
  // Venues fetch
  const getVenues = useGetLocationsForAllProjectsQuery();
  // Remove locations that are too detached from the meaning of venue from the options list
  const getFilteredVenues = getVenues.data?.filter(
    (location: Location) => location.googlePlacesId && location.streetAddress
  );

  const VENUES_OPTIONS = getFilteredVenues?.map((venue) => ({
    value: venue.googlePlacesId,
    label: venue.name,
  }));

  /// //////////
  // Labels fetch
  const getLabels = useGetLabelsForAllProjectsQuery();
  // EXCEPTION TO PATTERN FOR LABELS: Merge fetched labels across projects by name. When merged, combine ids into comma-delineated string
  const allUniqueLabelNames = getAllUniqueNames(getLabels.data || []);
  const LABELS_OPTIONS = allUniqueLabelNames?.map((labelName: string) => {
    const allLabelsWithSameName = getLabels.data?.filter(
      (label) => label.name === labelName
    );

    return {
      value: allLabelsWithSameName?.map((label) => label.id).join(',')!,
      label: labelName,
    };
  });

  /// //////////
  // Options type
  type FilterEventOptions =
    | typeof PROJECTS_OPTIONS
    | typeof MEMBERS_OPTIONS
    | typeof VENUES_OPTIONS
    | typeof EVENT_STATUSES_OPTIONS
    | typeof INVITE_STATUSES_OPTIONS
    | typeof LABELS_OPTIONS;

  // //////////
  // Active filters
  const activeFilters = {
    projectIds: searchParams.get(PROJECTS_PARAM_NAME),
    personIds: searchParams.get(MEMBERS_PARAM_NAME),
    locationGooglePlacesIds: searchParams.get(VENUES_PARAM_NAME),
    eventStatuses: searchParams.get(EVENT_STATUSES_PARAM_NAME),
    inviteStatuses: searchParams.get(INVITE_STATUSES_PARAM_NAME),
    labelIds: searchParams.get(LABELS_PARAM_NAME),
  } as FilterEventsRequestArgs;

  /// //////////
  // Staged filters; Awaiting user to click "Approve"
  const [stagedFilters, setStagedFilters] = useState(
    activeFilters as FilterEventsRequestArgs
  );

  /// //////////
  // Derive the initial filter states from the Url
  const deriveSelectedArrayFromUrlString = (paramName: FilterEventParamName) =>
    activeFilters[paramName as keyof FilterEventsRequestArgs]?.split(',') || [];

  // EXCEPTION TO PATTERN FOR LABELS: Special implementation for labels where x ids in the url do not correlate to x selected options
  // EXCEPTION TO PATTERN FOR MEMBERS: Special implementation for members where x ids in the url do not correlate to x selected options
  const deriveSelectedArrayFromUrlStringWithConcatenation = (
    paramName: FilterEventParamName,
    options: typeof MEMBERS_OPTIONS | typeof LABELS_OPTIONS
  ) => {
    const allIdsInUrl =
      activeFilters[paramName as keyof FilterEventsRequestArgs]?.split(',') ||
      [];

    const relevantOptions = options?.filter((option) =>
      allIdsInUrl.some((id) => option.value.includes(id))
    );

    const enhancedSelectedArray = relevantOptions?.map((id) => id.value);

    return enhancedSelectedArray || [];
  };

  const [projectIds, setProjectIds] = useState<string[]>(
    deriveSelectedArrayFromUrlString(PROJECTS_PARAM_NAME)
  );
  // EXCEPTION TO PATTERN FOR MEMBERS: Unique function
  const [personIds, setPersonIds] = useState<string[]>(
    deriveSelectedArrayFromUrlStringWithConcatenation(
      MEMBERS_PARAM_NAME,
      MEMBERS_OPTIONS
    )
  );
  const [locationGooglePlacesIds, setLocationGooglePlacesIds] = useState<
    string[]
  >(deriveSelectedArrayFromUrlString(VENUES_PARAM_NAME));
  const [eventStatuses, setEventStatuses] = useState<string[]>(
    deriveSelectedArrayFromUrlString(EVENT_STATUSES_PARAM_NAME)
  );
  const [inviteStatuses, setInviteStatuses] = useState<string[]>(
    deriveSelectedArrayFromUrlString(INVITE_STATUSES_PARAM_NAME)
  );
  // EXCEPTION TO PATTERN FOR LABELS: Unique function
  const [labelIds, setLabelIds] = useState<string[]>(
    deriveSelectedArrayFromUrlStringWithConcatenation(
      LABELS_PARAM_NAME,
      LABELS_OPTIONS
    )
  );

  React.useEffect(() => {
    const newStagedFilters = {} as FilterEventsRequestArgs;

    if (projectIds.length) {
      newStagedFilters.projectIds = projectIds.join(',');
    }

    if (personIds.length) {
      newStagedFilters.personIds = personIds.join(',');
    }

    if (locationGooglePlacesIds.length) {
      newStagedFilters.locationGooglePlacesIds =
        locationGooglePlacesIds.join(',');
    }

    if (eventStatuses.length) {
      newStagedFilters.eventStatuses = eventStatuses.join(',');
    }

    if (inviteStatuses.length) {
      newStagedFilters.inviteStatuses = inviteStatuses.join(',');
    }

    if (labelIds.length) {
      newStagedFilters.labelIds = labelIds.join(',');
    }

    setStagedFilters(newStagedFilters);
  }, [
    projectIds,
    personIds,
    locationGooglePlacesIds,
    eventStatuses,
    inviteStatuses,
    labelIds,
  ]);

  // TODO: I like how we handle building arrays in EditLabels more
  const getNewSelectedArray = (
    event: SelectChangeEvent<string[]>,
    options: FilterEventOptions
  ) => {
    const newSelectedArray = (event.target?.value as []) || [];
    const newSelectedId = newSelectedArray[newSelectedArray.length - 1];

    if (newSelectedId === 'all') {
      // Remove item from selected
      return newSelectedArray.length > 1
        ? []
        : options?.map((option) => option.value) || [];
    }

    // Add item to selected
    return newSelectedArray;
  };

  const getMultiSelectLabel = (
    selectedValues: string[],
    groupName: FilterEventGroup,
    options: FilterEventOptions
  ) => {
    let label = `All ${groupName}`;

    if (selectedValues.length === 1) {
      const [selectedId] = selectedValues;
      // TODO: Figure out what this type should be
      label = (options as any[])?.find(
        (option: { value: string; label: string }) =>
          option.value === selectedId
      )?.label;
    } else if (
      selectedValues.length > 1 &&
      !isAllSelected(selectedValues, options)
    ) {
      label = `(${selectedValues.length}) ${groupName} selected`;
    } else if (isAllSelected(selectedValues, options)) {
      label = `All ${groupName} selected`;
    }

    return label;
  };

  const getMultiSelectOptions = (
    selectedValues: string[],
    options: FilterEventOptions
  ) => [
    <MenuItem
      key="select-all-options"
      value="all"
      className={`${
        (isSomeSelected(selectedValues) &&
          'green-room-multi-select-toggle-all-menu-item-selected') ||
        ''
      }`}
    >
      <ListItemIcon>
        <Checkbox
          checked={isAllSelected(selectedValues, options)}
          indeterminate={isSomeButNotAllSelected(selectedValues, options)}
        />
      </ListItemIcon>
      <ListItemText
        className="green-room-multi-select-toggle-all-text"
        primary={isSomeSelected(selectedValues) ? 'Deselect all' : 'Select all'}
      />
    </MenuItem>,
    options?.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        <ListItemIcon>
          <Checkbox
            checked={
              selectedValues.indexOf(option.value) > -1 ||
              isAllSelected(selectedValues, options)
            }
          />
        </ListItemIcon>
        <ListItemText primary={option.label} />
      </MenuItem>
    )),
  ];

  const getMultiSelect = (
    label: FilterEventLabel,
    groupName: FilterEventGroup,
    selectedValues: string[],
    options: FilterEventOptions,
    // eslint-disable-next-line no-unused-vars
    onChange: (value: string[]) => void
  ) => (
    <FormControl fullWidth>
      <InputLabel id={label} shrink>
        {label}
      </InputLabel>
      <Select
        multiple
        displayEmpty
        notched
        disabled={!options?.length}
        labelId={label}
        label={label}
        value={selectedValues}
        onChange={(event: SelectChangeEvent<string[]>) =>
          onChange(getNewSelectedArray(event, options))
        }
        renderValue={(values: string[]) =>
          getMultiSelectLabel(values, groupName, options)
        }
        MenuProps={SELECT_MENU_PROPS}
      >
        {getMultiSelectOptions(selectedValues, options)}
      </Select>
    </FormControl>
  );

  // //////////
  // Chip component
  const [numberOfFilters, setNumberOfFilters] = useState<number>(0);

  const getChipLabel = () => {
    let label = 'No Filters' as string;

    if (numberOfFilters === 1) label = '1 filter';

    if (numberOfFilters > 1) label = `${numberOfFilters} filters`;

    return label;
  };

  // //////////
  // Dialogue component
  const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);

  // When the url changes, update the dialogue state; Important after clearing the filters via parent
  const deriveAllSelectStatesFromUrl: () => void = () => {
    setStagedFilters(activeFilters);
    setProjectIds(deriveSelectedArrayFromUrlString(PROJECTS_PARAM_NAME));
    // EXCEPTION TO PATTERN FOR MEMBERS: Unique function
    setPersonIds(
      deriveSelectedArrayFromUrlStringWithConcatenation(
        MEMBERS_PARAM_NAME,
        MEMBERS_OPTIONS
      )
    );
    setLocationGooglePlacesIds(
      deriveSelectedArrayFromUrlString(VENUES_PARAM_NAME)
    );
    setInviteStatuses(
      deriveSelectedArrayFromUrlString(INVITE_STATUSES_PARAM_NAME)
    );
    // EXCEPTION TO PATTERN FOR LABELS: Unique function
    setLabelIds(
      deriveSelectedArrayFromUrlStringWithConcatenation(
        LABELS_PARAM_NAME,
        LABELS_OPTIONS
      )
    );
  };

  // EXCEPTION TO PATTERN FOR MEMBERS: This useEffect is important because unlike the other URL derived states, member derived state requires fetched data to set
  // The other derived states are set correctly on component mount, but this one needs to wait for getMembers to fetch successfully to be accurate
  useEffect(() => {
    setPersonIds(
      deriveSelectedArrayFromUrlStringWithConcatenation(
        MEMBERS_PARAM_NAME,
        MEMBERS_OPTIONS
      )
    );
  }, [getMembers]);

  // EXCEPTION TO PATTERN FOR LABELS: This useEffect is important because unlike the other URL derived states, label derived state requires fetched data to set
  // The other derived states are set correctly on component mount, but this one needs to wait for getLabels to fetch successfully to be accurate
  useEffect(() => {
    setLabelIds(
      deriveSelectedArrayFromUrlStringWithConcatenation(
        LABELS_PARAM_NAME,
        LABELS_OPTIONS
      )
    );
  }, [getLabels]);

  useEffect(() => {
    deriveAllSelectStatesFromUrl();

    let filtersLength = 0;
    const projectsParam = searchParams.get(PROJECTS_PARAM_NAME);
    const membersParam = searchParams.get(MEMBERS_PARAM_NAME);
    const venuesParam = searchParams.get(VENUES_PARAM_NAME);
    const eventStatusesParam = searchParams.get(EVENT_STATUSES_PARAM_NAME);
    const inviteStatusesParam = searchParams.get(INVITE_STATUSES_PARAM_NAME);
    const labelsParam = searchParams.get(LABELS_PARAM_NAME);

    if (projectsParam) {
      filtersLength += 1;
    }

    if (membersParam) {
      filtersLength += 1;
    }

    if (venuesParam) {
      filtersLength += 1;
    }

    if (eventStatusesParam) {
      filtersLength += 1;
    }

    if (inviteStatusesParam) {
      filtersLength += 1;
    }

    if (labelsParam) {
      filtersLength += 1;
    }

    setNumberOfFilters(filtersLength);
  }, [searchParams]);

  // //////////
  // Helper to update the url when user clicks "Approve"
  const updateURL = () => {
    if (
      !stagedFilters.projectIds ||
      isAllSelected(projectIds, PROJECTS_OPTIONS)
    ) {
      searchParams.delete(PROJECTS_PARAM_NAME);
    } else {
      searchParams.set(PROJECTS_PARAM_NAME, stagedFilters.projectIds);
    }

    if (!stagedFilters.personIds || isAllSelected(personIds, MEMBERS_OPTIONS)) {
      searchParams.delete(MEMBERS_PARAM_NAME);
    } else {
      searchParams.set(MEMBERS_PARAM_NAME, stagedFilters.personIds);
    }

    if (
      !stagedFilters.locationGooglePlacesIds ||
      isAllSelected(locationGooglePlacesIds, VENUES_OPTIONS)
    ) {
      searchParams.delete(VENUES_PARAM_NAME);
    } else {
      searchParams.set(
        VENUES_PARAM_NAME,
        stagedFilters.locationGooglePlacesIds
      );
    }

    if (
      !stagedFilters.eventStatuses ||
      isAllSelected(eventStatuses, EVENT_STATUSES_OPTIONS)
    ) {
      searchParams.delete(EVENT_STATUSES_PARAM_NAME);
    } else {
      searchParams.set(EVENT_STATUSES_PARAM_NAME, stagedFilters.eventStatuses);
    }

    if (
      !stagedFilters.inviteStatuses ||
      isAllSelected(inviteStatuses, INVITE_STATUSES_OPTIONS)
    ) {
      searchParams.delete(INVITE_STATUSES_PARAM_NAME);
    } else {
      searchParams.set(
        INVITE_STATUSES_PARAM_NAME,
        stagedFilters.inviteStatuses
      );
    }

    if (!stagedFilters.labelIds || isAllSelected(labelIds, LABELS_OPTIONS)) {
      searchParams.delete(LABELS_PARAM_NAME);
    } else {
      searchParams.set(LABELS_PARAM_NAME, stagedFilters.labelIds);
    }

    // DO save to history
    setSearchParams(searchParams);
  };

  // TODO: This loading pattern breaks convention;
  if (getProjects.isLoading || getMembers.isLoading || getVenues.isLoading) {
    return null;
  }

  // //////////
  // Filter component
  return (
    <>
      <Chip
        className="green-room-chip"
        icon={<FilterListIcon />}
        label={getChipLabel()}
        color={numberOfFilters > 0 ? 'primary' : undefined}
        onClick={() => {
          setFilterDialogOpen(true);
        }}
      />
      <GreenRoomDialog
        open={filterDialogOpen}
        title="Filter"
        onClose={() => {
          deriveAllSelectStatesFromUrl();
          setFilterDialogOpen(false);
        }}
        actions={
          <GreenRoomButton
            type="accept"
            onClick={() => {
              updateURL();
              setFilterDialogOpen(false);
            }}
          >
            Apply
          </GreenRoomButton>
        }
      >
        <>
          {getMultiSelect(
            PROJECTS_LABEL,
            PROJECTS_GROUP,
            projectIds,
            PROJECTS_OPTIONS,
            setProjectIds
          )}
          {getMultiSelect(
            MEMBERS_LABEL,
            MEMBERS_GROUP,
            personIds,
            MEMBERS_OPTIONS,
            setPersonIds
          )}
          {getMultiSelect(
            VENUES_LABEL,
            VENUES_GROUP,
            locationGooglePlacesIds,
            VENUES_OPTIONS,
            setLocationGooglePlacesIds
          )}
          {getMultiSelect(
            EVENT_STATUSES_LABEL,
            EVENT_STATUSES_GROUP,
            eventStatuses,
            EVENT_STATUSES_OPTIONS,
            setEventStatuses
          )}
          {getMultiSelect(
            INVITE_STATUSES_LABEL,
            INVITE_STATUSES_GROUP,
            inviteStatuses,
            INVITE_STATUSES_OPTIONS,
            setInviteStatuses
          )}
          {getMultiSelect(
            LABELS_LABEL,
            LABELS_GROUP,
            labelIds,
            LABELS_OPTIONS,
            setLabelIds
          )}
        </>
      </GreenRoomDialog>
    </>
  );
}

export default FilterEvents;
