import moment from 'moment-timezone';

/// //////////
// Get string value for isAttending label
export const getStringValueForIsAttending = (isAttending: boolean | null) => {
  if (isAttending) return 'Attending';
  if (isAttending === false) return 'Declined';
  return 'Pending';
};

export const getFormattedDate = (timestamp: string | null) =>
  moment(timestamp).toDate().toLocaleString(undefined, {
    dateStyle: 'medium',
  });
