import { Typography } from '@mui/material';
import React from 'react';
import './KeyValue.scss';

interface KeyValueProps {
  secondary?: boolean | null;
  label: string;
  value: React.ReactNode | null;
  keyWidth?: string;
  centerVertically?: boolean | null;
}

function KeyValue({
  secondary,
  label,
  value,
  keyWidth,
  centerVertically,
}: KeyValueProps) {
  return (
    <div
      className="greenroom-key-values"
      style={{ alignItems: (centerVertically && 'center') || 'flex-start' }}
    >
      {(secondary && (
        <Typography variant="body2" className="greenroom-key" width={keyWidth}>
          {label}:
        </Typography>
      )) || (
        <h3 className="greenroom-key" style={{ width: keyWidth }}>
          {label}
        </h3>
      )}
      <Typography component="span" variant="body1" className="greenroom-value">
        {value}
      </Typography>
    </div>
  );
}

KeyValue.defaultProps = {
  secondary: false,
  keyWidth: '100px',
  centerVertically: false,
};

export default KeyValue;
