import React from 'react';
import './TabContent.scss';

interface TabContentProps {
  children?: React.ReactNode | null;
  actions?: React.ReactNode | null;
  className?: string;
}

function TabContent({ children, actions, className }: TabContentProps) {
  return (
    <>
      <div className={`tab-content ${className}`}>{children}</div>
      <div className="tab-actions">{actions}</div>
    </>
  );
}

TabContent.defaultProps = {
  children: null,
  actions: null,
  className: '',
};

export default TabContent;
