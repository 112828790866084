import { Event, Transaction } from '../api/events-api-slice';
import { PaymentType } from '../api/payment-types-api-slice';
import { ProjectTransaction } from '../api/transactions-api-slice';
import { Role, TeamMember, EditEvent } from '../models/edit-event';
import { getFormattedDate } from './event-helpers';

export const EXPENSE = 'Expense';
export const INCOME = 'Income';

interface TransactorProps {
  event: Event | EditEvent;
  transaction: Transaction | null;
  teamMembers?: readonly TeamMember[];
  roles?: readonly Role[];
}

export const getTransactor = ({
  event,
  transaction,
  teamMembers,
  roles,
}: TransactorProps) => {
  const eventPerson = event.eventPersons.find(
    (ep) => ep.id === transaction?.eventPersonId
  );
  const teamMember = teamMembers?.find((tm) => tm.id === eventPerson?.personId);
  const role = roles?.find((r) => r.id === eventPerson?.eventRoleTypeId);

  // TODO: Consider passing more associated content down, but for now, string will do
  // eslint-disable-next-line no-nested-ternary
  const transactor = eventPerson
    ? teamMember?.name
      ? `${teamMember?.name} (${role?.name})`
      : `(${role?.name})`
    : transaction?.transactor || '';

  return transactor;
};

export const getFormattedAmount = (
  amount: number,
  type: typeof EXPENSE | typeof INCOME
) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format((amount / 100) * (type === EXPENSE ? -1 : 1));

export const getCardTitle = (
  transactor: string | null | undefined,
  type: string | null | undefined
) => {
  if (transactor) {
    return transactor;
  }
  return type === 'Income' ? 'No payor' : 'No payee';
};

export const getPaymentInfo = (
  paymentTypes: PaymentType[] | undefined,
  transaction: Transaction | ProjectTransaction
) => {
  let string = 'Payment owed';
  const paymentType = paymentTypes?.find(
    (pt) => pt.id === transaction.paymentTypeId
  ) as PaymentType;

  if (transaction.isPaid) {
    string = `Paid on ${getFormattedDate(transaction.paidDate)}`;
  }

  if (paymentType) {
    string = `${string} (${paymentType.name})`;
  }

  return string;
};
