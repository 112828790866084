import { Button, Typography } from '@mui/material';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadIcon from '@mui/icons-material/Refresh';
import LogoutButton from '@mui/icons-material/Logout';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { ReactComponent as Crying } from './img/placeholders/crying.svg';

function OutagePage() {
  const { logout } = useAuth0();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px',
        }}
      >
        <img
          src="./Green_Room_logo_dark.png"
          alt="Green Room"
          style={{ width: '200px', marginBottom: '16px' }}
        />
        <Crying />
        <Typography variant="h4">Sorry, there was a problem.</Typography>
        <Typography variant="body1">
          We weren&apos;t able to load Green Room. <br />
          Please try reloading the page, or contact us if the problem persists.
        </Typography>
        <div style={{ marginTop: '16px' }}>
          <Button
            startIcon={<LoadIcon />}
            onClick={() => window.location.reload()}
          >
            Refresh
          </Button>
          <Button
            startIcon={<LogoutButton />}
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Log out
          </Button>
          <Button
            startIcon={<ChatBubbleIcon />}
            onClick={() => window.Intercom('show')}
          >
            Contact Us
          </Button>
        </div>
      </div>
    </div>
  );
}

export default OutagePage;
