export const PENDING_OPT_IN = 'PendingOptIn';
export const OPTED_IN = 'OptedIn';
export const OPTED_OUT = 'OptedOut';

export interface EditPerson {
  id: string | null;
  name: string;
  email: string | null;
  phoneNumber: string | null;
  projectId: string;
  defaultSendEmail: boolean;
  defaultSendText: boolean;
  phoneNumberStatus: string | null;
  phoneNumberOptInLastRequestedAt: string | null;
}

export const getSMSDisabled = (
  editPerson: EditPerson | undefined,
  isChecked: boolean,
  newPhoneNumber: boolean,
  smsEntitlementEnabled: boolean
) =>
  !isChecked &&
  (editPerson?.phoneNumberStatus !== OPTED_IN ||
    newPhoneNumber ||
    !smsEntitlementEnabled);

export const getEmailHelperText = (editPerson: EditPerson | undefined) => {
  let helperText;

  if (!editPerson) {
    helperText = 'Please select a team member to toggle notification settings';
  } else if (!editPerson?.email) {
    helperText = 'Please add an email to enable this setting';
  }

  return helperText;
};
