import React from 'react';
import Blankslate from '../Blankslate';
import { ReactComponent as Cowboy } from '../../img/placeholders/cowboy.svg';

interface NoEventRolesBlankslateProps {
  actions?: React.ReactNode | null;
  hideTitle?: boolean;
  children?: React.ReactNode | string;
}

function NoEventRolesBlankslate({
  actions,
  hideTitle,
  children,
}: NoEventRolesBlankslateProps) {
  return (
    <Blankslate
      title={!hideTitle ? 'No roles assigned' : null}
      actions={actions}
      svg={!hideTitle ? <Cowboy /> : null}
    >
      {children}
    </Blankslate>
  );
}

NoEventRolesBlankslate.defaultProps = {
  actions: null,
  hideTitle: false,
  children: null,
};

export default NoEventRolesBlankslate;
