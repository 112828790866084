import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { RefObject } from 'react';
// import AddIcon from '@mui/icons-material/Add';
import { useGetExternalAccountsQuery } from '../app/api/project-financial-account-slice';
import './ExternalAccountSelect.scss';

interface ExternalAccountSelectProps {
  projectId: string;
  id: string | null;
  // eslint-disable-next-line no-unused-vars
  onChange: (id: string) => void;
  innerRef?: RefObject<any>;
  disabled: boolean;
  label: string;
  className?: string | null;
}

function ExternalAccountSelect({
  projectId,
  id,
  onChange,
  innerRef,
  disabled,
  label,
  className,
}: ExternalAccountSelectProps) {
  // TODO: This type of Add Item option is repeated in src/components/ProjectSelect.tsx
  const ADD_BANK = 'create-a-new-bank-menu-item';
  const getExternalAccountsQuery = useGetExternalAccountsQuery(projectId);

  if (getExternalAccountsQuery.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={className || ''}>
      <FormControl fullWidth required>
        <InputLabel id="external-account-select-label" variant="standard">
          {label}
        </InputLabel>
        <Select
          inputRef={innerRef}
          label={label}
          labelId="external-account-select-label"
          value={id || ''}
          onChange={(e) => {
            if (e.target.value === ADD_BANK) {
              // TODO: Do something
            } else {
              onChange(e.target.value as string);
            }
          }}
          disabled={disabled}
          variant="standard"
        >
          {getExternalAccountsQuery.data?.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              {account.bankName} ••••{account.last4}
            </MenuItem>
          ))}
          {/* <MenuItem key={ADD_BANK} value={ADD_BANK}>
            <AddIcon className="add-new-select-option-icon" /> Add bank...
          </MenuItem> */}
        </Select>
      </FormControl>
    </div>
  );
}

ExternalAccountSelect.defaultProps = {
  innerRef: null,
  className: '',
};

export default ExternalAccountSelect;
