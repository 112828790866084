import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { TextField } from '@mui/material';
import configData from '../config.json';
import { EventLocation } from '../app/models/event-location';

interface EventLocationSelectProps {
  value?: EventLocation | null;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: EventLocation) => void;
}

function EventLocationSelect({ value, onChange }: EventLocationSelectProps) {
  const { ref } = usePlacesWidget({
    apiKey: configData.googleApiKey,
    onPlaceSelected: async (place) => {
      if (place) {
        /// //////////
        // Name
        const { name } = place;

        /// //////////
        // Street address
        const streetNumber = place.address_components?.find((x: any) =>
          x.types.includes('street_number')
        )?.short_name;
        const streetName = place.address_components?.find((x: any) =>
          x.types.includes('route')
        )?.short_name;
        const streetAddress =
          streetNumber && streetName && `${streetNumber} ${streetName}`;

        /// //////////
        // City
        let city: string = place.address_components?.find((x: any) =>
          x.types.includes('locality')
        )?.long_name;
        if (!city) {
          city = place.address_components?.find((x: any) =>
            x.types.includes('sublocality')
          )?.long_name;
        }
        if (!city) {
          city = place.address_components?.find((x: any) =>
            x.types.includes('postal_town')
          )?.long_name;
        }

        /// //////////
        // State
        const state: string = place.address_components?.find((x: any) =>
          x.types.includes('administrative_area_level_1')
        )?.short_name;

        /// //////////
        // Zipcode
        const zipCode: string = place.address_components?.find((x: any) =>
          x.types.includes('postal_code')
        )?.long_name;

        /// //////////
        // Timezone
        const location = place.geometry?.location;
        const tzResponse =
          location &&
          (await fetch(
            `https://maps.googleapis.com/maps/api/timezone/json?location=${location.lat()}%2C${location.lng()}&timestamp=${
              new Date().getTime() / 1000
            }&key=${configData.googleApiKey}`
          ));

        onChange({
          name,
          timeZone: (await tzResponse?.json())?.timeZoneId,
          streetAddress,
          city,
          state,
          zipCode,
          googlePlacesId: place?.place_id as string,
        });
      }
    },
    options: {
      types: [],
      fields: [
        'name',
        'address_component',
        'adr_address',
        'formatted_address',
        'geometry',
        'place_id',
      ],
    },
    inputAutocompleteValue: '',
  });

  const placeholder = 'Search by name or address';
  const label = 'Name or address';

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      fullWidth
      inputRef={ref}
      defaultValue={value?.name}
      autoComplete="off"
      onChange={(e) => {
        onChange({
          name: e.target.value || null,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          streetAddress: null,
          city: null,
          state: null,
          zipCode: null,
          googlePlacesId: null,
        });
      }}
    />
  );
}

EventLocationSelect.defaultProps = {
  value: null,
};

export default EventLocationSelect;
