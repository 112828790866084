import React from 'react';
import moment from 'moment';
import { Card, CardContent, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { EditEvent } from '../app/models/edit-event';
import Subheading from '../components/Text/Subheading';
import GreenRoomButton from '../components/GreenRoomButton';
import GreenRoomStack from '../components/GreenRoomStack';
import NoEventRolesBlankslate from '../components/Blankslates/NoEventLinksBlankslate';
import { EventLink } from '../app/api/events-api-slice';

interface EditEventPageLinksTabProps {
  editEvent: EditEvent;
  // eslint-disable-next-line no-unused-vars
  onChange: (editEvent: EditEvent) => void;
}

function EditEventPageLinksTab({
  editEvent,
  onChange,
}: EditEventPageLinksTabProps) {
  function onAddLink() {
    const newLinks = [
      ...editEvent.links,
      {
        id: moment().toISOString(),
        url: '',
        label: '',
      },
    ];

    onChange({
      ...editEvent,
      links: newLinks,
    });
  }

  function onDeleteLink(linkId: string) {
    const newLinks = editEvent.links.filter((link) => link.id !== linkId);

    onChange({
      ...editEvent,
      links: newLinks,
    });
  }

  function onChangeLinkField(
    link: EventLink,
    key: string,
    newValue: string | null
  ) {
    const newLinks = [...editEvent.links];
    const editedLinkIndex = newLinks.findIndex((l) => l.id === link.id);
    const editedLink = newLinks[editedLinkIndex];

    if (key === 'url') {
      newLinks[editedLinkIndex] = {
        ...editedLink,
        url: newValue || '',
      };
    }
    if (key === 'label') {
      newLinks[editedLinkIndex] = {
        ...editedLink,
        label: newValue || '',
      };
    }

    onChange({
      ...editEvent,
      links: newLinks,
    });
  }

  const links = editEvent.links.map((link) => (
    <Card key={link.id}>
      <CardContent className="edit-field-section-card-content">
        <div className="edit-field-group">
          <div className="edit-field">
            <TextField
              label="Name"
              fullWidth
              value={link.label}
              onChange={(e) => onChangeLinkField(link, 'label', e.target.value)}
              className="gr-edit-event-edit-event-link-label"
            />
          </div>
          <div className="edit-field">
            <TextField
              label="URL"
              required
              fullWidth
              value={link.url}
              onChange={(e) => onChangeLinkField(link, 'url', e.target.value)}
              className="gr-edit-event-edit-event-link-url"
            />
          </div>
        </div>
        <div className="edit-field-section-close-button-wrapper">
          <IconButton onClick={() => onDeleteLink(link.id)}>
            <CloseIcon />
          </IconButton>
        </div>
      </CardContent>
    </Card>
  ));

  const actions = (
    <div className="edit-field">
      <div className="event-role-type-select-and-add-button">
        <GreenRoomButton
          type="add"
          onClick={() => onAddLink()}
          className="gr-edit-event-add-link-url"
        >
          Add Link
        </GreenRoomButton>
      </div>
    </div>
  );

  /// //////////
  // Return main content
  return (
    <div>
      {!links.length && <NoEventRolesBlankslate actions={actions} />}
      {!!links.length && (
        <>
          <div>
            <Subheading>Links</Subheading>
            {links?.length > 0 && (
              <GreenRoomStack className="edit-field-section-card">
                {links}
              </GreenRoomStack>
            )}
          </div>
          <div>{actions}</div>
        </>
      )}
    </div>
  );
}

export default EditEventPageLinksTab;
