import React from 'react';
import { Avatar, Divider, Skeleton, Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FinancialAccountTransaction } from '../../app/api/project-financial-account-slice';
import {
  getFinancialAccountTransactionDisplayType,
  getFinancialAccountTransactionPreposition,
} from '../../app/helpers/financial-account-transaction-helpers';
import './FinancialAccountTransactionDetailDialog.scss';
import ErrorPage from '../../components/ErrorPage';
import GreenRoomDialog from '../../components/Dialog/GreenRoomDialog';
import GreenRoomButton from '../../components/GreenRoomButton';

interface FinancialAccountTransactionDetailDialogProps {
  open: boolean;
  onClose: () => void;
  transaction: FinancialAccountTransaction | undefined;
  isSuccess?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  error?: FetchBaseQueryError | SerializedError;
}

function FinancialAccountTransactionDetailDialog({
  open,
  onClose,
  transaction,
  isSuccess,
  isError,
  isLoading,
  error,
}: FinancialAccountTransactionDetailDialogProps) {
  function formatDateTime(date: Date) {
    return new Date(date).toLocaleString(undefined, {
      dateStyle: 'medium',
      timeStyle: 'long',
    });
  }

  const isLoadingOrUndefined = isLoading || !transaction;

  const displayType = () =>
    isLoadingOrUndefined ? (
      <Skeleton variant="text" />
    ) : (
      getFinancialAccountTransactionDisplayType(transaction)
    );
  const preposition = () =>
    isLoadingOrUndefined ? (
      <Skeleton variant="text" />
    ) : (
      getFinancialAccountTransactionPreposition(transaction)
    );
  const transactor = () =>
    isLoadingOrUndefined ? (
      <Skeleton variant="text" width={100} />
    ) : (
      transaction.transactor
    );
  const transactionAmount = () =>
    isLoadingOrUndefined ? (
      <Skeleton variant="text" width={100} />
    ) : (
      Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(transaction.amount / 100)
    );
  const displayStatus = () =>
    // eslint-disable-next-line no-nested-ternary
    isLoadingOrUndefined ? (
      <Skeleton variant="text" width={50} />
    ) : transaction.status === 'open' ? (
      'Processing'
    ) : (
      transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)
    );
  const transactionId = () =>
    isLoadingOrUndefined ? (
      <Skeleton variant="text" width={200} />
    ) : (
      transaction.id
    );
  const transactionInitiated = () =>
    isLoadingOrUndefined ? (
      <Skeleton variant="text" width={100} />
    ) : (
      <>
        Initiated: <b>{formatDateTime(new Date(transaction.createdAt!))}</b>
      </>
    );
  const transactionCompleted = () => {
    if (isLoadingOrUndefined) {
      return <Skeleton variant="text" width={100} />;
    }
    let c = 'pending';
    if (transaction.status === 'posted') {
      c = formatDateTime(new Date(transaction.postedAt!));
    } else if (transaction.status === 'voided') {
      c = formatDateTime(new Date(transaction.voidedAt!));
    }
    return (
      <>
        Completed: <b>{c}</b>
      </>
    );
  };
  const completedDot = () =>
    isLoadingOrUndefined || transaction.status === 'open'
      ? 'outlined'
      : 'filled';

  return (
    <GreenRoomDialog
      open={open}
      title="Transaction Details"
      onClose={onClose}
      actions={
        <>
          <GreenRoomButton
            type="contact"
            onClick={() =>
              window.Intercom(
                'showNewMessage',
                `I have a question about transaction ${transactionId()}. `
              )
            }
            disabled={!isSuccess}
          >
            Contact Us
          </GreenRoomButton>
          <GreenRoomButton
            type="error"
            onClick={() =>
              window.Intercom(
                'showNewMessage',
                `I need to report fraud regarding transaction ${transactionId()}. `
              )
            }
            disabled={!isSuccess}
          >
            Report Fraud
          </GreenRoomButton>
        </>
      }
    >
      {isError && error && (
        <ErrorPage
          error={error}
          action={onClose}
          actionText="Close"
          entity="transaction"
        />
      )}
      {(isSuccess || isLoading) && (
        <>
          <div className="fa-transaction-detail-heading">
            <Avatar>
              <AttachMoneyIcon />
            </Avatar>
            <Typography variant="body1">{displayType()}</Typography>
            <Typography variant="body2">
              {preposition()} {transactor()}
            </Typography>
            <Typography variant="h6">
              <b>{transactionAmount()}</b>
            </Typography>
            {transaction?.description && (
              <Typography variant="body2">
                {transaction?.description}
              </Typography>
            )}
          </div>
          <Divider />
          <div className="fa-transaction-detail-key-values-bar">
            <div className="fa-transaction-detail-key-values">
              <Typography variant="caption">Current Status</Typography>
              <Typography variant="body2">{displayStatus()}</Typography>
            </div>
            <div className="fa-transaction-detail-key-values">
              <Typography variant="caption">Transaction ID</Typography>
              <Typography variant="body2">
                {transaction?.hostedRegulatoryReceiptUrl == null &&
                  transactionId()}
                {transaction?.hostedRegulatoryReceiptUrl != null && (
                  <a
                    href={transaction?.hostedRegulatoryReceiptUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {transactionId()}
                  </a>
                )}
              </Typography>
            </div>
          </div>
          <Divider />
          <div className="fa-transaction-detail-timeline">
            <Timeline
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="caption">
                    {transactionInitiated()}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot variant={completedDot()} />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="caption">
                    {transactionCompleted()}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
            <div>
              <Typography variant="caption">
                Bank transfers initiated before 6pm CST on business days will
                typically be available in your bank account the next business
                day, but it can take up to 3 business days before funds arrive.
                Business days are Monday through Friday, excluding bank
                holidays.{' '}
                <a
                  href="https://help.greenroom.app/ach-external-transfer-timeline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more...
                </a>
              </Typography>
            </div>
          </div>
        </>
      )}
    </GreenRoomDialog>
  );
}

FinancialAccountTransactionDetailDialog.defaultProps = {
  isSuccess: false,
  isError: false,
  isLoading: false,
  error: undefined,
};

export default FinancialAccountTransactionDetailDialog;
