import React from 'react';

import { Chip } from '@mui/material';
import StarsIcon from '@mui/icons-material/WorkspacePremium';

interface PremiumFeatureChipProps {
  projectId: string;
  size: 'small' | 'medium';
  margin?: 'left' | 'top';
  label?: string;
}

function PremiumFeatureChip({
  projectId,
  size,
  margin,
  label,
}: PremiumFeatureChipProps) {
  return (
    <Chip
      icon={<StarsIcon />}
      label={label}
      size={size}
      color="primary"
      sx={{
        backgroundColor: '#2bb6f6',
        color: '#fff',
        marginTop: margin === 'top' ? '8px' : null,
        marginLeft: margin === 'left' ? '8px' : null,
        cursor: 'pointer',
      }}
      component="a"
      href={`${window.location.origin}/settings/project/${projectId}?tab=subscription`}
    />
  );
}

PremiumFeatureChip.defaultProps = {
  margin: undefined,
  label: 'Premium Feature',
};

export default PremiumFeatureChip;
