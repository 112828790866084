import React from 'react';
import { Avatar } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GreenRoomStack from '../../components/GreenRoomStack';
import GreenRoomCard from '../../components/GreenRoomCard';
import Subheading from '../../components/Text/Subheading';

function Card() {
  return (
    <>
      <Subheading>Primary cards inside a GreenRoomStack</Subheading>
      <GreenRoomStack>
        <GreenRoomCard
          key="6"
          title="Card with subtitle"
          subtitle="A subtitle"
        />
        <GreenRoomCard key="2" onClick={() => {}} title="Clickable card" />
        <GreenRoomCard key="3" title="Removable card" onRemove={() => {}} />
        <GreenRoomCard
          key="7"
          title="Card with everything"
          subtitle="A subtitle"
          left={<Avatar>Me</Avatar>}
          right="$100"
        />
      </GreenRoomStack>
      <br />
      <br />
      <Subheading>Secondary cards</Subheading>
      <GreenRoomCard
        secondary
        key="1"
        title="A secondary card"
        subtitle="Subtitle"
        left={<AttachMoneyIcon />}
        right="$0"
      />
      <GreenRoomCard
        secondary
        key="2"
        title="Another secondary card"
        subtitle="Subtitle"
        left={<AttachMoneyIcon />}
        right="$10"
      />
      <GreenRoomCard
        secondary
        key="3"
        title="A secondary card with no subtitle"
        left={<AttachMoneyIcon />}
        right="$100"
      />
    </>
  );
}

export default Card;
