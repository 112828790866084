import React, { useRef, useEffect } from 'react';
import { useMediaQuery, useTheme, Stack, Typography } from '@mui/material';
import Chart from 'chart.js/auto';
import { ChartType } from 'chart.js';
import moment from 'moment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Subheading from '../../components/Text/Subheading';
import { useGetProjectTransactionsSummaryQuery } from '../../app/api/transactions-api-slice';
import Loading from '../../components/Loading';
import GreenRoomCard from '../../components/GreenRoomCard';
import NoProjectTransactionsBlankslate from '../../components/Blankslates/NoProjectTransactionsBlankslate';

interface FinancesHomeProps {
  projectId: string;
}

/// //////////
// Chart labels
const EARNED_PROJECTED = 'Earned (Projected)';
const SPENT_PROJECTED = 'Spent (Projected)';

/// //////////
// Chart types
const barChartType: ChartType = 'bar';

/// //////////
// Basic chart formatters
const getFormattedCurrency = (value: number = 0) => value / 100;
const getChartColor = (type: 'earned' | 'spent', opacity: Number = 1) => {
  const rgb = (type === 'earned' && '53,180,76') || '26,35,126';

  return `rgba(${rgb}, ${opacity})`;
};

/// //////////
// X-Axis data collection
// Returns first and last date of each month
const currentMonth = moment();
const getPastMonth = (month: moment.Moment, x: number) =>
  moment(month).subtract(x, 'months');
const getFutureMonth = (month: moment.Moment, x: number) =>
  moment(month).add(x, 'months');

const getMonths = () => {
  const NUMBER_OF_PAST_MONTHS = 4;
  const NUMBER_OF_FUTURE_MONTHS = 1;
  const months = [currentMonth] as moment.Moment[];

  // Get labels for past months
  for (let i = 1; i <= NUMBER_OF_PAST_MONTHS; i += 1) {
    months.unshift(getPastMonth(currentMonth, i));
  }
  // Get labels for future months
  for (let i = 1; i <= NUMBER_OF_FUTURE_MONTHS; i += 1) {
    months.push(getFutureMonth(currentMonth, i));
  }

  const timeseriesRanges = months.map((month) => ({
    from: moment(month).startOf('month').format('YYYY-MM-DD'),
    to: moment(month).endOf('month').format('YYYY-MM-DD'),
  }));

  return timeseriesRanges;
};

/// //////////
// Y-Axis data collection
// Build array of financial summaries, one per month
const getTransactionsSummaries = (id: string | null) =>
  getMonths().map(({ from, to }) => {
    const transactionsSummary = useGetProjectTransactionsSummaryQuery(
      {
        projectId: id!,
        from,
        to,
      },
      { skip: !id }
    );

    return transactionsSummary;
  });

/// //////////
// Combine X-Axis and Y-Axis data
// Return chart data if any exists or returns null
const buildChartData = (id: string | null) => {
  // Use project id to fetch new transaction data
  const transactionsSummaries = getTransactionsSummaries(id);

  // Collect all data slices
  const dataSlices = transactionsSummaries.filter(
    ({ data }) =>
      data?.income ||
      data?.expenses ||
      data?.pendingIncome ||
      data?.pendingExpenses
  );

  const isFetchingData = transactionsSummaries.find(
    ({ isFetching }) => isFetching
  );

  // If fetching data still, return null
  if (isFetchingData) {
    return null;
  }

  // If all data slices are empty, return undefined to trigger blankslate
  if (!dataSlices.length) {
    return undefined;
  }

  // Format x-axis labels
  const getLabels = () =>
    transactionsSummaries.map((summary) =>
      moment(summary?.originalArgs?.from).format("MMM 'YY")
    );

  const commonDatasetProps = {
    borderWidth: 1,
    borderRadius: 5,
    borderSkipped: 'bottom' as const,
  };

  return {
    labels: getLabels(),
    datasets: [
      {
        label: 'Earned',
        data: transactionsSummaries.map(({ data }) =>
          getFormattedCurrency(data?.income)
        ),
        backgroundColor: getChartColor('earned', 0.85),
        borderColor: getChartColor('earned'),
        stack: 'Stack 0',
        ...commonDatasetProps,
      },
      {
        label: 'Spent',
        data: transactionsSummaries.map(({ data }) =>
          getFormattedCurrency(data?.expenses)
        ),
        backgroundColor: getChartColor('spent', 0.85),
        borderColor: getChartColor('spent'),
        stack: 'Stack 1',
        ...commonDatasetProps,
      },
      {
        label: EARNED_PROJECTED,
        data: transactionsSummaries.map(({ data }) =>
          getFormattedCurrency(data?.pendingIncome)
        ),
        backgroundColor: getChartColor('earned', 0.5),
        borderColor: getChartColor('earned'),
        stack: 'Stack 0',
        ...commonDatasetProps,
      },
      {
        label: SPENT_PROJECTED,
        data: transactionsSummaries.map(({ data }) =>
          getFormattedCurrency(data?.pendingExpenses)
        ),
        backgroundColor: getChartColor('spent', 0.5),
        borderColor: getChartColor('spent'),
        stack: 'Stack 1',
        ...commonDatasetProps,
      },
    ],
  };
};

/// //////////
// Complete ChartJS chart configuration object
// Returns configuration object as defined by ChartJS TODO: Add type for data
const getChartConfig = (data: any, isMobile: boolean) => ({
  type: barChartType,
  defaults: {
    font: {
      family: 'Urbanist',
    },
  },
  data,
  plugins: [
    {
      id: 'increase-legend-spacing',
      beforeInit(chart: any) {
        // Get reference to the original fit function
        const originalFit = chart.legend.fit;

        // Override the fit function
        // eslint-disable-next-line no-param-reassign
        chart.legend.fit = function fit() {
          // Call original function and bind scope in order to use `this` correctly inside it
          originalFit.bind(chart.legend)();
          // Change the height as suggested in another answers
          this.height += 16;
        };
      },
    },
  ],
  options: {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        onClick() {}, // Remove default filter click event from legend items
        labels: {
          boxHeight: 20,
          boxWidth: 30,
          padding: 12,
          useBorderRadius: true,
          // Hide projected legend items on mobile
          filter(item: any) {
            if (
              isMobile &&
              (item.text === EARNED_PROJECTED || item.text === SPENT_PROJECTED)
            ) {
              return false;
            }
            return item;
          },
        },
      },
      tooltip: {
        // Include a dollar sign in the ticks
        callbacks: {
          label(context: any) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          drawTicks: false,
        },
        border: {
          dash: [4, 4],
          display: false,
        },
        ticks: {
          padding: 16,
          // Include a dollar sign in the ticks
          callback(value: any) {
            return `$${value}`;
          },
        },
      },
    },
  },
});

function FinancesHome({ projectId }: FinancesHomeProps) {
  /// //////////
  // Responsiveness
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  /// //////////
  // Create reference to chart location
  const chartCanvasRef = useRef(null);

  /// //////////
  // Initialize chart data fetching
  const chartData = buildChartData(projectId);
  const isChartDataStillFetching = (data: any) => data === null;
  const isChartDataDoneFetchingAndEmpty = (data: any) => data === undefined;

  /// //////////
  // Rebuild the chart everytime:
  // 1) screen breakpoint crossed
  // 2) chartData updates because projectId changes
  useEffect(() => {
    const chartCanvas = chartCanvasRef.current;

    /// //////////
    // If the DOM hasn't rendered yet, don't try and build the chart; Requires reference
    // Let's also not try and build the chart if still fetching data or if no data exists
    if (!chartCanvas || !chartData || isChartDataStillFetching(chartData)) {
      return undefined;
    }

    // Global override of default font styles
    Chart.defaults.font.family = 'Urbanist';
    Chart.defaults.font.size = 14;

    const chartInstance = new Chart(
      chartCanvas,
      getChartConfig(chartData, mobile)
    );

    return () => chartInstance.destroy();
  }, [mobile, chartData]);

  /// //////////
  // IMPORTANT NOTE: <canvas> must always be rendered in order for the chart to have a referenced html element to load into
  // Because of this, we will create a loadingOrBlankslate variable that, when defined, will cause the main content to be invisible but still rendered in the DOM

  let loadingOrBlankslate;

  // If data fetching is ongoing, let's render a loading state
  if (isChartDataStillFetching(chartData)) {
    loadingOrBlankslate = <Loading />;
  } else if (!chartData && isChartDataDoneFetchingAndEmpty(chartData)) {
    // If the data fetching is done, but there is no data let's render a blankslate
    loadingOrBlankslate = <NoProjectTransactionsBlankslate />;
  }

  /// //////////
  // Build Current Month data

  // Define current month
  const currentMonthTransactionSummary: any =
    getTransactionsSummaries(projectId)[4];

  // Pluck individual current month metrics
  const currentMonthIncome = currentMonthTransactionSummary?.data?.income;
  const currentMonthPendingIncome =
    currentMonthTransactionSummary?.data?.pendingIncome;
  const currentMonthExpenses = currentMonthTransactionSummary?.data?.expenses;
  const currentMonthPendingExpenses =
    currentMonthTransactionSummary?.data?.pendingExpenses;

  // Calculate current month net income
  const currentMonthNetIncome =
    currentMonthIncome +
    currentMonthPendingIncome -
    currentMonthExpenses -
    currentMonthPendingExpenses;

  // Define previous month
  const previousMonthName: string = getPastMonth(currentMonth, 1).format('MMM');
  const previousMonthTransactionSummary: any =
    getTransactionsSummaries(projectId)[3];

  // Pluck individual previous month metrics
  const previousMonthIncome = previousMonthTransactionSummary?.data?.income;
  const previousMonthPendingIncome =
    previousMonthTransactionSummary?.data?.pendingIncome;
  const previousMonthExpenses = previousMonthTransactionSummary?.data?.expenses;
  const previousMonthPendingExpenses =
    previousMonthTransactionSummary?.data?.pendingExpenses;

  // Calculate previous month net income
  const previousMonthNetIncome =
    previousMonthIncome +
    previousMonthPendingIncome -
    previousMonthExpenses -
    previousMonthPendingExpenses;

  // Make comparisons between current month and previous month
  const previousMonthExpensesFromCurrentMonth = Math.abs(
    currentMonthExpenses - previousMonthExpenses
  );
  const previousMonthExpensesFromCurrentMonthIsPositive =
    currentMonthExpenses > previousMonthExpenses;
  const previousMonthNetIncomeFromCurrentMonth = Math.abs(
    currentMonthNetIncome - previousMonthNetIncome
  );
  const previousMonthNetIncomeFromCurrentMonthIsPositive =
    currentMonthNetIncome > previousMonthNetIncome;

  // Format currency; Use multiplier to make negative
  const getFormattedCurrencyWithSymbol = (value: number, multiplier = 1) =>
    Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(getFormattedCurrency(value * multiplier));

  // Build styles based on dollar amounts
  const positiveAmountStyles = {
    color: 'primary.dark',
    fontWeight: 700,
  };

  let currentMonthIncomeStyles = {};
  if (currentMonthIncome > 0) {
    currentMonthIncomeStyles = positiveAmountStyles;
  }

  let currentMonthNetIncomeStyles = {};
  if (currentMonthNetIncome > 0) {
    currentMonthNetIncomeStyles = positiveAmountStyles;
  }

  /// //////////
  // Render main content
  return (
    <>
      {loadingOrBlankslate}
      {!loadingOrBlankslate && (
        <Stack
          spacing={4}
          sx={{ visibility: loadingOrBlankslate ? 'hidden' : 'visible' }}
        >
          <div>
            <Subheading>Monthly Overview</Subheading>
            <div style={{ height: '340px' }}>
              <canvas ref={chartCanvasRef} />
            </div>
          </div>
          <div>
            <Subheading>Current Month</Subheading>
            <GreenRoomCard
              secondary
              key="earned-so-far"
              title="Earned So Far"
              left={
                <AttachMoneyIcon
                  sx={{ color: getChartColor('earned', 0.85) }}
                />
              }
              right={
                <Typography
                  variant="body1"
                  sx={{ ...currentMonthIncomeStyles }}
                >
                  {getFormattedCurrencyWithSymbol(currentMonthIncome)}
                </Typography>
              }
            />
            <GreenRoomCard
              secondary
              key="current-spend"
              title="Current Spend"
              subtitle={`${getFormattedCurrencyWithSymbol(
                previousMonthExpensesFromCurrentMonth
              )} ${
                previousMonthExpensesFromCurrentMonthIsPositive
                  ? 'more'
                  : 'less'
              } than ${previousMonthName}`}
              left={
                <ReceiptIcon sx={{ color: getChartColor('spent', 0.85) }} />
              }
              right={
                <Typography variant="body1">
                  {getFormattedCurrencyWithSymbol(
                    currentMonthExpenses,
                    (currentMonthExpenses > 0 && -1) || 1
                  )}
                </Typography>
              }
            />
            <GreenRoomCard
              secondary
              key="projected-total"
              title="Projected Net Income"
              subtitle={`${getFormattedCurrencyWithSymbol(
                previousMonthNetIncomeFromCurrentMonth
              )} ${
                previousMonthNetIncomeFromCurrentMonthIsPositive
                  ? 'more'
                  : 'less'
              } than ${previousMonthName}`}
              left={
                <AccessTimeIcon sx={{ color: getChartColor('earned', 0.5) }} />
              }
              right={
                <Typography
                  variant="body1"
                  sx={{ ...currentMonthNetIncomeStyles }}
                >
                  {getFormattedCurrencyWithSymbol(
                    currentMonthNetIncome,
                    (currentMonthNetIncome < 0 && -1) || 1
                  )}
                </Typography>
              }
            />
          </div>
        </Stack>
      )}
    </>
  );
}

export default FinancesHome;
