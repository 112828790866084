import React from 'react';
import { FormHelperText, Link } from '@mui/material';
import { EditPerson } from '../app/helpers/contact-helpers';

interface EmailHelperTextProps {
  editPerson: EditPerson | undefined;
  // eslint-disable-next-line react/no-unused-prop-types
  onClick?: () => void;
}

function EmailHelperText({ editPerson, onClick }: EmailHelperTextProps) {
  let helperText;

  if (!editPerson) {
    helperText = 'Please select a team member to toggle notification settings';
  } else if (!editPerson?.email) {
    helperText = 'Please add an email to enable this setting';

    if (onClick) {
      return (
        <FormHelperText>
          Please {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            component="button"
            onClick={onClick}
            style={{ textTransform: 'unset' }}
            variant="caption"
          >
            add an email
          </Link>{' '}
          to enable this setting
        </FormHelperText>
      );
    }
  }

  if (helperText) return <FormHelperText>{helperText}</FormHelperText>;

  return null;
}

EmailHelperText.defaultProps = {
  onClick: null,
};

export default EmailHelperText;
