import React from 'react';
import { Stack } from '@mui/material';
import GreenRoomButton from '../../components/GreenRoomButton';

function Buttons() {
  return (
    <Stack spacing={2} sx={{ marginTop: '24px' }}>
      <GreenRoomButton type="accept">Accept</GreenRoomButton>
      <GreenRoomButton type="create">Create</GreenRoomButton>
      <GreenRoomButton type="edit">Edit</GreenRoomButton>
      <GreenRoomButton type="transfer">Transfer</GreenRoomButton>
      <GreenRoomButton type="contact">Contact</GreenRoomButton>
      <GreenRoomButton type="add">Add</GreenRoomButton>
      <GreenRoomButton type="add" subtle>
        Add subtle
      </GreenRoomButton>
      <GreenRoomButton type="copy">Copy</GreenRoomButton>
      <GreenRoomButton type="copy" subtle>
        Copy subtle
      </GreenRoomButton>
      <GreenRoomButton type="cancel">Cancel</GreenRoomButton>
      <GreenRoomButton type="destroy">Destroy</GreenRoomButton>
      <GreenRoomButton type="error">Error</GreenRoomButton>
    </Stack>
  );
}

export default Buttons;
