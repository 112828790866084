import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useState, RefObject } from 'react';
import { useGetProjectsQuery } from '../app/api/projects-api-slice';
import CreateProjectDialog from '../pages/CreateProjectDialog';
import './ProjectSelect.scss';

interface ProjectSelectProps {
  id?: string | null;
  // eslint-disable-next-line no-unused-vars
  onChange: (id: string) => void;
  disabled?: boolean;
  innerRef?: RefObject<any>;
  autoSelectProject?: boolean;
}

function ProjectSelect({
  id,
  onChange,
  disabled,
  innerRef,
  autoSelectProject,
}: ProjectSelectProps) {
  // TODO: This type of Add Item option is repeated in src/components/ExternalAccountSelect.tsx
  const CREATE_EVENT = 'create-a-new-event-menu-item';
  const [createProjectDialogOpen, setCreateProjectDialogOpen] = useState(false);
  const projectsQuery = useGetProjectsQuery();

  if (projectsQuery.isLoading) {
    return <div>Loading...</div>;
  }

  // auto-select the project if there's only one
  if (autoSelectProject && projectsQuery.data?.length === 1 && !id) {
    onChange(projectsQuery.data[0].id);
  }

  return (
    <>
      <FormControl fullWidth required>
        <InputLabel id="project-select-label">Project</InputLabel>
        <Select
          inputRef={innerRef}
          label="Project"
          labelId="project-select-label"
          value={id}
          onChange={(e) => {
            if (e.target.value === CREATE_EVENT) {
              setCreateProjectDialogOpen(true);
            } else {
              onChange(e.target.value as string);
            }
          }}
          disabled={disabled}
        >
          {projectsQuery.data
            ?.filter(
              (project) => project.role === 'Owner' || project.role === 'Admin'
            )
            .map((project) => (
              <MenuItem key={project.id} value={project.id}>
                {project.name}
              </MenuItem>
            ))}
          <MenuItem key={CREATE_EVENT} value={CREATE_EVENT}>
            <AddIcon className="project-select-option-icon" /> Create project...
          </MenuItem>
        </Select>
      </FormControl>
      <CreateProjectDialog
        open={createProjectDialogOpen}
        openChange={(open, newProjectId) => {
          setCreateProjectDialogOpen(open);
          if (newProjectId) onChange(newProjectId);
        }}
      />
    </>
  );
}

ProjectSelect.defaultProps = {
  id: undefined,
  disabled: false,
  innerRef: null,
  autoSelectProject: true,
};

export default ProjectSelect;
