/* eslint-disable no-unused-vars */
import React from 'react';
import { DialogTitle, Button, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackNewIcon from '@mui/icons-material/ArrowBackIosNew';
import './GreenRoomDialogHeader.scss';

interface GreenRoomDialogHeaderProps {
  title: string | React.ReactNode;
  onClose: () => void;
  onCloseDisabled?: boolean | null;
  onBack?: () => void | null;
}

function GreenRoomDialogHeader({
  title,
  onClose,
  onCloseDisabled,
  onBack,
}: GreenRoomDialogHeaderProps) {
  return (
    <>
      <div className="green-room-dialog-header">
        <Button
          onClick={onBack}
          sx={{ visibility: (onBack && 'inherit') || 'hidden' }}
        >
          <ArrowBackNewIcon />
        </Button>
        <DialogTitle
          sx={{ visibility: (title && 'inherit') || 'hidden' }}
          className="green-room-dialog-title"
        >
          {title}
        </DialogTitle>
        <Button onClick={onClose} disabled={onCloseDisabled || false}>
          <CloseIcon />
        </Button>
      </div>
      <Divider className="green-room-dialog-divider" />
    </>
  );
}

GreenRoomDialogHeader.defaultProps = {
  onCloseDisabled: false,
  onBack: null,
};

export default GreenRoomDialogHeader;
