import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import { STATES } from '../app/helpers/states';

export interface Address {
  line1?: string;
  line2?: string | null;
  city?: string;
  state?: string;
  postalCode?: string;
}

interface AddressSelectProps {
  value: Address | null;
  // eslint-disable-next-line no-unused-vars
  onChange: (address: Address) => void;
  disabled?: boolean;
}

// eslint-disable-next-line no-unused-vars
function AddressSelect({ value, onChange, disabled }: AddressSelectProps) {
  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Address Line 1"
          value={value?.line1 ?? ''}
          disabled={disabled}
          onChange={(e) => onChange({ ...value, line1: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Address Line 2"
          value={value?.line2 ?? ''}
          disabled={disabled}
          onChange={(e) => onChange({ ...value, line2: e.target.value })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="City"
          value={value?.city ?? ''}
          disabled={disabled}
          onChange={(e) => onChange({ ...value, city: e.target.value })}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="state-label">State</InputLabel>
          <Select
            value={value?.state ?? ''}
            disabled={disabled}
            labelId="state-label"
            label="State"
            onChange={(e) => onChange({ ...value, state: e.target.value })}
          >
            <MenuItem key="" value="">
              (Select a state)
            </MenuItem>
            {STATES.map((state) => (
              <MenuItem key={state.abbreviation} value={state.abbreviation}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Zip Code"
          value={value?.postalCode ?? ''}
          disabled={disabled}
          onChange={(e) => onChange({ ...value, postalCode: e.target.value })}
        />
      </Grid>
    </>
  );
}

AddressSelect.defaultProps = {
  disabled: false,
};

export default AddressSelect;
