import { CircularProgress } from '@mui/material';
import React from 'react';
import './Loading.scss';

function Loading() {
  return (
    <div className="loading-wrapper">
      <CircularProgress size={150} />
    </div>
  );
}

export default Loading;
