import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useCreateProjectUserMutation } from '../app/api/projects-api-slice';
import ServerErrorDisplay from '../components/ServerErrorDisplay';
import GreenRoomButton from '../components/GreenRoomButton';
import GreenRoomTooltip from '../components/GreenRoomTooltip';
import './AddAdministratorDialog.scss';

export interface AddAdministratorDialogProps {
  projectId: string;
  fullScreen: boolean;
  onClose: () => void;
  currentUserRole: 'Owner' | 'Admin' | undefined;
}

function AddAdministratorDialog({
  projectId,
  fullScreen,
  onClose,
  currentUserRole,
}: AddAdministratorDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = React.useState('');
  const isEmailValid = email.match(
    /\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b/
  );
  const [makeOwner, setMakeOwner] = React.useState(false);

  const [createProjectUser, createProjectUserStatus] =
    useCreateProjectUserMutation();

  const handleSave = () => {
    if (!createProjectUserStatus.isLoading) {
      createProjectUser({
        projectId,
        email,
        role: makeOwner ? 'Owner' : 'Admin',
      });
    }
  };

  useEffect(() => {
    if (createProjectUserStatus.isSuccess) {
      onClose();
    }
    if (createProjectUserStatus.isError) {
      const errorDisplay = (
        <ServerErrorDisplay
          error={createProjectUserStatus.error}
          errorMessage="There was a problem adding the administrator."
        />
      );
      enqueueSnackbar(errorDisplay, {
        variant: 'error',
      });
    }
  }, [createProjectUserStatus]);

  return (
    <Dialog open fullScreen={fullScreen}>
      <DialogTitle>Add Project Administrator</DialogTitle>
      <DialogContent>
        <div className="add-administrator-content">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' && isEmailValid) {
                    handleSave();
                  }
                }}
                autoComplete="off"
                error={email !== '' && !isEmailValid}
              />
            </Grid>
            {currentUserRole === 'Owner' && (
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={makeOwner}
                        onChange={(e) => setMakeOwner(e.target.checked)}
                      />
                    }
                    label="Make Owner"
                  />
                </FormGroup>
                <GreenRoomTooltip title="Project owners can see and make changes to financial accounts, delete your project, and grant admin and ownership privileges to others." />
              </Grid>
            )}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <GreenRoomButton type="cancel" onClick={() => onClose()}>
          Cancel
        </GreenRoomButton>
        <GreenRoomButton
          type="accept"
          disabled={!isEmailValid}
          onClick={() => handleSave()}
        >
          Save
        </GreenRoomButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddAdministratorDialog;
