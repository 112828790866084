import { Button, Card, LinearProgress, Typography } from '@mui/material';
import StarsIcon from '@mui/icons-material/WorkspacePremium';
import React from 'react';
import './ProjectFileQuota.scss';
import { useGetFileQuotaQuery } from '../app/api/files-api-slice';
import getHumanReadable from '../app/helpers/file-size-helpers';

interface ProjectFileManagementProps {
  projectId: string;
}

function getColor(value: number) {
  if (value >= 90) {
    return 'error';
  }
  if (value >= 80) {
    return 'warning';
  }
  return 'primary';
}

function ProjectFileManagement({ projectId }: ProjectFileManagementProps) {
  const getFileQuota = useGetFileQuotaQuery(projectId);

  const totalBytes = getFileQuota.data?.totalBytes ?? 0;
  const totalBytesLimit = getFileQuota.data?.totalBytesLimit;
  const displayTotalBytes = getFileQuota.isFetching
    ? '...'
    : `${getHumanReadable(totalBytes)} / ${
        totalBytesLimit ? getHumanReadable(totalBytesLimit) : '∞'
      }`;
  let totalBytesPercentage = !totalBytesLimit
    ? 0
    : (totalBytes / totalBytesLimit) * 100;
  const displayTotalBytesPercentage = !totalBytesLimit
    ? null
    : ` (${totalBytesPercentage.toLocaleString(undefined, {
        maximumFractionDigits: 1,
      })}%)`;
  if (totalBytesPercentage > 100) {
    totalBytesPercentage = 100;
  }

  const progressVariant = getFileQuota.isFetching
    ? 'indeterminate'
    : 'determinate';

  return (
    <Card sx={{ margin: '8px 0px 8px 0px', padding: '8px 0px 8px 0px' }}>
      <div className="file-quota-body">
        <div className="file-quota-detail">
          <div>
            <Typography variant="caption">
              Space used: {displayTotalBytes}
              {displayTotalBytesPercentage}
            </Typography>
          </div>
          <LinearProgress
            value={totalBytesPercentage}
            variant={progressVariant}
            color={getColor(totalBytesPercentage)}
          />
        </div>
      </div>
      <Button
        startIcon={<StarsIcon />}
        size="small"
        fullWidth
        color="info"
        href={`${window.location.origin}/settings/project/${projectId}?tab=subscription`}
      >
        Need more space? Upgrade your subscription.
      </Button>
    </Card>
  );
}

export default ProjectFileManagement;
