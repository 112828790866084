/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import './AudioPlayer.scss';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import {
  DialogContent,
  Fab,
  IconButton,
  Slider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setAudioFile, type RootState, AudioFile } from '../app/store';
import GreenRoomDialog from './Dialog/GreenRoomDialog';

export function useAudioPlayer() {
  const audioFile = useSelector((state: RootState) => state.app.audioFile);
  const dispatch = useDispatch();

  return {
    audioFile,
    setAudioFile: (f: AudioFile | null) => dispatch(setAudioFile(f)),
  };
}

function formatTime(time: number) {
  const minutes = Math.floor(time / 60).toString();
  const seconds = Math.floor(time % 60)
    .toString()
    .padStart(2, '0');
  return `${minutes}:${seconds}`;
}

function AudioPlayer() {
  // eslint-disable-next-line no-shadow
  const { audioFile, setAudioFile } = useAudioPlayer();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const audio = useRef<HTMLAudioElement>(null);
  const [playerOpen, setPlayerOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [currentTime, setCurrentTime] = useState<string>('00:00');
  const [time, setTime] = useState<string>('00:00');
  const [sliderValue, setSliderValue] = useState<number>(0);

  useEffect(() => {
    if (audioFile) {
      if (audio.current) {
        audio.current.src = audioFile.path;
      }
      setPlayerOpen(true);
    } else {
      setPlayerOpen(false);
    }
  }, [audioFile]);

  const flexDirection = isMobile ? 'column' : 'row';

  return (
    <>
      {audioFile && (
        <div className="audio-player-fab">
          <Fab
            color="primary"
            size="small"
            onClick={() => {
              setPlayerOpen(true);
            }}
          >
            <svg
              id="equalizer"
              width="20px"
              height="14px"
              viewBox="0 0 10 7"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="#fff">
                <rect
                  id="bar1"
                  transform="translate(0.500000, 6.000000) rotate(180.000000) translate(-0.500000, -6.000000) "
                  x="0"
                  y="5"
                  width="1"
                  height="2px"
                />
                <rect
                  id="bar2"
                  transform="translate(3.500000, 4.500000) rotate(180.000000) translate(-3.500000, -4.500000) "
                  x="3"
                  y="2"
                  width="1"
                  height="5"
                />
                <rect
                  id="bar3"
                  transform="translate(6.500000, 3.500000) rotate(180.000000) translate(-6.500000, -3.500000) "
                  x="6"
                  y="0"
                  width="1"
                  height="7"
                />
                <rect
                  id="bar4"
                  transform="translate(9.500000, 5.000000) rotate(180.000000) translate(-9.500000, -5.000000) "
                  x="9"
                  y="3"
                  width="1"
                  height="4"
                />
              </g>
            </svg>
          </Fab>
        </div>
      )}
      <GreenRoomDialog
        title={<Typography variant="caption">{audioFile?.name}</Typography>}
        open={!!audioFile && playerOpen}
        onClose={() => setPlayerOpen(false)}
        fullWidth
        actions={audioFile?.actions}
      >
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection,
              alignItems: 'center',
            }}
          >
            <div
              style={{
                marginRight: '16px',
                minWidth: '220px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => {
                  audio.current!.currentTime = 0;
                }}
              >
                <SkipPreviousIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  if (isPlaying) {
                    audio.current?.pause();
                  } else {
                    audio.current?.play();
                  }
                }}
              >
                {!isPlaying && <PlayIcon />}
                {isPlaying && <PauseIcon />}
              </IconButton>
              <IconButton onClick={() => setAudioFile(null)}>
                <StopIcon />
              </IconButton>
              {currentTime} / {time}
            </div>
            <Slider
              sx={{ flex: 1 }}
              value={sliderValue}
              onChange={() => {
                setIsSeeking(true);
                setCurrentTime(formatTime(audio.current!.currentTime));
              }}
              onChangeCommitted={(_, value) => {
                setIsSeeking(false);
                if (audio.current) {
                  audio.current.currentTime =
                    ((value as number) / 100) * audio.current.duration;
                }
              }}
            />
          </div>
        </DialogContent>
      </GreenRoomDialog>
      {audioFile && (
        <audio
          autoPlay
          ref={audio}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onTimeUpdate={() => {
            if (isSeeking) return;
            setCurrentTime(formatTime(audio.current!.currentTime));
            setSliderValue(
              (audio.current!.currentTime / audio.current!.duration) * 100
            );
          }}
          onLoadedMetadata={() => {
            setTime(formatTime(audio.current!.duration));
          }}
        >
          <source src={audioFile.path} />
        </audio>
      )}
    </>
  );
}

export default AudioPlayer;
