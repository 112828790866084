import React, { useEffect, useState } from 'react';
import { Button, IconButton, List, ListItem, Typography } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Close';
import FolderIcon from '@mui/icons-material/Folder';
import { EditEvent } from '../app/models/edit-event';
import { GreenRoomFile, useLazyGetFileQuery } from '../app/api/files-api-slice';
import GreenRoomButton from '../components/GreenRoomButton';
import NoEventFilesBlankSlate from '../components/Blankslates/NoEventFilesBlankslate';
import Subheading from '../components/Text/Subheading';
import GreenRoomStack from '../components/GreenRoomStack';
import ProjectFileSelectionDialog from '../components/ProjectFileSelectionDialog';
import Loading from '../components/Loading';

interface EditEventPageFilesTabProps {
  editEvent: EditEvent;
  // eslint-disable-next-line no-unused-vars
  onChange: (editEvent: EditEvent) => void;
}

function EditEventPageFilesTab({
  editEvent,
  onChange,
}: EditEventPageFilesTabProps) {
  const [files, setFiles] = useState<GreenRoomFile[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [getFile] = useLazyGetFileQuery();
  const [open, setOpen] = useState(false);

  const actions = (
    <>
      {!!editEvent?.projectId && (
        <div className="edit-field">
          <div className="event-role-type-select-and-add-button">
            <GreenRoomButton
              type="add"
              onClick={() => setOpen(true)}
              className="gr-edit-event-add-file-url"
              disabled={!editEvent.projectId}
            >
              Add File
            </GreenRoomButton>
            <Button
              startIcon={<FolderIcon />}
              href={`${window.location.origin}/settings/project/${editEvent.projectId}?tab=files`}
            >
              Manage All Files
            </Button>
          </div>
        </div>
      )}
      {!editEvent?.projectId && (
        <b>To begin adding, select a project on the info tab.</b>
      )}
    </>
  );

  const displayFiles = files?.map((file) => (
    <ListItem key={file.id}>
      <Typography>{file.name}</Typography>
      <IconButton
        color="error"
        onClick={() => {
          const newFiles = editEvent.files.filter((id) => id !== file.id);
          onChange({ ...editEvent, files: newFiles });
        }}
      >
        <RemoveIcon />
      </IconButton>
    </ListItem>
  ));

  useEffect(() => {
    if (editEvent.files.length > 0) {
      if (files && editEvent.files.length < files.length) {
        const newFiles = files.filter((f) => editEvent.files.includes(f.id));
        if (newFiles.length === editEvent.files.length) {
          setFiles(newFiles);
          return;
        }
      }
      setLoading(true);
      Promise.all(editEvent.files.map((id) => getFile(id).unwrap())).then(
        (f) => {
          setFiles(f);
          setLoading(false);
        }
      );
    } else {
      setFiles([]);
    }
  }, [editEvent.files]);

  if (files === null || loading) return <Loading />;

  return (
    <div>
      {!files?.length && <NoEventFilesBlankSlate actions={actions} />}
      {!!files?.length && (
        <>
          <Subheading>Files</Subheading>
          <GreenRoomStack className="edit-field-section-card">
            <List>{displayFiles}</List>
          </GreenRoomStack>
          {actions}
        </>
      )}
      <ProjectFileSelectionDialog
        open={open}
        selectedFileIds={editEvent.files}
        onSelectionChange={(fileIds) =>
          onChange({ ...editEvent, files: fileIds })
        }
        onClose={() => setOpen(false)}
        projectId={editEvent.projectId!}
      />
    </div>
  );
}

export default EditEventPageFilesTab;
