import React from 'react';
// Manually plucking polyphil for a specific broken function in old Safari browsers
// NOTE: If we need to support entire target browsers, consider: https://www.npmjs.com/package/react-app-polyfill
import 'core-js/actual/array/at';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configData from './config.json';
import { store } from './app/store';
import getEnvironment from './app/helpers/environment-helpers';

let sentryDsn =
  'https://e86f04e4e3064c75a11b3fc9e8bae44e@o4504488550465536.ingest.sentry.io/4504488558067712';
let replaysSessionSampleRate = 1.0;
let replaysOnErrorSampleRate = 1.0;
const environment = getEnvironment();
if (environment === 'local') {
  sentryDsn = '';
}
if (environment !== 'production') {
  replaysSessionSampleRate = 0.0;
  replaysOnErrorSampleRate = 0.0;
}
Sentry.init({
  environment,
  dsn: sentryDsn,
  replaysSessionSampleRate,
  replaysOnErrorSampleRate,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
});

const stripePromise = loadStripe(configData.stripe.publicKey);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={configData.auth0.domain}
        clientId={configData.auth0.clientId}
        audience={configData.auth0.audience}
        redirectUri={window.location.origin}
      >
        <Elements stripe={stripePromise}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Elements>
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
