import { Grid, TextField } from '@mui/material';
import React from 'react';
import { Project } from '../app/api/projects-api-slice';

export interface ProjectInfoEditProps {
  project: Project;
  // eslint-disable-next-line no-unused-vars
  onChange: (project: Project) => void;
}

function ProjectInfoEdit({ project, onChange }: ProjectInfoEditProps) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          required
          label="Stage name"
          fullWidth
          placeholder='e.g.: "Marvin Berry and the Starlighters"'
          helperText="What do they put on the marquee?"
          value={project.name}
          onChange={(e) => onChange({ ...project, name: e.target.value })}
        />
      </Grid>
    </Grid>
  );
}

export default ProjectInfoEdit;
