import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  TextField,
  Autocomplete,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2';
import {
  useGetEventRoleTypesForAllProjectsQuery,
  useCreateEventRoleTypeMutation,
  useUpdateEventRoleTypeMutation,
  useDeleteEventRoleTypeMutation,
} from '../app/api/event-role-types-api-slice';
import GreenRoomButton from '../components/GreenRoomButton';
import './CreateEditEventRoleTypeDialog.scss';

export interface EditEventRoleType {
  id: string | null;
  name: string;
  projectId: string;
}

export interface CreateEditEventRoleTypeDialogProps {
  role: EditEventRoleType;
  open: boolean;
  fullScreen: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose: (id: string | null) => void;
}

function CreateEditEventRoleTypeDialog({
  role,
  open,
  fullScreen,
  onClose,
}: CreateEditEventRoleTypeDialogProps) {
  // Fetch data to allow user to create new from existing role
  const getRolesForAllProjects = useGetEventRoleTypesForAllProjectsQuery();
  const dedupedRolesinAllProjects =
    getRolesForAllProjects?.data?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.name === value.name)
    ) || [];
  const rolesInThisProject =
    getRolesForAllProjects?.data?.filter(
      (r: EditEventRoleType) => r.projectId === role?.projectId
    ) || [];

  // We want to provide autocomplete for roles not associated with this project already
  const uniqueRolesInOtherProjects = dedupedRolesinAllProjects?.filter(
    (a) => !rolesInThisProject.some((b) => a.name === b.name)
  );

  // Keep track of when an autocomplete option is highlighted in order to gate form submission on enter
  const [optionHighlighted, setOptionHighlighted] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [editRole, setEditRole] = useState(role);
  useEffect(() => {
    setEditRole(role);
  }, [role]);
  const [createEventRoleTypeMutation, createEventRoleTypeMutationStatus] =
    useCreateEventRoleTypeMutation();
  const [updateEventRoleTypeMutation, updateEventRoleTypeMutationStatus] =
    useUpdateEventRoleTypeMutation();
  const [deleteEventRoleTypeMutation, deleteEventRoleTypeMutationStatus] =
    useDeleteEventRoleTypeMutation();

  useEffect(() => {
    if (createEventRoleTypeMutationStatus.isSuccess) {
      onClose(createEventRoleTypeMutationStatus.data);
    } else if (updateEventRoleTypeMutationStatus.isSuccess) {
      onClose(updateEventRoleTypeMutationStatus.data);
    } else if (deleteEventRoleTypeMutationStatus.isSuccess) {
      onClose(null);
    } else if (
      createEventRoleTypeMutationStatus.isError ||
      updateEventRoleTypeMutationStatus.isError ||
      deleteEventRoleTypeMutationStatus.isError
    ) {
      enqueueSnackbar('There was a problem saving the changes.', {
        variant: 'error',
      });
    }
  }, [
    createEventRoleTypeMutationStatus,
    updateEventRoleTypeMutationStatus,
    deleteEventRoleTypeMutationStatus,
  ]);

  const mode = editRole.id ? 'Edit' : 'Add';

  const handleSave = () => {
    if (editRole.id) {
      updateEventRoleTypeMutation({
        id: editRole.id!,
        body: { name: editRole.name },
      });
    } else {
      createEventRoleTypeMutation({
        name: editRole.name,
        projectId: editRole.projectId,
      });
    }
  };

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle>{mode} Project Role</DialogTitle>
      <DialogContent>
        <div className="edit-event-role-type-content">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                options={uniqueRolesInOtherProjects.map(
                  (r: EditEventRoleType) => r?.name
                )}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    required
                    label="Name"
                    onChange={(e) =>
                      setEditRole({ ...editRole, name: e.target.value })
                    }
                    onKeyDown={(e) => {
                      // If an item in the autocomplete dropdown is highlighted, the enter key is used to select the option
                      // If an item in the autocomplete dropdown is not highlighted, the enter key is used to submit the form
                      if (!optionHighlighted && e.key === 'Enter') {
                        handleSave();
                      }
                    }}
                  />
                )}
                onChange={(e, value, reason) => {
                  if (reason === 'selectOption') {
                    setEditRole({
                      ...editRole,
                      name: value!,
                    });
                  }
                }}
                fullWidth
                value={editRole.name || ''}
                onHighlightChange={(e) => e && setOptionHighlighted(true)}
                onClose={() => setOptionHighlighted(false)}
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        {mode === 'Edit' && (
          <GreenRoomButton
            type="destroy"
            onClick={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it',
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteEventRoleTypeMutation(editRole.id!);
                }
              });
            }}
          >
            Delete
          </GreenRoomButton>
        )}
        <GreenRoomButton
          type="cancel"
          onClick={() => {
            onClose(null);
          }}
        >
          Cancel
        </GreenRoomButton>
        <GreenRoomButton
          type="accept"
          onClick={handleSave}
          disabled={!editRole.name}
        >
          Save
        </GreenRoomButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateEditEventRoleTypeDialog;
