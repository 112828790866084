import {
  Chip,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import Swal from 'sweetalert2/dist/sweetalert2';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  ProjectUser,
  useDeleteProjectUserMutation,
  useGetProjectQuery,
  useGetProjectUsersQuery,
} from '../app/api/projects-api-slice';
import { useGetUserQuery } from '../app/api/user-api-slice';
import './ProjectAdmins.scss';
import Loading from './Loading';
import AddAdministratorDialog from '../pages/AddAdministratorDialog';
import TabContent from './Tabs/TabContent';
import GreenRoomButton from './GreenRoomButton';
import GreenRoomStack from './GreenRoomStack';
import Blankslate from './Blankslate';
import ServerErrorDisplay from './ServerErrorDisplay';
import { ReactComponent as Cowboy } from '../img/placeholders/cowboy.svg';
import GreenRoomCard from './GreenRoomCard';

interface ProjectMembersProps {
  projectId: string;
}

function getUserDisplayName(user: ProjectUser) {
  if (user.isActive) {
    return `${user.name} (${user.email})`;
  }
  return user.email;
}

function ProjectAdmins({ projectId }: ProjectMembersProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const getProject = useGetProjectQuery(projectId);
  const getProjectUsers = useGetProjectUsersQuery(projectId);
  const getUser = useGetUserQuery();
  const [deleteProjectUserMutation, deleteProjectUserMutationStatus] =
    useDeleteProjectUserMutation();

  const { enqueueSnackbar } = useSnackbar();

  const [dialogOpen, setDialogOpen] = React.useState(false);

  /// //////////
  // Blankslate constants
  // NOTE: Not currently possible to render Admins blankslate (01/25/23), but good to reinforce the same pattern for eventual refactoring of project settings tabs
  const description = (
    <span>
      Project administrators have all the same access to{' '}
      <b>{getProject.data?.name}</b> as the owner, except they cannot delete the{' '}
      project. <b>Only add people you trust fully as administrators.</b>
    </span>
  );
  const action = (
    <GreenRoomButton type="add" onClick={() => setDialogOpen(true)}>
      Add Project Administrator
    </GreenRoomButton>
  );

  useEffect(() => {
    if (deleteProjectUserMutationStatus.isError) {
      const errorDisplay = (
        <ServerErrorDisplay
          error={deleteProjectUserMutationStatus.error}
          errorMessage="There was a problem removing the administrator."
        />
      );
      enqueueSnackbar(errorDisplay, {
        variant: 'error',
      });
    }
    if (
      deleteProjectUserMutationStatus.isSuccess &&
      deleteProjectUserMutationStatus.originalArgs!.userId === getUser.data?.id
    ) {
      navigate('/settings?tab=projects');
    }
  }, [deleteProjectUserMutationStatus]);

  /// //////////
  // Render loading state
  if (getProjectUsers.isLoading || getUser.isLoading) {
    return <Loading />;
  }

  const currentUserRole = getProjectUsers.data?.find(
    (user) => user.id === getUser.data?.id
  )?.role;

  /// //////////
  // Render main content
  return (
    <TabContent>
      {(getProjectUsers.data && getProjectUsers.data?.length > 0 && (
        <Stack spacing={2}>
          <div>{description}</div>
          <GreenRoomStack>
            {getProjectUsers.data?.map((user) => (
              <GreenRoomCard
                key={user.id}
                title={user.isActive ? user.name : <i>Invite sent</i>}
                subtitle={user.email}
                left={<Chip label={user.role} />}
                right={
                  (currentUserRole === 'Owner' || user.role === 'Admin') && (
                    <IconButton
                      onClick={() => {
                        const userName = getUserDisplayName(user);
                        let title = `Remove administrator access for ${userName}?`;
                        let text = `${userName} will no longer have administrator access ${getProject.data?.name}}. Are you sure you want to remove their access?`;
                        let confirmTextButton = 'Yes, remove their access!';
                        let cancelTextButton = 'No, keep their access';
                        if (user.id === getUser.data?.id) {
                          title = 'Remove your administrator access?';
                          text =
                            'You will no longer have administrator access to this project. Are you sure you want to remove your access?';
                          confirmTextButton = 'Yes, remove my access!';
                          cancelTextButton = 'No, keep my access';
                        }
                        Swal.fire({
                          title,
                          text,
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: confirmTextButton,
                          cancelButtonText: cancelTextButton,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deleteProjectUserMutation({
                              projectId,
                              userId: user.id,
                            });
                          }
                        });
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )
                }
              />
            ))}
          </GreenRoomStack>
          {action}
        </Stack>
      )) || (
        <Blankslate title="No admins" actions={action} svg={<Cowboy />}>
          {description}
        </Blankslate>
      )}
      {dialogOpen && (
        <AddAdministratorDialog
          projectId={projectId}
          onClose={() => setDialogOpen(false)}
          fullScreen={fullScreen}
          currentUserRole={currentUserRole}
        />
      )}
    </TabContent>
  );
}

export default ProjectAdmins;
