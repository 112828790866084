import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Swal from 'sweetalert2/dist/sweetalert2';
import { useNavigate } from 'react-router-dom';
import {
  Project,
  useDeleteProjectMutation,
  useExportProjectMutation,
  useUpdateProjectMutation,
} from '../app/api/projects-api-slice';
import Loading from '../components/Loading';
import ProjectInfoEdit from '../components/ProjectInfoEdit';
import TabContent from '../components/Tabs/TabContent';
import GreenRoomButton from '../components/GreenRoomButton';
import ServerErrorDisplay from '../components/ServerErrorDisplay';

interface ProjectSelectProps {
  project: Project;
}

function ProjectSettingsPageInfo({ project }: ProjectSelectProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [editProject, setEditProject] = useState(project);
  const [updateProject, updateProjectStatus] = useUpdateProjectMutation();
  const [deleteProject, deleteProjectStatus] = useDeleteProjectMutation();
  const [exportProject, exportProjectStatus] = useExportProjectMutation();

  useEffect(() => {
    if (project) {
      setEditProject(project);
    }
  }, [project]);

  useEffect(() => {
    if (updateProjectStatus.isSuccess) {
      enqueueSnackbar('Project info has been saved!', { variant: 'success' });
    }
  }, [updateProjectStatus]);

  useEffect(() => {
    if (deleteProjectStatus.isError) {
      const errorDisplay = (
        <ServerErrorDisplay
          error={deleteProjectStatus.error}
          errorMessage="There was a problem deleting the project."
        />
      );
      enqueueSnackbar(errorDisplay, { variant: 'error' });
    } else if (deleteProjectStatus.isSuccess) {
      enqueueSnackbar('Project has been deleted.', { variant: 'success' });
      navigate('/settings?tab=projects');
    }
  }, [deleteProjectStatus]);

  useEffect(() => {
    if (exportProjectStatus.isError) {
      const errorDisplay = (
        <ServerErrorDisplay
          error={exportProjectStatus.error}
          errorMessage="There was a problem exporting project data."
        />
      );
      enqueueSnackbar(errorDisplay, { variant: 'error' });
    } else if (exportProjectStatus.isSuccess) {
      enqueueSnackbar(
        'Project data is being exported and will be sent to your email.',
        {
          variant: 'success',
        }
      );
    }
  }, [exportProjectStatus]);

  if (!project) {
    return <Loading />;
  }

  const handleDeleteProject = () => {
    Swal.fire({
      title: 'Delete Project?',
      html:
        `<p>This will delete the project "${project.name}". All data associated with this project will be deleted.</p>` +
        `<p> <b>This action cannot be undone.</b> </p>` +
        "<p>Type 'DELETE' below to confirm and proceed.</p>",
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Delete',
      icon: 'warning',
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (result.value === 'DELETE') {
          await deleteProject(project.id);
        } else {
          enqueueSnackbar('The project was not deleted.', { variant: 'error' });
        }
      }
    });
  };

  return (
    <TabContent
      actions={
        <>
          <GreenRoomButton
            type="accept"
            onClick={() =>
              updateProject({
                id: editProject.id,
                body: { name: editProject.name },
              })
            }
            disabled={!editProject.name}
          >
            Save Changes
          </GreenRoomButton>
          {project.role === 'Owner' && (
            <GreenRoomButton type="destroy" onClick={handleDeleteProject}>
              Delete Project
            </GreenRoomButton>
          )}
        </>
      }
    >
      <ProjectInfoEdit project={editProject} onChange={setEditProject} />
      <div style={{ marginTop: '16px' }}>
        <Button
          variant="contained"
          color="info"
          startIcon={<FileDownloadIcon />}
          onClick={() => exportProject(project.id)}
          disabled={exportProjectStatus.isLoading}
        >
          {exportProjectStatus.isLoading && 'Exporting project data...'}
          {!exportProjectStatus.isLoading && 'Export Project Data'}
        </Button>
      </div>
    </TabContent>
  );
}

export default ProjectSettingsPageInfo;
