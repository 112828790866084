import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import configData from '../../config.json';
import { sec } from '../security';

const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: configData.apiDomain,
    prepareHeaders: async (headers) => {
      const token = await sec.getAccessTokenSilently()();
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  refetchOnMountOrArgChange: 10,
  tagTypes: [
    'Event',
    'User',
    'Project',
    'ProjectSubscription',
    'Person',
    'Label',
    'EventRoleType',
    'ProjectUser',
    'FeatureFlags',
    'ProjectFinancialAccount',
    'Card',
    'ProjectBandsintown',
    'BandsintownEvent',
    'PaymentType',
    'File',
    'FileQuota',
  ],
  endpoints: () => ({}),
});

export default emptySplitApi;
