import React from 'react';
import Blankslate from '../Blankslate';
import { ReactComponent as Wallet } from '../../img/placeholders/wallet.svg';

interface NoEventFinancesBlankslateProps {
  actions?: React.ReactNode | null;
}

function NoEventFinancesBlankslate({
  actions,
}: NoEventFinancesBlankslateProps) {
  return (
    <Blankslate title="No transactions" svg={<Wallet />} actions={actions}>
      Adding transactions lets you track your income and expenses for every
      event. Tracking your finances on events helps you better understand the
      overall success of your business and prepare for tax time.{' '}
      <a
        href="https://help.greenroom.app/how-do-i-track-my-finances-in-green-room"
        target="_blank"
        rel="noreferrer"
      >
        Learn more here
      </a>
      .
    </Blankslate>
  );
}

NoEventFinancesBlankslate.defaultProps = {
  actions: null,
};

export default NoEventFinancesBlankslate;
