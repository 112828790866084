import { Stack } from '@mui/material';
import CancelIcon from '@mui/icons-material/PowerOff';
import PowerIcon from '@mui/icons-material/Power';
import Swal from 'sweetalert2/dist/sweetalert2';
import React, { useState } from 'react';
import {
  useDeleteBandsintownAuthorizationMutation,
  useGetBandsintownConfigurationQuery,
} from '../app/api/bandsintown-api-slice';
import GreenRoomButton from '../components/GreenRoomButton';
import KeyValue from '../components/KeyValue';
import Loading from '../components/Loading';
import Subheading from '../components/Text/Subheading';
import Blankslate from '../components/Blankslate';
import { ReactComponent as BandsintownLogo } from '../img/companies/bandsintown.svg';
import { ReactComponent as GreenRoomToBandsintown } from '../img/placeholders/greenroom_to_bandsintown.svg';
import SetupBandsintownDialog from '../components/SetupBandsintownDialog';
import PremiumFeatureChip from '../components/PremiumFeatureChip';
import { useGetProjectEntitlementsQuery } from '../app/api/projects-api-slice';

interface BandsintownSettingsProps {
  projectId: string;
}

function BandsintownSettings({ projectId }: BandsintownSettingsProps) {
  const getBandsintownConfiguration =
    useGetBandsintownConfigurationQuery(projectId);

  const getProjectEntitlementsQuery = useGetProjectEntitlementsQuery(projectId);

  const [deleteBandsintownAuthorizationMutation] =
    useDeleteBandsintownAuthorizationMutation();

  const [setupDialogOpen, setSetupDialogOpen] = useState(false);

  if (
    getBandsintownConfiguration.isFetching ||
    getProjectEntitlementsQuery.isFetching
  ) {
    return <Loading />;
  }

  const blankSlate = (
    <Blankslate
      title="Push to Bandsintown"
      svg={<BandsintownLogo width={200} />}
      actions={
        <>
          {getProjectEntitlementsQuery.data?.bandsintown && (
            <GreenRoomButton
              type="create"
              icon={<PowerIcon />}
              onClick={() => setSetupDialogOpen(true)}
            >
              Connect Account
            </GreenRoomButton>
          )}
          {!getProjectEntitlementsQuery.data?.bandsintown && (
            <PremiumFeatureChip projectId={projectId} size="medium" />
          )}
        </>
      }
    >
      <GreenRoomToBandsintown />
      <div>
        Connect your <b>Bandsintown for Artists</b> account and select an artist
        to push events from <b>Green Room</b> to <b>Bandsintown</b>!
      </div>
    </Blankslate>
  );

  let content: React.ReactNode = null;

  if (getBandsintownConfiguration.isError) {
    content = (
      <div>
        An error occurred loading Bandsintown configuration. Please try again.
      </div>
    );
  } else if (
    !getBandsintownConfiguration.data?.enabled ||
    getBandsintownConfiguration.data?.artist === null
  ) {
    content = blankSlate;
  } else {
    content = (
      <>
        <BandsintownLogo width={200} />
        <Subheading>Push to Bandsintown</Subheading>
        <Stack spacing={2}>
          <KeyValue
            secondary
            label="Admin Email"
            value={getBandsintownConfiguration.data!.userEmail}
          />
          {getBandsintownConfiguration.data!.artist && (
            <KeyValue
              secondary
              label="Bandsintown Artist"
              value={
                <a
                  href={getBandsintownConfiguration.data!.artist?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getBandsintownConfiguration.data!.artist.name}
                </a>
              }
            />
          )}
          <GreenRoomButton
            type="destroy"
            icon={<CancelIcon />}
            onClick={() => {
              Swal.fire({
                title: 'Disconnect?',
                text: 'This will remove your Bandsintown authentication info permanently from Green Room. Are you sure you want to proceed?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await deleteBandsintownAuthorizationMutation(projectId);
                }
              });
            }}
          >
            Disconnect
          </GreenRoomButton>
        </Stack>
      </>
    );
  }

  return (
    <>
      {content}
      <SetupBandsintownDialog
        open={setupDialogOpen}
        onClose={() => setSetupDialogOpen(false)}
        projectId={projectId}
      />
    </>
  );
}

export default BandsintownSettings;
