import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  CardContent,
  Autocomplete,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { Transaction } from '../app/api/events-api-slice';
import {
  AutocompleteValue,
  getAutocompleteOptionLabel,
  getSelectedName,
  getAutocompleteOptions,
} from '../app/helpers/autocomplete-helpers';
import CreateEditPaymentTypeDialog from '../pages/CreateEditPaymentTypeDialog';
import {
  PaymentType,
  CreateEditPaymentType,
} from '../app/api/payment-types-api-slice';

interface EditEventTransactionCardProps {
  transaction: Transaction;
  transactor?: string | null;
  projectId: string | null;
  paymentTypes: PaymentType[] | undefined;
  // eslint-disable-next-line no-unused-vars
  onEditEventTransaction: (transaction: Transaction) => void;
  embedded?: boolean; // If true, hide redundant content
}

function EditEventTransactionCard({
  transaction,
  transactor,
  projectId,
  paymentTypes,
  onEditEventTransaction,
  embedded,
}: EditEventTransactionCardProps) {
  /// //////////
  // Media queries
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  /// //////////
  // Form labels
  const transactorLabel = transaction?.type === 'Income' ? 'Source' : 'Payee';
  const isPaidLabel = transaction?.type === 'Income' ? 'Received' : 'Paid Out';

  /// //////////
  // Create new payment types with Dialog
  const newPaymentType = {
    id: null,
    projectId,
    name: '',
  } as CreateEditPaymentType;

  const [newPaymentTypeDialogProps, setNewPaymentTypeDialogProps] =
    React.useState({
      open: false,
      paymentType: newPaymentType,
    });

  return (
    <>
      <CardContent>
        <Grid container spacing={2}>
          {!embedded && (
            <>
              {!transaction?.eventPersonId && (
                <Grid item xs={12}>
                  <InputLabel htmlFor="transaction-type">Type</InputLabel>
                  <ToggleButtonGroup
                    exclusive
                    id="transaction-type"
                    value={transaction?.type}
                    onChange={(e, value) => {
                      if (value) {
                        onEditEventTransaction({
                          ...transaction,
                          type: value,
                        });
                      }
                    }}
                    sx={{ width: '100%' }}
                    className="gr-edit-event-edit-event-transaction-card-type-toggle-button-group"
                  >
                    <ToggleButton
                      className="event-person-toggle-button"
                      value="Expense"
                    >
                      <Typography variant="body2">Expense</Typography>
                    </ToggleButton>
                    <ToggleButton
                      className="event-person-toggle-button"
                      value="Income"
                    >
                      <Typography variant="body2">Income</Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              )}
              {!!transaction?.eventPersonId && (
                <Grid item xs={12}>
                  <InputLabel>Type</InputLabel>
                  <Typography variant="body2">Expense (Paid Role)</Typography>
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  label={transactorLabel}
                  variant="standard"
                  value={transaction?.transactor || transactor}
                  disabled={!!transaction?.eventPersonId}
                  onChange={(event) => {
                    onEditEventTransaction({
                      ...transaction,
                      transactor: event.target.value,
                    });
                  }}
                  className="gr-edit-event-edit-event-transaction-card-transactor"
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="transaction-amount">Amount</InputLabel>
              <NumericFormat
                id="transaction-amount"
                customInput={Input}
                allowNegative={false}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                value={(transaction?.amount || 0) / 100}
                fixedDecimalScale
                decimalScale={2}
                thousandSeparator
                onValueChange={(values) => {
                  onEditEventTransaction({
                    ...transaction,
                    amount: Math.round((values.floatValue || 0) * 100),
                  });
                }}
                className="gr-edit-event-edit-event-transaction-card-amount"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                value={getSelectedName(
                  paymentTypes,
                  transaction?.paymentTypeId
                )}
                onChange={(event, newValue: AutocompleteValue) => {
                  if (
                    newValue &&
                    (newValue.inputValue || newValue.inputValue === '')
                  ) {
                    // Create a new value from the user input
                    setNewPaymentTypeDialogProps({
                      open: true,
                      paymentType: {
                        ...newPaymentType,
                        name: newValue.inputValue,
                      },
                    });
                  } else {
                    // Associate a new paymentTypeId if id is defined or remove association if callback value is null
                    onEditEventTransaction({
                      ...transaction,
                      paymentTypeId: newValue?.id,
                    });
                  }
                }}
                filterOptions={(options, params) =>
                  getAutocompleteOptions(options, params, 'payment method')
                }
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="payment-type"
                options={paymentTypes!}
                getOptionLabel={(option) => getAutocompleteOptionLabel(option)}
                renderOption={(props, option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <li {...props}>{option.name}</li>
                )}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="standard"
                    label="Payment Method"
                  />
                )}
                className="gr-edit-event-edit-event-transaction-card-payment-type"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Description"
              variant="standard"
              value={transaction?.description}
              onChange={(event) => {
                onEditEventTransaction({
                  ...transaction,
                  description: event.target.value,
                });
              }}
              className="gr-edit-event-edit-event-transaction-card-description"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              alignItems: 'flex-end',
              display: 'flex',
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={transaction?.isPaid}
                    onChange={(event) => {
                      onEditEventTransaction({
                        ...transaction,
                        isPaid: event.target.checked,
                        paidDate: event.target.checked
                          ? moment().format('YYYY-MM-DD')
                          : null,
                      });
                    }}
                    className="gr-edit-event-edit-event-transaction-card-is-paid"
                  />
                }
                label={isPaidLabel}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                value={transaction?.paidDate}
                disabled={!transaction?.isPaid}
                onChange={(e) => {
                  onEditEventTransaction({
                    ...transaction,
                    paidDate: e
                      ? moment(e).format('YYYY-MM-DD').toString()
                      : null,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    className="gr-edit-event-edit-event-transaction-card-paid-date"
                  />
                )}
                label="Date Paid"
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </CardContent>
      <CreateEditPaymentTypeDialog
        paymentType={newPaymentTypeDialogProps.paymentType}
        fullScreen={fullScreen}
        open={newPaymentTypeDialogProps.open}
        onClose={(id) => {
          onEditEventTransaction({
            ...transaction,
            paymentTypeId: id,
          });

          setNewPaymentTypeDialogProps({
            ...newPaymentTypeDialogProps,
            open: false,
          });
        }}
      />
    </>
  );
}

EditEventTransactionCard.defaultProps = {
  embedded: false,
  transactor: '',
};

export default EditEventTransactionCard;
