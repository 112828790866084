import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetBandsintownEventsForDateQuery } from '../app/api/bandsintown-api-slice';
import BetaChip from '../components/BetaChip';
import GreenRoomButton from '../components/GreenRoomButton';
import Subheading from '../components/Text/Subheading';
import PremiumFeatureChip from '../components/PremiumFeatureChip';

interface Props {
  projectId: string | null;
  bandsintownEntitlementEnabled: boolean;
  bandsintownConfigured: boolean;
  date: string | null;
  pushToBandsintown: boolean;
  currentPushToBandsintown: boolean;
  // eslint-disable-next-line no-unused-vars
  currentPushToBandsintownChange: (value: boolean) => void;
  currentBandsintownEventId: number | null;
  // eslint-disable-next-line no-unused-vars
  currentBandsintownEventIdChange: (value: number | null) => void;
  deleteEventfromBandsintown: () => void;
}

function EditEventBandsintown({
  projectId,
  bandsintownEntitlementEnabled,
  bandsintownConfigured,
  date,
  pushToBandsintown,
  currentPushToBandsintown,
  currentPushToBandsintownChange,
  currentBandsintownEventId,
  currentBandsintownEventIdChange,
  deleteEventfromBandsintown: removeEventFromBandsintown,
}: Props) {
  const navigate = useNavigate();

  const bandsintownEvents = useGetBandsintownEventsForDateQuery(
    { projectId: projectId!, date: date! },
    { skip: !projectId || !date }
  );

  return (
    <>
      <Subheading className="gr-edit-event-location-integrations">
        Integrations
      </Subheading>
      {pushToBandsintown && (
        <GreenRoomButton
          type="destroy"
          onClick={removeEventFromBandsintown}
          className="gr-edit-event-remove-from-bandsintown"
        >
          Delete from Bandsintown <BetaChip />
        </GreenRoomButton>
      )}
      {!pushToBandsintown && (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={currentPushToBandsintown}
                  value={currentPushToBandsintown}
                  onChange={(e) =>
                    currentPushToBandsintownChange(e.target.checked)
                  }
                  className="gr-edit-event-push-to-bandsintown"
                  disabled={
                    !bandsintownEntitlementEnabled || !bandsintownConfigured
                  }
                />
              }
              label={
                <>
                  Push to Bandsintown{' '}
                  {!bandsintownEntitlementEnabled && (
                    <PremiumFeatureChip
                      projectId={projectId!}
                      size="small"
                      margin="left"
                    />
                  )}
                  {bandsintownEntitlementEnabled && !bandsintownConfigured && (
                    <Chip
                      label="Not configured"
                      sx={{ marginLeft: '8px' }}
                      onClick={() =>
                        navigate(
                          `/settings/project/${projectId}?tab=integrations`
                        )
                      }
                    />
                  )}
                </>
              }
            />
          </FormGroup>
          {currentPushToBandsintown && (
            <FormControl fullWidth>
              <Select
                value={
                  currentBandsintownEventId === null
                    ? ''
                    : currentBandsintownEventId?.toString()
                }
                displayEmpty
                onChange={(e) =>
                  e.target.value === ''
                    ? currentBandsintownEventIdChange(null)
                    : currentBandsintownEventIdChange(
                        Number(e.target.value as string)
                      )
                }
                autoWidth
                className="gr-edit-event-push-to-bandsintown-event"
              >
                <MenuItem key="" value="">
                  Create new event in Bandsintown
                </MenuItem>
                {bandsintownEvents.data?.map((event) => (
                  <MenuItem
                    key={event.id.toString()}
                    value={event.id.toString()}
                  >
                    Existing event: {event.venue_name} on{' '}
                    {moment(event.start_date).format('MM/DD/YYYY')} at{' '}
                    {moment(event.start_time, 'hh:mm').format('h:mm a')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      )}
    </>
  );
}

export default EditEventBandsintown;
