import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';
import { EventStatus } from '../app/api/events-api-slice';
import './EventStatusSelect.scss';

interface EventStatusSelectProps {
  value: EventStatus;
  // eslint-disable-next-line no-unused-vars
  onChange: (newValue: EventStatus) => void;
  draftAllowed: boolean;
}

function EventStatusSelect({
  value,
  onChange,
  draftAllowed,
}: EventStatusSelectProps) {
  return (
    <FormControl fullWidth>
      <InputLabel id="status-label">Status</InputLabel>
      <Select
        labelId="status-label"
        label="Status"
        id="status"
        value={value}
        defaultValue={value ?? 'Confirmed'}
        onChange={(e) => onChange(e.target.value as EventStatus)}
      >
        {draftAllowed && (
          <MenuItem value="Draft">
            <div className="event-status-select-item">
              <EditIcon
                color="secondary"
                className="event-status-select-item-icon"
              />{' '}
              Draft
            </div>
          </MenuItem>
        )}

        <MenuItem value="Hold">
          <div className="event-status-select-item">
            <PendingIcon
              color="primary"
              className="event-status-select-item-icon"
            />{' '}
            Hold
          </div>
        </MenuItem>
        <MenuItem value="Confirmed">
          <div className="event-status-select-item">
            <CheckCircleIcon
              color="primary"
              className="event-status-select-item-icon"
            />{' '}
            Confirmed
          </div>
        </MenuItem>
        <MenuItem value="Cancelled">
          <div className="event-status-select-item">
            <CancelIcon
              color="secondary"
              className="event-status-select-item-icon"
            />{' '}
            Canceled
          </div>
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default EventStatusSelect;
