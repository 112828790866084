import emptySplitApi from './empty-api-slice';

export interface BandsintownArtist {
  id: number;
  name: string;
  url: string;
}

export interface GetBandsintownConfiguration {
  enabled: boolean;
  userEmail: string;
  artist: BandsintownArtist | null;
}

export interface CreateBandsintownAuthorizationBody {
  email: string;
  password: string;
}

export interface CreateBandsintownAuthorizationRequest {
  projectId: string;
  body: CreateBandsintownAuthorizationBody;
}

export interface BandsintownArtistSummary {
  id: number;
  name: string;
}

export interface CreateBandsintownConfigurationBody {
  artistId: number;
}

export interface CreateBandsintownConfigurationRequest {
  projectId: string;
  body: CreateBandsintownConfigurationBody;
}

export interface BandsintownEvent {
  id: number;
  lastUpdatedAt: string;
}

export interface BandsintownEventInfo {
  id: number;
  start_date: string;
  start_time: string;
  venue_name: string;
}

export interface GetBandsintownEventsForDateRequest {
  projectId: string;
  date: string;
}

export interface UpsertEventBandsintownRequest {
  eventId: string;
  bandsintownEventId: number | null;
}

export const bandsintownApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getBandsintownConfiguration: builder.query<
      GetBandsintownConfiguration,
      string
    >({
      query: (id) => `/projects/${id}/bandsintown/configuration`,
      providesTags: ['Project', 'ProjectBandsintown'],
    }),
    createBandsintownConfiguration: builder.mutation<
      void,
      CreateBandsintownConfigurationRequest
    >({
      query: (request) => ({
        url: `/projects/${request.projectId}/bandsintown/configuration`,
        method: 'POST',
        body: request.body,
      }),
      invalidatesTags: ['ProjectBandsintown'],
    }),
    deleteBandsintownAuthorization: builder.mutation<void, string>({
      query: (id) => ({
        url: `/projects/${id}/bandsintown/authorization`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ProjectBandsintown'],
    }),
    createBandsintownAuthorization: builder.mutation<
      void,
      CreateBandsintownAuthorizationRequest
    >({
      query: (request) => ({
        url: `/projects/${request.projectId}/bandsintown/authorization`,
        method: 'POST',
        body: request.body,
      }),
      invalidatesTags: ['ProjectBandsintown'],
    }),
    getBandsintownArtists: builder.query<BandsintownArtistSummary[], string>({
      query: (id) => `/projects/${id}/bandsintown/artists`,
      providesTags: ['Project', 'ProjectBandsintown'],
    }),
    getEventBandsintown: builder.query<BandsintownEvent, string>({
      query: (id) => `/events/${id}/bandsintown`,
      providesTags: ['Event', 'BandsintownEvent'],
    }),
    removeEventBandsintown: builder.mutation<void, string>({
      query: (id) => ({
        url: `/events/${id}/bandsintown`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BandsintownEvent'],
    }),
    upsertEventBandsintown: builder.mutation<
      void,
      UpsertEventBandsintownRequest
    >({
      query: (request) => {
        let url = `/events/${request.eventId}/bandsintown`;

        if (request.bandsintownEventId !== null) {
          url += `?bandsintownEventId=${request.bandsintownEventId}`;
        }

        return { url, method: 'POST' };
      },
      invalidatesTags: ['BandsintownEvent'],
    }),
    getBandsintownEventsForDate: builder.query<
      BandsintownEventInfo[],
      GetBandsintownEventsForDateRequest
    >({
      query: (request) =>
        `/projects/${request.projectId}/bandsintown/events?date=${request.date}`,
      providesTags: ['Project', 'ProjectBandsintown'],
    }),
  }),
});

export const {
  useGetBandsintownConfigurationQuery,
  useCreateBandsintownConfigurationMutation,
  useDeleteBandsintownAuthorizationMutation,
  useCreateBandsintownAuthorizationMutation,
  useGetBandsintownArtistsQuery,
  useGetEventBandsintownQuery,
  useUpsertEventBandsintownMutation,
  useRemoveEventBandsintownMutation,
  useGetBandsintownEventsForDateQuery,
} = bandsintownApiSlice;
