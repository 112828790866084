import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useSearchParams } from 'react-router-dom';
import SettingsPageUserInfo from './SettingsPageUserInfo';
import SettingsPageProjects from './SettingsPageProjects';
import StatefulTabs, {
  getSelectedTabLabel,
  TabProps,
} from '../components/Tabs/StatefulTabs';
import Heading from '../components/Text/Heading';
import setTitle from '../app/helpers/document-helpers';
import ProjectFinancialAccount from '../components/ProjectFinancialAccount';
import { useGetFeatureFlagsQuery } from '../app/api/feature-flags-slice';

function SettingsPage() {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const featureFlags = useGetFeatureFlagsQuery();

  const ACCOUNT_TAB = {
    label: 'Account',
    icon: <PersonIcon />,
    value: 'account',
  };

  const PROJECTS_TAB = {
    label: 'Projects',
    icon: <GroupIcon />,
    value: 'projects',
  };

  const FINANCIAL_ACCOUNT_TAB = {
    label: 'Financial Account',
    icon: <AttachMoneyIcon />,
    value: 'financial-account',
    beta: true,
  };

  const SETTINGS_TABS: TabProps[] = [ACCOUNT_TAB, PROJECTS_TAB];

  if (featureFlags.data?.personalProjectEnabled) {
    SETTINGS_TABS.push(FINANCIAL_ACCOUNT_TAB);
  }

  setTitle('Settings', getSelectedTabLabel(SETTINGS_TABS, tab!));

  return (
    <>
      <Heading>Settings</Heading>
      <StatefulTabs tabs={SETTINGS_TABS} />
      {tab === ACCOUNT_TAB.value && <SettingsPageUserInfo />}
      {tab === PROJECTS_TAB.value && <SettingsPageProjects />}
      {tab === FINANCIAL_ACCOUNT_TAB.value && (
        <ProjectFinancialAccount projectId="personal" />
      )}
    </>
  );
}

export default SettingsPage;
