export default function getEnvironment(): string {
  let environment = 'unknown';
  if (window.location.href.includes('greenroom.app')) {
    environment = 'production';
  } else if (window.location.href.includes('grnrm.xyz')) {
    environment = 'staging';
  } else if (window.location.href.includes('localhost')) {
    environment = 'local';
  }
  return environment;
}
