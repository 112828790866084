import emptySplitApi from './empty-api-slice';

interface ProjectBody {
  name: string;
}

export interface Project extends ProjectBody {
  id: string;
  role: 'EventViewer' | 'Owner' | 'Admin';
}

export interface CreateProject extends ProjectBody {}

export interface UpdateProject {
  id: string;
  body: ProjectBody;
}

export interface ProjectUser {
  id: string;
  name: string;
  email: string;
  role: 'Owner' | 'Admin';
  isActive: boolean;
}

export interface CreateProjectUser {
  projectId: string;
  role: 'Owner' | 'Admin';
  email: string;
}

export interface DeleteProjectUser {
  projectId: string;
  userId: string;
}

export interface ProjectSubscription {
  planId: string | null;
  planName: string | null;
  planDescription: string | null;
  interval: string | null;
  intervalCount: number | null;
  amount: number | null;
  status: string | null;
  currentPeriodStart: string | null;
  currentPeriodEnd: string | null;
  cancelAt: string | null;
  canceledAt: string | null;
}

export interface CreateSubscriptionPortalSessionRequestBody {
  redirectUrl: string;
}

export interface CreateSubscriptionPortalSessionRequest {
  projectId: string;
  body: CreateSubscriptionPortalSessionRequestBody;
}

export interface CreateSubscriptionPortalSessionResponse {
  url: string;
}

export interface Entitlements extends Record<string, string | boolean> {}

export const projectsApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<Project[], void>({
      query: () => `/projects`,
      providesTags: ['Project'],
    }),
    getProject: builder.query<Project, string>({
      query: (id) => `/projects/${id}`,
      providesTags: ['Project'],
    }),
    createProject: builder.mutation<string, CreateProject>({
      query: (project) => ({
        url: `/projects`,
        method: 'POST',
        body: project,
      }),
      invalidatesTags: ['Project'],
    }),
    updateProject: builder.mutation<void, UpdateProject>({
      query: (project) => ({
        url: `/projects/${project.id}`,
        method: 'PATCH',
        body: JSON.stringify(project.body),
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }),
      invalidatesTags: ['Project'],
    }),
    deleteProject: builder.mutation<void, string>({
      query: (id) => ({
        url: `/projects/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Project', 'Event'],
    }),
    getProjectUsers: builder.query<ProjectUser[], string>({
      query: (id) => `/projects/${id}/users`,
      providesTags: ['ProjectUser'],
    }),
    createProjectUser: builder.mutation<void, CreateProjectUser>({
      query: (payload) => ({
        url: `/projects/${payload.projectId}/users`,
        method: 'POST',
        body: { email: payload.email, role: payload.role },
      }),
      invalidatesTags: ['ProjectUser', 'Project'],
    }),
    deleteProjectUser: builder.mutation<void, DeleteProjectUser>({
      query: (payload) => ({
        url: `/projects/${payload.projectId}/users/${payload.userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ProjectUser'],
    }),
    getProjectSubscription: builder.query<ProjectSubscription, string>({
      query: (id) => `/projects/${id}/subscription`,
      providesTags: ['ProjectSubscription'],
    }),
    createSubscriptionPortalSession: builder.mutation<
      CreateSubscriptionPortalSessionResponse,
      CreateSubscriptionPortalSessionRequest
    >({
      query: (request) => ({
        url: `/projects/${request.projectId}/subscription_portal_session`,
        method: 'POST',
        body: request.body,
      }),
    }),
    getProjectEntitlements: builder.query<Entitlements, string>({
      query: (id) => `/projects/${id}/entitlements`,
    }),
    exportProject: builder.mutation<void, string>({
      query: (id) => ({
        url: `/projects/${id}/export`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectUsersQuery,
  useCreateProjectUserMutation,
  useDeleteProjectUserMutation,
  useGetProjectSubscriptionQuery,
  useCreateSubscriptionPortalSessionMutation,
  useGetProjectEntitlementsQuery,
  useExportProjectMutation,
} = projectsApiSlice;
