import React from 'react';
import { useNavigate } from 'react-router-dom';
import Blankslate from '../Blankslate';
import { ReactComponent as Wallet } from '../../img/placeholders/wallet.svg';
import GreenRoomButton from '../GreenRoomButton';

function NoProjectTransactionsBlankslate() {
  const navigate = useNavigate();

  return (
    <Blankslate
      title="No transactions on project"
      actions={
        <GreenRoomButton type="accept" onClick={() => navigate(`/events`)}>
          Go to events
        </GreenRoomButton>
      }
      svg={<Wallet />}
    >
      <span>
        There is no recent financial activity for this project. Add transactions
        to events to see a summary of finances here.{' '}
        <a
          href="https://help.greenroom.app/how-do-i-track-my-finances-in-green-room"
          target="_blank"
          rel="noreferrer"
        >
          Learn more here
        </a>
        .
      </span>
    </Blankslate>
  );
}

export default NoProjectTransactionsBlankslate;
