import { Link } from '@mui/material';
import React from 'react';

interface ButtonLinkProps {
  children?: React.ReactNode | null;
  onClick: () => void;
}

function ButtonLink({ children, onClick }: ButtonLinkProps) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link component="button" variant="body2" onClick={onClick}>
      {children}
    </Link>
  );
}

ButtonLink.defaultProps = {
  children: null,
};

export default ButtonLink;
