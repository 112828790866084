import React from 'react';
import { Stack } from '@mui/material';
import './GreenRoomStack.scss';

interface GreenRoomStackProps {
  children?: React.ReactNode | null;
  className?: string | null;
}

function GreenRoomStack({ children, className }: GreenRoomStackProps) {
  return (
    (children && (
      <Stack spacing={1} className={`green-room-stack ${className}`}>
        {children}
      </Stack>
    )) ||
    null
  );
}

GreenRoomStack.defaultProps = {
  children: null,
  className: '',
};

export default GreenRoomStack;
