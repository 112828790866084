import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  TextField,
  Autocomplete,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2';
import {
  useGetPaymentTypesForAllProjectsQuery,
  useCreatePaymentTypeMutation,
  useUpdatePaymentTypeMutation,
  useDeletePaymentTypeMutation,
  CreateEditPaymentType,
} from '../app/api/payment-types-api-slice';
import GreenRoomButton from '../components/GreenRoomButton';
import './CreateEditPaymentTypeDialog.scss';

export interface CreateEditPaymentTypeDialogProps {
  paymentType: CreateEditPaymentType;
  open: boolean;
  fullScreen: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose: (id: string | null) => void;
}

function CreateEditPaymentTypeDialog({
  paymentType,
  open,
  fullScreen,
  onClose,
}: CreateEditPaymentTypeDialogProps) {
  // Fetch data to allow user to create new from existing paymentType
  const getPayementTypesForAllProjects =
    useGetPaymentTypesForAllProjectsQuery();
  const dedupedPayementTypesinAllProjects =
    getPayementTypesForAllProjects?.data?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.name === value.name)
    ) || [];
  const paymentTypesInThisProject =
    getPayementTypesForAllProjects?.data?.filter(
      (r: CreateEditPaymentType) => r.projectId === paymentType?.projectId
    ) || [];

  // We want to provide autocomplete for paymentTypes not associated with this project already
  const uniquePayementTypesInOtherProjects =
    dedupedPayementTypesinAllProjects?.filter(
      (a) => !paymentTypesInThisProject.some((b) => a.name === b.name)
    );

  // Keep track of when an autocomplete option is highlighted in order to gate form submission on enter
  const [optionHighlighted, setOptionHighlighted] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [editPaymentType, setEditPaymentType] = useState(paymentType);
  useEffect(() => {
    setEditPaymentType(paymentType);
  }, [paymentType]);
  const [createPaymentTypeMutation, createPaymentTypeMutationStatus] =
    useCreatePaymentTypeMutation();
  const [updatePaymentTypeMutation, updatePaymentTypeMutationStatus] =
    useUpdatePaymentTypeMutation();
  const [deletePaymentTypeMutation, deletePaymentTypeMutationStatus] =
    useDeletePaymentTypeMutation();

  useEffect(() => {
    if (createPaymentTypeMutationStatus.isSuccess) {
      onClose(createPaymentTypeMutationStatus.data);
    } else if (updatePaymentTypeMutationStatus.isSuccess) {
      onClose(updatePaymentTypeMutationStatus.data);
    } else if (deletePaymentTypeMutationStatus.isSuccess) {
      onClose(null);
    } else if (
      createPaymentTypeMutationStatus.isError ||
      updatePaymentTypeMutationStatus.isError ||
      deletePaymentTypeMutationStatus.isError
    ) {
      enqueueSnackbar('There was a problem saving the changes.', {
        variant: 'error',
      });
    }
  }, [
    createPaymentTypeMutationStatus,
    updatePaymentTypeMutationStatus,
    deletePaymentTypeMutationStatus,
  ]);

  const mode = editPaymentType.id ? 'Edit' : 'Add';

  const handleSave = () => {
    if (editPaymentType.id) {
      updatePaymentTypeMutation({
        id: editPaymentType.id!,
        body: { name: editPaymentType.name },
      });
    } else {
      createPaymentTypeMutation({
        name: editPaymentType.name,
        projectId: editPaymentType.projectId,
      });
    }
  };

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle>{mode} Payment Method</DialogTitle>
      <DialogContent>
        {mode === 'Add' && (
          <Typography>
            You are creating a payment method to note where a transaction can be
            found for later reference. These are nicknames like “my venmo”,
            “Bill’s PayPal”, or “B of A”, that can be selected on transaction
            incomes and expenses in the future. These methods can be used to
            filter and search for transactions.
          </Typography>
        )}
        <div className="edit-event-payment-type-type-content">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                options={uniquePayementTypesInOtherProjects.map(
                  (r: CreateEditPaymentType) => r?.name
                )}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    required
                    label="Name"
                    onChange={(e) =>
                      setEditPaymentType({
                        ...editPaymentType,
                        name: e.target.value,
                      })
                    }
                    onKeyDown={(e) => {
                      // If an item in the autocomplete dropdown is highlighted, the enter key is used to select the option
                      // If an item in the autocomplete dropdown is not highlighted, the enter key is used to submit the form
                      if (!optionHighlighted && e.key === 'Enter') {
                        handleSave();
                      }
                    }}
                  />
                )}
                onChange={(e, value, reason) => {
                  if (reason === 'selectOption') {
                    setEditPaymentType({
                      ...editPaymentType,
                      name: value!,
                    });
                  }
                }}
                fullWidth
                value={editPaymentType.name || ''}
                onHighlightChange={(e) => e && setOptionHighlighted(true)}
                onClose={() => setOptionHighlighted(false)}
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        {mode === 'Edit' && (
          <GreenRoomButton
            type="destroy"
            onClick={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it',
              }).then((result) => {
                if (result.isConfirmed) {
                  deletePaymentTypeMutation(editPaymentType.id!);
                }
              });
            }}
          >
            Delete
          </GreenRoomButton>
        )}
        <GreenRoomButton
          type="cancel"
          onClick={() => {
            onClose(null);
          }}
        >
          Cancel
        </GreenRoomButton>
        <GreenRoomButton
          type="accept"
          onClick={handleSave}
          disabled={!editPaymentType.name}
        >
          Save
        </GreenRoomButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateEditPaymentTypeDialog;
