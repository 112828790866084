import emptySplitApi from './empty-api-slice';

interface PersonBody {
  name: string;
  email: string | null;
  phoneNumber: string | null;
  defaultSendEmail: boolean;
  defaultSendText: boolean;
}

interface CreatePerson extends PersonBody {
  projectId: string;
}

interface UpdatePerson {
  id: string;
  body: PersonBody;
}

export interface Person extends PersonBody {
  id: string;
  projectId: string;
  phoneNumberStatus: string;
  phoneNumberOptInLastRequestedAt: string | null;
}

export interface GetPersonsQuery {
  projectId: string;
}

export const personsApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPersonsForAllProjects: builder.query<Person[], void>({
      query: () => `/persons`,
      providesTags: ['Person'],
    }),
    getPersons: builder.query<Person[], GetPersonsQuery>({
      query: (query) => `/persons?projectId=${query.projectId}`,
      providesTags: ['Person'],
    }),
    createPerson: builder.mutation<string, CreatePerson>({
      query: (person) => ({
        url: `/persons`,
        method: 'POST',
        body: person,
      }),
      invalidatesTags: ['Person'],
    }),
    updatePerson: builder.mutation<void, UpdatePerson>({
      query: (person) => ({
        url: `/persons/${person.id}`,
        method: 'PATCH',
        body: JSON.stringify(person.body),
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }),
      invalidatesTags: ['Person'],
    }),
    deletePerson: builder.mutation<void, string>({
      query: (id) => ({
        url: `/persons/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Person'],
    }),
    resendTextOptIn: builder.mutation<void, string>({
      query: (id) => ({
        url: `/persons/${id}/resend_text_opt_in`,
        method: 'POST',
      }),
      invalidatesTags: ['Person'],
    }),
  }),
});

export const {
  useGetPersonsForAllProjectsQuery,
  useGetPersonsQuery,
  useCreatePersonMutation,
  useUpdatePersonMutation,
  useDeletePersonMutation,
  useResendTextOptInMutation,
} = personsApiSlice;
