import { Stack } from '@mui/material';
import React, { useState } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { useGetLabelsQuery } from '../app/api/labels-api-slice';
import CreateEditProjectLabelDialog, {
  addDefaultsToLabel,
} from '../pages/CreateEditProjectLabelDialog';
import GreenRoomButton from './GreenRoomButton';
import GreenRoomStack from './GreenRoomStack';
import TabContent from './Tabs/TabContent';
import Blankslate from './Blankslate';
import Loading from './Loading';
import { ReactComponent as Labels } from '../img/placeholders/labels.svg';
import GreenRoomCard from './GreenRoomCard';

interface ProjectLabelsProps {
  projectId: string;
}

function ProjectLabels({ projectId }: ProjectLabelsProps) {
  const newLabel = addDefaultsToLabel({ projectId });
  const getLabels = useGetLabelsQuery({ projectId });
  const [dialogProps, setDialogProps] = useState({
    open: false,
    label: newLabel,
  });

  /// //////////
  // Blankslate
  const description =
    'Labels are tags you can add to events to find or group them easily in tables and reports.';
  const action = (
    <GreenRoomButton
      type="add"
      onClick={() => setDialogProps({ open: true, label: newLabel })}
    >
      Add Project Label
    </GreenRoomButton>
  );

  /// //////////
  // Render loading state
  if (getLabels.isLoading) {
    return <Loading />;
  }

  return (
    <TabContent>
      {(getLabels.data && getLabels.data?.length > 0 && (
        <Stack spacing={2}>
          <div>{description}</div>
          <GreenRoomStack>
            {getLabels.data?.map((label) => (
              <GreenRoomCard
                key={label.id}
                title={label.name}
                left={<CircleIcon sx={{ color: label.color }} />}
                onClick={() => {
                  const editLabel = {
                    id: label.id,
                    name: label.name,
                    color: label.color,
                    projectId,
                  };
                  setDialogProps({
                    open: true,
                    label: editLabel,
                  });
                }}
              />
            ))}
          </GreenRoomStack>
          {action}
        </Stack>
      )) || (
        <Blankslate title="No labels" actions={action} svg={<Labels />}>
          {description}
        </Blankslate>
      )}
      <CreateEditProjectLabelDialog
        label={dialogProps.label}
        open={dialogProps.open}
        onClose={() => {
          setDialogProps({ ...dialogProps, open: false });
        }}
        labelNamesOnProject={getLabels.data?.map((l) => l.name)}
      />
    </TabContent>
  );
}

export default ProjectLabels;
