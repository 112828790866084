import ScienceIcon from '@mui/icons-material/Science';
import React from 'react';

function BetaChip() {
  return (
    <ScienceIcon color="primary" sx={{ fontSize: '16px', marginLeft: '4px' }} />
  );
}

export default BetaChip;
