import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import './UnsupportedBrowserDialog.scss';
import GreenRoomButton from './GreenRoomButton';

interface UnsupportedBrowserDialogProps {
  fullscreen: boolean;
}

function UnsupportedBrowserDialog({
  fullscreen,
}: UnsupportedBrowserDialogProps) {
  return (
    <Dialog open fullScreen={fullscreen}>
      <DialogTitle>Unsupported browser</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Green Room doesn&apos;t support embedded browsers (like the browser
          within Instagram). To log into or sign up for Green Room, please visit
          our website on Safari or Chrome:
        </DialogContentText>
        <GreenRoomButton
          type="copy"
          copyText="https://greenroom.app"
          className="unsupported-dialog-copy-button"
        >
          https://greenroom.app
        </GreenRoomButton>
      </DialogContent>
    </Dialog>
  );
}

export default UnsupportedBrowserDialog;
