import moment from 'moment';
import {
  DATE_FROM_PARAM_NAME,
  DATE_TO_PARAM_NAME,
} from '../../components/Filters/EventsFilters/FilterEventsByDate';
import { SORT_DIRECTION_PARAM_NAME } from '../../components/Filters/EventsFilters/SortEvents';
import emptySplitApi from './empty-api-slice';
import { GreenRoomFile } from './files-api-slice';

export interface EventPerson {
  id: string | null;
  personId: string | null;
  eventRoleTypeId: string | null;
  isAttending: boolean | null;
  sendEmail: boolean | null;
  sendText: boolean | null;
}

export interface Transaction {
  id: string | null;
  type: 'Income' | 'Expense';
  transactor: string | null;
  description: string;
  amount: number;
  isPaid: boolean;
  paidDate: string | null;
  eventPersonId: string | null;
  paymentTypeId: string | null;
  projectId: string | null;
  excludeFromReports: boolean;
}

export interface EventSensitiveInformation {
  transactions: Transaction[];
  sendEmail: boolean;
  sendICS: boolean;
  legacyICSEvent?: boolean;
  privateNotes: string | null;
}

export interface EventLink {
  id: string;
  url: string;
  label?: string | null;
}

export type EventStatus = 'Draft' | 'Hold' | 'Confirmed' | 'Cancelled';

export interface EventBase {
  startTime: string;
  endTime: string;
  arrivalTime: string | null;
  arrivalNotes: string | null;
  departureTime: string | null;
  departureNotes: string | null;
  soundCheckTime: string | null;
  soundCheckNotes: string | null;
  generalNotes: string | null;
  locationName: string;
  locationTimeZone: string;
  locationStreetAddress: string;
  locationCity: string;
  locationState: string;
  locationZipCode: string;
  locationGooglePlacesId: string | null;
  locationContactName: string | null;
  locationContactEmail: string | null;
  locationContactPhone: string | null;
  labels: string[] | null;
  status: EventStatus;
}

interface EventBodyBase extends EventBase {
  eventPersons: EventPerson[];
  sensitiveInformation: EventSensitiveInformation;
  links: EventLink[];
}

export interface EventBody extends EventBodyBase {
  files: GreenRoomFile[];
}

export interface SaveEventBody extends EventBodyBase {
  files: string[];
}
export interface PostEvent {
  body: SaveEventBody;
}

export interface QuickCreateEvent {
  projectId: string | null;
  defaultTimeZone: string;
  currentDate: string;
  userPrompt: string;
}

export interface PutEvent {
  id: string;
  body: SaveEventBody;
}

export interface DeleteEvent {
  id: string;
}

export interface Event extends EventBody {
  id: string;
  projectId: string;
  createdAt: string;
  modifiedAt: string;
}

export interface AttendanceSummary {
  attending: number;
  declined: number;
  pending: number;
}

export interface EventSummary extends EventBase {
  id: string;
  projectId: string;
  attendanceSummary: AttendanceSummary;
}

export interface EventSummariesRequest {
  offset: number;
  limit: number;
  before?: string | null; // TODO: Remove after EVENTS DATES V2
  after?: string | null; // TODO: Remove after EVENTS DATES V2
  from?: string | null;
  to?: string | null;
  desc?: boolean; // TODO: Remove after EVENTS SORT BY V2
  sort?: 'showDate' | 'createdDate' | 'venue';
  sortDirection?: 'Desc' | 'Asc';
  projectIds?: string | null;
  personIds?: string | null;
  eventStatuses?: string | null;
  inviteStatuses?: string | null;
  labelIds?: string | null;
}

export interface EventSummariesResponse {
  totalCount: number;
  allEventsCount: number;
  events: EventSummary[];
}

/// //////////
// Get a list of event summaries using url params, filtered when appropriate
const getEventSummariesRequestParams = (queryParams: EventSummariesRequest) => {
  /// //////////
  // First, enhance the url params to match the api
  // We do this conversion here so we can use the same UI components across apis; The conventions were defined with Transactions filtering
  // Search these terms to find api contingent TODO work:
  // ////////// EVENTS V2
  // ////////// EVENTS DATES V2
  // ////////// EVENTS SORT BY V2
  const enhancedQueryParams = { ...queryParams } as EventSummariesRequest;

  // Define constants
  // TODO: Remove after EVENTS V2
  // TODO: Remove after EVENTS SORT BY V2
  const SORT_DIRECTION_VALUE_DESC = 'Desc';
  const API_DEFINED_DESC_PARAM_NAME = 'desc';
  const API_DEFINED_AFTER_PARAM_NAME = 'after';
  const API_DEFINED_BEFORE_PARAM_NAME = 'before';

  // Convert the param values set by UI in url to values the api currently accepts
  // TODO: Remove after EVENTS SORT BY V2
  enhancedQueryParams[API_DEFINED_DESC_PARAM_NAME] =
    enhancedQueryParams[SORT_DIRECTION_PARAM_NAME] ===
    SORT_DIRECTION_VALUE_DESC;

  // TODO: Remove after EVENTS DATES V2
  enhancedQueryParams[API_DEFINED_AFTER_PARAM_NAME] =
    enhancedQueryParams[DATE_FROM_PARAM_NAME];

  // TODO: Remove after EVENTS DATES V2
  enhancedQueryParams[API_DEFINED_BEFORE_PARAM_NAME] =
    enhancedQueryParams[DATE_TO_PARAM_NAME];

  // Add a timestamp to all dates to capture the complete interval of the intended date range
  enhancedQueryParams[API_DEFINED_AFTER_PARAM_NAME] =
    enhancedQueryParams[API_DEFINED_AFTER_PARAM_NAME] &&
    moment(enhancedQueryParams[API_DEFINED_AFTER_PARAM_NAME])
      ?.utc()
      .startOf('day')
      .format();

  enhancedQueryParams[API_DEFINED_BEFORE_PARAM_NAME] =
    enhancedQueryParams[API_DEFINED_BEFORE_PARAM_NAME] &&
    moment(enhancedQueryParams[API_DEFINED_BEFORE_PARAM_NAME])
      ?.utc()
      .endOf('day')
      .format();

  // Delete unset filters; The api doesn't want them
  Object.keys(enhancedQueryParams).forEach((key) => {
    type Key = keyof EventSummariesRequest;
    if (
      enhancedQueryParams[key as Key] === null ||
      enhancedQueryParams[key as Key] === undefined
    ) {
      delete enhancedQueryParams[key as Key];
    }
  });

  return enhancedQueryParams;
};

export const eventsApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getEventSummaries: builder.query<
      EventSummariesResponse,
      EventSummariesRequest
    >({
      query: (queryParams) => ({
        url: 'events',
        method: 'GET',
        params: getEventSummariesRequestParams(queryParams),
      }),
      providesTags: ['Event'],
    }),
    getEvent: builder.query<Event, string>({
      query: (id) => `/events/${id}`,
      providesTags: ['Event'],
    }),
    createEvent: builder.mutation<string, PostEvent>({
      query: (event) => ({
        url: `/events`,
        method: 'POST',
        body: event.body,
      }),
      invalidatesTags: ['Event'],
    }),
    quickCreateEvent: builder.mutation<string, QuickCreateEvent>({
      query: (payload) => ({
        url: `/events/createFromPrompt`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Event'],
    }),
    updateEvent: builder.mutation<void, PutEvent>({
      query: (event) => ({
        url: `/events/${event.id}`,
        method: 'PUT',
        body: JSON.stringify(event.body),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['Event'],
    }),
    deleteEvent: builder.mutation<void, DeleteEvent>({
      query: (event) => ({
        url: `/events/${event.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Event'],
    }),
  }),
});

export const {
  useGetEventSummariesQuery,
  useGetEventQuery,
  useCreateEventMutation,
  useQuickCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
} = eventsApiSlice;
