import React, { useState } from 'react';
import { Stack, Link, Divider } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import Tabs from './Tabs';
import Buttons from './Buttons';
import Cards from './Cards';
import Breadcrumbs from '../../components/Breadcrumbs';
import Dialogs from './Dialogs';
import KeyValues from './KeyValues';
import FileUploadExample from './FileUploadExample';
import AudioPlayerExample from './AudioPlayerExample';

const linkStyles = {
  fontSize: '24px',
  textDecoration: 'none',
  textTransform: 'unset',
  width: 'fit-content',
  cursor: 'pointer',
};

function Home() {
  // Start Breadcrumbs Example
  const crumbSelectOptions = [
    {
      name: 'Active child',
      id: '1',
    },
    {
      name: 'Another child',
      id: '2',
    },
    {
      name: 'Another child with a long name',
      id: '3',
    },
  ];

  const [crumbSelectValue, setCrumbSelectValue] = useState<string | null>(
    crumbSelectOptions[0].id
  );

  const crumbs = [
    {
      name: 'Grandparent',
      to: './',
    },
    {
      name: 'Parent',
    },
    {
      name: 'Active',
      options: crumbSelectOptions,
      value: crumbSelectValue,
      onChange: setCrumbSelectValue,
    },
  ];

  const components = [
    {
      name: 'Tabs',
      value: 'tabs',
      component: <Tabs />,
    },
    {
      name: 'Buttons',
      value: 'buttons',
      component: <Buttons />,
    },
    {
      name: 'Cards',
      value: 'cards',
      component: <Cards />,
    },
    {
      name: 'Breadcrumbs',
      value: 'breadcrumbs',
      component: <Breadcrumbs crumbs={crumbs} />,
    },
    {
      name: 'Dialogs/Drawers',
      value: 'dialogs',
      component: <Dialogs />,
    },
    {
      name: 'KeyValues',
      value: 'keyvalues',
      component: <KeyValues />,
    },
    {
      name: 'File Upload',
      value: 'fileupload',
      component: <FileUploadExample />,
    },
    {
      name: 'Audio Player',
      value: 'audioplayer',
      component: <AudioPlayerExample />,
    },
  ];
  // End Breadcrumbs Example

  const [searchParams, setSearchParams] = useSearchParams();
  const activeComponent = components.find(
    (component) => component.value === searchParams.get('component')
  );

  if (!activeComponent) {
    return (
      <Stack spacing={2}>
        <Breadcrumbs
          crumbs={[
            {
              name: 'Green Room Components',
            },
          ]}
        />
        <Divider
          sx={{
            borderColor: 'rgba(0, 0, 0, 0.12)',
            width: '100%',
            marginBottom: '24px',
          }}
        />
        {components.map((component) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            onClick={() => {
              setSearchParams({ component: component.value });
            }}
            sx={linkStyles}
            key={component.value}
          >
            {component.name}
          </Link>
        ))}
      </Stack>
    );
  }

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Breadcrumbs
        crumbs={[
          {
            name: 'Green Room Components',
            to: '/components',
          },
          {
            name: activeComponent.name,
          },
        ]}
      />
      <Divider
        sx={{
          borderColor: 'rgba(0, 0, 0, 0.12)',
          width: '100%',
          marginBottom: '24px',
        }}
      />
      {activeComponent.component}
    </div>
  );
}

export default Home;
