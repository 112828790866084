import emptySplitApi from './empty-api-slice';

interface EventRoleTypeBody {
  name: string;
}

interface CreateEventRoleType extends EventRoleTypeBody {
  projectId: string;
}

interface UpdateEventRoleType {
  id: string;
  body: EventRoleTypeBody;
}

export interface EventRoleType extends EventRoleTypeBody {
  id: string;
  projectId: string;
}

export interface GetEventRoleTypesQuery {
  projectId: string;
}

export const eventRoleTypesApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getEventRoleTypesForAllProjects: builder.query<EventRoleType[], void>({
      query: () => `/event_role_types`,
      providesTags: ['EventRoleType'],
    }),
    getEventRoleTypes: builder.query<EventRoleType[], GetEventRoleTypesQuery>({
      query: (query) => `/event_role_types?projectId=${query.projectId}`,
      providesTags: ['EventRoleType'],
    }),
    createEventRoleType: builder.mutation<string, CreateEventRoleType>({
      query: (eventRoleType) => ({
        url: `/event_role_types`,
        method: 'POST',
        body: eventRoleType,
      }),
      invalidatesTags: ['EventRoleType'],
    }),
    updateEventRoleType: builder.mutation<void, UpdateEventRoleType>({
      query: (eventRoleType) => ({
        url: `/event_role_types/${eventRoleType.id}`,
        method: 'PATCH',
        body: JSON.stringify(eventRoleType.body),
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }),
      invalidatesTags: ['EventRoleType'],
    }),
    deleteEventRoleType: builder.mutation<void, string>({
      query: (id) => ({
        url: `/event_role_types/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EventRoleType'],
    }),
  }),
});

export const {
  useGetEventRoleTypesForAllProjectsQuery,
  useGetEventRoleTypesQuery,
  useCreateEventRoleTypeMutation,
  useUpdateEventRoleTypeMutation,
  useDeleteEventRoleTypeMutation,
} = eventRoleTypesApiSlice;
