import React, { useEffect, useState } from 'react';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridRowParams,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import { useGetFilesQuery } from '../app/api/files-api-slice';
import getHumanReadable from '../app/helpers/file-size-helpers';

interface ProjectFileListingProps {
  projectId: string;
  selectedFileIds: string[];
  // eslint-disable-next-line no-unused-vars
  onSelectionChange: (fileIds: string[]) => void;
  height?: string | number;
}

function Toolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

function ProjectFileListing({
  projectId,
  selectedFileIds,
  onSelectionChange,
  height,
}: ProjectFileListingProps) {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 100,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'modifiedAt', sort: 'desc' },
  ]);

  let sort: 'Name' | 'Size' | 'CreatedAt' | 'ModifiedDate';
  let sortDirection: 'Asc' | 'Desc';
  if (sortModel.length > 0) {
    switch (sortModel[0].field) {
      case 'name':
        sort = 'Name';
        break;
      case 'size':
        sort = 'Size';
        break;
      case 'modifiedAt':
        sort = 'ModifiedDate';
        break;
      default:
        sort = 'Name';
    }
    sortDirection = sortModel[0].sort === 'asc' ? 'Asc' : 'Desc';
  } else {
    sort = 'Name';
    sortDirection = 'Asc';
  }

  const [filter, setFilter] = useState<string | null>(null);

  const getFiles = useGetFilesQuery({
    projectId,
    limit: paginationModel.pageSize,
    offset: paginationModel.page * paginationModel.pageSize,
    sort,
    sortDirection,
    namePrefix: filter,
  });

  const [total, setTotal] = useState(0);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 400,
      flex: 1,
      filterable: false,
    },
    {
      field: 'modifiedAt',
      headerName: 'Last Modified At',
      valueFormatter: ({ value }) => {
        const date = moment(value);
        if (date.isSameOrAfter(moment().subtract(1, 'week'))) {
          return date.fromNow();
        }
        return new Date(value).toLocaleString();
      },
      minWidth: 160,
      filterable: false,
    },
    {
      field: 'size',
      headerName: 'Size',
      valueFormatter: ({ value }) => getHumanReadable(value),
      filterable: false,
      minWidth: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 50,
      getActions: (params: GridRowParams) => {
        const file = getFiles.currentData?.files.find(
          (f) => f.id === params.id
        );
        return [
          <GridActionsCellItem
            icon={<DownloadIcon />}
            label="Download"
            onClick={() => {
              const { url } = file!;
              const link = document.createElement('a');
              link.href = url;
              link.click();
            }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    if (getFiles.currentData) {
      setTotal(getFiles.currentData.total);
    }
  }, [getFiles.currentData]);

  return (
    <div style={{ height }}>
      <DataGridPro
        columns={columns}
        loading={getFiles.isFetching}
        rows={getFiles.currentData?.files || []}
        rowCount={total}
        checkboxSelection
        rowSelectionModel={selectedFileIds}
        onRowSelectionModelChange={(model) => {
          onSelectionChange(model.map((id) => (id as GridRowId).toString()));
        }}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => {
          setPaginationModel(model);
        }}
        sortModel={sortModel}
        onSortModelChange={(model) => {
          setSortModel(model);
        }}
        filterMode="server"
        onFilterModelChange={(model) => {
          if (
            model.quickFilterValues &&
            model.quickFilterValues.length > 0 &&
            (model.quickFilterValues[0] as string) !== ''
          ) {
            setFilter(model.quickFilterValues[0] as string);
          } else {
            setFilter(null);
          }
        }}
        slots={{
          toolbar: Toolbar,
        }}
        keepNonExistentRowsSelected
        localeText={{
          footerRowSelected: (count) =>
            count !== 1
              ? `${count.toLocaleString()} files selected`
              : `${count.toLocaleString()} file selected`,
          footerTotalRows: 'Total Files',
          checkboxSelectionSelectAllRows: 'Select all files',
          checkboxSelectionUnselectAllRows: 'Unselect all files',
          checkboxSelectionSelectRow: 'Select file',
          checkboxSelectionUnselectRow: 'Unselect file',
          noRowsLabel: 'No files',
          noResultsOverlayLabel: 'No files',
        }}
        pinnedColumns={{
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          right: ['actions'],
        }}
      />
    </div>
  );
}

ProjectFileListing.defaultProps = {
  height: '100%',
};

export default ProjectFileListing;
