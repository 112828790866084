import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import React from 'react';
import { useGetPersonsQuery } from '../app/api/persons-api-slice';
import { Project } from '../app/api/projects-api-slice';
import GreenRoomButton from '../components/GreenRoomButton';
import ProjectMembers from '../components/ProjectMembers';

export interface OnboardingPageProjectMembersProps {
  project: Project;
  onNext: () => void;
}

function OnboardingPageProjectMembers({
  project,
  onNext,
}: OnboardingPageProjectMembersProps) {
  const getPersons = useGetPersonsQuery({ projectId: project.id });

  return (
    <>
      <DialogTitle>
        Who&apos;s involved with <strong>{project.name}</strong>?
      </DialogTitle>
      <DialogContent>
        <div className="onboarding-content">
          <ProjectMembers projectId={project.id} hideTitle />
        </div>
      </DialogContent>
      <DialogActions>
        <GreenRoomButton type="cancel" onClick={onNext}>
          Skip
        </GreenRoomButton>
        <GreenRoomButton
          type="accept"
          onClick={() => {
            onNext();
          }}
          disabled={getPersons.isLoading || getPersons.data?.length === 0}
        >
          Next
        </GreenRoomButton>
      </DialogActions>
    </>
  );
}

export default OnboardingPageProjectMembers;
