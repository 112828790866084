/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Typography } from '@mui/material';
import {
  ToolbarProps,
  ToolbarSlot,
  TransformToolbarSlot,
} from '@react-pdf-viewer/toolbar';
import GreenRoomDialog from './Dialog/GreenRoomDialog';

interface PdfViewerProps {
  name: string;
  url: string;
  open: boolean;
  onClose: () => void;
}

function PdfViewer({ name, url, open, onClose }: PdfViewerProps) {
  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    OpenMenuItem: () => <></>,
    Open: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
  });

  const renderToolbar = (
    // eslint-disable-next-line no-unused-vars
    Toolbar: (props: ToolbarProps) => React.ReactElement
  ) => (
    // eslint-disable-next-line no-use-before-define
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });
  const { renderDefaultToolbar } =
    defaultLayoutPluginInstance.toolbarPluginInstance;

  return (
    <GreenRoomDialog
      fullWidth
      fullscreen
      open={open}
      onClose={onClose}
      title={<Typography variant="caption">{name}</Typography>}
    >
      {url && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} />
        </Worker>
      )}
    </GreenRoomDialog>
  );
}

export default PdfViewer;
