import { Backdrop, CircularProgress } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { useState } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2';
import { useInterval } from 'usehooks-ts';
import { useGetProjectFinancialAccountQuery } from '../app/api/project-financial-account-slice';
import { sec } from '../app/security';
import configData from '../config.json';
import GreenRoomButton from './GreenRoomButton';
import Blankslate from './Blankslate';
import { ReactComponent as Piggy } from '../img/placeholders/piggy.svg';
import { ReactComponent as Documents } from '../img/placeholders/documents.svg';
import { ReactComponent as Locked } from '../img/placeholders/locked.svg';
import { ReactComponent as Crying } from '../img/placeholders/crying.svg';
import { ReactComponent as Sandclock } from '../img/placeholders/sandclock.svg';
import { ReactComponent as Close } from '../img/placeholders/close.svg';
import ButtonLink from './Buttons/ButtonLink';
import Loading from './Loading';
import { useGetUserQuery } from '../app/api/user-api-slice';
import { useGetProjectQuery } from '../app/api/projects-api-slice';
import './ProjectFinancialAccountSetup.scss';
import ProjectFinancialAccountSetupConfirmationDialog from './ProjectFinancialAccountSetupConfirmationDialog';

interface ProjectFinancialAccountSetupProps {
  projectId: string;
  hideEditButton?: boolean;
}

interface SetupProjectFinancialAccountResponse {
  redirectUrl: string;
}

function ProjectFinancialAccountSetup({
  projectId,
  hideEditButton,
}: ProjectFinancialAccountSetupProps) {
  const getProjectFinancialAccountQuery =
    useGetProjectFinancialAccountQuery(projectId);

  const getProject = useGetProjectQuery(projectId);
  const getUser = useGetUserQuery();

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useInterval(() => {
    if (
      getProjectFinancialAccountQuery.isSuccess &&
      getProjectFinancialAccountQuery.data &&
      !getProjectFinancialAccountQuery.data.enabled &&
      getProjectFinancialAccountQuery.data.disabledReason ===
        'greenroom_setup_pending'
    ) {
      getProjectFinancialAccountQuery.refetch();
    }
  }, 5000);

  const [setupInProgress, setSetupInProgress] = React.useState(false);

  const setupFinancialAccount = async () => {
    setSetupInProgress(true);
    const token = await sec.getAccessTokenSilently()();
    fetch(`${configData.apiDomain}/projects/${projectId}/financial_account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ redirectUrl: window.location.href }),
    })
      .then(
        async (response) =>
          (await response.json()) as SetupProjectFinancialAccountResponse
      )
      .then((response) => {
        window.open(response.redirectUrl, '_self');
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Error Occurred',
          text: 'There was a problem setting up your financial account. Please try again later. If the problem persists, please contact us at support@greenroom.app',
        });
        setSetupInProgress(false);
      });
  };

  if (setupInProgress) {
    return (
      <Backdrop open>
        <CircularProgress size={150} />
      </Backdrop>
    );
  }

  if (
    getProjectFinancialAccountQuery.isLoading ||
    getProject.isLoading ||
    getUser.isLoading
  ) {
    return <Loading />;
  }

  const editAccountButton = (
    <GreenRoomButton
      type="edit"
      onClick={() => {
        setupFinancialAccount();
      }}
    >
      Edit
    </GreenRoomButton>
  );

  if (
    getProjectFinancialAccountQuery.isSuccess &&
    !getProjectFinancialAccountQuery.data.enabled &&
    (getProjectFinancialAccountQuery.data.disabledReason ===
      'greenroom_setup_pending' ||
      getProjectFinancialAccountQuery.data.disabledReason ===
        'user_setup_pending')
  ) {
    return (
      <>
        {getProjectFinancialAccountQuery.data.disabledReason ===
          'greenroom_setup_pending' && (
          <Blankslate
            title="Processing your submission"
            svg={<Sandclock />}
            actions={editAccountButton}
          >
            <span>
              It looks like you have already completed your submission.
              We&apos;re working on completing your account setup. This can take
              anywhere from a few minutes to two business days. If you&apos;ve
              gotten stuck, please{' '}
              <ButtonLink
                onClick={() =>
                  window.Intercom(
                    'showNewMessage',
                    'I submitted my application for a Green Room financial account, but it appears to be stuck.'
                  )
                }
              >
                Contact us
              </ButtonLink>
              . Use the link below to return to your account creation form.
            </span>
          </Blankslate>
        )}
        {getProjectFinancialAccountQuery.data.disabledReason ===
          'user_setup_pending' && (
          <Blankslate
            title="Continue setting up your account"
            svg={<Documents />}
            actions={
              <GreenRoomButton
                type="accept"
                onClick={() => {
                  setupFinancialAccount();
                }}
                className="fa-setup-button"
              >
                Resume Creating Account
              </GreenRoomButton>
            }
          >
            <span>
              It looks like you have already started creating a financial
              account, but didn&apos;t finish. Click the link below to pick up
              where you left off.
            </span>
          </Blankslate>
        )}
      </>
    );
  }

  if (
    getProjectFinancialAccountQuery.isSuccess &&
    !getProjectFinancialAccountQuery.data.enabled &&
    getProjectFinancialAccountQuery.data.disabledReason === 'closed'
  ) {
    return (
      <Blankslate title="Account closed" svg={<Close />}>
        <span>
          Your financial account has been closed.
          <br />
          <ButtonLink
            onClick={() =>
              window.Intercom(
                'showNewMessage',
                'My financial account was closed for my project, but I would like to re-open an account.'
              )
            }
          >
            Contact us
          </ButtonLink>{' '}
          if you would like to re-open your account.
        </span>
      </Blankslate>
    );
  }

  if (
    getProjectFinancialAccountQuery.isSuccess &&
    !getProjectFinancialAccountQuery.data.enabled
  ) {
    return (
      <Blankslate title="Account disabled" svg={<Locked />}>
        <span>
          Your financial account is disabled right now.
          <br />
          <ButtonLink
            onClick={() =>
              window.Intercom(
                'showNewMessage',
                'My financial account is disabled right now.'
              )
            }
          >
            Contact us
          </ButtonLink>{' '}
          for assistance.
        </span>
      </Blankslate>
    );
  }

  if (
    getProjectFinancialAccountQuery.isError &&
    (getProjectFinancialAccountQuery.error as FetchBaseQueryError)?.status ===
      404
  ) {
    let title = 'No Financial Account';
    let body = (
      <>
        Financial Accounts let you keep your music finances separate, pay your
        team instantly, and spend your music earning as instantly. Create an
        account below to get started!
      </>
    );
    if (projectId === 'personal') {
      title = 'No Personal Financial Account';
      body = (
        <>
          Financial accounts let you accept payments from others in Green Room.
        </>
      );
    }
    return (
      <>
        <Blankslate
          title={title}
          actions={
            <GreenRoomButton
              type="create"
              onClick={() => {
                setShowConfirmationDialog(true);
              }}
              className="fa-setup-button"
            >
              Create Financial Account
            </GreenRoomButton>
          }
          svg={<Piggy />}
        >
          <span>{body}</span>
        </Blankslate>
        <ProjectFinancialAccountSetupConfirmationDialog
          open={showConfirmationDialog}
          onAccept={() => {
            setShowConfirmationDialog(false);
            setupFinancialAccount();
          }}
          projectName={
            getProject.data?.id === 'personal'
              ? 'yourself'
              : getProject.data?.name!
          }
          onCancel={() => setShowConfirmationDialog(false)}
        />
      </>
    );
  }

  if (getProjectFinancialAccountQuery.isError) {
    return (
      <Blankslate title="Something went wrong" svg={<Crying />}>
        <span>
          There was a problem loading your financial account. If this problem
          persists, please{' '}
          <ButtonLink
            onClick={() =>
              window.Intercom(
                'showNewMessage',
                "I'm having trouble viewing my financial account."
              )
            }
          >
            contact us
          </ButtonLink>{' '}
          for assistance.
        </span>
      </Blankslate>
    );
  }

  if (hideEditButton) {
    return null;
  }

  return (
    <div className="financial-account-info-actions">
      {editAccountButton}
      <GreenRoomButton
        type="destroy"
        onClick={() => {
          window.Intercom(
            'showNewMessage',
            'I would like to close my financial account.'
          );
        }}
      >
        Close Account
      </GreenRoomButton>
    </div>
  );
}

ProjectFinancialAccountSetup.defaultProps = {
  hideEditButton: false,
};

export default ProjectFinancialAccountSetup;
