import React from 'react';
import { Button, SxProps, Theme } from '@mui/material';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Send from '@mui/icons-material/Send';
import Delete from '@mui/icons-material/Delete';
import Report from '@mui/icons-material/Report';
import Message from '@mui/icons-material/Message';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './GreenRoomButton.scss';

interface GreenRoomButtonProps {
  children?: React.ReactNode | null;
  // Pass one of the 3 actions below
  onClick?: () => any;
  to?: string | null;
  copyText?: string;
  // Choose a button style
  type:
    | 'accept'
    | 'create'
    | 'edit'
    | 'transfer'
    | 'contact'
    | 'add'
    | 'cancel'
    | 'destroy'
    | 'error'
    | 'copy';
  // Remove color if desired
  subtle?: boolean;
  disabled?: boolean;
  className?: string | null;
  sx?: SxProps<Theme>;
  icon?: React.ReactNode;
}

function GreenRoomButton({
  children,
  onClick,
  to,
  copyText,
  type,
  subtle,
  disabled,
  className,
  sx,
  icon,
}: GreenRoomButtonProps) {
  const { enqueueSnackbar } = useSnackbar();

  interface props {
    component?: typeof Link | typeof Button;
    onClick?: () => void | null;
    to?: string | null;
    startIcon?: React.ReactNode | null;
    color: 'primary' | 'secondary' | 'error';
    variant: 'contained' | 'outlined' | 'text';
  }

  /// //////////
  // Default button styles
  let content = children;
  const buttonProps: props = {
    color: 'primary',
    variant: 'contained',
  };

  /// //////////
  // Button as Link
  if (to) {
    buttonProps.component = Link;
    buttonProps.to = to;
  }

  /// //////////
  // Button as Button
  if (onClick) {
    buttonProps.onClick = onClick;
  }

  /// //////////
  // Button as Copy to clipboard
  if (copyText) {
    buttonProps.onClick = () => {
      navigator.clipboard.writeText(copyText || '');
      enqueueSnackbar('Copied', {
        variant: 'success',
      });
    };
  }

  /// //////////
  // Use `type` to apply styles
  if (type === 'accept') {
    // Use default
  } else if (type === 'create') {
    buttonProps.startIcon = <Add />;
  } else if (type === 'edit') {
    buttonProps.startIcon = <Edit />;
  } else if (type === 'transfer') {
    buttonProps.startIcon = <Send />;
  } else if (type === 'contact') {
    buttonProps.variant = 'text';
    buttonProps.startIcon = <Message />;
  } else if (type === 'add') {
    buttonProps.variant = 'text';
    buttonProps.startIcon = <Add />;
  } else if (type === 'cancel') {
    buttonProps.color = 'secondary';
    buttonProps.variant = 'outlined';
  } else if (type === 'destroy') {
    buttonProps.color = 'error';
    buttonProps.variant = 'text';
    buttonProps.startIcon = <Delete />;
  } else if (type === 'error') {
    buttonProps.color = 'error';
    buttonProps.variant = 'text';
    buttonProps.startIcon = <Report />;
  } else if (type === 'copy') {
    buttonProps.variant = 'text';
    content = (
      <>
        <div className="green-room-button-content">{children}</div>
        <ContentCopyIcon />
      </>
    );
  }

  if (icon) {
    buttonProps.startIcon = icon;
  }

  /// //////////
  // Use `subtle` to overwrite styles
  // TODO: This feels like a working solution until the button library develops more patterns
  if (subtle) {
    buttonProps.color = 'secondary';
    buttonProps.variant = 'outlined';
  }

  /// //////////
  // Render button
  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
      disabled={disabled}
      className={`green-room-button ${className}`}
      sx={sx}
    >
      {content}
    </Button>
  );
}

GreenRoomButton.defaultProps = {
  children: null,
  onClick: null,
  to: null,
  subtle: false,
  disabled: false,
  className: '',
  copyText: '',
  sx: undefined,
  icon: undefined,
};

export default GreenRoomButton;
