import { GreenRoomFile } from '../api/files-api-slice';

function isExtension(file: GreenRoomFile, extensions: string[]) {
  return extensions.some((extension) =>
    file.name.toLowerCase().endsWith(extension)
  );
}

export function isAudioFile(file: GreenRoomFile) {
  return isExtension(file, ['.mp3', '.wav', '.m4a', '.ogg', '.flac', '.aac']);
}

export function isPdf(file: GreenRoomFile) {
  return isExtension(file, ['.pdf']);
}

export function isImage(file: GreenRoomFile) {
  return isExtension(file, [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.bmp',
    '.svg',
    '.webp',
    '.tiff',
    '.tif',
  ]);
}

export default isAudioFile;
