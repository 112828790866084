import React, { useEffect, useState } from 'react';
import {
  Stripe,
  StripeIssuingCardCvcDisplayElement,
  StripeIssuingCardExpiryDisplayElement,
  StripeIssuingCardNumberDisplayElement,
  StripeIssuingCardNumberDisplayElementOptions,
  StripeIssuingCardPinDisplayElement,
  loadStripe,
} from '@stripe/stripe-js';
import { useSnackbar } from 'notistack';
import { Button, DialogContent, Skeleton } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CopyIcon from '@mui/icons-material/ContentCopy';
import {
  Card,
  useCreateCardEphemeralKeyMutation,
} from '../../app/api/project-financial-account-slice';
import configData from '../../config.json';
import GreenRoomDialog from '../../components/Dialog/GreenRoomDialog';
import './ViewCardDialog.scss';
import { useGetFeatureFlagsQuery } from '../../app/api/feature-flags-slice';

interface ViewCardDialogProps {
  projectId: string;
  stripeConnectAccountId: string;
  card: Card | null;
  open: boolean;
  onClose: () => void;
}

interface StripeElements {
  cardNumber?: StripeIssuingCardNumberDisplayElement;
  cardNumberCopy?: StripeIssuingCardNumberDisplayElement;
  cardCvc?: StripeIssuingCardCvcDisplayElement;
  cardCvcCopy?: StripeIssuingCardCvcDisplayElement;
  cardExpiry?: StripeIssuingCardExpiryDisplayElement;
  cardExpiryCopy?: StripeIssuingCardExpiryDisplayElement;
  cardPin?: StripeIssuingCardPinDisplayElement;
}

function ViewCardDialog({
  projectId,
  stripeConnectAccountId,
  card,
  open,
  onClose,
}: ViewCardDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const getFeatureFlags = useGetFeatureFlagsQuery();

  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [nonce, setNonce] = useState<string | null>(null);
  const [stripeElements, setStripeElements] = useState<StripeElements>({});

  const [createCardEphemeralKey, createCardEphemeralKeyStatus] =
    useCreateCardEphemeralKeyMutation();

  useEffect(() => {
    if (stripeConnectAccountId) {
      loadStripe(configData.stripe.publicKey, {
        stripeAccount: stripeConnectAccountId,
      }).then((s) => setStripe(s));
    } else {
      setStripe(null);
    }
  }, [stripeConnectAccountId]);

  useEffect(() => {
    if (stripe && card) {
      stripe
        ?.createEphemeralKeyNonce({ issuingCard: card?.id! })
        .then((result) => {
          createCardEphemeralKey({
            projectId,
            cardId: card?.id!,
            nonce: result.nonce!,
          });
          setNonce(result.nonce!);
        });
    }
  }, [stripe, card]);

  useEffect(() => {});

  const showCopiedSnackbar = () => {
    enqueueSnackbar('Copied', {
      variant: 'success',
    });
  };

  useEffect(() => {
    if (createCardEphemeralKeyStatus.isSuccess) {
      const elements = stripe?.elements();

      if (card?.type === 'virtual') {
        const cardNumber = elements?.create('issuingCardNumberDisplay', {
          issuingCard: card?.id!,
          nonce,
          ephemeralKeySecret:
            createCardEphemeralKeyStatus.data!.ephemeralKeySecret,
        } as StripeIssuingCardNumberDisplayElementOptions);
        cardNumber?.mount('#card-number');

        const cardNumberCopy = elements?.create('issuingCardCopyButton', {
          toCopy: 'number',
          style: {
            base: {
              lineHeight: '2',
            },
          },
        });
        cardNumberCopy?.on('click', () => showCopiedSnackbar());
        cardNumberCopy?.mount('#card-number-copy-button');

        const cardCvc = elements?.create('issuingCardCvcDisplay', {
          issuingCard: card?.id!,
          nonce,
          ephemeralKeySecret:
            createCardEphemeralKeyStatus.data!.ephemeralKeySecret,
        } as StripeIssuingCardNumberDisplayElementOptions);
        cardCvc?.mount('#card-cvc');

        const cardCvcCopy = elements?.create('issuingCardCopyButton', {
          toCopy: 'cvc',
          style: {
            base: {
              lineHeight: '2',
            },
          },
        });
        cardCvcCopy?.on('click', () => showCopiedSnackbar());
        cardCvcCopy?.mount('#card-cvc-copy-button');

        const cardExpiry = elements?.create('issuingCardExpiryDisplay', {
          issuingCard: card?.id!,
          nonce,
          ephemeralKeySecret:
            createCardEphemeralKeyStatus.data!.ephemeralKeySecret,
        } as StripeIssuingCardNumberDisplayElementOptions);
        cardExpiry?.mount('#card-expiry');

        const cardExpiryCopy = elements?.create('issuingCardCopyButton', {
          toCopy: 'expiry',
          style: {
            base: {
              lineHeight: '2',
            },
          },
        });
        cardExpiryCopy?.on('click', () => showCopiedSnackbar());
        cardExpiryCopy?.mount('#card-expiry-copy-button');

        setStripeElements({ cardNumber, cardNumberCopy, cardCvc, cardExpiry });
      } else if (
        card?.type === 'physical' &&
        getFeatureFlags.data?.issuingPinEnabled
      ) {
        const cardPin = elements?.create('issuingCardPinDisplay', {
          issuingCard: card?.id!,
          nonce,
          ephemeralKeySecret:
            createCardEphemeralKeyStatus.data!.ephemeralKeySecret,
        } as StripeIssuingCardNumberDisplayElementOptions);
        cardPin?.mount('#card-pin');

        setStripeElements({ cardPin });
      }
    }
  }, [createCardEphemeralKeyStatus.isSuccess]);

  const handleClose = () => {
    onClose();
    setNonce(null);
    stripeElements.cardNumber?.destroy();
    stripeElements.cardNumberCopy?.destroy();
    stripeElements.cardCvc?.destroy();
    stripeElements.cardCvcCopy?.destroy();
    stripeElements.cardExpiry?.destroy();
    stripeElements.cardExpiryCopy?.destroy();
    stripeElements.cardPin?.destroy();
    setStripeElements({});
  };

  return (
    <GreenRoomDialog
      open={open}
      onClose={handleClose}
      title="Card Details"
      actions={
        <>
          {card?.status === 'inactive' && (
            <div className="finances-cards-button-wrapper">
              <Button
                variant="contained"
                startIcon={<CheckCircleIcon />}
                onClick={() => {
                  window.Intercom(
                    'showNewMessage',
                    `I would like to activate my card ending with ${card.last4}.`
                  );
                }}
              >
                Activate
              </Button>
            </div>
          )}
          {card?.status === 'active' && (
            <div className="finances-cards-button-wrapper">
              <Button
                variant="contained"
                startIcon={<CancelIcon />}
                color="error"
                onClick={() => {
                  window.Intercom(
                    'showNewMessage',
                    `I would like to cancel my card ending with ${card.last4}.`
                  );
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </>
      }
    >
      <DialogContent>
        <div className="view-card-dialog-key-and-value">
          <b>Card Number: </b>{' '}
          <div className="view-card-dialog-value-and-copy-button">
            {card?.type === 'virtual' && (
              <>
                <div className="view-card-dialog-value">
                  <div id="card-number">
                    <Skeleton width={200} />
                  </div>
                </div>
                <div className="view-card-dialog-copy-button-container">
                  <div
                    className="view-card-dialog-copy-button-iframe"
                    id="card-number-copy-button"
                  />
                  <CopyIcon className="view-card-dialog-copy-button" />
                </div>
              </>
            )}
            {card?.type === 'physical' && (
              <div className="view-card-dialog-value">
                •••• •••• •••• {card.last4}
              </div>
            )}
          </div>
        </div>
        <div className="view-card-dialog-key-and-value">
          <b>CVC: </b>{' '}
          <div className="view-card-dialog-value-and-copy-button">
            {card?.type === 'virtual' && (
              <>
                <div className="view-card-dialog-value">
                  <div id="card-cvc">
                    <Skeleton width={25} />
                  </div>
                </div>
                <div className="view-card-dialog-copy-button-container">
                  <div
                    className="view-card-dialog-copy-button-iframe"
                    id="card-cvc-copy-button"
                  />
                  <CopyIcon className="view-card-dialog-copy-button" />
                </div>
              </>
            )}
            {card?.type === 'physical' && (
              <div className="view-card-dialog-value">•••</div>
            )}
          </div>
        </div>
        <div className="view-card-dialog-key-and-value">
          <b>Expiry Date: </b>{' '}
          <div className="view-card-dialog-value-and-copy-button">
            {card?.type === 'virtual' && (
              <>
                <div className="view-card-dialog-value">
                  <div id="card-expiry">
                    <Skeleton width={50} />
                  </div>
                </div>
                <div className="view-card-dialog-copy-button-container">
                  <div
                    className="view-card-dialog-copy-button-iframe"
                    id="card-expiry-copy-button"
                  />
                  <CopyIcon className="view-card-dialog-copy-button" />
                </div>
              </>
            )}
            {card?.type === 'physical' && (
              <div className="view-card-dialog-value">
                {card.expMonth.toString().padStart(2, '0')}/
                {card.expYear - 2000}
              </div>
            )}
          </div>
        </div>
        {getFeatureFlags?.data?.issuingPinEnabled && card?.type === 'physical' && (
          <div className="view-card-dialog-key-and-value">
            <b>PIN: </b>{' '}
            <div className="view-card-dialog-value-and-copy-button">
              {card?.type === 'physical' && (
                <div className="view-card-dialog-value">
                  <div id="card-pin">
                    <Skeleton width={50} />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </DialogContent>
    </GreenRoomDialog>
  );
}

export default ViewCardDialog;
