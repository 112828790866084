/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Link,
  Typography,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Project,
  useCreateSubscriptionPortalSessionMutation,
  useGetProjectSubscriptionQuery,
} from '../app/api/projects-api-slice';
import Loading from './Loading';
import getFormattedAmountWithCurrencySign from '../app/helpers/currency-helpers';
import Heading from './Text/Heading';
import GreenRoomCard from './GreenRoomCard';
import configData from '../config.json';

interface ProjectSubscriptionProps {
  project: Project;
}

function ProjectSubscription({ project }: ProjectSubscriptionProps) {
  const { user } = useAuth0();
  const getProjectSubscription = useGetProjectSubscriptionQuery(project.id);
  const [
    createSubscriptionPortalSessionMutation,
    createSubscriptionPortalSessionMutationStatus,
  ] = useCreateSubscriptionPortalSessionMutation();
  const [navigationInProgress, setNavigationInProgress] = useState(false);

  localStorage.setItem('lastProjectSubscriptionSettingsVisited', project.id);

  useEffect(() => {
    if (createSubscriptionPortalSessionMutationStatus.isSuccess) {
      setNavigationInProgress(true);
      window.open(
        createSubscriptionPortalSessionMutationStatus.data.url,
        '_self'
      );
    }
  }, [createSubscriptionPortalSessionMutationStatus]);

  if (getProjectSubscription.isLoading) {
    return <Loading />;
  }

  if (navigationInProgress) {
    return (
      <Backdrop open>
        <CircularProgress size={150} />
      </Backdrop>
    );
  }

  const interval =
    getProjectSubscription.data?.intervalCount === 1
      ? getProjectSubscription.data?.interval
      : `${getProjectSubscription.data?.intervalCount} ${getProjectSubscription.data?.interval}s`;

  if (getProjectSubscription.data?.planId) {
    const currentPeriodEnd =
      getProjectSubscription.data.currentPeriodEnd !== null
        ? new Date(getProjectSubscription.data.currentPeriodEnd)
        : null;

    const canceledAt =
      getProjectSubscription.data.canceledAt !== null
        ? new Date(getProjectSubscription.data.canceledAt)
        : null;

    const status = getProjectSubscription.data?.status!;

    if (status === 'canceled') {
      return (
        <Alert severity="info" sx={{ marginTop: '8px' }}>
          Your subscription was canceled on {canceledAt?.toLocaleDateString()}.
          If you&apos;d like to renew your subscription,{' '}
          <Link
            component="button"
            onClick={() => {
              window.Intercom(
                'showNewMessage',
                `I would like to renew my subscription for ${project.name}.`
              );
            }}
            style={{ textTransform: 'unset' }}
            variant="body2"
          >
            <b>let us know</b>
          </Link>
          .
        </Alert>
      );
    }

    const chip = () => {
      if (canceledAt) {
        return <Chip label="Canceled" color="default" />;
      }
      if (status === 'trialing') {
        return <Chip label="Free Trial" color="warning" />;
      }
      if (status === 'active') {
        return <Chip label="Active" color="success" />;
      }
      if (status === 'past_due') {
        return <Chip label="Past Due" color="error" />;
      }
      if (status === 'incomplete' || status === 'incomplete_expired') {
        return <Chip label="Incomplete" color="error" />;
      }
      if (status === 'paused') {
        return <Chip label="Paused" color="default" />;
      }
      return null;
    };
    return (
      <div>
        <Heading>Active Subscription</Heading>
        <GreenRoomCard
          left={chip()}
          title={getProjectSubscription.data.planName}
          subtitle={`${getFormattedAmountWithCurrencySign(
            getProjectSubscription.data.amount!
          )}
          / ${interval}`}
        >
          {currentPeriodEnd && getProjectSubscription.data?.amount! > 0 && (
            <Typography variant="body2">
              {canceledAt
                ? `Cancellation was requested on ${canceledAt.toLocaleDateString()} and will be canceled on `
                : 'Next bill on '}{' '}
              {currentPeriodEnd.toLocaleDateString()}.
            </Typography>
          )}
        </GreenRoomCard>
        <Button
          variant="text"
          sx={{ marginTop: '8px' }}
          startIcon={<SettingsIcon />}
          disabled={createSubscriptionPortalSessionMutationStatus.isLoading}
          onClick={() =>
            createSubscriptionPortalSessionMutation({
              projectId: project.id,
              body: { redirectUrl: window.location.href },
            })
          }
        >
          {canceledAt ? 'Renew Subscription' : 'Manage Subscription'}
        </Button>
      </div>
    );
  }

  return (
    <div>
      <stripe-pricing-table
        pricing-table-id={configData.stripe.pricingTableId}
        publishable-key={configData.stripe.publicKey}
        customer-email={user!.email}
        client-reference-id={project.id}
      />
    </div>
  );
}

export default ProjectSubscription;
