import { Project, useGetProjectsQuery } from '../api/projects-api-slice';

/// //////////
// Returns projects that the user is owner or admin of
const useGetAuthorizedProjectsQuery = () => {
  const projectsQuery = useGetProjectsQuery();
  const projects =
    projectsQuery?.data?.filter(
      (project: Project) => project.role === 'Owner' || project.role === 'Admin'
    ) || [];

  projectsQuery.data = projects;

  return projectsQuery;
};

export default useGetAuthorizedProjectsQuery;
