import { Typography } from '@mui/material';
import React from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { Transaction, EventSummary } from '../../app/api/events-api-slice';
import { ProjectTransaction } from '../../app/api/transactions-api-slice';
import GreenRoomCard from '../../components/GreenRoomCard';
import {
  getFormattedAmount,
  INCOME,
  getPaymentInfo,
} from '../../app/helpers/transaction-helpers';
import { LOCATION_TBD } from '../../app/helpers/location-helpers';
import { PaymentType } from '../../app/api/payment-types-api-slice';
import { getFormattedDate } from '../../app/helpers/event-helpers';

interface EventTransactionCardProps {
  transaction: Transaction | ProjectTransaction;
  paymentTypes: PaymentType[] | undefined;
  eventSummary?: EventSummary | null;
  onClick?: () => void;
  title?: string;
}

function EventTransactionCard({
  transaction,
  paymentTypes,
  eventSummary,
  onClick,
  title,
}: EventTransactionCardProps) {
  let iconStyles = {};

  if (transaction.type === INCOME) {
    iconStyles = {
      color: 'primary.dark',
      fontWeight: 700,
    };
  }

  const locationName = eventSummary?.locationName || LOCATION_TBD;

  return (
    <GreenRoomCard
      key={transaction.id}
      onClick={onClick || undefined}
      title={title}
      left={
        transaction.isPaid ? (
          <AttachMoneyIcon sx={{ ...iconStyles }} />
        ) : (
          <HourglassEmptyIcon sx={{ ...iconStyles }} />
        )
      }
      subtitle={
        <>
          {eventSummary && (
            <div>{`${locationName} on ${getFormattedDate(
              eventSummary.startTime
            )}`}</div>
          )}
          <div>{getPaymentInfo(paymentTypes, transaction)}</div>
        </>
      }
      right={
        <Typography variant="body1" sx={{ ...iconStyles }}>
          {getFormattedAmount(transaction.amount, transaction.type)}
        </Typography>
      }
    />
  );
}

EventTransactionCard.defaultProps = {
  eventSummary: null,
  onClick: null,
  title: null,
};

export default EventTransactionCard;
