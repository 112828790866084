import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import './BreadcrumbsSelect.scss';

export interface BreadcrumbSelectOptionProps {
  id: string;
  name: string;
}

interface BreadcrumbsSelectProps {
  label: string;
  options: BreadcrumbSelectOptionProps[];
  value?: string | null;
  // eslint-disable-next-line no-unused-vars
  onChange?: (id: string | null) => void;
}

function BreadcrumbsSelect({
  label,
  options,
  value,
  onChange,
}: BreadcrumbsSelectProps) {
  const onSelectChange = onChange || (() => {});

  // auto-select the project if there's more than 1
  if (options?.length > 0 && !value && onChange) {
    onSelectChange(options[0].id);
  }

  return (
    <FormControl className="breadcrumbs-select-form">
      <Select
        value={value}
        onChange={(e) => {
          onSelectChange(e.target.value || null);
        }}
        label={label}
        className="breadcrumbs-select"
        sx={{ color: 'primary.main' }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

BreadcrumbsSelect.defaultProps = {
  value: null,
  onChange() {},
};

export default BreadcrumbsSelect;
