import { Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useGetPersonsQuery } from '../app/api/persons-api-slice';
import CreateEditProjectMemberDialog from '../pages/CreateEditProjectMemberDialog';
import GreenRoomButton from './GreenRoomButton';
import GreenRoomStack from './GreenRoomStack';
import TabContent from './Tabs/TabContent';
import Blankslate from './Blankslate';
import Loading from './Loading';
import { ReactComponent as Bench } from '../img/placeholders/bench.svg';
import GreenRoomCard from './GreenRoomCard';
import { EditPerson } from '../app/helpers/contact-helpers';

interface ProjectMembersProps {
  projectId: string;
  hideTitle?: boolean;
}

function ProjectMembers({ projectId, hideTitle }: ProjectMembersProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getPersons = useGetPersonsQuery({ projectId });
  const newEditPerson = {
    id: null,
    name: '',
    email: null,
    phoneNumber: null,
    projectId,
    defaultSendEmail: false,
    defaultSendText: false,
    phoneNumberStatus: null,
  } as EditPerson;
  const [dialogProps, setDialogProps] = useState({
    open: false,
    person: { ...newEditPerson },
  });

  useEffect(() => {
    if (getPersons.data && dialogProps.person && dialogProps.person.id) {
      const updatedPerson = getPersons.data.find(
        (person) => person.id === dialogProps.person.id
      );
      setDialogProps({
        ...dialogProps,
        person: {
          ...dialogProps.person,
          phoneNumberStatus: updatedPerson?.phoneNumberStatus!,
          phoneNumberOptInLastRequestedAt:
            updatedPerson?.phoneNumberOptInLastRequestedAt!,
        },
      });
    }
  }, [getPersons.data]);

  /// //////////
  // Blankslate constants
  const description =
    'Project members are people that perform or help the project put on shows. Add them below to make them available when you create events.';
  const action = (
    <GreenRoomButton
      type="add"
      onClick={() =>
        setDialogProps({ open: true, person: { ...newEditPerson } })
      }
    >
      Add Project Member
    </GreenRoomButton>
  );

  /// //////////
  // Render loading state
  if (getPersons.isLoading) {
    return <Loading />;
  }

  return (
    <TabContent>
      {(getPersons.data && getPersons.data?.length > 0 && (
        <Stack spacing={2}>
          <div>{description}</div>
          <GreenRoomStack>
            {getPersons.data?.map((person) => (
              <GreenRoomCard
                key={person.id}
                title={person.name}
                onClick={() => {
                  const editPerson = {
                    id: person.id,
                    name: person.name,
                    email: person.email,
                    phoneNumber: person.phoneNumber,
                    projectId,
                    defaultSendEmail: person.defaultSendEmail,
                    defaultSendText: person.defaultSendText,
                    phoneNumberStatus: person.phoneNumberStatus,
                    phoneNumberOptInLastRequestedAt:
                      person.phoneNumberOptInLastRequestedAt,
                  };
                  setDialogProps({
                    open: true,
                    person: editPerson,
                  });
                }}
              />
            ))}
          </GreenRoomStack>
          {action}
        </Stack>
      )) || (
        <Blankslate
          title={!hideTitle ? 'No members' : null}
          actions={action}
          svg={!hideTitle ? <Bench /> : null}
        >
          {description}
        </Blankslate>
      )}
      <CreateEditProjectMemberDialog
        person={dialogProps.person}
        fullScreen={fullScreen}
        open={dialogProps.open}
        onClose={() => {
          setDialogProps({ ...dialogProps, open: false });
        }}
      />
    </TabContent>
  );
}

ProjectMembers.defaultProps = {
  hideTitle: false,
};

export default ProjectMembers;
