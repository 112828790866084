import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import InfoIcon from '@mui/icons-material/Info';
import GroupIcon from '@mui/icons-material/Group';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import LabelIcon from '@mui/icons-material/Label';
import PaymentsIcon from '@mui/icons-material/Payments';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FolderIcon from '@mui/icons-material/Folder';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import Loading from '../components/Loading';
import ProjectMembers from '../components/ProjectMembers';
import ProjectLabels from '../components/ProjectLabels';
import { Project } from '../app/api/projects-api-slice';
import ProjectSettingsPageInfo from './ProjectSettingsPageInfo';
import ProjectRoles from '../components/ProjectRoles';
import ProjectFinancialAccount from '../components/ProjectFinancialAccount';
import StatefulTabs, {
  getSelectedTabLabel,
  TabProps,
} from '../components/Tabs/StatefulTabs';
import setTitle from '../app/helpers/document-helpers';
import ProjectAdmins from '../components/ProjectAdmins';
import { useGetFeatureFlagsQuery } from '../app/api/feature-flags-slice';
import Breadcrumbs from '../components/Breadcrumbs';
import getProjectCrumb from '../app/helpers/get-project-crumb';
import useGetAuthorizedProjectsQuery from '../app/hooks/use-get-authorized-projects-query';
import ProjectIntegrationsTab from './ProjectIntegrationsTab';
import ProjectPaymentTypes from '../components/ProjectPaymentTypes';
import ProjectSubscription from '../components/ProjectSubscription';
import ProjectFileManagement from './ProjectFileManagement';
import { useGetProjectFinancialAccountQuery } from '../app/api/project-financial-account-slice';

const SUBSCRIPTION_TAB = {
  label: 'Subscription',
  icon: <ReceiptLongIcon />,
  value: 'subscription',
};

const PROJECT_TAB = {
  label: 'Info',
  icon: <InfoIcon />,
  value: 'project',
};

const PROJECT_ADMINS_TAB = {
  label: 'Admins',
  icon: <AdminPanelSettingsIcon />,
  value: 'project-admins',
};

const MEMBERS_TAB = {
  label: 'Members',
  icon: <GroupIcon />,
  value: 'members',
};

const ROLES_TAB = {
  label: 'Roles',
  icon: <MusicNoteIcon />,
  value: 'roles',
};

const LABELS_TAB = {
  label: 'Labels',
  icon: <LabelIcon />,
  value: 'labels',
};

const PAYMENT_TYPES_TAB = {
  label: 'Payment Methods',
  icon: <PaymentsIcon />,
  value: 'paymentTypes',
};

const FINANCIAL_ACCOUNT_TAB = {
  label: 'Financial Account',
  icon: <AttachMoneyIcon />,
  value: 'financial-account',
  beta: true,
};

const FILE_MANAGEMENT_TAB = {
  label: 'File Management',
  icon: <FolderIcon />,
  value: 'files',
};

const INTEGRATIONS_TAB = {
  label: 'Integrations',
  icon: <IntegrationInstructionsOutlinedIcon />,
  value: 'integrations',
};

const PROJECT_SETTINGS_TABS: TabProps[] = [
  SUBSCRIPTION_TAB,
  PROJECT_TAB,
  PROJECT_ADMINS_TAB,
  MEMBERS_TAB,
  ROLES_TAB,
  LABELS_TAB,
  PAYMENT_TYPES_TAB,
];

function ProjectSettingsPage() {
  /// //////////
  // Feature flags
  const getFeatureFlags = useGetFeatureFlagsQuery();

  /// //////////
  // Navigation
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const { user } = useAuth0();

  /// //////////
  // Get authorized projects
  const {
    data: projects = [],
    isLoading: projectsLoading,
    isError: projectsError,
  } = useGetAuthorizedProjectsQuery();

  const getProjectFinancialAccountQuery = useGetProjectFinancialAccountQuery(
    id!,
    { skip: !id }
  );

  /// //////////
  // Set project
  const [project, setProject] = useState<Project | null>(null);

  useEffect(() => {
    if (id && projects.length) {
      localStorage.setItem(`${user?.sub}_lastProjectId`, id);
      setProject(projects.find((p: Project) => p.id === id) || null);
    }
  }, [id]);

  /// //////////
  // Build tabs
  const [tabs, setTabs] = useState<TabProps[] | []>([]);

  useEffect(() => {
    if (project && !getFeatureFlags.isLoading) {
      let newTabs = PROJECT_SETTINGS_TABS;

      if (getFeatureFlags.data?.filesEnabled) {
        newTabs = [...newTabs, FILE_MANAGEMENT_TAB];
      }

      const showFinancialTab =
        getFeatureFlags.data?.financialAccountsEnabled &&
        getProjectFinancialAccountQuery.isSuccess &&
        getProjectFinancialAccountQuery.data.enabled &&
        project?.role === 'Owner';
      if (showFinancialTab) {
        newTabs = [...newTabs, FINANCIAL_ACCOUNT_TAB];
      }

      newTabs = [...newTabs, INTEGRATIONS_TAB];

      setTabs(newTabs);
    }
  }, [project, getFeatureFlags, getProjectFinancialAccountQuery]);

  useEffect(() => {
    // if tab is invalid, redirect to project tab
    if (tabs.length && (!tab || !tabs.find((t) => t.value === tab))) {
      navigate(`/settings/project/${id}?tab=${SUBSCRIPTION_TAB.value}`);
    }
  });

  /// //////////
  // Render loading state
  if (
    projectsLoading ||
    !tabs.length ||
    getProjectFinancialAccountQuery.isLoading
  ) {
    return <Loading />;
  }

  /// //////////
  // Redirect if fetching error
  if (projectsError) {
    enqueueSnackbar('Unable to find project.', { variant: 'error' });
    navigate('/settings');
  }

  /// //////////
  // Set page title
  setTitle(
    'Settings',
    'Projects',
    project?.name!,
    getSelectedTabLabel(PROJECT_SETTINGS_TABS, tab!)
  );

  /// //////////
  // Get breadcrumbs
  const getBreadcrumbs = () => {
    const onChangeProjectCrumb = (projectId: any) => {
      if (projectId) {
        navigate(`/settings/project/${projectId}?tab=${tab}`);
      }
    };

    const projectCrumb = getProjectCrumb(
      id || null,
      projects,
      onChangeProjectCrumb,
      '/settings?tab=projects'
    );

    const crumbs = [
      {
        name: 'Settings',
        to: '/settings?tab=projects',
      },
      {
        name: 'Projects',
      },
    ];

    if (projectCrumb) crumbs.push(projectCrumb);

    return <Breadcrumbs crumbs={crumbs} />;
  };

  /// //////////
  // Render main content
  return (
    <>
      {getBreadcrumbs()}
      <StatefulTabs tabs={tabs} />
      <>
        {project && tab === SUBSCRIPTION_TAB.value && (
          <ProjectSubscription project={project} />
        )}
        {project && tab === PROJECT_TAB.value && (
          <ProjectSettingsPageInfo project={project} />
        )}
        {project && tab === PROJECT_ADMINS_TAB.value && (
          <ProjectAdmins projectId={id!} />
        )}
        {project && tab === MEMBERS_TAB.value && (
          <ProjectMembers projectId={id!} />
        )}
        {project && tab === ROLES_TAB.value && <ProjectRoles projectId={id!} />}
        {project && tab === LABELS_TAB.value && (
          <ProjectLabels projectId={id!} />
        )}
        {project && tab === PAYMENT_TYPES_TAB.value && (
          <ProjectPaymentTypes projectId={id!} />
        )}
        {project && tab === FILE_MANAGEMENT_TAB.value && (
          <ProjectFileManagement projectId={id!} />
        )}
        {project && tab === FINANCIAL_ACCOUNT_TAB.value && (
          <ProjectFinancialAccount projectId={id!} />
        )}
        {project && tab === INTEGRATIONS_TAB.value && (
          <ProjectIntegrationsTab projectId={id!} />
        )}
      </>
    </>
  );
}

export default ProjectSettingsPage;
