import React, { CSSProperties } from 'react';
import { Typography } from '@mui/material';
import GreenRoomDialog from './Dialog/GreenRoomDialog';

interface ImageViewerProps {
  name: string;
  url: string;
  open: boolean;
  onClose: () => void;
}

function ImageViewer({ name, url, open, onClose }: ImageViewerProps) {
  const containerStyle: CSSProperties = {
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
  };
  const imgStyle: CSSProperties = {
    maxWidth: '100%',
    maxHeight: '100%',
    height: 'auto',
    width: 'auto',
  };

  return (
    <GreenRoomDialog
      fullWidth
      fullscreen
      open={open}
      onClose={onClose}
      title={<Typography variant="caption">{name}</Typography>}
    >
      {url && (
        <div style={containerStyle}>
          <img src={url} alt={name} style={imgStyle} />
        </div>
      )}
    </GreenRoomDialog>
  );
}

export default ImageViewer;
