import React from 'react';
import { Button } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
import GreenRoomDialog from '../../components/Dialog/GreenRoomDialog';

interface FinancialAccountTransferChoiceDialogProps {
  open: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onChoiceSelected: (choice: 'external_account' | 'user') => void;
}

function FinancialAccountTransferChoiceDialog({
  open,
  onClose,
  onChoiceSelected,
}: FinancialAccountTransferChoiceDialogProps) {
  return (
    <GreenRoomDialog open={open} title="Transfer" onClose={onClose}>
      <Button
        variant="outlined"
        startIcon={<PersonIcon />}
        onClick={() => onChoiceSelected('user')}
      >
        Pay another Green Room user
      </Button>
      <Button
        variant="outlined"
        startIcon={<AccountBalanceIcon />}
        onClick={() => onChoiceSelected('external_account')}
      >
        Transfer To External Account
      </Button>
    </GreenRoomDialog>
  );
}

export default FinancialAccountTransferChoiceDialog;
