import React, { useState, useEffect } from 'react';
import {
  Chip,
  SelectChangeEvent,
  TextField,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useSearchParams } from 'react-router-dom';
import GreenRoomDialog from '../../Dialog/GreenRoomDialog';
import GreenRoomButton from '../../GreenRoomButton';
import {
  getSelectParamFromURL,
  getSelectOptions,
  getDateParamFromURL,
  getCustomActionBar,
  formatDateForAPI,
  formatDateForUI,
} from '../filter-helpers';
// TODO: Using duplicate styles as EditDatePicker; Consider refactoring when patterns settle
import '../../EditDatePicker.scss';

/// //////////
// Constants: Date
export const DATE_FROM_PARAM_NAME = 'from';
export const DATE_TO_PARAM_NAME = 'to';
const DATE_FROM_DEFAULT_VALUE = formatDateForAPI(moment().subtract(14, 'days')); // Two weeks ago
const DATE_TO_DEFAULT_VALUE = formatDateForAPI(moment()); // Today

/// //////////
// Constants: Date range
export const DATE_RANGE_PARAM_NAME = 'dateRange';
export const DATE_RANGE_VALUE_ALL_TIME = 'allTime';
export const DATE_RANGE_VALUE_CUSTOM = 'custom';
const DATE_RANGE_VALUE_SEVEN = '7';
const DATE_RANGE_VALUE_THIRTY = '30';
const DATE_RANGE_VALUE_NINETY = '90';

const DATE_RANGE_OPTIONS = [
  {
    value: DATE_RANGE_VALUE_ALL_TIME,
    label: 'All time',
  },
  {
    value: DATE_RANGE_VALUE_SEVEN,
    label: 'Past 7 Days',
  },
  {
    value: DATE_RANGE_VALUE_THIRTY,
    label: 'Past 30 Days',
  },
  {
    value: DATE_RANGE_VALUE_NINETY,
    label: 'Past 90 Days',
  },
  {
    value: DATE_RANGE_VALUE_CUSTOM,
    label: 'Custom',
  },
] as const;
export const DATE_RANGE_DEFAULT_VALUE = DATE_RANGE_OPTIONS[0].value;

/// //////////
// Types
export interface FilterTransactionsByDateRequestArgs {
  from?: string | null;
  to?: string | null;
}
interface FilterTransactionsByDateSearchParams
  extends FilterTransactionsByDateRequestArgs {
  dateRange:
    | typeof DATE_RANGE_VALUE_ALL_TIME
    | typeof DATE_RANGE_VALUE_SEVEN
    | typeof DATE_RANGE_VALUE_THIRTY
    | typeof DATE_RANGE_VALUE_NINETY
    | typeof DATE_RANGE_VALUE_CUSTOM;
}

/// //////////
// Component
function FilterTransactionsByDate() {
  /// //////////
  // Responsiveness
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  /// //////////
  // Navigation
  const [searchParams, setSearchParams] = useSearchParams();

  // //////////
  // Active filters
  const activeFilters = {
    dateRange: getSelectParamFromURL(
      DATE_RANGE_PARAM_NAME,
      DATE_RANGE_DEFAULT_VALUE,
      DATE_RANGE_OPTIONS,
      searchParams
    ),
    from: null,
    to: null,
  } as FilterTransactionsByDateSearchParams;

  // //////////
  // If date range is custom, pluck from and to from URL
  if (activeFilters.dateRange === DATE_RANGE_VALUE_CUSTOM) {
    activeFilters.from = getDateParamFromURL(
      DATE_FROM_PARAM_NAME,
      DATE_FROM_DEFAULT_VALUE,
      searchParams
    );
    activeFilters.to = getDateParamFromURL(
      DATE_TO_PARAM_NAME,
      DATE_TO_DEFAULT_VALUE,
      searchParams
    );
  }

  /// //////////
  // Staged filters; Awaiting user to click "Approve"
  const [stagedFilters, setStagedFilters] = useState(
    activeFilters as FilterTransactionsByDateSearchParams
  );

  // //////////
  // DatePicker components
  const [fromPickerOpen, setFromPickerOpen] = useState<boolean>(false);
  const [toPickerOpen, setToPickerOpen] = useState<boolean>(false);

  // //////////
  // Chip component
  const getChipLabel = () => {
    // Non-custom date range
    let label = DATE_RANGE_OPTIONS.find(
      (option) => option.value === activeFilters.dateRange
    )?.label as string;

    // Custom date range
    if (activeFilters.dateRange === DATE_RANGE_VALUE_CUSTOM) {
      const from = activeFilters.from
        ? formatDateForUI(moment(activeFilters.from))
        : null;
      const to = activeFilters.to
        ? formatDateForUI(moment(activeFilters.to))
        : null;

      if (from && to) {
        label = `${from} - ${to}`;
      } else if (from) {
        label = `On or after ${from}`;
      } else if (to) {
        label = `On or before ${to}`;
      }
    }

    return label;
  };

  // //////////
  // Dialogue component
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dateRangeCustomInputVisible, setDateRangeCustomInputVisible] =
    useState<boolean>(false);

  // Render datepickers when date range is custom
  const updateCustomInputVisibility = (
    newFilters: FilterTransactionsByDateSearchParams
  ) => {
    setDateRangeCustomInputVisible(
      newFilters.dateRange === DATE_RANGE_VALUE_CUSTOM
    );
  };

  // When the url changes, update the dialogue state; Important after clearing the filters via parent
  const updateDialogueForm = (
    newFilters: FilterTransactionsByDateSearchParams
  ) => {
    setStagedFilters(newFilters);
    updateCustomInputVisibility(newFilters);
  };

  useEffect(() => {
    updateDialogueForm(activeFilters);
  }, [searchParams]);

  // //////////
  // Helper to update the url when user clicks "Approve"
  const updateURL = () => {
    if (
      stagedFilters.dateRange === DATE_RANGE_VALUE_CUSTOM &&
      !stagedFilters.from &&
      !stagedFilters.to
    ) {
      searchParams.set(DATE_RANGE_PARAM_NAME, DATE_RANGE_VALUE_ALL_TIME);
    } else {
      searchParams.set(DATE_RANGE_PARAM_NAME, stagedFilters.dateRange);
    }

    if (stagedFilters.from) {
      searchParams.set(DATE_FROM_PARAM_NAME, stagedFilters.from);
    } else {
      // Remove param if not set; user cleared the input
      searchParams.delete(DATE_FROM_PARAM_NAME);
    }

    if (stagedFilters.to) {
      searchParams.set(DATE_TO_PARAM_NAME, stagedFilters.to);
    } else {
      // Remove param if not set; user cleared the input
      searchParams.delete(DATE_TO_PARAM_NAME);
    }

    // DO save to history
    setSearchParams(searchParams);
  };

  // //////////
  // Filter component
  return (
    <>
      <Chip
        className="green-room-chip"
        icon={<CalendarTodayIcon />}
        label={getChipLabel()}
        color={
          activeFilters.dateRange !== DATE_RANGE_DEFAULT_VALUE
            ? 'primary'
            : undefined
        }
        onClick={() => {
          setDialogOpen(true);
        }}
      />
      <GreenRoomDialog
        open={dialogOpen}
        title="Filter By Date"
        onClose={() => {
          updateDialogueForm(activeFilters);
          setDialogOpen(false);
        }}
        actions={
          <GreenRoomButton
            type="accept"
            onClick={() => {
              updateURL();
              setDialogOpen(false);
            }}
          >
            Apply
          </GreenRoomButton>
        }
      >
        <FormControl fullWidth>
          <InputLabel id="dateRange">Date Range</InputLabel>
          <Select
            labelId="dateRange"
            label="Date Range"
            value={stagedFilters.dateRange}
            onChange={(e: SelectChangeEvent) => {
              const value = e.target
                .value as typeof DATE_RANGE_OPTIONS[number]['value'];

              const newStagedFilters = {
                dateRange: value,
                from: null,
                to: null,
              } as FilterTransactionsByDateSearchParams;

              if (value === DATE_RANGE_VALUE_CUSTOM) {
                newStagedFilters.from = DATE_FROM_DEFAULT_VALUE;
                newStagedFilters.to = DATE_TO_DEFAULT_VALUE;
              }

              updateDialogueForm(newStagedFilters);
            }}
          >
            {getSelectOptions(DATE_RANGE_OPTIONS)}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div
            style={{
              display: dateRangeCustomInputVisible ? 'flex' : 'none',
            }}
          >
            <DatePicker
              value={stagedFilters.from}
              onChange={(date: Moment | null) => {
                setStagedFilters({
                  ...stagedFilters,
                  from: (date && formatDateForAPI(date)) || null,
                });
              }}
              open={fromPickerOpen}
              onClose={() => setFromPickerOpen(false)}
              components={{
                ActionBar: (methods) => getCustomActionBar(methods, mobile),
              }}
              PopperProps={{
                placement: 'auto',
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  fullWidth
                  autoComplete="off"
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                    onClick: () => setFromPickerOpen(true),
                    onKeyDown: (event) => {
                      if (event.key === 'Enter' || event.key === ' ')
                        setFromPickerOpen(true);
                    },
                  }}
                  className="edit-date-picker-input"
                  sx={{ marginRight: '8px' }}
                />
              )}
              disableOpenPicker
              label="From"
              showToolbar
            />
            <DatePicker
              value={stagedFilters.to}
              onChange={(date: Moment | null) => {
                setStagedFilters({
                  ...stagedFilters,
                  to: (date && formatDateForAPI(date)) || null,
                });
              }}
              open={toPickerOpen}
              onClose={() => setToPickerOpen(false)}
              components={{
                ActionBar: (methods) => getCustomActionBar(methods, mobile),
              }}
              PopperProps={{
                placement: 'auto',
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  fullWidth
                  autoComplete="off"
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                    onClick: () => setToPickerOpen(true),
                    onKeyDown: (event) => {
                      if (event.key === 'Enter' || event.key === ' ')
                        setToPickerOpen(true);
                    },
                  }}
                  className="edit-date-picker-input"
                />
              )}
              disableOpenPicker
              label="To"
              showToolbar
            />
          </div>
        </LocalizationProvider>
      </GreenRoomDialog>
    </>
  );
}

export default FilterTransactionsByDate;
