import React, { ReactNode, useEffect } from 'react';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Alert,
  Button,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import GreenRoomDialog from '../components/Dialog/GreenRoomDialog';
import ProjectSelect from '../components/ProjectSelect';
import GreenRoomButton from '../components/GreenRoomButton';
import GreenRoomTooltip from '../components/GreenRoomTooltip';
import {
  useGetEventSummariesQuery,
  useQuickCreateEventMutation,
} from '../app/api/events-api-slice';
import { useGetProjectEntitlementsQuery } from '../app/api/projects-api-slice';
import { formatDateForAPI } from '../components/Filters/filter-helpers';
import PremiumFeatureChip from '../components/PremiumFeatureChip';

interface QuickCreateEventDialogProps {
  open: boolean;
  onClose: () => void;
}

function QuickCreateEventDialog({
  open,
  onClose,
}: QuickCreateEventDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [projectId, setProjectId] = React.useState<string | null>(null);
  const [eventDetails, setEventDetails] = React.useState<string>('');
  const [error, setError] = React.useState<boolean>(false);

  const [quickCreateEventMutation, quickCreateEventMutationStatus] =
    useQuickCreateEventMutation();

  const getProjectEntitlements = useGetProjectEntitlementsQuery(projectId!, {
    skip: !projectId,
  });
  const futureEventsLimit = getProjectEntitlements.data?.futureEventCreateLimit
    ? Number(getProjectEntitlements.data?.futureEventCreateLimit)
    : null;
  const getFutureEvents = useGetEventSummariesQuery(
    {
      projectIds: projectId!,
      from: formatDateForAPI(moment()),
      offset: 0,
      limit: 0,
    },
    { skip: !projectId }
  );
  const futureEventCreationDisabled =
    !!futureEventsLimit &&
    futureEventsLimit <= getFutureEvents.data?.totalCount!;

  const handleClose = () => {
    onClose();
  };

  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDate = new Date().toISOString();

  const body: ReactNode = (
    <>
      <ProjectSelect
        id={projectId}
        onChange={(id) => setProjectId(id)}
        disabled={quickCreateEventMutationStatus.isLoading}
        autoSelectProject={false}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="body1">
          Give us basic details about the event (date, time, place, and any
          other details), or copy/paste from a text message or email. We&apos;ll
          take care of the rest!{' '}
        </Typography>
        <GreenRoomTooltip
          title={
            <>
              <Typography variant="caption">
                <b>Example:</b> &quot;Show this Friday 7pm at Rowlf&apos;s
                Tavern. Backline drums and sound system are provided. Park in
                the back lot. Guaranteed payment of $5000.&quot;
              </Typography>
              <br />
              <br />
              <Typography variant="caption">
                <b>Example:</b> &quot;July 4 at Poor Richard&apos;s. Show time
                at 8pm. Bring your own sound equipment. Arrive no earlier than
                6. Sound check at 6:45. $3500 plus percentage of bar. &quot;
              </Typography>
            </>
          }
        />
      </div>
      <TextField
        label="Event details"
        multiline
        rows={4}
        value={eventDetails}
        onChange={(e) => {
          setEventDetails(e.target.value);
          setError(false);
        }}
        required
        disabled={quickCreateEventMutationStatus.isLoading}
      />
      {quickCreateEventMutationStatus.isLoading && <LinearProgress />}
      {error && (
        <Alert severity="error">
          Sorry, we were not able to create an event based on your description.
          Try phrasing it in a different way and submitting it again. If the
          problem persists, please contact us.
        </Alert>
      )}
      {futureEventCreationDisabled && (
        <div>
          <PremiumFeatureChip
            projectId={projectId!}
            size="small"
            label="Event limit reached. Upgrade to create more."
          />
        </div>
      )}
    </>
  );

  useEffect(() => {
    if (quickCreateEventMutationStatus.isSuccess) {
      handleClose();
      enqueueSnackbar('Event created successfully!', {
        variant: 'success',
      });
      navigate(`/events/${quickCreateEventMutationStatus.data}/edit`);
    }
    if (quickCreateEventMutationStatus.isError) {
      setError(true);
    }
  }, [quickCreateEventMutationStatus]);

  return (
    <GreenRoomDialog
      open={open}
      title="Quick Event Create (beta)"
      onClose={handleClose}
      actions={
        <>
          <Button
            startIcon={<AutoFixHighIcon />}
            onClick={() => {
              quickCreateEventMutation({
                projectId,
                userPrompt: eventDetails,
                defaultTimeZone: currentTimeZone,
                currentDate,
              });
            }}
            variant="contained"
            disabled={
              !projectId ||
              !eventDetails ||
              quickCreateEventMutationStatus.isLoading ||
              getProjectEntitlements.isLoading ||
              getFutureEvents.isLoading ||
              futureEventCreationDisabled
            }
          >
            {quickCreateEventMutationStatus.isLoading && <>Creating...</>}
            {!quickCreateEventMutationStatus.isLoading && <>Create</>}
          </Button>
          <GreenRoomButton
            type="cancel"
            onClick={handleClose}
            disabled={quickCreateEventMutationStatus.isLoading}
          >
            Cancel
          </GreenRoomButton>
        </>
      }
    >
      {body}
    </GreenRoomDialog>
  );
}

export default QuickCreateEventDialog;
