import { TablePagination, Stack, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Blankslate from '../../components/Blankslate';
import Loading from '../../components/Loading';
import {
  GetProjectTransactionsRequest,
  useGetProjectTransactionsQuery,
} from '../../app/api/transactions-api-slice';
import GreenRoomStack from '../../components/GreenRoomStack';
import { useGetPersonsQuery } from '../../app/api/persons-api-slice';
import GreenRoomDialog from '../../components/Dialog/GreenRoomDialog';
import KeyValue from '../../components/KeyValue';
import { ReactComponent as Search } from '../../img/placeholders/search.svg';
import TransactionsFilters, {
  FilterRequestArgs,
} from '../../components/Filters/TransactionsFilters';
import EventTransactionCard from './EventTransactionCard';
import {
  getFormattedAmount,
  getCardTitle,
} from '../../app/helpers/transaction-helpers';
import { getFormattedDate } from '../../app/helpers/event-helpers';
import { getCityAndState } from '../../app/helpers/location-helpers';
import { PaymentType } from '../../app/api/payment-types-api-slice';

interface FinancesTransactionsProps {
  projectId: string;
  blankslate: React.ReactElement;
  eventId?: string | null;
  paymentTypes: PaymentType[] | undefined;
}

function FinancesTransactions({
  projectId,
  blankslate,
  eventId,
  paymentTypes,
}: FinancesTransactionsProps) {
  /// //////////
  // Navigation
  const [searchParams, setSearchParams] = useSearchParams();

  /// //////////
  // Transaction search param
  const TRANSACTION_PARAM_NAME = 'transaction';
  const transactionId = searchParams.get(TRANSACTION_PARAM_NAME);

  /// //////////
  // Pagination constants
  const PAGE_LENGTH_DEFAULT = 10;

  /// //////////
  // Transactions without filters
  const unfilteredRequestArgs = {
    limit: PAGE_LENGTH_DEFAULT,
    eventId,
    projectId,
    offset: 0,
  } as GetProjectTransactionsRequest;

  /// //////////
  // Transactions with filters
  const [requestArgs, setRequestArgs] = useState({
    ...unfilteredRequestArgs,
  } as GetProjectTransactionsRequest);

  const pageLength = requestArgs.limit || PAGE_LENGTH_DEFAULT;

  /// //////////
  // Fetch filtered transactions
  const getProjectTransactionsQuery =
    useGetProjectTransactionsQuery(requestArgs);
  const filteredTransactionsLength =
    getProjectTransactionsQuery?.data?.totalCount;

  /// //////////
  // Fetch unfiltered transactions to see if there are any transactions
  const getUnfilteredProjectTransactionsQuery = useGetProjectTransactionsQuery(
    unfilteredRequestArgs
  );
  const unfilteredTransactionsLength =
    getUnfilteredProjectTransactionsQuery?.data?.totalCount;

  // //////////
  // Refetch transacations when filters update
  const onUpdateFilterRequestArgs = (filterRequestArgs: FilterRequestArgs) => {
    setRequestArgs({
      ...requestArgs,
      offset: 0, // Reset the page number after any filtering
      ...filterRequestArgs,
    });
  };

  // //////////
  // Refetch transactions on project change
  const [previousProjectId, setPreviousProjectId] = useState<string>(projectId);
  useEffect(() => {
    if (projectId && previousProjectId !== projectId)
      setRequestArgs({
        ...requestArgs,
        projectId,
        offset: 0,
      });

    setPreviousProjectId(projectId);
  }, [projectId]);

  /// //////////
  // Fetch transactors
  const getPersonsQuery = useGetPersonsQuery({ projectId });

  /// //////////
  // Define pagination
  const currentPage = requestArgs.offset / pageLength;

  /// //////////
  // Render loading
  if (
    getProjectTransactionsQuery.isLoading ||
    getUnfilteredProjectTransactionsQuery.isLoading ||
    getPersonsQuery.isLoading
  ) {
    return <Loading />;
  }

  /// //////////
  // Render blankslate
  if (!unfilteredTransactionsLength) {
    return blankslate;
  }

  /// //////////
  // Selected transaction for dialog
  const selectedTransaction =
    getProjectTransactionsQuery.data?.transactions.find(
      (transaction) => transaction.id === transactionId
    );

  /// //////////
  // Selected transaction payment type for dialog
  const paymentType = paymentTypes?.find(
    (pt) => pt.id === selectedTransaction?.paymentTypeId
  ) as PaymentType;

  /// //////////
  // Render main content
  return (
    <>
      <TransactionsFilters
        onUpdateFilterRequestArgs={onUpdateFilterRequestArgs}
        requestArgs={requestArgs}
      />
      <Stack spacing={2}>
        {(!filteredTransactionsLength && (
          <Blankslate title="No transactions for your filters" svg={<Search />}>
            <span>
              There is no financial activity that matches your filters. Please
              try broadening your search.
            </span>
          </Blankslate>
        )) || (
          <GreenRoomStack>
            {getProjectTransactionsQuery.isFetching && (
              <LinearProgress className="green-room-linear-progress" />
            )}
            {getProjectTransactionsQuery.data?.transactions.map(
              (transaction) => (
                <EventTransactionCard
                  key={transaction.id}
                  transaction={transaction}
                  paymentTypes={paymentTypes}
                  eventSummary={!eventId ? transaction.event : null}
                  onClick={() => {
                    searchParams.set(TRANSACTION_PARAM_NAME, transaction.id);
                    setSearchParams(searchParams);
                  }}
                  title={getCardTitle(
                    getPersonsQuery.data?.find(
                      (p) => p.id === transaction.transactorPersonId
                    )?.name || transaction.transactor,
                    transaction.type
                  )}
                />
              )
            )}
          </GreenRoomStack>
        )}
        <GreenRoomDialog
          open={!!selectedTransaction}
          title={getCardTitle(
            getPersonsQuery.data?.find(
              (p) => p.id === selectedTransaction?.transactorPersonId
            )?.name || selectedTransaction?.transactor,
            selectedTransaction?.type
          )}
          onClose={() => {
            searchParams.delete(TRANSACTION_PARAM_NAME);
            setSearchParams(searchParams);
          }}
        >
          <KeyValue
            secondary
            keyWidth="120px"
            label="Transaction Date"
            value={
              (selectedTransaction?.isPaid &&
                getFormattedDate(selectedTransaction?.paidDate)) ||
              'Payment owed'
            }
          />
          <KeyValue
            secondary
            keyWidth="120px"
            label="Type"
            value={selectedTransaction?.type}
          />
          <KeyValue
            secondary
            keyWidth="120px"
            label="Amount"
            value={
              selectedTransaction &&
              getFormattedAmount(
                selectedTransaction?.amount,
                selectedTransaction?.type
              )
            }
          />
          {!!paymentType && (
            <KeyValue
              secondary
              keyWidth="120px"
              label="Payment Method"
              value={paymentType.name}
            />
          )}
          {!!selectedTransaction?.stripeTransactionId && (
            <KeyValue
              secondary
              keyWidth="120px"
              label="Payment Method"
              value={
                <Link
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  to={`/finances?project=${projectId}&tab=account&transaction=${selectedTransaction?.stripeTransactionId}`}
                >
                  <span>Financial Account</span>
                </Link>
              }
            />
          )}
          {!!selectedTransaction?.description && (
            <KeyValue
              secondary
              keyWidth="120px"
              label="Description"
              value={selectedTransaction?.description}
            />
          )}
          {!!selectedTransaction?.event && (
            <KeyValue
              secondary
              keyWidth="120px"
              label="Associated Event"
              value={
                // TODO: Turn this into a GreenRoomChip component if style repeats
                selectedTransaction?.event && (
                  <Link
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    to={`/events/${selectedTransaction?.event!.id}`}
                  >
                    <span>
                      {getFormattedDate(selectedTransaction?.event.startTime)}
                    </span>
                    <span>{selectedTransaction?.event.locationName}</span>
                    <span>
                      {getCityAndState(
                        selectedTransaction?.event.locationCity,
                        selectedTransaction?.event.locationState
                      )}
                    </span>
                  </Link>
                )
              }
            />
          )}
        </GreenRoomDialog>
      </Stack>
      {!!filteredTransactionsLength && (
        <TablePagination
          component="div"
          labelRowsPerPage="Items per page"
          count={filteredTransactionsLength || 0}
          page={currentPage}
          rowsPerPageOptions={[10, 25]}
          rowsPerPage={pageLength}
          onRowsPerPageChange={(e) =>
            setRequestArgs({
              ...requestArgs,
              limit: Number.isInteger(e?.target?.value)
                ? (e.target.value as unknown as number)
                : PAGE_LENGTH_DEFAULT,
              offset: 0,
            })
          }
          onPageChange={(_, newPageNumber) =>
            unfilteredTransactionsLength
              ? setRequestArgs({
                  ...requestArgs,
                  offset: pageLength * newPageNumber,
                })
              : undefined
          }
          sx={{
            margin: '16px 0 0',
            color: 'primary',
          }}
        />
      )}
    </>
  );
}

FinancesTransactions.defaultProps = {
  eventId: null,
};

export default FinancesTransactions;
