import emptySplitApi from './empty-api-slice';

export const LABEL_COLORS = [
  '#9FA8DA',
  '#81D4FA',
  '#A5D6A7',
  '#FFCC80',
  '#CE93D8',
  '#E6EE9C',
] as const;

interface LabelBody {
  name: string;
  color: typeof LABEL_COLORS[number];
}

interface CreateLabel extends LabelBody {
  projectId: string;
}

interface UpdateLabel {
  id: string;
  body: LabelBody;
}

export interface Label extends LabelBody {
  id: string;
  projectId: string;
}

export interface GetLabelsQuery {
  projectId: string;
}

export const labelsApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getLabelsForAllProjects: builder.query<Label[], void>({
      query: () => `/labels`,
      providesTags: ['Label'],
    }),
    getLabels: builder.query<Label[], GetLabelsQuery>({
      query: (query) => `/labels?projectId=${query.projectId}`,
      providesTags: ['Label'],
    }),
    createLabel: builder.mutation<string, CreateLabel>({
      query: (label) => ({
        url: `/labels`,
        method: 'POST',
        body: label,
      }),
      invalidatesTags: ['Label'],
    }),
    updateLabel: builder.mutation<void, UpdateLabel>({
      query: (label) => ({
        url: `/labels/${label.id}`,
        method: 'PUT',
        body: JSON.stringify(label.body),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['Label'],
    }),
    deleteLabel: builder.mutation<void, string>({
      query: (id) => ({
        url: `/labels/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Label'],
    }),
  }),
});

export const {
  useGetLabelsForAllProjectsQuery,
  useGetLabelsQuery,
  useCreateLabelMutation,
  useUpdateLabelMutation,
  useDeleteLabelMutation,
} = labelsApiSlice;
