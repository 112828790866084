import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import './ErrorPage.scss';
import { useNavigate } from 'react-router-dom';
import Heading from './Text/Heading';
import GreenRoomButton from './GreenRoomButton';

interface ErrorPageProps {
  error: FetchBaseQueryError | SerializedError | undefined;
  entity?: string;
  action?: () => void;
  actionText?: string;
}

interface ErrorData {
  title: string;
  detail: string;
  status: number;
}

function ErrorPage({ error, entity, action, actionText }: ErrorPageProps) {
  const navigate = useNavigate();
  const errorData =
    (error && 'data' in error && (error.data as ErrorData)) || null;

  const title = errorData?.status === 404 ? 'Not Found' : 'There was a problem';
  const message =
    errorData?.status === 404
      ? `The requested ${entity} could not be found.`
      : `There was a problem. Please try again, or contact support.`;

  return (
    <div className="error-page">
      <div className="error-page-main-content">
        <Heading>{title}</Heading>
        <span>{message}</span>
        <div className="error-page-actions">
          <GreenRoomButton
            type="accept"
            onClick={() => {
              if (action) {
                action();
                return;
              }
              navigate('/');
            }}
          >
            {actionText || 'Go home'}
          </GreenRoomButton>
        </div>
      </div>

      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Technical details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

ErrorPage.defaultProps = {
  entity: 'content',
  action: null,
  actionText: null,
};

export default ErrorPage;
