import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useSnackbar } from 'notistack';
import {
  EditPerson,
  PENDING_OPT_IN,
  OPTED_OUT,
  OPTED_IN,
} from '../app/helpers/contact-helpers';
import GreenRoomTooltip from './GreenRoomTooltip';
import { useResendTextOptInMutation } from '../app/api/persons-api-slice';

interface SMSStatusProps {
  editPerson: EditPerson | undefined;
  newPhoneNumber: boolean;
}

function SMSStatus({ editPerson, newPhoneNumber }: SMSStatusProps) {
  const snackbar = useSnackbar();
  const [resendTextOptIn, resendTextOptInStatus] = useResendTextOptInMutation();

  useEffect(() => {
    if (resendTextOptInStatus.isError) {
      snackbar.enqueueSnackbar('Error sending text message', {
        variant: 'error',
      });
    } else if (resendTextOptInStatus.isSuccess) {
      snackbar.enqueueSnackbar('Consent for SMS messages re-sent.', {
        variant: 'success',
      });
    }
  }, [resendTextOptInStatus]);

  let helperText =
    'Your contact will receive a one-time text message asking to opt in to receiving text messages from Green Room if a phone number is provided';
  let content = <Typography variant="caption">{helperText}</Typography>;
  let tooltip =
    'This indicates whether this person has consented to receiving text message notifications.';

  const phoneNumberOptInLastRequestedAt =
    editPerson == null || editPerson.phoneNumberOptInLastRequestedAt == null
      ? null
      : new Date(editPerson.phoneNumberOptInLastRequestedAt!);

  if (newPhoneNumber) {
    helperText =
      'You are providing a new phone number for your contact. When you save the contact, we will send them a new text message to consent to SMS.';
  } else if (editPerson?.phoneNumberStatus === PENDING_OPT_IN) {
    let resendButtonDisabled = resendTextOptInStatus.isLoading;
    if (
      (new Date().getTime() - phoneNumberOptInLastRequestedAt!.getTime()) /
        1000 <
      86400
    ) {
      tooltip =
        'The contact was sent a request to opt-in to SMS messages from Green Room. You can re-send this request after 24 hours.';
      resendButtonDisabled = true;
    } else {
      tooltip =
        'The contact was sent a request to opt-in to SMS messages from Green Room. You can re-send this request now.';
    }
    content = (
      <>
        <AccessTimeIcon sx={{ marginRight: '4px' }} />
        <Typography>
          SMS consent requested on{' '}
          {phoneNumberOptInLastRequestedAt?.toLocaleString()}.
        </Typography>
        <Button
          variant="text"
          size="small"
          disabled={resendButtonDisabled}
          sx={{ marginLeft: '4px' }}
          onClick={() => {
            resendTextOptIn(editPerson.id!);
          }}
        >
          Resend request
        </Button>
      </>
    );
  } else if (editPerson?.phoneNumberStatus === OPTED_OUT) {
    content = (
      <>
        <SpeakerNotesOffIcon color="error" sx={{ marginRight: '4px' }} />
        <Typography>SMS consent declined</Typography>
      </>
    );
  } else if (editPerson?.phoneNumberStatus === OPTED_IN) {
    content = (
      <>
        <ChatIcon color="success" sx={{ marginRight: '4px' }} />
        <Typography>SMS notifications enabled</Typography>
      </>
    );
  }

  return (
    <>
      {content}
      <GreenRoomTooltip title={tooltip} />
    </>
  );
}

export default SMSStatus;
