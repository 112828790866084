import React, { useState, useEffect } from 'react';
import {
  Chip,
  InputLabel,
  FormControl,
  Select,
  SelectChangeEvent,
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import GreenRoomDialog from '../../Dialog/GreenRoomDialog';
import GreenRoomButton from '../../GreenRoomButton';
import {
  getSelectParamFromURL,
  getSelectOptions,
  onSelectChange,
} from '../filter-helpers';
import { useGetPaymentTypesForAllProjectsQuery } from '../../../app/api/payment-types-api-slice';
import { getAllUniqueNames } from '../EventsFilters/FilterEvents';
import {
  SELECT_MENU_PROPS,
  isAllSelected,
  isSomeButNotAllSelected,
  isSomeSelected,
} from '../../Selects/select-helpers';

/// //////////
// TODO: Most of the logic in this component is duplicated in FilterEvents

const NULL_FILTER_OPTION_VALUE = 'null';
const ANY_FILTER_OPTION_VALUE = 'any';

/// //////////
// Constants: Type
export const TYPE_PARAM_NAME = 'type';
const TYPE_VALUE_ANY = 'any';
const TYPE_VALUE_EXPENSE = 'Expense';
const TYPE_VALUE_INCOME = 'Income';
const TYPE_OPTIONS = [
  {
    value: TYPE_VALUE_ANY,
    label: 'Expense and income',
  },
  {
    value: TYPE_VALUE_EXPENSE,
    label: TYPE_VALUE_EXPENSE,
  },
  {
    value: TYPE_VALUE_INCOME,
    label: TYPE_VALUE_INCOME,
  },
] as const;
export const TYPE_DEFAULT_VALUE = TYPE_OPTIONS[0].value;

/// //////////
// Constants: Pay status
export const PAY_STATUS_PARAM_NAME = 'isPaid';
const PAY_STATUS_VALUE_ANY = 'any';
const PAY_STATUS_VALUE_PAID = 'true';
const PAY_STATUS_VALUE_NOT_PAID = 'false';
const PAY_STATUS_OPTIONS = [
  {
    value: PAY_STATUS_VALUE_ANY,
    label: 'Paid and unpaid',
  },
  {
    value: PAY_STATUS_VALUE_PAID,
    label: 'Paid',
  },
  {
    value: PAY_STATUS_VALUE_NOT_PAID,
    label: 'Unpaid',
  },
] as const;
export const PAY_STATUS_DEFAULT_VALUE = PAY_STATUS_OPTIONS[0].value;

/// //////////
// Constants: Payment types
export const PAYMENT_TYPES_PARAM_NAME = 'paymentTypeIds';
const PAYMENT_TYPES_LABEL = 'Payment methods';
const PAYMENT_TYPES_GROUP = 'payment methods';

/// //////////
// Types
export interface FilterTransactionsRequestArgs {
  type:
    | typeof TYPE_VALUE_ANY
    | typeof TYPE_VALUE_EXPENSE
    | typeof TYPE_VALUE_INCOME;
  isPaid:
    | typeof PAY_STATUS_VALUE_ANY
    | typeof PAY_STATUS_VALUE_PAID
    | typeof PAY_STATUS_VALUE_NOT_PAID;
  paymentTypeIds?: string;
}

function FilterTransactions() {
  /// //////////
  // Navigation
  const [searchParams, setSearchParams] = useSearchParams();

  /// //////////
  // Payment types fetch
  const getPaymentTypes = useGetPaymentTypesForAllProjectsQuery();
  // Merge fetched payment types across projects by name. When merged, combine ids into comma-delineated string
  const allUniquePaymentTypeNames = getAllUniqueNames(
    getPaymentTypes.data || []
  );
  const PAYMENT_TYPES_OPTIONS = allUniquePaymentTypeNames
    ?.map((paymentTypeName: string) => {
      const allPaymentTypesWithSameName = getPaymentTypes.data?.filter(
        (paymentType) => paymentType.name === paymentTypeName
      );

      return {
        value: allPaymentTypesWithSameName
          ?.map((paymentType) => paymentType.id)
          .join(',')!,
        label: paymentTypeName,
      };
    })
    .concat({ value: 'financial_account', label: 'Financial Account' });

  // Add null option
  PAYMENT_TYPES_OPTIONS.push({
    value: NULL_FILTER_OPTION_VALUE,
    label: '(None)',
  });

  // //////////
  // Active filters
  const activeFilters = {
    type: getSelectParamFromURL(
      TYPE_PARAM_NAME,
      TYPE_DEFAULT_VALUE,
      TYPE_OPTIONS,
      searchParams
    ),
    isPaid: getSelectParamFromURL(
      PAY_STATUS_PARAM_NAME,
      PAY_STATUS_DEFAULT_VALUE,
      PAY_STATUS_OPTIONS,
      searchParams
    ),
    paymentTypeIds: searchParams.get(PAYMENT_TYPES_PARAM_NAME),
  } as FilterTransactionsRequestArgs;

  /// //////////
  // Staged filters; Awaiting user to click "Approve"
  const [stagedFilters, setStagedFilters] = useState(
    activeFilters as FilterTransactionsRequestArgs
  );

  const deriveSelectedArrayFromUrlStringWithConcatenation = (
    paramName: typeof PAYMENT_TYPES_PARAM_NAME,
    options: typeof PAYMENT_TYPES_OPTIONS
  ) => {
    const allIdsInUrl =
      activeFilters[paramName as keyof FilterTransactionsRequestArgs]?.split(
        ','
      ) || [];

    const relevantOptions = options?.filter((option) =>
      allIdsInUrl.some((id) => option.value.includes(id))
    );

    const enhancedSelectedArray = relevantOptions?.map((id) => id.value);

    return enhancedSelectedArray || [];
  };

  const [paymentTypeIds, setPaymentTypeIds] = useState<string[]>(
    deriveSelectedArrayFromUrlStringWithConcatenation(
      PAYMENT_TYPES_PARAM_NAME,
      PAYMENT_TYPES_OPTIONS
    )
  );

  React.useEffect(() => {
    const newStagedFilters = stagedFilters as FilterTransactionsRequestArgs;

    if (paymentTypeIds.length) {
      newStagedFilters.paymentTypeIds = paymentTypeIds.join(',');
    } else {
      newStagedFilters.paymentTypeIds = undefined;
    }

    setStagedFilters(newStagedFilters);
  }, [paymentTypeIds]);

  const getNewSelectedArray = (
    event: SelectChangeEvent<string[]>,
    options: typeof PAYMENT_TYPES_OPTIONS
  ) => {
    const newSelectedArray = (event.target?.value as []) || [];
    const newSelectedId = newSelectedArray[newSelectedArray.length - 1];

    if (newSelectedId === ANY_FILTER_OPTION_VALUE) {
      // Remove item from selected
      return newSelectedArray.length > 1
        ? []
        : options?.map((option) => option.value) || [];
    }

    // Add item to selected
    return newSelectedArray;
  };

  const getMultiSelectLabel = (
    selectedValues: string[],
    groupName: typeof PAYMENT_TYPES_GROUP,
    options: typeof PAYMENT_TYPES_OPTIONS
  ) => {
    let label = `All ${groupName}`;

    if (selectedValues.length === 1) {
      const [selectedId] = selectedValues;
      // TODO: Figure out what this type should be
      label = (options as any[])?.find(
        (option: { value: string; label: string }) =>
          option.value === selectedId
      )?.label;
    } else if (
      selectedValues.length > 1 &&
      !isAllSelected(selectedValues, options)
    ) {
      label = `(${selectedValues.length}) ${groupName} selected`;
    } else if (isAllSelected(selectedValues, options)) {
      label = `All ${groupName} selected`;
    }

    return label;
  };

  const getMultiSelectOptions = (
    selectedValues: string[],
    options: typeof PAYMENT_TYPES_OPTIONS
  ) => [
    <MenuItem
      key="select-all-options"
      value={ANY_FILTER_OPTION_VALUE}
      className={`${
        (isSomeSelected(selectedValues) &&
          'green-room-multi-select-toggle-all-menu-item-selected') ||
        ''
      }`}
    >
      <ListItemIcon>
        <Checkbox
          checked={isAllSelected(selectedValues, options)}
          indeterminate={isSomeButNotAllSelected(selectedValues, options)}
        />
      </ListItemIcon>
      <ListItemText
        className="green-room-multi-select-toggle-all-text"
        primary={isSomeSelected(selectedValues) ? 'Deselect all' : 'Select all'}
      />
    </MenuItem>,
    options?.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        <ListItemIcon>
          <Checkbox
            checked={
              selectedValues.indexOf(option.value) > -1 ||
              isAllSelected(selectedValues, options)
            }
          />
        </ListItemIcon>
        <ListItemText primary={option.label} />
      </MenuItem>
    )),
  ];

  const getMultiSelect = (
    label: typeof PAYMENT_TYPES_LABEL,
    groupName: typeof PAYMENT_TYPES_GROUP,
    selectedValues: string[],
    options: typeof PAYMENT_TYPES_OPTIONS,
    // eslint-disable-next-line no-unused-vars
    onChange: (value: string[]) => void
  ) => (
    <FormControl fullWidth>
      <InputLabel id={label} shrink>
        {label}
      </InputLabel>
      <Select
        multiple
        displayEmpty
        notched
        disabled={!options?.length}
        labelId={label}
        label={label}
        value={selectedValues}
        onChange={(event: SelectChangeEvent<string[]>) =>
          onChange(getNewSelectedArray(event, options))
        }
        renderValue={(values: string[]) =>
          getMultiSelectLabel(values, groupName, options)
        }
        MenuProps={SELECT_MENU_PROPS}
      >
        {getMultiSelectOptions(selectedValues, options)}
      </Select>
    </FormControl>
  );

  // //////////
  // Chip component
  const [numberOfFilters, setNumberOfFilters] = useState<number>(0);

  const getChipLabel = () => {
    let label = 'No Filters' as string;

    if (numberOfFilters === 1) label = '1 filter';

    if (numberOfFilters > 1) label = `${numberOfFilters} filters`;

    return label;
  };

  // //////////
  // Dialogue component
  const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);

  // When the url changes, update the dialogue state; Important after clearing the filters via parent
  const deriveAllSelectStatesFromUrl: () => void = () => {
    setStagedFilters(activeFilters);
    setPaymentTypeIds(
      deriveSelectedArrayFromUrlStringWithConcatenation(
        PAYMENT_TYPES_PARAM_NAME,
        PAYMENT_TYPES_OPTIONS
      )
    );
  };

  useEffect(() => {
    setPaymentTypeIds(
      deriveSelectedArrayFromUrlStringWithConcatenation(
        PAYMENT_TYPES_PARAM_NAME,
        PAYMENT_TYPES_OPTIONS
      )
    );
  }, [getPaymentTypes]);

  // When the url changes, update the dialogue state; Important after clearing the filters via parent
  useEffect(() => {
    deriveAllSelectStatesFromUrl();

    let filtersLength = 0;
    const typeParam = searchParams.get(TYPE_PARAM_NAME);
    const payStatusParam = searchParams.get(PAY_STATUS_PARAM_NAME);
    const paymentTypesParams = searchParams.get(PAYMENT_TYPES_PARAM_NAME);

    if (typeParam && typeParam !== TYPE_VALUE_ANY) {
      filtersLength += 1;
    }

    if (payStatusParam && payStatusParam !== PAY_STATUS_VALUE_ANY) {
      filtersLength += 1;
    }

    if (paymentTypesParams) {
      filtersLength += 1;
    }

    setNumberOfFilters(filtersLength);
  }, [searchParams]);

  // //////////
  // Helper to update the url when user clicks "Approve"
  const updateURL = () => {
    if (stagedFilters.type === TYPE_VALUE_ANY) {
      searchParams.delete(TYPE_PARAM_NAME);
    } else {
      searchParams.set(TYPE_PARAM_NAME, stagedFilters.type);
    }

    if (stagedFilters.isPaid === PAY_STATUS_VALUE_ANY) {
      searchParams.delete(PAY_STATUS_PARAM_NAME);
    } else {
      searchParams.set(PAY_STATUS_PARAM_NAME, stagedFilters.isPaid);
    }

    if (
      !stagedFilters.paymentTypeIds ||
      isAllSelected(paymentTypeIds, PAYMENT_TYPES_OPTIONS)
    ) {
      searchParams.delete(PAYMENT_TYPES_PARAM_NAME);
    } else {
      searchParams.set(PAYMENT_TYPES_PARAM_NAME, stagedFilters.paymentTypeIds);
    }

    // DO save to history
    setSearchParams(searchParams);
  };

  // TODO: This loading pattern breaks convention;
  if (getPaymentTypes.isLoading) {
    return null;
  }

  // //////////
  // Filter component
  return (
    <>
      <Chip
        className="green-room-chip"
        icon={<FilterListIcon />}
        label={getChipLabel()}
        color={numberOfFilters > 0 ? 'primary' : undefined}
        onClick={() => {
          setFilterDialogOpen(true);
        }}
      />
      <GreenRoomDialog
        open={filterDialogOpen}
        title="Filter"
        onClose={() => {
          setStagedFilters(activeFilters);
          setFilterDialogOpen(false);
        }}
        actions={
          <GreenRoomButton
            type="accept"
            onClick={() => {
              updateURL();
              setFilterDialogOpen(false);
            }}
          >
            Apply
          </GreenRoomButton>
        }
      >
        <>
          <FormControl fullWidth>
            <InputLabel id="type">Transaction types</InputLabel>
            <Select
              labelId="type"
              label="Transaction types"
              value={stagedFilters.type}
              onChange={(e) =>
                onSelectChange(
                  e,
                  'type',
                  TYPE_OPTIONS,
                  setStagedFilters,
                  stagedFilters
                )
              }
            >
              {getSelectOptions(TYPE_OPTIONS)}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="pay-status">Pay status</InputLabel>
            <Select
              labelId="pay-status"
              label="Pay status"
              value={stagedFilters.isPaid}
              onChange={(e) =>
                onSelectChange(
                  e,
                  'isPaid',
                  PAY_STATUS_OPTIONS,
                  setStagedFilters,
                  stagedFilters
                )
              }
            >
              {getSelectOptions(PAY_STATUS_OPTIONS)}
            </Select>
          </FormControl>
          {getMultiSelect(
            PAYMENT_TYPES_LABEL,
            PAYMENT_TYPES_GROUP,
            paymentTypeIds,
            PAYMENT_TYPES_OPTIONS,
            setPaymentTypeIds
          )}
        </>
      </GreenRoomDialog>
    </>
  );
}

export default FilterTransactions;
