import emptySplitApi from './empty-api-slice';

export interface ProjectFinancialAccount {
  enabled: boolean;
  disabledReason: string | null;
  cashBalance: number | null;
  inboundPendingBalance: number | null;
  outboundPendingBalance: number | null;
  status: string | null;
  bankName: string | null;
  accountHolderName: string | null;
  accountNumber: string | null;
  accountNumberLast4: string | null;
  routingNumber: string | null;
  entityType: string | null;
  entityName: string | null;
  entityAddress: string | null;
  stripeConnectAccountId: string;
}

export interface SetupProjectFinancialAccountPayload {
  redirectUrl: string;
}

export interface SetupProjectFinancialAccount {
  id: string;
  payload: SetupProjectFinancialAccountPayload;
}

export interface SetupProjectFinancialAccountResponse {
  redirectUrl: string;
}

export interface ExternalAccount {
  id: string;
  bankName: string;
  last4: string;
  status: string;
}

export interface CreateExternalAccountPayload {
  financialConnectionSessionId: string;
}

export interface GetFinancialAccountTransactionsRequest {
  projectId: string;
  limit: number;
  startingAfter: string | null;
}

export interface GetFinancialAccountTransactionRequest {
  projectId: string;
  transactionId?: string | null;
}

export interface FinancialAccountTransaction {
  id: string;
  amount: number;
  amountCash: number;
  amountInboundPending: number;
  amountOutboundPending: number;
  createdAt: string;
  transactor: string;
  flowType: string;
  description: string | null;
  status: string;
  postedAt: string | null;
  voidedAt: string | null;
  hostedRegulatoryReceiptUrl: string | null;
}

export interface GetFinancialAccountTransactionsResponse {
  hasMore: boolean;
  financialAccountTransactions: FinancialAccountTransaction[];
}

export interface CreateExternalAccount {
  projectId: string;
  payload: CreateExternalAccountPayload;
}

export interface OutboundTransferRequestPayload {
  amount: number;
  externalAccountId: string;
}

export interface OutboundTransferRequest {
  projectId: string;
  payload: OutboundTransferRequestPayload;
}

export interface OutboundPaymentRequestPayload {
  amount: number;
  destinationEmail: string;
  description: string | null | undefined;
}

export interface OutboundPaymentRequest {
  projectId: string;
  payload: OutboundPaymentRequestPayload;
}

export interface StripeAddress {
  line1?: string;
  line2?: string | null;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
}

export interface Cardholder {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  status: string;
  billingAddress: StripeAddress;
  phoneNumber: string | null;
  email: string | null;
}

export interface ShippingDetails {
  address: StripeAddress;
  carrier: string | null;
  eta: string | null;
  name: string | null;
  phoneNumber: string | null;
  requiresSignature: boolean | null;
  service: string;
  status: string;
  trackingNumber: string;
  trackingUrl: string;
}

export interface GetCardsRequest {
  projectId: string;
  status: string;
}

export interface Card {
  id: string;
  cardholder: Cardholder;
  status: string;
  cancellationReason: string | null;
  expMonth: number;
  expYear: number;
  last4: string;
  type: string;
  brand: string;
  shipping: ShippingDetails | null;
}

export interface GetCardsResponse {
  hasMore: boolean;
  cards: Card[];
}

export interface GetPersonsRequest {
  projectId: string;
}

export interface Person {
  firstName: string;
  lastName: string;
  address: StripeAddress;
  phoneNumber: string | null;
  email: string | null;
}

export interface GetPersonsResponse {
  hasMore: boolean;
  persons: Person[];
}

export interface GetCardholdersRequest {
  projectId: string;
}

export interface GetCardholdersResponse {
  hasMore: boolean;
  cardholders: Cardholder[];
}

export interface CreateCardholder {
  firstName: string;
  lastName: string;
  billingAddress: StripeAddress;
  phoneNumber: string | null;
  email: string | null;
}

export interface CreateCardShippingInfo {
  shippingName: string | null;
  shippingAddress: StripeAddress | null;
}

export interface CreateCardPayload {
  cardholderId: string | null;
  newCardholder: CreateCardholder | null;
  type: string;
  shippingInfo: CreateCardShippingInfo | null;
}

export interface CreateCardRequest {
  projectId: string;
  payload: CreateCardPayload;
}

export interface CreateCardEphemeralKeyRequest {
  projectId: string;
  cardId: string;
  nonce: string;
}

export interface CardEphemeralKeyResponse {
  ephemeralKeySecret: string;
}

export const projectFinancialAccountApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjectFinancialAccount: builder.query<ProjectFinancialAccount, string>({
      query: (id) => `/projects/${id}/financial_account`,
      providesTags: ['Project', 'ProjectFinancialAccount'],
    }),
    setupProjectFinancialAccount: builder.mutation<
      SetupProjectFinancialAccountResponse,
      SetupProjectFinancialAccount
    >({
      query: (setup) => ({
        url: `/projects/${setup.id}/financial_account`,
        method: 'POST',
        body: setup.payload,
      }),
      invalidatesTags: ['ProjectFinancialAccount'],
    }),
    getExternalAccounts: builder.query<ExternalAccount[], string>({
      query: (projectId) =>
        `/projects/${projectId}/financial_account/external_accounts`,
      providesTags: ['Project', 'ProjectFinancialAccount'],
    }),
    createExternalAccount: builder.mutation<string, CreateExternalAccount>({
      query: (create) => ({
        url: `/projects/${create.projectId}/financial_account/external_accounts`,
        method: 'POST',
        body: create.payload,
      }),
      invalidatesTags: ['ProjectFinancialAccount'],
    }),
    deleteExternalAccount: builder.mutation<
      void,
      { projectId: string; id: string }
    >({
      query: ({ projectId, id }) => ({
        url: `/projects/${projectId}/financial_account/external_accounts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ProjectFinancialAccount'],
    }),
    getFinancialAccountTransactions: builder.query<
      GetFinancialAccountTransactionsResponse,
      GetFinancialAccountTransactionsRequest
    >({
      query: ({ projectId, limit, startingAfter }) => ({
        url: `/projects/${projectId}/financial_account/transactions?limit=${limit}${
          startingAfter ? `&startingAfter=${startingAfter}` : ''
        }`,
      }),
      merge: (currentCache, responseData, meta) => {
        if (meta.arg.startingAfter === null) {
          return responseData;
        }
        return {
          hasMore: responseData.hasMore,
          financialAccountTransactions: [
            ...currentCache.financialAccountTransactions,
            ...responseData.financialAccountTransactions,
          ],
        };
      },
      serializeQueryArgs: ({ queryArgs }) => ({
        projectId: queryArgs.projectId,
      }),
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.startingAfter !== previousArg?.startingAfter,
      providesTags: ['Project', 'ProjectFinancialAccount'],
    }),
    getFinancialAccountTransaction: builder.query<
      FinancialAccountTransaction,
      GetFinancialAccountTransactionRequest
    >({
      query: (query) => ({
        url: `/projects/${query.projectId}/financial_account/transactions/${query.transactionId}`,
      }),
      providesTags: ['Project', 'ProjectFinancialAccount'],
    }),
    createOutboundTransferRequest: builder.mutation<
      void,
      OutboundTransferRequest
    >({
      query: (create) => ({
        url: `/projects/${create.projectId}/financial_account/transactions/outbound_transfer`,
        method: 'POST',
        body: create.payload,
      }),
      invalidatesTags: ['ProjectFinancialAccount'],
    }),
    createOutboundPaymentRequest: builder.mutation<
      void,
      OutboundPaymentRequest
    >({
      query: (request) => ({
        url: `/projects/${request.projectId}/financial_account/transactions/outbound_payment`,
        method: 'POST',
        body: request.payload,
      }),
      invalidatesTags: ['ProjectFinancialAccount'],
    }),
    getCards: builder.query<GetCardsResponse, GetCardsRequest>({
      query: ({ projectId, status }) => ({
        url: `/projects/${projectId}/financial_account/cards?status=${status}`,
      }),
      providesTags: ['Project', 'ProjectFinancialAccount', 'Card'],
    }),
    getFinancialAccountPersons: builder.query<
      GetPersonsResponse,
      GetPersonsRequest
    >({
      query: ({ projectId }) => ({
        url: `/projects/${projectId}/financial_account/persons`,
      }),
      providesTags: ['Project', 'ProjectFinancialAccount'],
    }),
    getCardholders: builder.query<
      GetCardholdersResponse,
      GetCardholdersRequest
    >({
      query: ({ projectId }) => ({
        url: `/projects/${projectId}/financial_account/cardholders`,
      }),
      providesTags: ['Project', 'ProjectFinancialAccount'],
    }),
    createCard: builder.mutation<Card, CreateCardRequest>({
      query: ({ projectId, payload }) => ({
        url: `/projects/${projectId}/financial_account/cards`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Card'],
    }),
    createCardEphemeralKey: builder.mutation<
      CardEphemeralKeyResponse,
      CreateCardEphemeralKeyRequest
    >({
      query: ({ projectId, cardId, nonce }) => ({
        url: `/projects/${projectId}/financial_account/cards/${cardId}/ephemeral_keys`,
        method: 'POST',
        body: { nonce },
      }),
    }),
  }),
});

export const {
  useGetProjectFinancialAccountQuery,
  useSetupProjectFinancialAccountMutation,
  useGetExternalAccountsQuery,
  useCreateExternalAccountMutation,
  useDeleteExternalAccountMutation,
  useGetFinancialAccountTransactionsQuery,
  useGetFinancialAccountTransactionQuery,
  useCreateOutboundTransferRequestMutation,
  useCreateOutboundPaymentRequestMutation,
  useGetCardsQuery,
  useGetFinancialAccountPersonsQuery,
  useGetCardholdersQuery,
  useCreateCardMutation,
  useCreateCardEphemeralKeyMutation,
} = projectFinancialAccountApiSlice;
