import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2/dist/sweetalert2';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Close';
import FileIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ReactComponent as AddFile } from '../img/placeholders/add_file.svg';
import { ReactComponent as DropFile } from '../img/placeholders/drop_file.svg';
import {
  GreenRoomFile,
  useGetFileQuotaQuery,
  useUploadFileMutation,
} from '../app/api/files-api-slice';
import getHumanReadable from '../app/helpers/file-size-helpers';

interface PendingNewFile {
  autoGeneratedId: string;
  id: string | null;
  file: File;
}

interface ProjectFileUploadProps {
  isUploading: boolean | null;
  // eslint-disable-next-line no-unused-vars
  setIsUploading: (isUploading: boolean | null) => void;
  // eslint-disable-next-line no-unused-vars
  onComplete: (files: GreenRoomFile[] | null) => void;
  projectId: string;
}

function ProjectFileUpload({
  isUploading,
  setIsUploading,
  onComplete,
  projectId,
}: ProjectFileUploadProps) {
  const getFileQuota = useGetFileQuotaQuery(projectId);

  const [pendingNewFiles, setPendingNewFiles] = useState<PendingNewFile[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<GreenRoomFile[]>([]);
  const [erroredFiles, setErroredFiles] = useState<File[]>([]);
  const [uploadFileMutation, uploadFileMutationStatus] =
    useUploadFileMutation();
  const onDrop = (acceptedFiles: File[]) => {
    const totalBytesAdded = acceptedFiles.reduce((acc, f) => acc + f.size, 0);
    if (
      getFileQuota.data?.totalBytesLimit &&
      totalBytesAdded + getFileQuota.data!.totalBytes >
        getFileQuota.data!.totalBytesLimit
    ) {
      const filesNoun = acceptedFiles.length === 1 ? 'file' : 'files';
      const itNoun = acceptedFiles.length === 1 ? 'it' : 'they';
      Swal.fire({
        title: 'File size limit exceeded',
        html: `The ${filesNoun} cannot be uploaded because ${itNoun} will cause this project to exceed its file storage capacity of ${getHumanReadable(
          getFileQuota.data!.totalBytesLimit
        )}. Upgrade this project's subscription to increase your file storage capacity.`,
        icon: 'error',
      });
      return;
    }
    setPendingNewFiles([
      ...pendingNewFiles,
      ...acceptedFiles.map((f) => ({
        autoGeneratedId: uuid(),
        id: null,
        file: f,
      })),
    ]);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dropzone = useDropzone({ onDrop, noClick: isMobile });

  function close(files: GreenRoomFile[] | null) {
    setUploadedFiles([]);
    setErroredFiles([]);
    setPendingNewFiles([]);
    onComplete(files);
  }

  async function uploadFiles() {
    setIsUploading(true);
    for (let i = 0; i < pendingNewFiles.length; i += 1) {
      const f = pendingNewFiles[i];
      const formData = new FormData();
      formData.append('file', f.file);
      formData.append('projectId', projectId);
      // eslint-disable-next-line no-await-in-loop
      await uploadFileMutation(formData);
    }
    setIsUploading(false);
  }

  useEffect(() => {
    if (uploadFileMutationStatus.isSuccess) {
      const uploadedFile = uploadFileMutationStatus.data!;
      setUploadedFiles([...uploadedFiles, uploadedFile]);
    }
    if (uploadFileMutationStatus.isError) {
      const erroredFile = uploadFileMutationStatus.originalArgs?.get(
        'file'
      ) as File;
      setErroredFiles([...erroredFiles, erroredFile]);
    }
  }, [uploadFileMutationStatus]);

  useEffect(() => {
    if (
      isUploading === false &&
      (uploadedFiles.length > 0 || erroredFiles.length > 0)
    ) {
      if (erroredFiles.length > 0) {
        Swal.fire({
          title: 'Error',
          html: `There was an error uploading the following files: <br/>${erroredFiles
            .map((f) => f.name)
            .join('<br/>')}`,
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      }
      close(uploadedFiles);
    }
  }, [isUploading, uploadedFiles, erroredFiles]);

  if (isUploading !== null) {
    const totalBytes = pendingNewFiles.reduce((acc, f) => acc + f.file.size, 0);
    const percentUploaded =
      uploadedFiles
        .map((f) => f.size)
        .concat(erroredFiles.map((f) => f.size))
        .reduce((acc, size) => acc + size, 0) / totalBytes;
    return (
      <>
        <DialogTitle>
          {/* div where all items are aligned horizontally */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!isUploading && (
              <CheckCircleIcon color="success" sx={{ marginRight: '8px' }} />
            )}
            {isUploading && (
              <CircularProgress size={24} sx={{ marginRight: '8px' }} />
            )}
            {isUploading ? 'Uploading...' : 'Upload Complete'}
          </div>
        </DialogTitle>
        <DialogContent>
          <LinearProgress
            value={isUploading ? percentUploaded * 100 : 100}
            variant={
              pendingNewFiles.length > 1 || !isUploading
                ? 'determinate'
                : 'indeterminate'
            }
          />
        </DialogContent>
      </>
    );
  }

  return (
    <>
      <DialogContent>
        <div className="upload">
          {isMobile && (
            <div {...dropzone.getRootProps} className="upload-fixed">
              <input {...dropzone.getInputProps()} />
              <Button
                startIcon={<AddIcon />}
                fullWidth
                onClick={dropzone.open}
                variant="outlined"
              >
                Add Files
              </Button>
            </div>
          )}
          {!isMobile && (
            <div className="upload-fixed">
              <div
                className={
                  dropzone.isDragActive
                    ? 'project-file-upload-area project-file-upload-is-dragging'
                    : 'project-file-upload-area'
                }
              >
                <div {...dropzone.getRootProps()}>
                  <input {...dropzone.getInputProps()} />
                  {!dropzone.isDragActive && <AddFile height="180px" />}
                  {dropzone.isDragActive && <DropFile height="180px" />}
                  <div style={{ marginBottom: '8px' }}>
                    {dropzone.isDragActive ? (
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Drop the files...
                      </Typography>
                    ) : (
                      <Typography variant="body1">
                        Drag and drop some files here, or click to select files.
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="upload-list">
            <List>
              {pendingNewFiles.map((f) => (
                <ListItem
                  key={f.autoGeneratedId}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        setPendingNewFiles(
                          pendingNewFiles.filter(
                            (p) => p.autoGeneratedId !== f.autoGeneratedId
                          )
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemIcon>
                    <FileIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={f.file.name}
                    primaryTypographyProps={{ noWrap: true }}
                    secondary={`${new Date(
                      f.file.lastModified
                    ).toLocaleDateString()} - ${getHumanReadable(f.file.size)}`}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close(null)}>Cancel</Button>
        <Button
          disabled={!pendingNewFiles.length}
          variant="contained"
          onClick={() => uploadFiles()}
        >
          Upload
        </Button>
      </DialogActions>
    </>
  );
}

export default ProjectFileUpload;
