import React from 'react';
import { MuiTelInput } from 'mui-tel-input';

interface EditPhoneNumberProps {
  label: string;
  value: string | null;
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (newValue: string | null) => void;
}

// TODO: If ever relevant to implement, MuiTelInput provides phone number validation https://viclafouch.github.io/mui-tel-input/docs/phone-validation/
function EditPhoneNumber({
  label,
  value,
  disabled,
  onChange,
}: EditPhoneNumberProps) {
  return (
    <MuiTelInput
      disabled={disabled}
      fullWidth
      label={label}
      defaultCountry="US"
      preferredCountries={['US']}
      forceCallingCode
      focusOnSelectCountry // Autofocus the input when the user selects a country in the list.
      value={value || undefined}
      onChange={(newValue, metadata) => onChange(metadata.numberValue)} // Return string without whitespaces if defined or null if only country code exists
    />
  );
}

EditPhoneNumber.defaultProps = {
  disabled: false,
};

export default EditPhoneNumber;
