import React from 'react';
import { useNavigate } from 'react-router-dom';
import GreenRoomDialog from '../../components/Dialog/GreenRoomDialog';
import GreenRoomButton from '../../components/GreenRoomButton';

function Dialogs() {
  const navigate = useNavigate();

  return (
    <GreenRoomDialog
      open
      title="Dialog/Drawer"
      onClose={() => navigate('/components')}
      onCloseDisabled={false}
      onBack={() => navigate('/components')}
      actions={
        <>
          <GreenRoomButton
            type="contact"
            onClick={() => navigate('/components')}
          >
            Contact
          </GreenRoomButton>
          <GreenRoomButton type="error" onClick={() => navigate('/components')}>
            Report
          </GreenRoomButton>
        </>
      }
    >
      <span>
        I appear as a Dialog on desktop but a Drawer on mobile.
        <ul>
          <li>
            You can pass me <code>onClose</code> and <code>onBack</code>{' '}
            functions
          </li>
          <li>You can pass me any number of actions</li>
        </ul>
      </span>
    </GreenRoomDialog>
  );
}

export default Dialogs;
