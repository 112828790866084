import React, { useState, useEffect } from 'react';
import { Chip, InputLabel, FormControl, Select } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import GreenRoomDialog from '../../Dialog/GreenRoomDialog';
import GreenRoomButton from '../../GreenRoomButton';
import {
  getSelectParamFromURL,
  getSelectOptions,
  onSelectChange,
} from '../filter-helpers';

/// //////////
// Constants: Sort
export const SORT_PARAM_NAME = 'sort';
const SORT_VALUE_DATE = 'Date';
const SORT_VALUE_AMOUNT = 'Amount';
const SORT_OPTIONS = [
  {
    value: SORT_VALUE_DATE,
    label: SORT_VALUE_DATE,
  },
  {
    value: SORT_VALUE_AMOUNT,
    label: SORT_VALUE_AMOUNT,
  },
] as const;
export const SORT_DEFAULT_VALUE = SORT_OPTIONS[0].value;

/// //////////
// Constants: Sort direction
export const SORT_DIRECTION_PARAM_NAME = 'sortDirection';
const SORT_DIRECTION_VALUE_DESC = 'Desc';
const SORT_DIRECTION_VALUE_ASC = 'Asc';
const SORT_DIRECTION_OPTIONS = [
  {
    value: SORT_DIRECTION_VALUE_ASC,
    label: 'Ascending',
  },
  {
    value: SORT_DIRECTION_VALUE_DESC,
    label: 'Descending',
  },
] as const;
export const SORT_DIRECTION_DEFAULT_VALUE = SORT_DIRECTION_OPTIONS[1].value;

/// //////////
// Types
export interface SortTransactionsRequestArgs {
  sort: typeof SORT_VALUE_DATE | typeof SORT_VALUE_AMOUNT;
  sortDirection:
    | typeof SORT_DIRECTION_VALUE_DESC
    | typeof SORT_DIRECTION_VALUE_ASC;
}

function SortTransactions() {
  /// //////////
  // Navigation
  const [searchParams, setSearchParams] = useSearchParams();

  // //////////
  // Active filters
  const activeFilters = {
    sort: getSelectParamFromURL(
      SORT_PARAM_NAME,
      SORT_DEFAULT_VALUE,
      SORT_OPTIONS,
      searchParams
    ),
    sortDirection: getSelectParamFromURL(
      SORT_DIRECTION_PARAM_NAME,
      SORT_DIRECTION_DEFAULT_VALUE,
      SORT_DIRECTION_OPTIONS,
      searchParams
    ),
  } as SortTransactionsRequestArgs;

  /// //////////
  // Staged filters; Awaiting user to click "Approve"
  const [stagedFilters, setStagedFilters] = useState(
    activeFilters as SortTransactionsRequestArgs
  );

  // //////////
  // Chip component
  const getChipLabel = () => {
    // Sort by amount
    let label = SORT_VALUE_AMOUNT;

    // Sort by date
    if (activeFilters.sort === SORT_VALUE_DATE) {
      label = SORT_VALUE_DATE;
    }

    return label;
  };

  // //////////
  // Dialogue component
  const [sortDialogOpen, setSortDialogOpen] = useState<boolean>(false);

  // When the url changes, update the dialogue state; Important after clearing the filters via parent
  useEffect(() => {
    setStagedFilters(activeFilters);
  }, [searchParams]);

  // //////////
  // Helper to update the url when user clicks "Approve"
  const updateURL = () => {
    searchParams.set(SORT_PARAM_NAME, stagedFilters.sort);
    searchParams.set(SORT_DIRECTION_PARAM_NAME, stagedFilters.sortDirection);

    // DO save to history
    setSearchParams(searchParams);
  };

  // //////////
  // Filter component
  return (
    <>
      <Chip
        className="green-room-chip"
        icon={
          (activeFilters.sortDirection === SORT_DIRECTION_VALUE_DESC && (
            <ArrowDownwardIcon />
          )) || <ArrowUpwardIcon />
        }
        label={getChipLabel()}
        onClick={() => {
          setSortDialogOpen(true);
        }}
      />
      <GreenRoomDialog
        open={sortDialogOpen}
        title="Sort"
        onClose={() => {
          setStagedFilters(activeFilters);
          setSortDialogOpen(false);
        }}
        actions={
          <GreenRoomButton
            type="accept"
            onClick={() => {
              updateURL();
              setSortDialogOpen(false);
            }}
          >
            Apply
          </GreenRoomButton>
        }
      >
        <>
          <FormControl fullWidth>
            <InputLabel id="sort">Sort By</InputLabel>
            <Select
              labelId="sort"
              label="Sort By"
              value={stagedFilters.sort}
              onChange={(e) =>
                onSelectChange(
                  e,
                  'sort',
                  SORT_OPTIONS,
                  setStagedFilters,
                  stagedFilters
                )
              }
            >
              {getSelectOptions(SORT_OPTIONS)}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="sort-direction">Sort Direction</InputLabel>
            <Select
              labelId="sort-direction"
              label="Sort Direction"
              value={stagedFilters.sortDirection}
              onChange={(e) =>
                onSelectChange(
                  e,
                  'sortDirection',
                  SORT_DIRECTION_OPTIONS,
                  setStagedFilters,
                  stagedFilters
                )
              }
            >
              {getSelectOptions(SORT_DIRECTION_OPTIONS)}
            </Select>
          </FormControl>
        </>
      </GreenRoomDialog>
    </>
  );
}

export default SortTransactions;
