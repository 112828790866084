import { TablePagination, LinearProgress, Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  EventSummariesRequest,
  useGetEventSummariesQuery,
} from '../app/api/events-api-slice';
import EventCard from '../components/EventCard';
import Loading from '../components/Loading';
import Heading from '../components/Text/Heading';
import setTitle from '../app/helpers/document-helpers';
import TabContent from '../components/Tabs/TabContent';
import Blankslate from '../components/Blankslate';
import GreenRoomButton from '../components/GreenRoomButton';
import GreenRoomStack from '../components/GreenRoomStack';
import { ReactComponent as Calendar } from '../img/placeholders/calendar.svg';
import { ReactComponent as Search } from '../img/placeholders/search.svg';
import EventsFilters, {
  FilterRequestArgs,
} from '../components/Filters/EventsFilters';
import shallowEquals from '../app/helpers/shallow-equals';
import QuickCreateEventDialog from './QuickCreateEventDialog';
import { useGetFeatureFlagsQuery } from '../app/api/feature-flags-slice';

function Events() {
  /// //////////
  // Navigation
  const navigate = useNavigate();
  setTitle('Events');

  // Quick Create Event Dialog
  const getFeatureFlags = useGetFeatureFlagsQuery();
  const [quickCreateEventDialogOpen, setQuickCreateEventDialogOpen] =
    useState(false);

  /// //////////
  // Fetch filtered events
  // NOTE: Don't make first fetch until required args have been set by EventsFilter via onUpdateFilterRequestArgs
  const [requestArgs, setRequestArgs] = useState({} as EventSummariesRequest);
  const eventSummaries = useGetEventSummariesQuery(requestArgs, {
    skip: !Object.keys(requestArgs).length,
  });

  /// //////////
  // Pagination constants
  const PAGE_LENGTH_DEFAULT = 10;
  const pageLength = requestArgs.limit || PAGE_LENGTH_DEFAULT;

  // //////////
  // Refetch events when filters update
  const onUpdateFilterRequestArgs = (filterRequestArgs: FilterRequestArgs) => {
    // TODO: Depricate these variables; They support the temprary data conversion below
    const prunedFilterRequestArgs = { ...filterRequestArgs };
    const newInviteStatuses = prunedFilterRequestArgs.inviteStatuses || '';
    delete prunedFilterRequestArgs.inviteStatuses;

    const newRequestArgs = {
      ...requestArgs,
      limit: pageLength,
      offset: 0, // Reset the page number after any filtering
      ...prunedFilterRequestArgs,
      inviteStatuses: newInviteStatuses,
    } as EventSummariesRequest;

    // If the new and old request arguments are unalike, fetch the new data
    if (!shallowEquals(newRequestArgs, requestArgs)) {
      setRequestArgs(newRequestArgs);
    }
  };

  /// //////////
  // Define pagination
  const filteredEventsLength = eventSummaries?.data?.totalCount;
  const unfilteredEventsLength = eventSummaries?.data?.allEventsCount;
  const currentPage = requestArgs.offset / pageLength;

  /// //////////
  // Bottom actions component
  const getActions = () => (
    <>
      <GreenRoomButton type="create" to="/events/create">
        Create
      </GreenRoomButton>
      {getFeatureFlags.data?.quickCreateEventEnabled && (
        <Button
          startIcon={<AutoFixHighIcon />}
          onClick={() => setQuickCreateEventDialogOpen(true)}
          variant="outlined"
        >
          Quick Create
        </Button>
      )}
    </>
  );

  /// //////////
  // Docs component
  const getAddEventHelpLink = () => (
    <a
      href="https://help.greenroom.app/how-do-i-add-events-in-green-room"
      target="_blank"
      rel="noreferrer"
    >
      learn more here
    </a>
  );

  /// //////////
  // Loading state
  const loading = eventSummaries.isLoading || getFeatureFlags.isLoading;

  /// //////////
  // Define main content
  let mainContent = <Loading />;

  if (loading) {
    mainContent = <Loading />;
  } else if (unfilteredEventsLength) {
    mainContent = (!filteredEventsLength && (
      <Blankslate title="No events for your filters" svg={<Search />}>
        <span>
          There are no events that match your filters. Please try broadening
          your search.
        </span>
      </Blankslate>
    )) || (
      <GreenRoomStack>
        {eventSummaries.isFetching && (
          <LinearProgress className="green-room-linear-progress" />
        )}
        {eventSummaries.data?.events.map((event) => (
          <EventCard
            key={event.id}
            event={event}
            onClick={() => navigate(`/events/${event.id}`)}
          />
        ))}
      </GreenRoomStack>
    );
  } else if (unfilteredEventsLength === 0) {
    mainContent = (
      <Blankslate title="No events" actions={getActions()} svg={<Calendar />}>
        <span>
          Add an event in Green Room to track your schedule and coordinate your
          team. You can {getAddEventHelpLink()}.
        </span>
      </Blankslate>
    );
  }

  /// //////////
  // Render main content
  return (
    <>
      <Heading>Events</Heading>
      <TabContent
        actions={(!loading && unfilteredEventsLength && getActions()) || null}
      >
        <EventsFilters
          onUpdateFilterRequestArgs={onUpdateFilterRequestArgs}
          unfilteredEventsLength={unfilteredEventsLength}
        />
        {mainContent}
        {!!filteredEventsLength && (
          <TablePagination
            component="div"
            labelRowsPerPage="Events per page"
            count={filteredEventsLength || 0}
            page={currentPage}
            rowsPerPageOptions={[10, 25]}
            rowsPerPage={pageLength}
            onRowsPerPageChange={(e) =>
              setRequestArgs({
                ...requestArgs,
                limit: Number.isInteger(e?.target?.value)
                  ? (e.target.value as unknown as number)
                  : PAGE_LENGTH_DEFAULT,
                offset: 0,
              })
            }
            onPageChange={(_, newPageNumber) =>
              unfilteredEventsLength
                ? setRequestArgs({
                    ...requestArgs,
                    offset: pageLength * newPageNumber,
                  })
                : undefined
            }
            sx={{
              margin: '16px 0 0',
              color: 'primary',
            }}
          />
        )}
      </TabContent>
      <QuickCreateEventDialog
        open={quickCreateEventDialogOpen}
        onClose={() => setQuickCreateEventDialogOpen(false)}
      />
    </>
  );
}

export default Events;
