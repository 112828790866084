import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { Project, useGetProjectsQuery } from '../app/api/projects-api-slice';
import Loading from '../components/Loading';

import './OnboardingPage.scss';
import OnboardingPageCreateProject from './OnboardingPageCreateProject';
import OnboardingPageProjectMembers from './OnboardingPageProjectMembers';
import OnboardingPageProjectRoles from './OnboardingPageProjectRoles';

export interface OnboardProjectProps {
  // eslint-disable-next-line no-unused-vars
  onboardingComplete: (cancelled?: boolean) => void;
}

function OnboardProject({ onboardingComplete }: OnboardProjectProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [onboardingStep, updateOnboardingStep] = useState('createProject');
  const [project, setProject] = useState<Project | null>(null);
  const getProjects = useGetProjectsQuery();

  if (getProjects.isLoading) {
    return <Loading />;
  }

  return (
    <Dialog open fullScreen={fullScreen}>
      {onboardingStep === 'createProject' && (
        <OnboardingPageCreateProject
          onBack={() => onboardingComplete(true)}
          onNext={(p) => {
            setProject(p);
            updateOnboardingStep('projectMembers');
          }}
        />
      )}
      {onboardingStep === 'projectMembers' && (
        <OnboardingPageProjectMembers
          project={project!}
          onNext={() => updateOnboardingStep('projectRoles')}
        />
      )}
      {onboardingStep === 'projectRoles' && (
        <OnboardingPageProjectRoles
          project={project!}
          onNext={onboardingComplete}
        />
      )}
    </Dialog>
  );
}

export default OnboardProject;
