import React from 'react';
import Blankslate from '../Blankslate';
import { ReactComponent as Links } from '../../img/placeholders/link.svg';

interface NoEventLinksBlankslateProps {
  actions?: React.ReactNode | null;
}

function NoEventLinksBlankSlate({ actions }: NoEventLinksBlankslateProps) {
  return (
    <Blankslate title="No shared links" actions={actions} svg={<Links />}>
      A place to share URLs for sheet music, set lists, song collections, or
      anything else that will help the team perform.
    </Blankslate>
  );
}

NoEventLinksBlankSlate.defaultProps = {
  actions: null,
};

export default NoEventLinksBlankSlate;
