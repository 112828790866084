import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ErrorIcon from '@mui/icons-material/Error';
import { useSearchParams } from 'react-router-dom';
import Subheading from '../../components/Text/Subheading';
import Blankslate from '../../components/Blankslate';
import StatefulTabs from '../../components/Tabs/StatefulTabs';
import GreenRoomButton from '../../components/GreenRoomButton';
import TabContent from '../../components/Tabs/TabContent';
import { ReactComponent as Mailbox } from '../../img/placeholders/mailbox.svg';

const tabs = [
  {
    label: 'Standard Tab Layout',
    value: '1',
    icon: <DashboardIcon />,
  },
  {
    label: 'Blankslate Tab Layout',
    value: '2',
    icon: <ErrorIcon />,
  },
];

function Tabs() {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <StatefulTabs tabs={tabs} />
      <TabContent
        actions={
          <>
            <GreenRoomButton type="create">Tab Action</GreenRoomButton>
            <GreenRoomButton type="cancel">Tab Action</GreenRoomButton>
            <GreenRoomButton type="destroy">Tab Action</GreenRoomButton>
          </>
        }
      >
        {tab === '1' && (
          <>
            <Subheading>Subheading</Subheading>
            <div>
              Tab content goes here: Lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
              occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id est laborum.
            </div>
          </>
        )}
        {tab === '2' && (
          <Blankslate title="Blankslate" svg={<Mailbox />}>
            The blankslate body is typically a descripton of the feature.
          </Blankslate>
        )}
      </TabContent>
    </div>
  );
}

export default Tabs;
