import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Chip } from '@mui/material';
import { EventStatus } from '../app/api/events-api-slice';

interface EventStatusChipProps {
  status: EventStatus;
}

function EventStatusChip({ status }: EventStatusChipProps) {
  if (status === 'Draft') {
    return (
      <Chip
        icon={<EditIcon />}
        label="Draft"
        color="secondary"
        variant="outlined"
        size="small"
      />
    );
  }
  if (status === 'Hold') {
    return (
      <Chip
        icon={<PendingIcon />}
        label="Hold"
        color="primary"
        variant="outlined"
        size="small"
      />
    );
  }
  if (status === 'Confirmed') {
    return (
      <Chip
        icon={<CheckCircleIcon />}
        label="Confirmed"
        color="primary"
        variant="outlined"
        size="small"
      />
    );
  }
  if (status === 'Cancelled') {
    return (
      <Chip
        icon={<CancelIcon />}
        label="Canceled"
        color="secondary"
        variant="outlined"
        size="small"
      />
    );
  }
  return null;
}

export default EventStatusChip;
