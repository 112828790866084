import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Swal from 'sweetalert2/dist/sweetalert2';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CloudIcon from '@mui/icons-material/Cloud';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import React, { useState, useEffect } from 'react';
import AddressSelect, { Address } from '../../components/AddressSelect';
import {
  Card,
  useCreateCardMutation,
  useGetCardholdersQuery,
  useGetFinancialAccountPersonsQuery,
} from '../../app/api/project-financial-account-slice';
import GreenRoomDialog from '../../components/Dialog/GreenRoomDialog';
import GreenRoomButton from '../../components/GreenRoomButton';
import { useGetFeatureFlagsQuery } from '../../app/api/feature-flags-slice';
import GreenRoomCard from '../../components/GreenRoomCard';
import './CreateCardDialog.scss';

export interface CreateCardDialogProps {
  projectId: string;
  cards: Card[];
  open: boolean;
  onClose: () => void;
}

interface Cardholder {
  cardholderId: string | null;
  firstName: string;
  lastName: string;
  shippingName: string;
  phoneNumber: string | null;
  email: string | null;
}

const STEP_CARD_TYPE = 0;
const STEP_CARDHOLDER = 1;
const STEP_SHIPPING_INFO = 2;
const STEP_TERMS_OF_SERVICE = 3;
const STEPS = [
  STEP_CARD_TYPE,
  STEP_CARDHOLDER,
  STEP_SHIPPING_INFO,
  STEP_TERMS_OF_SERVICE,
];

const SHIPPING_SERVICES = [
  {
    value: 'standard',
    label: 'Standard',
    timeframe: '5-8 business days',
    cost: null,
  },
  {
    value: 'express',
    label: 'Express',
    timeframe: '4 business days',
    cost: '$16',
  },
  {
    value: 'priority',
    label: 'Priority',
    timeframe: '2-3 business days',
    cost: '$22',
  },
];

function CreateCardDialog({
  projectId,
  cards,
  open,
  onClose,
}: CreateCardDialogProps) {
  const getFeatureFlags = useGetFeatureFlagsQuery();
  const getPersons = useGetFinancialAccountPersonsQuery({ projectId });
  const getCardholders = useGetCardholdersQuery({ projectId });

  const [cardType, setCardType] = useState<string | null>(null);

  const [cardholder, setCardholder] = useState<Cardholder | null>(null);
  const [cardholderAddress, setCardholderAddress] = useState<Address | null>(
    null
  );
  const [useDifferentShippingAddress, setUseDifferentShippingAddress] =
    useState(false);
  const [shippingService, setShippingService] = useState('standard');
  const [shippingAddress, setShippingAddress] = useState<Address | null>(null);

  const [acceptedTermsOfService, setAcceptedTermsOfService] = useState(false);
  const [step, setStep] = useState(0);

  const [createCardMutation, createCardMutationStatus] =
    useCreateCardMutation();

  const close = () => {
    if (createCardMutationStatus.isLoading) return;
    setStep(0);
    setCardType(null);
    setAcceptedTermsOfService(false);
    createCardMutationStatus.reset();
    onClose();
  };

  const createCard = () => {
    const createCardPayload = {
      cardholderId: cardholder!.cardholderId,
      newCardholder: !cardholder?.cardholderId
        ? {
            firstName: cardholder!.firstName,
            lastName: cardholder!.lastName,
            billingAddress: {
              line1: cardholderAddress!.line1,
              line2: cardholderAddress!.line2,
              city: cardholderAddress!.city,
              state: cardholderAddress!.state,
              postalCode: cardholderAddress!.postalCode,
              country: 'US',
            },
            phoneNumber: cardholder!.phoneNumber,
            email: cardholder!.email,
          }
        : null,
      type: cardType!,
      shippingInfo:
        cardType === 'physical'
          ? {
              shippingName: cardholder!.shippingName,
              shippingAddress: {
                line1: shippingAddress!.line1,
                line2: shippingAddress!.line2,
                city: shippingAddress!.city,
                state: shippingAddress!.state,
                postalCode: shippingAddress!.postalCode,
                country: 'US',
              },
            }
          : null,
      shippingService,
    };
    createCardMutation({
      projectId,
      payload: createCardPayload,
    });
  };

  useEffect(() => {
    if (createCardMutationStatus.isSuccess) {
      close();
      if (cardType === 'physical') {
        const timeframe = SHIPPING_SERVICES.find(
          (service) => service.value === shippingService
        )?.timeframe;
        Swal.fire({
          title: `You're all set!`,
          text: `We've begun creating your business expense card and you should receive it in ${timeframe}. If you have any questions, please contact us.`,
          icon: 'success',
        });
      } else {
        Swal.fire({
          title: `You're all set!`,
          text: `We've created your virtual expense card. You can start using it immediately. To view your card number, click on the card in the list.`,
          icon: 'success',
        });
      }
    } else if (createCardMutationStatus.isError) {
      close();
      Swal.fire({
        title: 'Error creating card',
        text: 'There was an error creating your card. Please try again or contact us.',
        icon: 'error',
      });
    }
  }, [createCardMutationStatus, step]);

  useEffect(() => {
    if (getCardholders.data && getCardholders.data.cardholders.length > 0) {
      setCardholder({
        cardholderId: getCardholders.data.cardholders[0].id,
        firstName: getCardholders.data.cardholders[0].firstName,
        lastName: getCardholders.data.cardholders[0].lastName,
        shippingName: getCardholders.data.cardholders[0].name,
        phoneNumber: getCardholders.data.cardholders[0].phoneNumber,
        email: getCardholders.data.cardholders[0].email,
      });
      const address = {
        line1: getCardholders.data.cardholders[0].billingAddress.line1,
        line2: getCardholders.data.cardholders[0].billingAddress.line2,
        city: getCardholders.data.cardholders[0].billingAddress.city,
        state: getCardholders.data.cardholders[0].billingAddress.state,
        postalCode:
          getCardholders.data.cardholders[0].billingAddress.postalCode,
      };
      setCardholderAddress(address);
      setShippingAddress(address);
    } else if (getPersons.data) {
      setCardholder({
        cardholderId: null,
        firstName: getPersons.data.persons[0].firstName,
        lastName: getPersons.data.persons[0].lastName,
        shippingName: `${getPersons.data.persons[0].firstName} ${getPersons.data.persons[0].lastName}`,
        phoneNumber: getPersons.data.persons[0].phoneNumber,
        email: getPersons.data.persons[0].email,
      });
      const address = {
        line1: getPersons.data.persons[0].address.line1,
        line2: getPersons.data.persons[0].address.line2,
        city: getPersons.data.persons[0].address.city,
        state: getPersons.data.persons[0].address.state,
        postalCode: getPersons.data.persons[0].address.postalCode,
      };
      setCardholderAddress(address);
      setShippingAddress(address);
    }
  }, [getPersons, getCardholders]);

  if (
    getFeatureFlags.isLoading ||
    getCardholders.isLoading ||
    getPersons.isLoading ||
    !cardholder ||
    !cardholderAddress ||
    !shippingAddress
  ) {
    return null;
  }

  let title: string = '';
  let content: React.ReactNode = null;
  if (step === STEP_CARD_TYPE) {
    title = 'Create card';
    content = (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Create a business expense card to{' '}
            <b>spend your Green Room balance immediately</b>.
            <br />
            <br />
            Select whether you want a <b>physical</b> or <b>virtual</b> card
            below to get started.
          </Typography>
          <div className="create-card-type-options">
            {cards.filter((c) => c.type === 'physical').length > 0 && (
              <GreenRoomCard
                left={<CreditCardIcon />}
                right={<DoNotDisturbIcon />}
              >
                <Typography variant="body1">
                  <b>Physical card</b>
                </Typography>
                <Typography variant="caption">
                  You already have a physical card.
                </Typography>
              </GreenRoomCard>
            )}
            {cards.filter((c) => c.type === 'physical').length === 0 && (
              <GreenRoomCard
                left={<CreditCardIcon />}
                right={<ArrowRightIcon />}
                onClick={() => {
                  setCardType('physical');
                  setStep(step + 1);
                }}
              >
                <Typography variant="body1">
                  <b>Physical card</b>
                </Typography>
              </GreenRoomCard>
            )}
            {cards.filter((c) => c.type === 'virtual').length > 0 && (
              <GreenRoomCard left={<CloudIcon />} right={<DoNotDisturbIcon />}>
                <Typography variant="body1">
                  <b>Virtual card</b>
                </Typography>
                <Typography variant="caption">
                  You already have a virtual card.
                </Typography>
              </GreenRoomCard>
            )}
            {cards.filter((c) => c.type === 'virtual').length === 0 && (
              <GreenRoomCard
                left={<CloudIcon />}
                right={<ArrowRightIcon />}
                onClick={() => {
                  setCardType('virtual');
                  setStep(step + 1);
                }}
              >
                <Typography variant="body1">
                  <b>Virtual card</b>
                </Typography>
              </GreenRoomCard>
            )}
          </div>
        </Grid>
      </Grid>
    );
  } else if (step === STEP_CARDHOLDER) {
    title = 'Cardholder';
    content = (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            At this time, you can only create a card for{' '}
            <b>you, the individual that created your financial account</b>.
            Confirm that&apos;s what you want to do below.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="First Name"
            value={cardholder?.firstName}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Last Name"
            value={cardholder?.lastName}
            disabled
          />
        </Grid>
        <AddressSelect value={cardholderAddress} disabled onChange={() => {}} />
        <Grid item xs={12}>
          <Button
            sx={{ textTransform: 'none' }}
            startIcon={<ChatBubbleIcon />}
            onClick={() =>
              window.Intercom(
                'showNewMessage',
                'I would like to create a business expense card for someone else.'
              )
            }
          >
            Want to create a card for someone else? Let us know!
          </Button>
        </Grid>
      </Grid>
    );
  } else if (step === STEP_SHIPPING_INFO) {
    title = 'Shipping Info';
    content = (
      <Grid container spacing={2}>
        {getFeatureFlags.data?.cardsExpeditedShipping && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="shipping-method-label">
                Shipping Method
              </InputLabel>
              <Select
                value={shippingService}
                onChange={(e) => setShippingService(e.target.value as string)}
                label="Shipping Method"
              >
                {SHIPPING_SERVICES.map((service) => (
                  <MenuItem key={service.value} value={service.value}>
                    {service.label} ({service.timeframe}){' '}
                    {service.cost && `-${service.cost}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={useDifferentShippingAddress}
                  value={useDifferentShippingAddress}
                  onChange={(e) => {
                    setUseDifferentShippingAddress(e.target.checked);
                    if (!e.target.checked) {
                      setShippingAddress(cardholderAddress);
                    }
                  }}
                />
              }
              label="Use different shipping address"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <AddressSelect
              value={shippingAddress}
              onChange={setShippingAddress}
              disabled={!useDifferentShippingAddress}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  } else if (step === STEP_TERMS_OF_SERVICE) {
    title = 'Terms of Service';
    content = (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            By checking the box below, you are indicating that you have read and
            agree to the{' '}
            <a
              href="https://stripe.com/legal/issuing/spend-card-user-terms"
              target="_blank"
              rel="noreferrer"
            >
              Stripe Issuing: Spend Card User Terms of service
            </a>{' '}
            that govern the use of this spend card.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptedTermsOfService}
                onChange={() =>
                  setAcceptedTermsOfService(!acceptedTermsOfService)
                }
                name="termsOfService"
                color="primary"
                disabled={createCardMutationStatus.isLoading}
              />
            }
            label="I agree to the Stripe Issuing terms of service linked above."
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <GreenRoomDialog
      title={title}
      open={open}
      onClose={() => close()}
      onBack={
        createCardMutationStatus.isLoading
          ? undefined
          : () => {
              if (step === 0) {
                close();
              } else if (
                step - 1 === STEP_SHIPPING_INFO &&
                cardType === 'virtual'
              ) {
                setStep(step - 2);
              } else {
                setStep(step - 1);
              }
            }
      }
      fullscreen
      actions={
        <>
          <GreenRoomButton
            type="cancel"
            onClick={() => close()}
            disabled={createCardMutationStatus.isLoading}
          >
            Cancel
          </GreenRoomButton>
          {step < STEPS.length - 1 && step !== STEP_CARD_TYPE && (
            <GreenRoomButton
              type="accept"
              onClick={() => {
                if (step + 1 === STEP_SHIPPING_INFO && cardType === 'virtual') {
                  setStep(step + 2);
                } else {
                  setStep(step + 1);
                }
              }}
            >
              Next
            </GreenRoomButton>
          )}
          {step === STEPS.length - 1 && (
            <GreenRoomButton
              type="accept"
              disabled={
                !acceptedTermsOfService || createCardMutationStatus.isLoading
              }
              onClick={() => createCard()}
            >
              {createCardMutationStatus.isLoading && 'Submitting...'}
              {!createCardMutationStatus.isLoading && 'Submit'}
            </GreenRoomButton>
          )}
        </>
      }
    >
      <div>{content}</div>
    </GreenRoomDialog>
  );
}

export default CreateCardDialog;
