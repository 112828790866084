import { createFilterOptions } from '@mui/material/Autocomplete';
import { FilterOptionsState } from '@mui/material';

/// //////////
// Types
// Note: The MUI Autocomplete onChange value type is poorly typed and documented as of 5/4/2023
// The Autocomplete onChange can pass back any of the following, and depending on what it passes back we need to respond accordingly
// 1. It can be a Select option, in which case we update the foreign key on the parent
// 4. It can be a string value, as in the user is typing into the TextField. We don't need to emit this value.
// 3. It can be an object of type { name: string, inputValue: string }, in the case that a user chooses to create a new option from the TextField value, in which case we need to use this info to pop open a create new dialog
// 4. It can be null, as in the user cleared the input with the remove button, in which case we need to nullify the foreign key on the parent
export type AutocompleteValue = any;
type AutocompleteOption = {
  id?: string | null;
  name: string;
  projectId?: string;
  inputValue?: string;
};

/// //////////
// Get selected name
export const getSelectedName = (
  array: { id: string; name: string }[] | undefined,
  id: string | null | undefined
) => array?.find((type) => type.id === id)?.name || '';

/// //////////
// Get string value rendered in Autocomplete
export const getAutocompleteOptionLabel = (value: AutocompleteValue) => {
  // Value selected from the list
  if (typeof value === 'string') {
    return value;
  }
  // Newly typed and not yet saved value
  if (value.inputValue) {
    return value.inputValue;
  }
  // Dropdown list label
  if (value.name) {
    return value.name;
  }

  return '';
};

/// //////////
// Get filtered autocomplete options
export const getAutocompleteOptions = (
  options: AutocompleteOption[],
  params: FilterOptionsState<AutocompleteOption>,
  label: string
) => {
  const filtered = createFilterOptions<AutocompleteOption>()(options, params);

  const { inputValue } = params;
  // Suggest the creation of a new value
  const isExisting = options.some((option) => inputValue === option.name);
  if (inputValue !== '' && !isExisting) {
    filtered.push({
      inputValue,
      name: `Add "${inputValue}"`,
    });
  }
  if (inputValue === '') {
    filtered.push({
      inputValue,
      name: `+ Add new ${label}`,
    });
  }

  return filtered;
};
