import React from 'react';
import { Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BlockIcon from '@mui/icons-material/Block';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import './FinancialAccountTransactionCard.scss';
import {
  getFinancialAccountTransactionDisplayType,
  getFinancialAccountTransactionPreposition,
} from '../../app/helpers/financial-account-transaction-helpers';
import { FinancialAccountTransaction } from '../../app/api/project-financial-account-slice';
import GreenRoomCard from '../../components/GreenRoomCard';

// Transaction status constants
const OPEN = 'open';
const VOID = 'void';

interface FinancialAccountTransactionCardProps {
  transaction: FinancialAccountTransaction;
  onClick: () => void;
}

const getIcon = (transaction: FinancialAccountTransaction) => {
  if (transaction.status === VOID) {
    return <BlockIcon />;
  }

  if (transaction.status === OPEN) {
    return <HourglassEmptyIcon />;
  }

  return <AttachMoneyIcon />;
};

function FinancialAccountTransactionCard({
  transaction,
  onClick,
}: FinancialAccountTransactionCardProps) {
  const displayType = getFinancialAccountTransactionDisplayType(transaction);
  const preposition = getFinancialAccountTransactionPreposition(transaction);

  return (
    <GreenRoomCard
      key={transaction.id}
      title={`${displayType} ${preposition} ${transaction.transactor}`}
      subtitle={new Date(transaction.createdAt).toLocaleString(undefined, {
        dateStyle: 'medium',
      })}
      left={getIcon(transaction)}
      right={
        <Typography variant="body1">
          {Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(transaction.amount / 100)}
        </Typography>
      }
      onClick={() => onClick()}
    >
      {transaction.description}
    </GreenRoomCard>
  );
}

export default FinancialAccountTransactionCard;
