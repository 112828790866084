import React from 'react';
import { FormHelperText, Link } from '@mui/material';
import {
  EditPerson,
  PENDING_OPT_IN,
  OPTED_OUT,
  OPTED_IN,
} from '../app/helpers/contact-helpers';

interface SMSHelperTextProps {
  editPerson: EditPerson | undefined;
  newPhoneNumber: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  onClick?: () => void;
}

function SMSHelperText({
  editPerson,
  newPhoneNumber,
  onClick,
}: SMSHelperTextProps) {
  let helperText;

  if (newPhoneNumber) {
    helperText =
      "This contact's SMS preferences will be reset on save because you have changed their phone number. On save, they will receive a new consent prompt and this setting will be enabled once they accept.";
  } else if (!editPerson) {
    helperText = 'Please select a team member to toggle notification settings';
  } else if (editPerson?.phoneNumberStatus === PENDING_OPT_IN) {
    helperText =
      'The contact was sent a notice that they must agree to before this setting is enabled';
  } else if (editPerson?.phoneNumberStatus === OPTED_OUT) {
    helperText = 'The contact declined to receiving SMS updates';
  } else if (editPerson?.phoneNumberStatus !== OPTED_IN) {
    helperText =
      'Please add a phone number. On save, the contact will receive a text message to consent to SMS. This setting is enabled once they accept.';

    if (onClick) {
      return (
        <FormHelperText>
          Please {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            component="button"
            onClick={onClick}
            style={{ textTransform: 'unset' }}
            variant="caption"
          >
            add a phone number
          </Link>
          . On save, the contact will receive a text message to consent to SMS.
          This setting is enabled once they accept.
        </FormHelperText>
      );
    }
  }

  if (helperText) return <FormHelperText>{helperText}</FormHelperText>;

  return null;
}

SMSHelperText.defaultProps = {
  onClick: null,
};

export default SMSHelperText;
