import { Stack } from '@mui/material';
import React from 'react';
import { useGetProjectFinancialAccountQuery } from '../app/api/project-financial-account-slice';
import KeyValue from './KeyValue';
import Subheading from './Text/Subheading';

interface ProjectFinancialAccountInfoProps {
  projectId: string;
}

function ProjectFinancialAccountInfo({
  projectId,
}: ProjectFinancialAccountInfoProps) {
  const getProjectFinancialAccountQuery =
    useGetProjectFinancialAccountQuery(projectId);

  if (getProjectFinancialAccountQuery.isLoading) {
    return null;
  }

  const address = getProjectFinancialAccountQuery.data!.entityAddress;

  return (
    <>
      <Subheading>Account Info</Subheading>
      <Stack spacing={2}>
        <KeyValue
          secondary
          label="Entity type"
          value={getProjectFinancialAccountQuery.data!.entityType}
        />
        <KeyValue
          secondary
          label="Name"
          value={getProjectFinancialAccountQuery!.data!.entityName}
        />
        <KeyValue
          secondary
          label="Address"
          value={<span style={{ whiteSpace: 'pre-wrap' }}>{address}</span>}
        />
      </Stack>
    </>
  );
}

export default ProjectFinancialAccountInfo;
