import { GetTokenSilentlyOptions } from '@auth0/auth0-react';

let getAccessTokenSilently: (
  // eslint-disable-next-line no-unused-vars
  options?: GetTokenSilentlyOptions | undefined
) => Promise<string>;

// eslint-disable-next-line import/prefer-default-export
export const sec = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  // eslint-disable-next-line no-return-assign
  setAccessTokenSilently: (
    // eslint-disable-next-line no-unused-vars
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>
  ) => (getAccessTokenSilently = func),
};
