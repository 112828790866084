import { Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import BreadcrumbsSelect, {
  BreadcrumbSelectOptionProps,
} from './BreadcrumbsSelect';
import './Breadcrumbs.scss';
import Heading from './Text/Heading';

// A breadcrumb can be static text, a link, or a select dropdown
// {name} = Static breadcrumb
// {name, to} = Link breadcrumb
// {name, options, value, onChange} = Select breadcrumb
export interface BreadcrumbProps {
  name: string;
  to?: string | number | null;
  options?: BreadcrumbSelectOptionProps[];
  value?: string | null;
  // eslint-disable-next-line no-unused-vars
  onChange?: (id: string | null) => void | null;
}

interface BreadcrumbsProps {
  crumbs: BreadcrumbProps[];
}

function Breadcrumbs({ crumbs = [] }: BreadcrumbsProps) {
  const breadcrumbs: any = [];
  let title;

  crumbs?.forEach((crumb: any, index = 0) => {
    const isFirstCrumb = index === 0;
    const isLastCrumb = crumbs.length === index + 1;
    const isOnlyCrumb = isFirstCrumb && isLastCrumb;

    const linkStyles = {
      fontSize: isLastCrumb ? '24p' : '16px',
      textDecoration: 'none',
      textTransform: 'unset',
      width: 'fit-content',
      cursor: 'pointer',
    };

    let joiner;

    if (!isFirstCrumb && !isLastCrumb) {
      joiner = <span style={{ margin: '0 4px' }}>/</span>;
    }

    let crumbJSX = <span>{crumb.name}</span>;

    if (crumb.to) {
      crumbJSX = (
        <Link component={RouterLink} to={crumb.to} sx={linkStyles}>
          {crumb.name}
        </Link>
      );
    }

    if (crumb?.options?.length) {
      crumbJSX = (
        <BreadcrumbsSelect
          options={crumb.options}
          onChange={crumb.onChange}
          label={crumb.name}
          value={crumb.value}
        />
      );
    }

    const crumbWithJoinerJSX = (
      <React.Fragment key={crumb.name}>
        {joiner}
        {crumbJSX}
      </React.Fragment>
    );

    if (isOnlyCrumb || isLastCrumb) {
      title = crumbWithJoinerJSX;
    } else {
      breadcrumbs.push(crumbWithJoinerJSX);
    }
  });

  return (
    <>
      <span className="breadcrumbs-wrapper">
        <h4 className="breadcrumbs">{breadcrumbs}</h4>
      </span>
      <Heading className="breadcrumbs-title">{title}</Heading>
    </>
  );
}

export default Breadcrumbs;
