import React from 'react';
import { Card, Typography, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import './GreenRoomCard.scss';

interface GreenRoomCardProps {
  title?: React.ReactNode | string;
  subtitle?: React.ReactNode | string | null;
  left?: React.ReactNode | null;
  right?: React.ReactNode | null;
  onClick?: () => void;
  onRemove?: () => void;
  secondary?: boolean | null;
  children?: React.ReactNode;
}

function GreenRoomCard({
  title,
  subtitle,
  left,
  right,
  onClick,
  onRemove,
  secondary,
  children,
}: GreenRoomCardProps) {
  return (
    <Card
      sx={{ padding: '16px', cursor: onClick ? 'pointer' : 'unset' }}
      elevation={secondary ? 0 : 1}
      onClick={onClick}
      className={`green-room-card ${
        (secondary && 'green-room-card-secondary') || ''
      }`}
    >
      <>
        {left && <div className="green-room-card-left">{left}</div>}
        <div className="green-room-card-body">
          {title && (
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              {title}
            </Typography>
          )}
          {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
          {children}
        </div>
        {right && <div className="green-room-card-right">{right}</div>}
        {onRemove && (
          <div className="green-room-card-right">
            <IconButton onClick={onRemove}>
              <ClearIcon />
            </IconButton>
          </div>
        )}
      </>
    </Card>
  );
}

GreenRoomCard.defaultProps = {
  title: null,
  subtitle: null,
  left: null,
  right: null,
  onClick: null,
  onRemove: null,
  secondary: null,
  children: null,
};

export default GreenRoomCard;
