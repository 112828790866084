import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import GreenRoomDialog from './Dialog/GreenRoomDialog';
import GreenRoomButton from './GreenRoomButton';

interface ProjectFinancialAccountSetupConfirmationDialogProps {
  projectName: string;
  open: boolean;
  onCancel: () => void;
  onAccept: () => void;
}

interface Affirmations {
  createAccountForProject: boolean;
  basedInUS: boolean;
  useForBusinessPurposes: boolean;
}

function ProjectFinancialAccountSetupConfirmationDialog({
  projectName,
  open,
  onCancel,
  // eslint-disable-next-line no-unused-vars
  onAccept,
}: ProjectFinancialAccountSetupConfirmationDialogProps) {
  const [affirmations, setAffirmations] = React.useState<Affirmations>({
    createAccountForProject: false,
    basedInUS: false,
    useForBusinessPurposes: false,
  });

  return (
    <GreenRoomDialog
      open={open}
      title="Create Financial Account?"
      onClose={onCancel}
      actions={
        <>
          <GreenRoomButton
            type="accept"
            disabled={
              !affirmations.createAccountForProject ||
              !affirmations.basedInUS ||
              !affirmations.useForBusinessPurposes
            }
            onClick={onAccept}
          >
            Create
          </GreenRoomButton>
          <GreenRoomButton type="cancel" onClick={onCancel}>
            Cancel
          </GreenRoomButton>
        </>
      }
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={affirmations.createAccountForProject}
              onChange={(e) =>
                setAffirmations({
                  ...affirmations,
                  createAccountForProject: e.target.checked,
                })
              }
            />
          }
          label={`I confirm that I want to create a financial account for ${projectName}.`}
        />
      </FormGroup>
      <Typography variant="body1">
        Green Room Financial Accounts are only available to US citizens and
        US-based businesses. However, individuals or the authorized persons of
        these US businesses may reside in over 150 countries. You can read more
        about all national residence limitations on Financial Accounts{' '}
        <a
          href="https://help.greenroom.app/financial-accounts"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={affirmations.basedInUS}
              onChange={(e) =>
                setAffirmations({
                  ...affirmations,
                  basedInUS: e.target.checked,
                })
              }
            />
          }
          label="I confirm that I am a United States citizen or US-based business, and am based in a supported country."
        />
      </FormGroup>
      <Typography variant="body1">
        Green Room Financial Accounts are for business uses only. Personal and
        non-business use cases are prohibited.
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={affirmations.useForBusinessPurposes}
              onChange={(e) =>
                setAffirmations({
                  ...affirmations,
                  useForBusinessPurposes: e.target.checked,
                })
              }
            />
          }
          label="I confirm that I will only use Green Room Financial Accounts for business purposes."
        />
      </FormGroup>
    </GreenRoomDialog>
  );
}

export default ProjectFinancialAccountSetupConfirmationDialog;
