/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  TextField,
  IconButton,
  Card,
  CardContent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Alert,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment-timezone';
import Swal from 'sweetalert2/dist/sweetalert2';
import ProjectSelect from '../components/ProjectSelect';
import { EventLocation } from '../app/models/event-location';
import EventLocationSelect from '../components/EventLocationSelect';
import EditPhoneNumber from '../components/EditPhoneNumber';
import './EditEventPage.scss';
import {
  EventStatus,
  Transaction,
  useCreateEventMutation,
  useDeleteEventMutation,
  useGetEventQuery,
  useUpdateEventMutation,
  useGetEventSummariesQuery,
} from '../app/api/events-api-slice';
import Loading from '../components/Loading';
import EditDatePicker from '../components/EditDatePicker';
import ErrorPage from '../components/ErrorPage';
import ServerErrorDisplay from '../components/ServerErrorDisplay';
import EventTabs, {
  EVENT_TABS,
  EVENT_TAB_VALUES,
} from '../components/Tabs/EventTabs';
import TabContent from '../components/Tabs/TabContent';
import Heading from '../components/Text/Heading';
import EventTransactionCards from './EventTransactionCards';
import EditEventPageLinksTab from './EditEventPageLinksTab';
import { EditEvent, ToEditEvent } from '../app/models/edit-event';
import setTitle from '../app/helpers/document-helpers';
import {
  useGetProjectEntitlementsQuery,
  useGetProjectQuery,
} from '../app/api/projects-api-slice';
import GreenRoomButton from '../components/GreenRoomButton';
import GreenRoomStack from '../components/GreenRoomStack';
import GreenRoomTooltip from '../components/GreenRoomTooltip';
import deepEquals from '../app/helpers/deep-equals';
import usePrompt from '../app/hooks/use-prompt';
import EditLabels from '../components/Labels/EditLabels';
import Subheading from '../components/Text/Subheading';
import EditEventBandsintown from './EditEventBandsintown';
import {
  useGetBandsintownConfigurationQuery,
  useGetEventBandsintownQuery,
  useRemoveEventBandsintownMutation,
  useUpsertEventBandsintownMutation,
} from '../app/api/bandsintown-api-slice';
import { useGetPaymentTypesQuery } from '../app/api/payment-types-api-slice';
import { useGetEventRoleTypesQuery } from '../app/api/event-role-types-api-slice';
import { useGetPersonsQuery } from '../app/api/persons-api-slice';
import EventRoleCards from '../components/EventRoleCards';
import EventStatusSelect from '../components/EventStatusSelect';
import { formatDateForAPI } from '../components/Filters/filter-helpers';
import PremiumFeatureChip from '../components/PremiumFeatureChip';
import { useGetFeatureFlagsQuery } from '../app/api/feature-flags-slice';
import EditEventPageFilesTab from './EditEventPageFilesTab';

export interface EditEventPageProps {
  mode: 'edit' | 'create';
}

function EditEventPage({ mode }: EditEventPageProps) {
  const getFeatureFlags = useGetFeatureFlagsQuery();

  /// //////////
  // Navigation and banner params
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const { enqueueSnackbar } = useSnackbar();
  const duplicateFromEventId = searchParams.get('duplicateFrom');
  const [createMore, setCreateMore] = useState(false);

  /// //////////
  // Create new event hooks
  const [createMutation, createMutationStatus] = useCreateEventMutation();

  useEffect(() => {
    if (mode === 'create') {
      if (createMutationStatus.isSuccess) {
        enqueueSnackbar('Event created successfully', {
          variant: 'success',
        });
        if (createMore) {
          navigate(
            `./../create?duplicateFrom=${createMutationStatus.data}&tab=${tab}`
          );
          createMutationStatus.reset();
        } else {
          navigate(`./../${createMutationStatus.data}`);
        }
      } else if (createMutationStatus.isError) {
        const errorDisplay = (
          <ServerErrorDisplay
            error={createMutationStatus.error}
            errorMessage="There was a problem saving the event."
          />
        );
        enqueueSnackbar(errorDisplay, {
          variant: 'error',
        });
      }
    }
  }, [createMutationStatus, navigate, enqueueSnackbar]);

  /// //////////
  // Update existing event hooks
  const [updateMutation, updateMutationStatus] = useUpdateEventMutation();

  useEffect(() => {
    if (mode === 'edit') {
      if (updateMutationStatus.isSuccess) {
        enqueueSnackbar('Event updated successfully', {
          variant: 'success',
        });
        navigate(`./../?tab=${tab}`);
      } else if (updateMutationStatus.isError) {
        const errorDisplay = (
          <ServerErrorDisplay
            error={updateMutationStatus.error}
            errorMessage="There was a problem saving the event."
          />
        );
        enqueueSnackbar(errorDisplay, {
          variant: 'error',
        });
      }
    }
  }, [updateMutationStatus, navigate, enqueueSnackbar]);

  /// //////////
  // Delete existing event hooks
  const [deleteMutation, deleteMutationStatus] = useDeleteEventMutation();

  useEffect(() => {
    if (deleteMutationStatus.isSuccess) {
      enqueueSnackbar('Event deleted successfully', {
        variant: 'success',
      });
      navigate('/events');
    } else if (deleteMutationStatus.isError) {
      enqueueSnackbar('There was a problem deleting the event.', {
        variant: 'error',
      });
    }
  }, [deleteMutationStatus, navigate, enqueueSnackbar]);

  /// //////////
  // Manage form state
  const [event, setEvent] = React.useState<EditEvent>();
  const [location, setLocation] = React.useState<EventLocation | null>(null);
  const getEvent = useGetEventQuery(id! || duplicateFromEventId!, {
    skip: mode === 'create' && !duplicateFromEventId,
  });
  const getRoles = useGetEventRoleTypesQuery(
    {
      projectId: event?.projectId!,
    },
    { skip: !event?.projectId }
  );
  const getPersons = useGetPersonsQuery(
    {
      projectId: event?.projectId!,
    },
    { skip: !event?.projectId }
  );
  const getProject = useGetProjectQuery(event?.projectId!, {
    skip: !event?.projectId,
  });
  const requestArgsForSingleEvent = {
    limit: 1,
    offset: 0,
  };
  const getEventSummaries = useGetEventSummariesQuery(
    requestArgsForSingleEvent
  );
  const eventSummariesExist =
    !!getEventSummaries?.data?.totalCount &&
    getEventSummaries?.data?.totalCount > 0;

  const stepMode = searchParams.get('stepMode') != null || !eventSummariesExist;

  /// //////////
  // Fetch payment types
  const getPaymentTypes = useGetPaymentTypesQuery(
    {
      projectId: event?.projectId!,
    },
    { skip: !event?.projectId }
  );

  const getProjectEntitlements = useGetProjectEntitlementsQuery(
    event?.projectId!,
    { skip: !event?.projectId }
  );
  const futureEventsLimit = getProjectEntitlements.data?.futureEventCreateLimit
    ? Number(getProjectEntitlements.data?.futureEventCreateLimit)
    : null;
  const getFutureEvents = useGetEventSummariesQuery(
    {
      projectIds: event?.projectId!,
      from: formatDateForAPI(moment()),
      offset: 0,
      limit: 0,
    },
    { skip: !event?.projectId }
  );

  /// //////////
  // Bandsintown integration
  const getBandsintownEvent = useGetEventBandsintownQuery(id!, {
    skip: mode === 'create',
  });
  const [pushToBandsintown, setPushToBandsintown] = useState<boolean | null>(
    null
  );
  const [bandsintownEventId, setBandsintownEventId] = useState<number | null>(
    null
  );
  const getBandsintownConfiguration = useGetBandsintownConfigurationQuery(
    event?.projectId!,
    { skip: !event?.projectId }
  );
  const [upsertEventBandsintown] = useUpsertEventBandsintownMutation();
  const [removeEventBandsintown] = useRemoveEventBandsintownMutation();
  const removeFromBandsintown = async () => {
    try {
      await removeEventBandsintown(id!).unwrap();
    } catch (error) {
      enqueueSnackbar(
        'Error removing event from Bandsintown. Please try again, or contact us for help.',
        { variant: 'error' }
      );
    }
  };
  useEffect(() => {
    if (getBandsintownEvent.isSuccess) {
      setPushToBandsintown(true);
    } else {
      setPushToBandsintown(false);
    }
    setBandsintownEventId(null);
  }, [event?.projectId, event?.startTime]);

  // Optional timestamps
  const [showLoadIn, setShowLoadIn] = useState<boolean>(false);
  const [showSoundCheck, setShowSoundCheck] = useState<boolean>(false);
  const [showLoadOut, setShowLoadOut] = useState<boolean>(false);

  /// //////////
  // Disable form submission if required params are missing
  const REQUIRED_FIELD_NAMES = {
    PROJECT: 'project',
    START_DATE: 'startTime',
  };
  const requiredFields = [
    {
      name: REQUIRED_FIELD_NAMES.PROJECT,
      value: event?.projectId,
      tab: 'info',
      ref: useRef<HTMLInputElement>(null),
    },
    {
      name: REQUIRED_FIELD_NAMES.START_DATE,
      value: event?.startTime,
      tab: 'info',
      ref: useRef<HTMLLabelElement>(null),
    },
  ];
  const invalidRequiredParams = requiredFields.filter((field) => !field.value);
  const invalidParamsCount = invalidRequiredParams.length;
  const futureEventCreationDisabled =
    !!futureEventsLimit &&
    futureEventsLimit <= getFutureEvents.data?.totalCount!;
  const submitIsDisabled = !!invalidParamsCount;
  const [originalDataObject, setOriginalDataObject] = useState<any>(null);

  const createNewEvent = () =>
    ({
      id: null,
      projectId: null,
      startTime: null,
      endTime: null,
      arrivalTime: null,
      arrivalNotes: null,
      departureTime: null,
      departureNotes: null,
      soundCheckTime: null,
      soundCheckNotes: null,
      generalNotes: null,
      locationContactName: null,
      locationContactEmail: null,
      locationContactPhone: null,
      sensitiveInformation: {
        transactions: [],
        sendEmail: true,
        sendICS: true,
        privateNotes: null,
      },
      eventPersons: [],
      links: [],
      labels: [],
      status: 'Confirmed' as EventStatus,
      files: [],
    } as EditEvent);

  /// //////////
  // Create state for new event
  useEffect(() => {
    if (!id && !duplicateFromEventId) {
      const newEvent = createNewEvent();
      const newLocation = {
        name: null,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        streetAddress: null,
        city: null,
        state: null,
        zipCode: null,
        googlePlacesId: null,
      };
      setEvent(newEvent);
      setLocation(newLocation);
      setOriginalDataObject({ event: newEvent, location: newLocation });
    }
  }, [id, duplicateFromEventId, setEvent, setLocation]);

  /// //////////
  // Create state for existing event
  useEffect(() => {
    // NOTE: Don't rebuild state after a failed update; Let user correct errors
    if (getEvent.data && !updateMutationStatus.isError) {
      const fetchedEvent = ToEditEvent(getEvent.data)!;
      const fetchedLocation = {
        name: getEvent.data.locationName,
        timeZone: getEvent.data.locationTimeZone,
        streetAddress: getEvent.data.locationStreetAddress,
        city: getEvent.data.locationCity,
        state: getEvent.data.locationState,
        zipCode: getEvent.data.locationZipCode,
        googlePlacesId: getEvent.data.locationGooglePlacesId!,
      };
      if (duplicateFromEventId) {
        setEvent({ ...fetchedEvent, id: null });
        setLocation(fetchedLocation);
      } else {
        setEvent(fetchedEvent);
        setLocation(fetchedLocation);
        setOriginalDataObject({
          event: fetchedEvent,
          location: fetchedLocation,
        });
      }
      setShowLoadIn(!!getEvent.data.arrivalTime);
      setShowSoundCheck(!!getEvent.data.soundCheckTime);
      setShowLoadOut(!!getEvent.data.departureTime);
    }
  }, [getEvent, setEvent, setLocation]);

  /// //////////
  // Prompt unsaved changes before navigation
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  usePrompt(
    'There are unsaved changes to the event. Navigating away from this page will lose those changes.',
    formIsDirty,
    pathname,
    tab
  );

  useEffect(() => {
    if (
      originalDataObject &&
      !deepEquals(originalDataObject, { event, location })
    ) {
      setFormIsDirty(true);
    } else {
      setFormIsDirty(false);
    }
  }, [event]);

  /// //////////
  // Render error state
  if (getEvent.isError) {
    return (
      <ErrorPage
        error={getEvent.error}
        entity="event"
        action={() => navigate('/events')}
        actionText="Go to events"
      />
    );
  }

  /// //////////
  // Render loading state
  if (
    !event ||
    getRoles.isLoading ||
    getPersons.isLoading ||
    getProject.isLoading ||
    getPaymentTypes.isLoading ||
    getEventSummaries.isLoading ||
    createMutationStatus.isLoading ||
    createMutationStatus.isSuccess ||
    updateMutationStatus.isLoading ||
    updateMutationStatus.isSuccess ||
    deleteMutationStatus.isLoading ||
    deleteMutationStatus.isSuccess ||
    getProjectEntitlements.isLoading ||
    getFutureEvents.isLoading
  ) {
    return <Loading />;
  }

  /// //////////
  // State specific copy
  const heading =
    mode === 'create'
      ? 'Create Event'
      : `Edit Event for ${getProject.data?.name}`;
  setTitle(heading);
  const saveButtonText = mode === 'create' ? 'Create' : 'Save';

  /// //////////
  // Function to save form
  async function saveChanges() {
    await setFormIsDirty(false);

    const body = {
      projectId: event!.projectId!,
      locationName: location?.name!,
      locationTimeZone:
        location?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
      locationStreetAddress: location?.streetAddress!,
      locationCity: location?.city!,
      locationState: location?.state!,
      locationZipCode: location?.zipCode!,
      locationGooglePlacesId: location?.googlePlacesId!,
      locationContactName: event!.locationContactName,
      locationContactEmail: event!.locationContactEmail,
      locationContactPhone: event!.locationContactPhone,
      startTime: moment.tz(event!.startTime, location?.timeZone!).toISOString(),
      endTime: moment.tz(event!.endTime, location?.timeZone!).toISOString(),
      arrivalTime: moment
        .tz(event!.arrivalTime, location?.timeZone!)
        .toISOString(),
      arrivalNotes: event!.arrivalNotes,
      departureTime: moment
        .tz(event!.departureTime, location?.timeZone!)
        .toISOString(),
      departureNotes: event!.departureNotes,
      soundCheckTime: moment
        .tz(event!.soundCheckTime, location?.timeZone!)
        .toISOString(),
      soundCheckNotes: event!.soundCheckNotes,
      generalNotes: event!.generalNotes,
      sensitiveInformation: {
        transactions: event!.sensitiveInformation.transactions,
        sendEmail: event!.sensitiveInformation.sendEmail,
        sendICS: event!.sensitiveInformation.sendICS,
        privateNotes: event!.sensitiveInformation.privateNotes,
      },
      eventPersons: event!.eventPersons,
      links: event!.links,
      labels: event!.labels,
      status: event!.status,
      privateNotes: event!.sensitiveInformation.privateNotes,
      files: event!.files,
    };
    let saveEventId: string | null = null;
    let saveSuccess = false;
    try {
      if (mode === 'create') {
        saveEventId = await createMutation({
          body,
        }).unwrap();
      } else {
        await updateMutation({
          id: event!.id!,
          body,
        });
        saveEventId = event!.id;
      }
      saveSuccess = true;
    } catch {
      // this will get caught elsewhere
    }
    if (saveSuccess) {
      try {
        if (pushToBandsintown) {
          await upsertEventBandsintown({
            eventId: saveEventId!,
            bandsintownEventId,
          }).unwrap();
        }
      } catch (e) {
        enqueueSnackbar('Error saving to Bandsintown', { variant: 'error' });
      }
    }
  }

  /// //////////
  // Functions to build helper text for date and time pickers
  const getDurationInHoursAndMinutes = (
    start: string | null,
    end: string | null
  ) => {
    const startTime = moment(start);
    const endTime = moment(end);
    const duration = moment.duration(endTime.diff(startTime));
    const hours = Math.floor(Math.abs(duration.asHours()));
    const minutes = Math.abs(duration.asMinutes()) % 60;

    const hoursString = hours ? `${hours} hour${hours !== 1 ? 's' : ''}` : '';
    const minutesString = minutes
      ? `${minutes} minute${minutes !== 1 ? 's' : ''}`
      : '';
    const connectorString = hoursString && minutesString ? ' ' : '';
    const durationPhrase = `${hoursString}${connectorString}${minutesString}`;

    return durationPhrase;
  };

  const getDuration = (
    startLabel: string,
    start: string | null,
    endLabel: string,
    end: string | null
  ) => {
    const durationPhrase = getDurationInHoursAndMinutes(start, end);
    const beforeOrAfterPhrase = moment(start).isSameOrBefore(end)
      ? 'before'
      : 'after';
    const relativePhrase = durationPhrase ? beforeOrAfterPhrase : 'at';

    return (
      <span>
        {`${startLabel} begins`}
        <strong>{` ${durationPhrase} ${relativePhrase}`}</strong>
        {` ${endLabel}`}
      </span>
    );
  };

  const getShowLength = (start: string | null, end: string | null) => {
    const durationPhrase = getDurationInHoursAndMinutes(start, end);

    if (durationPhrase) {
      return (
        <span>
          {`Event length is `}
          <strong>{`${durationPhrase}`}</strong>
        </span>
      );
    }

    return <span>Event has no length</span>;
  };

  /// //////////
  // Define Next Tab and Last Tab for navigation and conditional button rendering
  const lastTabValue = EVENT_TABS[EVENT_TABS.length - 1]?.value;
  const nextTabValue =
    EVENT_TABS[
      EVENT_TABS.indexOf(
        EVENT_TABS.find((eventTab) => eventTab.value === tab)!
      ) + 1
    ]?.value;

  const getMainEventTimeFields = (isRequired: boolean = false) => {
    const isRequiredRef = requiredFields.find(
      (field) => field.name === REQUIRED_FIELD_NAMES.START_DATE
    )?.ref;

    type isRequiredPropsType = {
      innerRef?: typeof isRequiredRef;
      dateRequired?: boolean;
    };

    const isRequiredProps: isRequiredPropsType = {};

    if (isRequired) {
      isRequiredProps.innerRef = isRequiredRef;
      isRequiredProps.dateRequired = true;
    }

    return (
      <>
        <div className="edit-field gr-edit-event-start-time">
          <Subheading className="gr-edit-event-start-time-subheading">
            Start Time
          </Subheading>
          <EditDatePicker
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...isRequiredProps}
            dateLabel="Date"
            timeLabel="Time"
            value={event.startTime!}
            onChange={(time) => {
              /// //////////
              // Set defaults

              // If the date is being set for the first time...
              // ...1) Set the new start time as 6pm
              const newStartTime = event.startTime
                ? time
                : moment(time).add(18, 'hours');

              // ...2) Set the default end time as 8pm
              const defaultEndTime =
                !event.startTime && moment(newStartTime).add(2, 'hours');

              /// //////////
              // Calculate new timestamps based on start time

              const getNewEventTime = (
                newRelativeTime: moment.Moment | null, // New start time or end time
                oldEventTime: moment.Moment | string | null, // Event time to recalculate
                oldRelativeTime: string | null = event.startTime // Old start time or end time
              ) => {
                // Calculate the duration that existed between two events before this update
                const duration = moment.duration(
                  moment(oldEventTime).diff(moment(oldRelativeTime))
                );

                // Calculate the duration that existed between two events before this update
                return newRelativeTime && oldEventTime
                  ? moment(newRelativeTime)
                      .add(duration)
                      .format('YYYY-MM-DDTHH:mm:ss')
                  : null;
              };

              // Apply the updates
              if (newStartTime?.isValid()) {
                const newEndTime = defaultEndTime
                  ? defaultEndTime?.format('YYYY-MM-DDTHH:mm:ss')
                  : getNewEventTime(newStartTime, event.endTime);

                setEvent({
                  ...event,
                  startTime: newStartTime.format('YYYY-MM-DDTHH:mm:ss'),
                  endTime: newEndTime,
                  arrivalTime: getNewEventTime(newStartTime, event.arrivalTime),
                  soundCheckTime: getNewEventTime(
                    newStartTime,
                    event.soundCheckTime
                  ),
                  departureTime: getNewEventTime(
                    newEndTime ? moment(newEndTime) : null,
                    event.departureTime,
                    event.endTime
                  ),
                });
              }
            }}
          />
        </div>
        <div className="edit-field gr-edit-event-end-time">
          <Subheading className="gr-edit-event-end-time-subheading">
            End Time
          </Subheading>
          <EditDatePicker
            dateLabel="Date"
            timeLabel="Time"
            value={event.endTime}
            dateDisabled={!event.startTime}
            minDateTime={event.startTime}
            helperText={
              (event.endTime &&
                getShowLength(event.endTime, event.startTime)) ||
              undefined
            }
            onChange={(newEndTime) => {
              if (newEndTime?.isValid()) {
                // Calculate the duration that existed between show end and departure before this update
                const durationFromShowEndToDeparture = moment.duration(
                  moment(event.departureTime).diff(moment(event.endTime))
                );

                // Apply the updates
                setEvent({
                  ...event,
                  endTime: moment(newEndTime).format('YYYY-MM-DDTHH:mm:ss'),
                  departureTime: event.departureTime
                    ? moment(newEndTime)
                        .add(durationFromShowEndToDeparture)
                        .format('YYYY-MM-DDTHH:mm:ss')
                    : null,
                });
              }
            }}
          />
        </div>
      </>
    );
  };

  /// //////////
  // Return singular Tab content
  const getTabContent = () => {
    if (tab === EVENT_TAB_VALUES.INFO) {
      return (
        <div style={{ marginTop: '4px' }}>
          {mode === 'create' && (
            <div className="gr-edit-event-project edit-field">
              <ProjectSelect
                innerRef={
                  requiredFields.find(
                    (field) => field.name === REQUIRED_FIELD_NAMES.PROJECT
                  )?.ref
                }
                id={event.projectId || ''}
                onChange={(projectId) =>
                  setEvent({
                    ...event,
                    projectId,
                    eventPersons: [],
                    sensitiveInformation: {
                      ...event.sensitiveInformation,
                      transactions: event.sensitiveInformation.transactions
                        .filter((t) => t.eventPersonId == null)
                        .map(
                          (t) => ({ ...t, paymentTypeId: null } as Transaction)
                        ),
                    },
                  })
                }
              />
            </div>
          )}
          <div className="gr-edit-event-status-field edit-field">
            <EventStatusSelect
              value={event.status}
              onChange={(newValue) => {
                setEvent({
                  ...event,
                  status: newValue,
                });
              }}
              draftAllowed={
                mode === 'create' || getEvent.data?.status === 'Draft'
              }
            />
          </div>
          {getMainEventTimeFields(true)}
          <Subheading className="gr-edit-event-location-subheading">
            Location
          </Subheading>
          <div className="gr-edit-event-location edit-field">
            <EventLocationSelect
              value={location}
              onChange={(l) => setLocation(l)}
            />
          </div>
          <Subheading className="gr-edit-event-contact-subheading">
            Contact
          </Subheading>
          <div className="gr-edit-event-contact-name edit-field">
            <TextField
              label="Name"
              fullWidth
              value={event.locationContactName}
              onChange={(e) => {
                setEvent({
                  ...event,
                  locationContactName: e.target.value,
                });
              }}
            />
          </div>
          <div className="gr-edit-event-contact-email edit-field">
            <TextField
              label="Email"
              fullWidth
              value={event.locationContactEmail}
              onChange={(e) => {
                setEvent({
                  ...event,
                  locationContactEmail: e.target.value,
                });
              }}
            />
          </div>
          <div className="gr-edit-event-contact-phone edit-field">
            <EditPhoneNumber
              label="Phone"
              value={event.locationContactPhone}
              onChange={(newValue) => {
                setEvent({
                  ...event,
                  locationContactPhone: newValue,
                });
              }}
            />
          </div>
          <Subheading className="gr-edit-event-notes-subheading">
            Notes
          </Subheading>
          <div className="gr-edit-event-general-notes edit-field edit-field-inline">
            <TextField
              label="General Notes"
              fullWidth
              multiline
              minRows={2}
              value={event.generalNotes || ''}
              onChange={(e) => {
                setEvent({
                  ...event,
                  generalNotes: e.target.value,
                });
              }}
            />
            <GreenRoomTooltip title="Everyone on your team will see these notes." />
          </div>
          <div className="gr-edit-event-private-notes edit-field edit-field-inline">
            <TextField
              label="Private Notes"
              fullWidth
              multiline
              minRows={2}
              value={event.sensitiveInformation.privateNotes || ''}
              onChange={(e) => {
                setEvent({
                  ...event,
                  sensitiveInformation: {
                    ...event.sensitiveInformation,
                    privateNotes: e.target.value,
                  },
                });
              }}
            />
            <GreenRoomTooltip title="Only you and other admins of this project will see these notes." />
          </div>
          <div className="gr-edit-event-labels">
            <EditLabels
              projectId={event.projectId}
              selectedLabelIds={event.labels || []}
              onChange={(labelIds: string[]) => {
                setEvent({
                  ...event,
                  labels: labelIds,
                });
              }}
            />
          </div>
          {!getBandsintownEvent.isFetching &&
            !getProjectEntitlements.isFetching &&
            !getBandsintownConfiguration.isFetching &&
            event.projectId && (
              <EditEventBandsintown
                projectId={event.projectId}
                bandsintownEntitlementEnabled={
                  !!getProjectEntitlements.data?.bandsintown
                }
                bandsintownConfigured={
                  !!getBandsintownConfiguration.data?.enabled
                }
                date={event.startTime}
                pushToBandsintown={getBandsintownEvent.isSuccess}
                currentPushToBandsintown={pushToBandsintown || false}
                currentPushToBandsintownChange={(value) =>
                  setPushToBandsintown(value)
                }
                currentBandsintownEventId={bandsintownEventId}
                currentBandsintownEventIdChange={(value) =>
                  setBandsintownEventId(value)
                }
                deleteEventfromBandsintown={() => {
                  Swal.fire({
                    title: 'Remove from Bandsintown?',
                    text: 'Are you sure you want to remove this event from Bandsintown? This cannot be reversed.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, remove it!',
                    cancelButtonText: 'No, keep it',
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await removeFromBandsintown();
                    }
                  });
                }}
              />
            )}
        </div>
      );
    }
    if (tab === EVENT_TAB_VALUES.TIMES) {
      return (
        <div className="edit-event-tab-times">
          {getMainEventTimeFields()}
          {(showLoadIn && (
            <>
              <Subheading className="gr-edit-event-load-in-subheading">
                Load-In
              </Subheading>
              <GreenRoomStack className="edit-field-section-card">
                <Card>
                  <CardContent className="edit-field-section-card-content">
                    <div className="edit-field-group">
                      <div className="edit-field gr-edit-event-load-in">
                        <EditDatePicker
                          dateLabel="Date"
                          timeLabel="Time"
                          value={event.arrivalTime}
                          maxDateTime={event.startTime}
                          helperText={getDuration(
                            'Load-In',
                            event.arrivalTime,
                            'Start Time',
                            event.startTime
                          )}
                          onChange={(time) => {
                            if (time?.isValid()) {
                              setEvent({
                                ...event,
                                arrivalTime: time?.format(
                                  'YYYY-MM-DDTHH:mm:ss'
                                ),
                              });
                            }
                          }}
                        />
                      </div>

                      <div className="edit-field gr-edit-event-load-in-notes">
                        <TextField
                          label="Notes"
                          fullWidth
                          multiline
                          minRows={2}
                          value={event.arrivalNotes}
                          onChange={(e) => {
                            setEvent({
                              ...event,
                              arrivalNotes: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="edit-field-section-close-button-wrapper">
                      <IconButton
                        onClick={() => {
                          setShowLoadIn(!showLoadIn);
                          setEvent({
                            ...event,
                            arrivalTime: null,
                            arrivalNotes: null,
                          });
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </CardContent>
                </Card>
              </GreenRoomStack>
            </>
          )) || (
            <div className="edit-field-group-toggle-button-wrapper">
              <GreenRoomButton
                type="add"
                disabled={!event.startTime}
                onClick={() => {
                  // Set arrival time to 2 hours before start time
                  setEvent({
                    ...event,
                    arrivalTime: moment(event.startTime)
                      .subtract(2, 'hours')
                      .format('YYYY-MM-DDTHH:mm:ss'),
                  });

                  // Render arrival time card
                  setShowLoadIn(!showLoadIn);
                }}
              >
                Add Load-In
              </GreenRoomButton>
            </div>
          )}
          {(showSoundCheck && (
            <>
              <Subheading className="gr-edit-event-sound-check-subheading">
                Sound Check
              </Subheading>
              <GreenRoomStack className="edit-field-section-card">
                <Card>
                  <CardContent className="edit-field-section-card-content">
                    <div className="edit-field-group">
                      <div className="edit-field gr-edit-event-sound-check">
                        <EditDatePicker
                          dateLabel="Date"
                          timeLabel="Time"
                          value={event.soundCheckTime}
                          maxDateTime={event.startTime}
                          helperText={getDuration(
                            'Sound Check',
                            event.soundCheckTime,
                            'Start Time',
                            event.startTime
                          )}
                          onChange={(time) => {
                            if (time?.isValid()) {
                              setEvent({
                                ...event,
                                soundCheckTime: time?.format(
                                  'YYYY-MM-DDTHH:mm:ss'
                                ),
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="edit-field gr-edit-event-sound-check-notes">
                        <TextField
                          label="Notes"
                          fullWidth
                          multiline
                          minRows={2}
                          value={event.soundCheckNotes}
                          onChange={(e) => {
                            setEvent({
                              ...event,
                              soundCheckNotes: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="edit-field-section-close-button-wrapper">
                      <IconButton
                        onClick={() => {
                          setShowSoundCheck(!showSoundCheck);
                          setEvent({
                            ...event,
                            soundCheckTime: null,
                            soundCheckNotes: null,
                          });
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </CardContent>
                </Card>
              </GreenRoomStack>
            </>
          )) || (
            <div className="edit-field-group-toggle-button-wrapper">
              <GreenRoomButton
                type="add"
                disabled={!event.startTime}
                onClick={() => {
                  // Set sound check time to 1 hour before start time
                  setEvent({
                    ...event,
                    soundCheckTime: moment(event.startTime)
                      .subtract(1, 'hours')
                      .format('YYYY-MM-DDTHH:mm:ss'),
                  });

                  // Render departure time card
                  setShowSoundCheck(!showSoundCheck);
                }}
              >
                Add Sound check
              </GreenRoomButton>
            </div>
          )}
          {(showLoadOut && (
            <>
              <Subheading className="gr-edit-event-load-out-subheading">
                Load-Out
              </Subheading>
              <GreenRoomStack className="edit-field-section-card">
                <Card>
                  <CardContent className="edit-field-section-card-content">
                    <div className="edit-field-group">
                      <div className="edit-field gr-edit-event-load-out">
                        <EditDatePicker
                          dateLabel="Date"
                          timeLabel="Time"
                          value={event.departureTime}
                          minDateTime={event.endTime}
                          helperText={getDuration(
                            'Load-Out',
                            event.departureTime,
                            'End Time',
                            event.endTime
                          )}
                          onChange={(time) => {
                            if (time?.isValid()) {
                              setEvent({
                                ...event,
                                departureTime: time?.format(
                                  'YYYY-MM-DDTHH:mm:ss'
                                ),
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="edit-field gr-edit-event-load-out-notes">
                        <TextField
                          label="Notes"
                          fullWidth
                          multiline
                          minRows={2}
                          value={event.departureNotes}
                          onChange={(e) => {
                            setEvent({
                              ...event,
                              departureNotes: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="edit-field-section-close-button-wrapper">
                      <IconButton
                        onClick={() => {
                          setShowLoadOut(!showLoadOut);
                          setEvent({
                            ...event,
                            departureTime: null,
                            departureNotes: null,
                          });
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </CardContent>
                </Card>
              </GreenRoomStack>
            </>
          )) || (
            <div className="edit-field-group-toggle-button-wrapper">
              <GreenRoomButton
                type="add"
                disabled={!event.startTime}
                onClick={() => {
                  // Set departure time to 1 hour after end time
                  setEvent({
                    ...event,
                    departureTime: moment(event.endTime)
                      .add(1, 'hours')
                      .format('YYYY-MM-DDTHH:mm:ss'),
                  });

                  // Render departure time card
                  setShowLoadOut(!showLoadOut);
                }}
              >
                Add Load-Out
              </GreenRoomButton>
            </div>
          )}
        </div>
      );
    }
    if (tab === EVENT_TAB_VALUES.ROLES && event) {
      return (
        <>
          <div className="edit-field edit-field-inline gr-edit-event-admin-email-notifications">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={event.sensitiveInformation.sendEmail}
                    value={event.sensitiveInformation.sendEmail}
                    onChange={(e) =>
                      setEvent({
                        ...event,
                        sensitiveInformation: {
                          ...event.sensitiveInformation,
                          sendEmail: e.target.checked,
                        },
                      })
                    }
                  />
                }
                label={<>Send email updates to project admins</>}
              />
            </FormGroup>
            <GreenRoomTooltip title="This will send email notifications with calendar invitations to yourself and any other admins or owners of this project." />
          </div>
          <div className="edit-field">
            <EventRoleCards
              event={event}
              roles={getRoles.data}
              persons={getPersons.data}
              paymentTypes={getPaymentTypes.data}
              onCreateOrUpdate={setEvent}
              showSensitiveInformation
            />
          </div>
        </>
      );
    }
    if (tab === EVENT_TAB_VALUES.FINANCES && event) {
      return (
        <>
          <Alert severity="info" sx={{ margin: '8px' }}>
            Only you and other project admins can see finances related to this
            event.
          </Alert>
          <EventTransactionCards
            event={event}
            roles={getRoles.data}
            persons={getPersons.data}
            paymentTypes={getPaymentTypes.data}
            onCreateOrUpdate={setEvent}
          />
        </>
      );
    }
    if (tab === EVENT_TAB_VALUES.LINKS) {
      return <EditEventPageLinksTab editEvent={event} onChange={setEvent} />;
    }
    if (tab === EVENT_TAB_VALUES.FILES) {
      return <EditEventPageFilesTab editEvent={event} onChange={setEvent} />;
    }
    return null;
  };

  /// //////////
  // Get tab actions
  const getTabActions = () => {
    if (mode === 'create' && futureEventCreationDisabled) {
      return (
        <PremiumFeatureChip
          projectId={event.projectId!}
          size="small"
          label="Event limit reached. Upgrade to create more."
        />
      );
    }
    return (
      <>
        {/* Render 'Next' button if we're in step mode and not on last tab */}
        {(stepMode && tab !== lastTabValue && (
          <GreenRoomButton
            type="accept"
            onClick={() => {
              searchParams.set('tab', nextTabValue!);
              setSearchParams(searchParams);
            }}
            className="gr-edit-event-next-button"
          >
            Next
          </GreenRoomButton>
        )) || (
          <GreenRoomButton
            type="accept"
            onClick={async () => {
              await saveChanges();
            }}
            disabled={submitIsDisabled}
            className="gr-edit-event-save-button"
          >
            {saveButtonText}
          </GreenRoomButton>
        )}
        <GreenRoomButton
          type="cancel"
          onClick={() => {
            navigate(`./../?tab=${tab}`);
          }}
          className="gr-edit-event-cancel-button"
        >
          Cancel
        </GreenRoomButton>
        {mode === 'create' && !submitIsDisabled && (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={createMore}
                  checked={createMore}
                  onChange={(_, checked) => setCreateMore(checked)}
                />
              }
              label="Create more"
            />
          </FormGroup>
        )}
        {mode === 'edit' && (
          <GreenRoomButton
            type="destroy"
            onClick={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it',
              }).then(async (result) => {
                if (result.isConfirmed) {
                  if (getBandsintownEvent.isSuccess) {
                    await removeFromBandsintown();
                  }
                  deleteMutation({
                    id: event.id!,
                  });
                }
              });
            }}
            className="gr-edit-event-delete-button"
          >
            Delete
          </GreenRoomButton>
        )}
        {submitIsDisabled && (
          <Button
            variant="text"
            size="small"
            sx={{ textTransform: 'unset' }}
            onClick={async () => {
              const relevantTab = invalidRequiredParams[0].tab;

              await navigate({
                pathname: '.',
                search: `?tab=${relevantTab}`,
              });

              const visibleInvalidRequiredParams = invalidRequiredParams.filter(
                (field) => field.tab === relevantTab
              );

              if (visibleInvalidRequiredParams[0].ref) {
                visibleInvalidRequiredParams[0].ref?.current?.focus();
              }
            }}
          >
            {invalidParamsCount} required field{invalidParamsCount > 1 && 's'}{' '}
            missing
          </Button>
        )}
      </>
    );
  };

  /// //////////
  // Render main content
  return (
    <>
      <Heading>{heading}</Heading>
      <EventTabs
        showSensitiveInformation
        filesEnabled={!!getFeatureFlags.currentData?.filesEnabled}
      />
      <TabContent actions={getTabActions()}>{getTabContent()}</TabContent>
    </>
  );
}

export default EditEventPage;
