import { Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Blankslate from '../../components/Blankslate';
import { ReactComponent as Cards } from '../../img/placeholders/cards.svg';
import { ReactComponent as Visa } from '../../img/creditcards/visa.svg';
import Loading from '../../components/Loading';
import GreenRoomButton from '../../components/GreenRoomButton';
import {
  Card,
  useGetCardsQuery,
  useGetProjectFinancialAccountQuery,
} from '../../app/api/project-financial-account-slice';
import ErrorPage from '../../components/ErrorPage';
import Heading from '../../components/Text/Heading';
import GreenRoomStack from '../../components/GreenRoomStack';
import GreenRoomCard from '../../components/GreenRoomCard';
import './FinancesCards.scss';
import CreateCardDialog from './CreateCardDialog';
import ViewCardDialog from './ViewCardDialog';

export interface FinancesCardsProps {
  projectId: string;
}

function FinancesCards({ projectId }: FinancesCardsProps) {
  const theme = useTheme();

  const [createCardDialogOpen, setCreateCardDialogOpen] = useState(false);

  const getProjectFinancialAccountQuery =
    useGetProjectFinancialAccountQuery(projectId);

  const getActiveCards = useGetCardsQuery({ projectId, status: 'active' });
  const getInactiveCards = useGetCardsQuery({ projectId, status: 'inactive' });

  const [viewCard, setViewCard] = useState<Card | null>(null);

  const blankSlateDescription =
    'Green Room Business Expense Cards allow you to spend your Financial Account balance on music-related expenses.';

  if (
    getActiveCards.isLoading ||
    getInactiveCards.isLoading ||
    getProjectFinancialAccountQuery.isLoading
  )
    return <Loading />;

  if (
    getProjectFinancialAccountQuery.isError ||
    !getProjectFinancialAccountQuery.data?.enabled
  ) {
    const error = getProjectFinancialAccountQuery.error as FetchBaseQueryError;
    if (
      error?.status === 404 ||
      !getProjectFinancialAccountQuery.data?.enabled
    ) {
      return (
        <Blankslate
          title="No Business Expense Cards"
          actions={
            <GreenRoomButton
              type="accept"
              to={`/finances?project=${projectId}&tab=account`}
            >
              Go There
            </GreenRoomButton>
          }
          svg={<Cards />}
        >
          {blankSlateDescription}
          <b> Before creating a card, create a financial account.</b>
        </Blankslate>
      );
    }
    return <ErrorPage error={getProjectFinancialAccountQuery.error} />;
  }

  if (getActiveCards.isError || getInactiveCards.isError) {
    return <ErrorPage error={getActiveCards.error || getInactiveCards.error} />;
  }

  let content;
  if (
    getActiveCards.data?.cards.length ||
    getInactiveCards.data?.cards.length
  ) {
    let cards: Card[] = [];
    cards = cards.concat(getActiveCards.data?.cards || []);
    cards = cards.concat(getInactiveCards.data?.cards || []);

    content = (
      <>
        <Heading>Cards</Heading>
        <GreenRoomStack>
          {cards.map((card) => (
            <GreenRoomCard
              key={card.id}
              left={
                <Visa
                  height={64}
                  width={64}
                  fill={
                    card.status === 'active'
                      ? theme.palette.primary.main
                      : theme.palette.secondary.light
                  }
                />
              }
              title={
                <Typography variant="h6">{card.cardholder.name}</Typography>
              }
              subtitle={
                <Typography variant="subtitle1">
                  •••• •••• •••• {card.last4} ({card.type})
                </Typography>
              }
              onClick={() => setViewCard(card)}
            >
              <Typography variant="body1">
                Exp {card.expMonth}/{card.expYear - 2000}{' '}
                {card.status === 'inactive' && ' (Inactive)'}
              </Typography>
            </GreenRoomCard>
          ))}
        </GreenRoomStack>
        <div style={{ marginTop: theme.spacing(1) }}>
          <GreenRoomButton
            type="create"
            onClick={() => setCreateCardDialogOpen(true)}
            disabled={
              createCardDialogOpen ||
              (!!getActiveCards.data?.cards.filter((c) => c.type === 'physical')
                .length &&
                !!getActiveCards.data?.cards.filter((c) => c.type === 'virtual')
                  .length)
            }
          >
            Create Card
          </GreenRoomButton>
        </div>
      </>
    );
  } else {
    content = (
      <Blankslate
        title="No Business Expense Cards"
        svg={<Cards />}
        actions={
          <GreenRoomButton
            type="create"
            onClick={() => setCreateCardDialogOpen(true)}
            disabled={createCardDialogOpen}
          >
            Create Card
          </GreenRoomButton>
        }
      >
        <span>{blankSlateDescription}</span>
      </Blankslate>
    );
  }

  return (
    <>
      {content}
      <CreateCardDialog
        projectId={projectId}
        cards={getActiveCards.data?.cards!}
        open={createCardDialogOpen}
        onClose={() => setCreateCardDialogOpen(false)}
      />
      <ViewCardDialog
        projectId={projectId}
        stripeConnectAccountId={
          getProjectFinancialAccountQuery.data?.stripeConnectAccountId!
        }
        card={viewCard}
        open={!!viewCard}
        onClose={() => setViewCard(null)}
      />
    </>
  );
}

export default FinancesCards;
