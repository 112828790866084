import React from 'react';
import './Text.scss';

interface HeadingProps {
  children?: React.ReactNode | null;
  className?: string | null;
}

function Heading({ children, className }: HeadingProps) {
  return <h2 className={`green-room-heading ${className}`}>{children}</h2>;
}

Heading.defaultProps = {
  children: null,
  className: '',
};

export default Heading;
