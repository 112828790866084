import React from 'react';
import Blankslate from '../Blankslate';
import { ReactComponent as File } from '../../img/placeholders/file.svg';

interface NoEventFilesBlankslateProps {
  actions?: React.ReactNode | null;
}

function NoEventFilesBlankSlate({ actions }: NoEventFilesBlankslateProps) {
  return (
    <Blankslate title="No shared files" actions={actions} svg={<File />}>
      A place to share files for sheet music, set lists, song collections, or
      anything else that will help the team perform.
    </Blankslate>
  );
}

NoEventFilesBlankSlate.defaultProps = {
  actions: null,
};

export default NoEventFilesBlankSlate;
