import { Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import CreateEditPaymentTypeDialog from '../pages/CreateEditPaymentTypeDialog';
import {
  CreateEditPaymentType,
  useGetPaymentTypesQuery,
} from '../app/api/payment-types-api-slice';
import TabContent from './Tabs/TabContent';
import GreenRoomButton from './GreenRoomButton';
import GreenRoomStack from './GreenRoomStack';
import GreenRoomCard from './GreenRoomCard';
import { ReactComponent as Payments } from '../img/placeholders/payments.svg';
import Loading from './Loading';
import Blankslate from './Blankslate';

interface ProjectPaymentTypesProps {
  projectId: string;
}

function ProjectPaymentTypes({ projectId }: ProjectPaymentTypesProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getPaymentTypes = useGetPaymentTypesQuery({ projectId });
  const newPaymentType = {
    id: null,
    projectId,
    name: '',
  } as CreateEditPaymentType;
  const [dialogProps, setDialogProps] = useState({
    open: false,
    paymentType: newPaymentType,
  });

  /// //////////
  // Blankslate constants
  const description =
    'Payment methods are useful for categorizing finances. These methods can be used to filter and search for transactions throughout the app.';
  const action = (
    <GreenRoomButton
      type="add"
      onClick={() =>
        setDialogProps({ open: true, paymentType: newPaymentType })
      }
    >
      Add Payment Method
    </GreenRoomButton>
  );

  /// //////////
  // Render loading state
  const loading = getPaymentTypes.isLoading;
  if (loading) return <Loading />;

  /// //////////
  // Render main content
  return (
    <TabContent>
      {(getPaymentTypes.data && getPaymentTypes.data?.length > 0 && (
        <Stack spacing={2}>
          <div>{description}</div>
          <GreenRoomStack>
            {getPaymentTypes.data?.map((paymentType) => (
              <GreenRoomCard
                key={paymentType.id}
                title={paymentType.name}
                onClick={() => {
                  const editPaymentType = {
                    id: paymentType.id,
                    name: paymentType.name,
                    projectId,
                  };
                  setDialogProps({
                    open: true,
                    paymentType: editPaymentType,
                  });
                }}
              />
            ))}
          </GreenRoomStack>
          {action}
        </Stack>
      )) || (
        <Blankslate
          title="No payment methods"
          actions={action}
          svg={<Payments />}
        >
          {description}
        </Blankslate>
      )}
      <CreateEditPaymentTypeDialog
        paymentType={dialogProps.paymentType}
        fullScreen={fullScreen}
        open={dialogProps.open}
        onClose={() => {
          setDialogProps({ ...dialogProps, open: false });
        }}
      />
    </TabContent>
  );
}

export default ProjectPaymentTypes;
