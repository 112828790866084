import React from 'react';
import GreenRoomCard from '../components/GreenRoomCard';
import GreenRoomStack from '../components/GreenRoomStack';
import TabContent from '../components/Tabs/TabContent';
import BandsintownSettings from './BandsintownSettings';

interface ProjectIntegrationsTabProps {
  projectId: string;
}

function ProjectIntegrationsTab({ projectId }: ProjectIntegrationsTabProps) {
  return (
    <TabContent>
      <GreenRoomStack>
        <GreenRoomCard>
          <BandsintownSettings projectId={projectId} />
        </GreenRoomCard>
      </GreenRoomStack>
    </TabContent>
  );
}

export default ProjectIntegrationsTab;
