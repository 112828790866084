import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Drawer,
  Paper,
} from '@mui/material';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HelpIcon from '@mui/icons-material/Help';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SupportLinks from './components/SupportLinks';
import { useGetFeatureFlagsQuery } from './app/api/feature-flags-slice';

function NavbarBottom() {
  const location = useLocation();
  const [value, setValue] = React.useState('');
  const handleChange = (event: any, newValue: React.SetStateAction<string>) => {
    setValue(newValue);
  };

  const [supportDrawerOpen, setSupportDrawerOpen] = useState(false);

  const getFeatureFlags = useGetFeatureFlagsQuery();
  const showFinancesTab = getFeatureFlags.data?.financesEnabled;

  useEffect(() => {
    const locationSplitAtSlashes = location.pathname.split('/');
    setValue(locationSplitAtSlashes[1]);
  }, [location.pathname]);

  const navItemStyles = {
    textTransform: 'unset',
    color: 'secondary.contrastText',
    '&.Mui-selected': {
      backgroundColor: 'primary.dark',
      color: 'secondary.contrastText',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'primary.dark',
      color: 'secondary.contrastText',
    },
    '&.Mui-selected:focus': {
      backgroundColor: 'primary.dark',
      color: 'secondary.contrastText',
    },
  };

  const [supportBadge, setSupportBadge] = useState(0);
  useEffect(() => {
    window.Intercom('onUnreadCountChange', (unreadCount: number) => {
      setSupportBadge(unreadCount);
    });
  });

  return (
    <Box display={{ xs: 'block', sm: 'none' }}>
      <Paper
        sx={{
          bottom: 0,
          left: 0,
          right: 0,
          '& .MuiPaper-root, .MuiBottomNavigation-root, .MuiBottomNavigationAction-root, .MuiButtonBase-root-MuiBottomNavigationAction-root,':
            {
              backgroundColor: 'secondary.dark',
            },
        }}
        className="navbar"
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(e, newValue) => {
            if (newValue === 'support') {
              window.Intercom('show');
            } else {
              handleChange(e, newValue);
            }
          }}
        >
          <BottomNavigationAction
            sx={navItemStyles}
            label="Events"
            value="events"
            component={Link}
            to="/events"
            icon={<UpcomingIcon />}
            className="nav-bar-events"
          />
          {showFinancesTab && (
            <BottomNavigationAction
              sx={navItemStyles}
              label="Finances"
              value="finances"
              component={Link}
              to="/finances"
              icon={<AttachMoneyIcon />}
              className="nav-bar-finances"
            />
          )}
          <BottomNavigationAction
            sx={navItemStyles}
            label="Settings"
            value="settings"
            component={Link}
            to="/settings"
            icon={<SettingsIcon />}
            className="nav-bar-settings"
          />
          <BottomNavigationAction
            sx={navItemStyles}
            label="Support"
            value="support"
            icon={
              <Badge badgeContent={supportBadge} color="success">
                <HelpIcon />
              </Badge>
            }
            className="nav-bar-support"
          />
        </BottomNavigation>
      </Paper>
      <Drawer
        anchor="bottom"
        open={supportDrawerOpen}
        onClose={() => setSupportDrawerOpen(false)}
      >
        <SupportLinks />
      </Drawer>
    </Box>
  );
}

export default NavbarBottom;
