// This is a custom hook that fires just before navigation that gives the user a prompt if conditions are met
// Currently the following conditions need to be met for it to prompt the user:
// 1. a user attempts to navigate
// 2. the route the user wants to navigate to is more different from the current path than just search params
// 3. some other variable condition is met, for example a form is dirty

// TODO: Generalize behvaior if this hook is needed for other forms

// This custom hook is a workaround until two conditions are met:
// 1. React-router V6 natively supports usePrompt and useBlocker: https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743
// 2. usePrompt can validate a path before firing to accomodate multi-tab forms: https://github.com/remix-run/react-router/issues/7926

import { History, Transition } from 'history';
import { useCallback, useContext, useEffect } from 'react';
import { Navigator } from 'react-router';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

type ExtendNavigator = Navigator & Pick<History, 'block'>;

export function useBlocker(
  // eslint-disable-next-line no-unused-vars
  blocker: (tx: Transition) => void,
  shouldPerformCheckOnNavigate = false,
  originPathname: string | null = null,
  searchParams: string | null = null
) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!shouldPerformCheckOnNavigate) return undefined;

    const unblock = (navigator as ExtendNavigator).block((tx) => {
      const newPathName = tx.location.pathname;
      const onlySearchParamsChanged = originPathname === newPathName;

      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      if (onlySearchParamsChanged) {
        unblock();
        tx.retry();
      } else {
        blocker(autoUnblockingTx);
      }
    });

    return unblock;
    // It's important to reinitilize this useEffect when searchParams update
  }, [navigator, blocker, shouldPerformCheckOnNavigate, searchParams]);
}

export default function usePrompt(
  message: string,
  shouldPerformCheckOnNavigate = false,
  originPathname: string | null = null,
  searchParams: string | null = null
) {
  const blocker = useCallback(
    (tx: Transition) => {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) tx.retry();
    },
    [message]
  );

  useBlocker(
    blocker,
    shouldPerformCheckOnNavigate,
    originPathname,
    searchParams
  );
}
