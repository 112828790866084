import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import EditPhoneNumber from './EditPhoneNumber';
import { User } from '../app/api/user-api-slice';
import BetaChip from './BetaChip';
import GreenRoomCard from './GreenRoomCard';

interface UserInfoEditProps {
  user: User;
  // eslint-disable-next-line no-unused-vars
  onChange: (user: User) => void;
  hideAdditionalSettings?: boolean;
}

function UserInfoEdit({
  user,
  onChange,
  hideAdditionalSettings,
}: UserInfoEditProps) {
  return (
    <div className="user-info-edit">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            required
            label="First name"
            fullWidth
            value={user.legalFirstName || ''}
            onChange={(e) =>
              onChange({
                ...user,
                legalFirstName: e.target.value,
                preferredName:
                  user.legalFirstName === user.preferredName
                    ? e.target.value
                    : user.preferredName,
              })
            }
            autoComplete="given-name"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Last name"
            fullWidth
            value={user.legalLastName || ''}
            onChange={(e) =>
              onChange({ ...user, legalLastName: e.target.value })
            }
            autoComplete="family-name"
          />
        </Grid>
        <Grid item xs={5} sm={6}>
          <TextField
            required
            label="Preferred name"
            fullWidth
            value={user.preferredName || ''}
            onChange={(e) =>
              onChange({ ...user, preferredName: e.target.value })
            }
            autoComplete="nickname"
          />
        </Grid>
        {!hideAdditionalSettings && (
          <>
            <Grid item xs={7} sm={6}>
              <EditPhoneNumber
                label="Phone number"
                value={user.phoneNumber || ''}
                onChange={(newValue) => {
                  onChange({ ...user, phoneNumber: newValue });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Zip code"
                fullWidth
                value={user.zipCode || ''}
                onChange={(e) => onChange({ ...user, zipCode: e.target.value })}
                autoComplete="postal-code"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="time-zone-label">Time Zone</InputLabel>
                <Select
                  value={user.timeZone || ''}
                  labelId="time-zone-label"
                  label="Time Zone"
                  onChange={(e) =>
                    onChange({ ...user, timeZone: e.target.value })
                  }
                >
                  {moment.tz
                    .names()
                    .filter((tz) => !tz.startsWith('Etc'))
                    .map((tz) => (
                      <MenuItem key={tz} value={tz}>
                        {tz} (UTC{moment.tz(tz).utcOffset() > 0 ? '+' : ''}
                        {Math.floor(moment.tz(tz).utcOffset() / 60).toString()}:
                        {(moment.tz(tz).utcOffset() % 60)
                          .toString()
                          .padStart(2, '0')}
                        )
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <GreenRoomCard>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={user.betaAccess}
                        value={user.betaAccess}
                        onChange={(e) =>
                          onChange({ ...user, betaAccess: e.target.checked })
                        }
                      />
                    }
                    label={
                      <>
                        Enable Beta Features <BetaChip />
                      </>
                    }
                  />
                  <Typography variant="caption">
                    This will grant you access to new and experimental features
                    in the application. These will usually be labelled with an
                    icon on the navigation elements you use to access them.
                    These are provided for testing and feedback, and may not be
                    supported or improved. Please send us your feedback!
                  </Typography>
                </FormGroup>
              </GreenRoomCard>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

UserInfoEdit.defaultProps = {
  hideAdditionalSettings: false,
};

export default UserInfoEdit;
