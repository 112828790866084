import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import './NavbarSide.scss';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Logout } from '@mui/icons-material';
import { useGetUserQuery } from './app/api/user-api-slice';
import SupportLinks from './components/SupportLinks';
import { useGetFeatureFlagsQuery } from './app/api/feature-flags-slice';

function NavbarSide() {
  const navItems = [
    {
      key: 'events',
      text: 'Events',
      icon: <UpcomingIcon className="nav-list-icon" />,
      visible: true,
      className: 'nav-bar-events',
    },
    {
      key: 'finances',
      text: 'Finances',
      icon: <AttachMoneyIcon className="nav-list-icon" />,
      visible: false,
      className: 'nav-bar-finances',
    },
    {
      key: 'settings',
      text: 'Settings',
      icon: <SettingsIcon className="nav-list-icon" />,
      visible: true,
      className: 'nav-bar-settings',
    },
  ];

  const location = useLocation();

  const getFeatureFlags = useGetFeatureFlagsQuery();
  if (getFeatureFlags.data?.financesEnabled) {
    navItems[1].visible = true;
  }

  const [supportDrawerOpen, setSupportDrawerOpen] = useState(false);

  const { user, logout } = useAuth0();

  const getUserQuery = useGetUserQuery();

  const focusStyles = {
    ':focus': {
      backgroundColor: 'unset',
      boxShadow: 'inset 0 0 0 3px rgba(255, 255, 255, 0.5)',
    },
  };

  const navItemStyles = {
    paddingLeft: '36px',
    '&.Mui-selected': {
      backgroundColor: 'primary.dark',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'primary.dark',
    },
    '&.Mui-selected:focus': {
      backgroundColor: 'primary.dark',
    },
    ...focusStyles,
  };

  const [supportBadge, setSupportBadge] = useState(0);
  useEffect(() => {
    window.Intercom('onUnreadCountChange', (unreadCount: number) => {
      setSupportBadge(unreadCount);
    });
  });

  return (
    <Box
      className="App-sidebar"
      sx={{
        backgroundColor: 'secondary.dark',
        color: 'secondary.contrastText',
      }}
      display={{ xs: 'none', sm: 'block' }}
    >
      <div className="logo-container">
        <img src="/Green_Room_logo_light.png" alt="Green Room" />
      </div>
      <div className="nav-container">
        <div className="nav-list">
          <List>
            {navItems
              .filter((item) => item.visible === true)
              .map((item) => (
                <ListItemButton
                  key={item.key}
                  component={Link}
                  to={item.key}
                  selected={location.pathname.includes(`/${item.key}`)}
                  sx={navItemStyles}
                  className={item.className}
                >
                  <ListItemIcon className="nav-list-icon">
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            <ListItemButton
              key="support"
              onClick={() => window.Intercom('show')}
              sx={navItemStyles}
              className="nav-bar-support"
            >
              <ListItemIcon className="nav-list-icon">
                <HelpIcon className="nav-list-icon" />
              </ListItemIcon>
              <Badge badgeContent={supportBadge} color="success">
                <ListItemText primary="Support" />
              </Badge>
            </ListItemButton>
          </List>
        </div>
        <div className="nav-user">
          <Avatar src={user?.picture} />
          <div className="user-text overflow">
            <div className="user-name overflow">
              {getUserQuery.data &&
                `${getUserQuery.data.preferredName} ${getUserQuery.data.legalLastName}`}
            </div>
            <div className="email">{user?.email}</div>
          </div>
          <div>
            <IconButton
              aria-label="logout"
              onClick={() => logout({ returnTo: window.location.origin })}
              sx={focusStyles}
            >
              <Logout htmlColor="#ffffff" />
            </IconButton>
          </div>
        </div>
      </div>
      <Drawer
        anchor="left"
        open={supportDrawerOpen}
        onClose={() => setSupportDrawerOpen(false)}
      >
        <SupportLinks />
      </Drawer>
    </Box>
  );
}

export default NavbarSide;
