import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LinkIcon from '@mui/icons-material/Link';
import UploadFileIcon from '@mui/icons-material/InsertDriveFile';
import StatefulTabs, { TabProps } from './StatefulTabs';

const INFO_TAB = {
  label: 'Info',
  icon: <InfoIcon />,
  value: 'info',
  sensitive: false,
};

const TIMES_TAB = {
  label: 'Times',
  icon: <AccessTimeIcon />,
  value: 'times',
  sensitive: false,
};

const ROLES_TAB = {
  label: 'Roles',
  icon: <PeopleIcon />,
  value: 'roles',
  sensitive: false,
};

const FINANCES_TAB = {
  label: 'Finances',
  icon: <AttachMoneyIcon />,
  value: 'finances',
  sensitive: true,
};

const LINKS_TAB = {
  label: 'Links',
  icon: <LinkIcon />,
  value: 'links',
  sensitive: false,
};

const FILES_TAB = {
  label: 'Files',
  icon: <UploadFileIcon />,
  value: 'files',
  sensitive: false,
};

export const EVENT_TAB_VALUES = {
  INFO: INFO_TAB.value,
  TIMES: TIMES_TAB.value,
  ROLES: ROLES_TAB.value,
  FINANCES: FINANCES_TAB.value,
  LINKS: LINKS_TAB.value,
  FILES: FILES_TAB.value,
};

export const EVENT_TABS: TabProps[] = [
  INFO_TAB,
  TIMES_TAB,
  ROLES_TAB,
  FINANCES_TAB,
  LINKS_TAB,
];

interface EventTabsProps {
  showSensitiveInformation: boolean;
  filesEnabled?: boolean;
}

function EventTabs({ showSensitiveInformation, filesEnabled }: EventTabsProps) {
  let displayTabs = EVENT_TABS;

  if (filesEnabled) {
    displayTabs = [...displayTabs, FILES_TAB];
  }

  return (
    <StatefulTabs
      tabs={displayTabs}
      showSensitiveInformation={showSensitiveInformation}
    />
  );
}

EventTabs.defaultProps = {
  filesEnabled: false,
};

export default EventTabs;
