import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Swal from 'sweetalert2/dist/sweetalert2';
import { useSnackbar } from 'notistack';
import ProjectFileListing from '../components/ProjectFileListing';
import ProjectFileQuota from '../components/ProjectFileQuota';
import TabContent from '../components/Tabs/TabContent';
import { useDeleteFilesMutation } from '../app/api/files-api-slice';

interface ProjectFileManagementProps {
  projectId: string;
}

function ProjectFileManagement({ projectId }: ProjectFileManagementProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { enqueueSnackbar } = useSnackbar();

  const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);

  const [deleteFiles, deleteFilesStatus] = useDeleteFilesMutation();

  const deleteButtonClicked = () => {
    Swal.fire({
      title: 'Are you sure?',
      html:
        `<p>This will permanently delete the selected files everywhere they are used in Green Room.</p>` +
        `<p> <b>This action cannot be undone.</b> </p>`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      icon: 'warning',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteFiles({ projectId, fileIds: selectedFileIds });
      }
    });
  };

  useEffect(() => {
    if (deleteFilesStatus.isError) {
      enqueueSnackbar('There was a problem deleting the files.', {
        variant: 'error',
      });
    } else if (deleteFilesStatus.isSuccess) {
      enqueueSnackbar('Files have been deleted.', { variant: 'success' });
      setSelectedFileIds([]);
    }
  }, [deleteFilesStatus]);

  return (
    <>
      <TabContent>
        <ProjectFileQuota projectId={projectId} />
        <ProjectFileListing
          projectId={projectId}
          selectedFileIds={selectedFileIds}
          onSelectionChange={setSelectedFileIds}
          height={isMobile ? 'calc(100vh - 436px)' : 'calc(100vh - 374px)'}
        />
        <div style={{ marginTop: '8px' }}>
          <Button
            variant="contained"
            color="error"
            disabled={!selectedFileIds.length}
            onClick={() => deleteButtonClicked()}
          >
            Delete Selected
          </Button>
        </div>
      </TabContent>
      <Dialog open={deleteFilesStatus.isLoading} fullWidth>
        <DialogTitle>Deleting Files...</DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ProjectFileManagement;
