/* eslint-disable no-unused-vars */
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import {
  CreateBandsintownAuthorizationBody,
  useCreateBandsintownAuthorizationMutation,
  useCreateBandsintownConfigurationMutation,
  useGetBandsintownArtistsQuery,
  useGetBandsintownConfigurationQuery,
} from '../app/api/bandsintown-api-slice';
import GreenRoomDialog from './Dialog/GreenRoomDialog';
import GreenRoomButton from './GreenRoomButton';

interface SetupBandsintownDialogProps {
  projectId: string;
  open: boolean;
  onClose: () => void;
}

interface EmailPasswordState {
  email: string;
  password: string;
  error: string | null;
}

const STEP_EMAIL_PASSWORD = 0;
const STEP_ARTIST_SELECTION = 1;
const STEPS = [STEP_EMAIL_PASSWORD, STEP_ARTIST_SELECTION];

function SetupBandsintownDialog({
  projectId,
  open,
  onClose,
}: SetupBandsintownDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [step, setStep] = useState(STEP_EMAIL_PASSWORD);
  const [emailAndPassword, setEmailAndPassword] = useState<EmailPasswordState>({
    email: '',
    password: '',
    error: null,
  });
  const [
    createBandsintownAuthorizationMutation,
    createBandsintownAuthorizationMutationStatus,
  ] = useCreateBandsintownAuthorizationMutation();
  const getBandsintownConfiguration =
    useGetBandsintownConfigurationQuery(projectId);
  const getArtists = useGetBandsintownArtistsQuery(projectId, {
    skip: step !== STEP_ARTIST_SELECTION,
  });
  const [selectedArtistId, setSelectedArtistId] = useState<string>('');
  const [
    createBandsintownConfigurationMutation,
    createBandsintownConfigurationMutationStatus,
  ] = useCreateBandsintownConfigurationMutation();

  const title: string = 'Push to Bandsintown';
  let content: React.ReactNode = null;
  let actions: React.ReactNode = null;

  const close = () => {
    setStep(STEP_EMAIL_PASSWORD);
    setEmailAndPassword({
      email: '',
      password: '',
      error: null,
    });
    setSelectedArtistId('');
    onClose();
  };

  // eslint-disable-next-line no-constant-condition
  if (step === STEP_EMAIL_PASSWORD) {
    content = (
      <Stack spacing={2}>
        <Alert severity="info">
          <Typography variant="body1">
            We need your <b>Bandsintown for Artists</b> email and password to
            enable this integration.{' '}
            <b>
              We will not store or log your password; once we obtain a
              connection, we will discard your password immediately.
            </b>
          </Typography>
          <Typography variant="caption">
            We understand security concerns with sharing your login credentials
            with us. You can provide feedback to Bandsintown about their
            integration process via their{' '}
            <a
              href="https://artistfeedback.bandsintown.com/feedback/posts/require-integrations-use-oauth-instead-of-directly-using-my-pass"
              target="_blank"
              rel="noreferrer"
            >
              feedback site
            </a>
            .
          </Typography>
        </Alert>
        <TextField
          label="Bandsintown for Artists Email"
          value={emailAndPassword.email}
          onChange={(e) =>
            setEmailAndPassword({
              ...emailAndPassword,
              email: e.target.value,
              error: null,
            })
          }
          disabled={createBandsintownAuthorizationMutationStatus.isLoading}
        />
        <TextField
          label="Bandsintown for Artists Password"
          value={emailAndPassword.password}
          onChange={(e) =>
            setEmailAndPassword({
              ...emailAndPassword,
              password: e.target.value,
              error: null,
            })
          }
          type="password"
          disabled={createBandsintownAuthorizationMutationStatus.isLoading}
        />
        {emailAndPassword.error && (
          <Alert severity="error">{emailAndPassword.error}</Alert>
        )}
      </Stack>
    );
    actions = (
      <>
        <GreenRoomButton type="cancel" onClick={() => close()}>
          Cancel
        </GreenRoomButton>
        <GreenRoomButton
          type="accept"
          onClick={() => {
            createBandsintownAuthorizationMutation({
              projectId,
              body: {
                email: emailAndPassword.email,
                password: emailAndPassword.password,
              },
            });
          }}
          disabled={
            !emailAndPassword.email ||
            !emailAndPassword.password ||
            createBandsintownAuthorizationMutationStatus.isLoading
          }
        >
          Continue
        </GreenRoomButton>
      </>
    );
  } else if (step === STEP_ARTIST_SELECTION) {
    if (getArtists.isError || emailAndPassword.error) {
      content = (
        <Alert severity="error">
          An error occurred trying to retrieve list of artists from Bandsintown.
          Please try again.
        </Alert>
      );
      actions = (
        <GreenRoomButton type="cancel" onClick={() => close()}>
          Cancel
        </GreenRoomButton>
      );
    } else {
      content = (
        <Stack spacing={2}>
          <Typography variant="body1">
            Choose an artist managed by this Bandsintown account to connect to
            this Green Room project.
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="artists-label">Artist</InputLabel>
            <Select
              labelId="artists-label"
              label="Artist"
              disabled={!getArtists.isSuccess}
              defaultValue={selectedArtistId}
              value={selectedArtistId}
              onChange={(e) => setSelectedArtistId(e.target.value as string)}
            >
              {getArtists.isSuccess &&
                getArtists.data !== undefined &&
                getArtists.data?.map((artist) => (
                  <MenuItem value={artist.id}>{artist.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
      );
      actions = (
        <>
          <GreenRoomButton type="cancel" onClick={() => close()}>
            Cancel
          </GreenRoomButton>
          <GreenRoomButton
            type="accept"
            icon={<CheckIcon />}
            disabled={!selectedArtistId || !getArtists.isSuccess}
            onClick={() => {
              createBandsintownConfigurationMutation({
                projectId,
                body: {
                  artistId: Number(selectedArtistId),
                },
              })
                .then(() => {
                  close();
                })
                .catch(() => {
                  setEmailAndPassword({
                    ...emailAndPassword,
                    error:
                      'An error occurred trying to communicate with Bandsintown for Artists. Please try again later.',
                  });
                });
            }}
          >
            Complete Setup
          </GreenRoomButton>
        </>
      );
    }
  }

  useEffect(() => {
    if (
      createBandsintownAuthorizationMutationStatus.isSuccess ||
      (getBandsintownConfiguration.data?.userEmail &&
        !getBandsintownConfiguration.data?.enabled)
    ) {
      setStep(STEP_ARTIST_SELECTION);
    } else if (createBandsintownAuthorizationMutationStatus.isError) {
      const error =
        createBandsintownAuthorizationMutationStatus.error as FetchBaseQueryError;
      if (error.status === 401) {
        setEmailAndPassword({
          ...emailAndPassword,
          error: 'The Bandsintown for Artists email or password was invalid.',
        });
      } else {
        setEmailAndPassword({
          ...emailAndPassword,
          error:
            'An error occurred trying to communicate with Bandsintown for Artists. Please try again later.',
        });
      }
    }
  }, [
    createBandsintownAuthorizationMutationStatus,
    getBandsintownConfiguration,
  ]);

  return (
    <GreenRoomDialog
      open={open}
      fullscreen
      title={title}
      onClose={() => close()}
      actions={actions}
    >
      {content}
    </GreenRoomDialog>
  );
}

export default SetupBandsintownDialog;
