import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  GetFinancialAccountTransactionsRequest,
  useGetFinancialAccountTransactionsQuery,
} from '../../app/api/project-financial-account-slice';
import GreenRoomStack from '../../components/GreenRoomStack';
import FinancialAccountTransactionCard from './FinancialAccountTransactionCard';
import './FinancialAccountTransactionsList.scss';

interface FinancialAccountTransactionsListProps {
  projectId: string;
}

function FinancialAccountTransactionsList({
  projectId,
}: FinancialAccountTransactionsListProps) {
  const PAGE_LENGTH_LIMIT = 10;
  const [requestArgs, setRequestArgs] = useState({
    projectId,
    limit: PAGE_LENGTH_LIMIT,
    startingAfter: null,
  } as GetFinancialAccountTransactionsRequest);

  const [searchParams, setSearchParams] = useSearchParams();

  const getFinancialAccountTransactionsQuery =
    useGetFinancialAccountTransactionsQuery(requestArgs);

  useEffect(() => {
    setRequestArgs({
      projectId,
      limit: PAGE_LENGTH_LIMIT,
      startingAfter: null,
    });
  }, [projectId]);

  if (
    getFinancialAccountTransactionsQuery.isLoading ||
    !getFinancialAccountTransactionsQuery.data
  ) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Typography
        variant="subtitle1"
        className="financial-account-transactions-list-title"
      >
        Recent Transactions
      </Typography>
      {getFinancialAccountTransactionsQuery.data.financialAccountTransactions
        .length === 0 && (
        <Typography
          variant="body2"
          className="financial-account-transactions-list-subtitle"
        >
          No Transactions
        </Typography>
      )}
      {getFinancialAccountTransactionsQuery.data?.financialAccountTransactions
        .length > 0 && (
        <GreenRoomStack>
          {getFinancialAccountTransactionsQuery.data?.financialAccountTransactions.map(
            (transaction) => (
              <FinancialAccountTransactionCard
                transaction={transaction}
                key={transaction.id}
                onClick={() => {
                  searchParams.set('transaction', transaction.id);
                  setSearchParams(searchParams);
                }}
              />
            )
          )}
        </GreenRoomStack>
      )}

      {getFinancialAccountTransactionsQuery.data?.hasMore && (
        <Button
          onClick={() => {
            setRequestArgs({
              ...requestArgs,
              startingAfter:
                getFinancialAccountTransactionsQuery.data!
                  .financialAccountTransactions[
                  getFinancialAccountTransactionsQuery.data!
                    .financialAccountTransactions.length - 1
                ].id,
            });
          }}
        >
          Load more
        </Button>
      )}
    </div>
  );
}

export default FinancialAccountTransactionsList;
