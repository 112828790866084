import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetProjectsQuery } from '../app/api/projects-api-slice';
import OnboardProject from './OnboardProject';
import Loading from '../components/Loading';
import TabContent from '../components/Tabs/TabContent';
import Subheading from '../components/Text/Subheading';
import GreenRoomButton from '../components/GreenRoomButton';
import GreenRoomStack from '../components/GreenRoomStack';
import GreenRoomCard from '../components/GreenRoomCard';
import Blankslate from '../components/Blankslate';
import { ReactComponent as Armchair } from '../img/placeholders/armchair.svg';

function SettingsPageProjects() {
  const getProjects = useGetProjectsQuery();
  const navigate = useNavigate();
  const [projectOnboarding, setProjectOnboarding] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('createDialog')) {
      setProjectOnboarding(true);
    } else {
      setProjectOnboarding(false);
    }
  }, [searchParams]);

  /// //////////
  // Blankslate constants
  const description =
    'Projects are groups of people of any size that perform. Add them below to make them available when you create events.';
  const action = (
    <GreenRoomButton
      type="create"
      onClick={() => {
        searchParams.set('createDialog', '');
        setSearchParams(searchParams);
      }}
    >
      Create New Project
    </GreenRoomButton>
  );

  /// //////////
  // Render loading state
  const loading = getProjects.isLoading;
  if (loading) return <Loading />;

  /// //////////
  // Render main content
  return (
    <TabContent
      actions={(!loading && getProjects.data?.length && action) || null}
    >
      {(getProjects.data && getProjects.data?.length > 0 && (
        <Stack spacing={2}>
          <div>
            <Subheading>Projects you manage</Subheading>
            <div>{description}</div>
          </div>
          <GreenRoomStack>
            {getProjects.data
              ?.filter(
                (project) =>
                  project.role === 'Owner' || project.role === 'Admin'
              )
              .map((project) => (
                <GreenRoomCard
                  key={project.id}
                  title={project.name}
                  onClick={() => navigate(`/settings/project/${project.id}`)}
                />
              ))}
          </GreenRoomStack>
        </Stack>
      )) || (
        <Blankslate title="No projects" actions={action} svg={<Armchair />}>
          {description}
        </Blankslate>
      )}
      {projectOnboarding && (
        <OnboardProject
          onboardingComplete={() => {
            searchParams.delete('createDialog');
            setSearchParams(searchParams);
          }}
        />
      )}
    </TabContent>
  );
}

export default SettingsPageProjects;
