import { Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useGetEventRoleTypesQuery } from '../app/api/event-role-types-api-slice';
import CreateEditEventRoleTypeDialog, {
  EditEventRoleType,
} from '../pages/CreateEditEventRoleTypeDialog';
import TabContent from './Tabs/TabContent';
import GreenRoomButton from './GreenRoomButton';
import GreenRoomStack from './GreenRoomStack';
import GreenRoomCard from './GreenRoomCard';
import { ReactComponent as Accordion } from '../img/placeholders/accordion.svg';
import Loading from './Loading';
import Blankslate from './Blankslate';

interface ProjectRolesProps {
  projectId: string;
  hideTitle?: boolean;
}

function ProjectRoles({ projectId, hideTitle }: ProjectRolesProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getRoles = useGetEventRoleTypesQuery({ projectId });
  const newEditEventRoleType = {
    id: null,
    name: '',
    projectId,
  } as EditEventRoleType;
  const [dialogProps, setDialogProps] = useState({
    open: false,
    role: newEditEventRoleType,
  });

  /// //////////
  // Blankslate constants
  const description =
    'Project roles are positions that need to be filled to fulfill an event. By adding them to Green Room you can track which roles have been filled by whom for each event.';
  const action = (
    <GreenRoomButton
      type="add"
      onClick={() => setDialogProps({ open: true, role: newEditEventRoleType })}
    >
      Add Project Role
    </GreenRoomButton>
  );

  /// //////////
  // Render loading state
  const loading = getRoles.isLoading;
  if (loading) return <Loading />;

  /// //////////
  // Render main content
  return (
    <TabContent>
      {(getRoles.data && getRoles.data?.length > 0 && (
        <Stack spacing={2}>
          <div>{description}</div>
          <GreenRoomStack>
            {getRoles.data?.map((role) => (
              <GreenRoomCard
                key={role.id}
                title={role.name}
                onClick={() => {
                  const editRole = {
                    id: role.id,
                    name: role.name,
                    projectId,
                  };
                  setDialogProps({
                    open: true,
                    role: editRole,
                  });
                }}
              />
            ))}
          </GreenRoomStack>
          {action}
        </Stack>
      )) || (
        <Blankslate
          title={!hideTitle ? 'No project roles' : null}
          actions={action}
          svg={!hideTitle ? <Accordion /> : null}
        >
          Project roles are positions that need to be filled for an event. For
          example: lead guitar, lighting, drums. Add them below to track which
          roles have been filled by whom for each event.
        </Blankslate>
      )}
      <CreateEditEventRoleTypeDialog
        role={dialogProps.role}
        fullScreen={fullScreen}
        open={dialogProps.open}
        onClose={() => {
          setDialogProps({ ...dialogProps, open: false });
        }}
      />
    </TabContent>
  );
}

ProjectRoles.defaultProps = {
  hideTitle: false,
};

export default ProjectRoles;
