import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SortEvents, {
  SORT_PARAM_NAME,
  SORT_DEFAULT_VALUE,
  SORT_DIRECTION_PARAM_NAME,
  SORT_DIRECTION_DEFAULT_VALUE,
  SortEventsRequestArgs,
} from './SortEvents';
import FilterEventsByDate, {
  DATE_FROM_PARAM_NAME,
  DATE_TO_PARAM_NAME,
  DATE_RANGE_PARAM_NAME,
  DATE_RANGE_DEFAULT_VALUE,
  DATE_RANGE_VALUE_PAST,
  DATE_RANGE_VALUE_UPCOMING,
  DATE_RANGE_VALUE_NEXT_THIRTY,
  DATE_RANGE_VALUE_REMAINDER_OF_YEAR,
  DATE_RANGE_VALUE_CUSTOM,
  FilterEventsByDateRequestArgs,
} from './FilterEventsByDate';
import FilterEvents, {
  FilterEventsRequestArgs,
  PROJECTS_PARAM_NAME,
  MEMBERS_PARAM_NAME,
  VENUES_PARAM_NAME,
  INVITE_STATUSES_PARAM_NAME,
  LABELS_PARAM_NAME,
  EVENT_STATUSES_PARAM_NAME,
} from './FilterEvents';
import { formatDateForAPI } from '../filter-helpers';

/// //////////
// Types
export type FilterRequestArgs = SortEventsRequestArgs &
  FilterEventsByDateRequestArgs &
  FilterEventsRequestArgs;
interface EventsFiltersProps {
  // eslint-disable-next-line no-unused-vars
  onUpdateFilterRequestArgs: (filterRequestArgs: FilterRequestArgs) => void;
  unfilteredEventsLength: number | undefined;
}

function EventsFilters({
  onUpdateFilterRequestArgs,
  unfilteredEventsLength,
}: EventsFiltersProps) {
  /// //////////
  // Navigation
  const [searchParams, setSearchParams] = useSearchParams();

  /// //////////
  // Required filters; Optionally restored to default when clearing filters
  const requiredFilters = [
    {
      name: SORT_PARAM_NAME,
      defaultValue: SORT_DEFAULT_VALUE,
      resetOnClear: false,
    },
    {
      name: SORT_DIRECTION_PARAM_NAME,
      defaultValue: SORT_DIRECTION_DEFAULT_VALUE,
      resetOnClear: false,
    },
    {
      name: DATE_RANGE_PARAM_NAME,
      defaultValue: DATE_RANGE_DEFAULT_VALUE,
      resetOnClear: true,
    },
  ];

  /// //////////
  // Non-required filters; Deleted from URL when clearing filters
  const optionalFilters = [
    DATE_FROM_PARAM_NAME,
    DATE_TO_PARAM_NAME,
    PROJECTS_PARAM_NAME,
    MEMBERS_PARAM_NAME,
    VENUES_PARAM_NAME,
    INVITE_STATUSES_PARAM_NAME,
    LABELS_PARAM_NAME,
  ];

  // //////////
  // Some filter params should be set to default values on load; Helper to track if they are set
  const isUnitialized = (urlParams: URLSearchParams) => {
    const unitializedFilters = requiredFilters.filter(
      (filter) => !urlParams.get(filter.name)
    );

    return !!unitializedFilters.length;
  };

  // //////////
  // Check if non-default filters are applied
  const isClearFiltersButtonVisible = (urlParams: URLSearchParams) => {
    const resettableRequiredFilters = requiredFilters.filter(
      (filter) =>
        filter.resetOnClear &&
        urlParams.get(filter.name) &&
        urlParams.get(filter.name) !== filter.defaultValue
    );

    const resettableOptionalFields = optionalFilters.filter((filterName) =>
      urlParams.get(filterName)
    );

    return (
      !!resettableRequiredFilters.length || !!resettableOptionalFields.length
    );
  };

  // //////////
  // On button click, reset filters to default and save url to history
  const clearFilters = () => {
    // Reset required filters to default values if flagged
    requiredFilters.forEach((filter) => {
      if (filter.resetOnClear)
        searchParams.set(filter.name, filter.defaultValue);
    });

    // Delete all optional filter parameters
    optionalFilters.forEach((filterName) => {
      searchParams.delete(filterName);
    });

    // DO save to history
    setSearchParams(searchParams, { replace: true });
  };

  // //////////
  // When URL updates, add missing required defaults to URL or fire callback to refetch data
  useEffect(() => {
    // //////////
    // If component is unitialized, set the missing params first, which will cause a refire of this useEffect
    if (isUnitialized(searchParams)) {
      requiredFilters.forEach((filter) => {
        if (!searchParams.get(filter.name)) {
          searchParams.set(filter.name, filter.defaultValue);
        }
      });

      if (searchParams.get('tab') === 'past') {
        searchParams.set(DATE_RANGE_PARAM_NAME, DATE_RANGE_VALUE_PAST);
        searchParams.set(SORT_DIRECTION_PARAM_NAME, 'Desc');
      }

      searchParams.delete('tab');

      // DO NOT save to history
      setSearchParams(searchParams, { replace: true });
    } else {
      // //////////
      // Pluck or derive the from and to params
      const dateRange = searchParams.get(DATE_RANGE_PARAM_NAME);
      // All time
      let from;
      let to;

      if (dateRange === DATE_RANGE_VALUE_PAST) {
        // Past
        to = formatDateForAPI(moment());
      } else if (dateRange === DATE_RANGE_VALUE_UPCOMING) {
        // Upcoming
        from = formatDateForAPI(moment());
      } else if (dateRange === DATE_RANGE_VALUE_NEXT_THIRTY) {
        // Next 30 days
        from = formatDateForAPI(moment());
        to = formatDateForAPI(moment().add(30, 'days'));
      } else if (dateRange === DATE_RANGE_VALUE_REMAINDER_OF_YEAR) {
        // Remainder of year
        from = formatDateForAPI(moment());
        to = formatDateForAPI(moment().endOf('year'));
      } else if (dateRange === DATE_RANGE_VALUE_CUSTOM) {
        // Custom
        from = searchParams.get(DATE_FROM_PARAM_NAME);
        to = searchParams.get(DATE_TO_PARAM_NAME);
      }

      // //////////
      // Fire callback with new filter request args
      const newFilters = {
        sort: searchParams.get(SORT_PARAM_NAME),
        sortDirection: searchParams.get(SORT_DIRECTION_PARAM_NAME),
        from,
        to,
        projectIds: searchParams.get(PROJECTS_PARAM_NAME),
        personIds: searchParams.get(MEMBERS_PARAM_NAME),
        locationGooglePlacesIds: searchParams.get(VENUES_PARAM_NAME),
        eventStatuses: searchParams.get(EVENT_STATUSES_PARAM_NAME),
        inviteStatuses: searchParams.get(INVITE_STATUSES_PARAM_NAME),
        labelIds: searchParams.get(LABELS_PARAM_NAME),
      } as FilterRequestArgs;

      onUpdateFilterRequestArgs(newFilters);
    }
  }, [searchParams]);

  // //////////
  // Filters wrapper component
  return (
    <div style={{ display: unfilteredEventsLength ? 'block' : 'none' }}>
      <FilterEventsByDate />
      <FilterEvents />
      <SortEvents />
      {isClearFiltersButtonVisible(searchParams) && (
        <Chip
          variant="outlined"
          onClick={clearFilters}
          onDelete={clearFilters}
          label="Clear filters"
          deleteIcon={<DeleteIcon />}
          color="secondary"
          className="green-room-chip"
        />
      )}
    </div>
  );
}

export default EventsFilters;
