import emptySplitApi from './empty-api-slice';

export interface GreenRoomFile {
  id: string;
  name: string;
  size: number;
  createdAt: string;
  createdBy: string;
  updatedAt: string | null;
  updatedBy: string | null;
  url: string;
}

interface GetFilesResponse {
  total: number;
  files: GreenRoomFile[];
}

interface GetFilesQuery {
  projectId: string;
  offset: number;
  limit: number;
  sort: 'Name' | 'Size' | 'CreatedAt' | 'ModifiedDate';
  sortDirection: 'Asc' | 'Desc';
  namePrefix: string | null;
}

interface GetFileQuota {
  fileCount: number;
  fileCountLimit: number | null;
  totalBytes: number;
  totalBytesLimit: number | null;
}

export interface DeleteFilesRequest {
  projectId: string;
  fileIds: string[];
}

export const filesApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFiles: builder.query<GetFilesResponse, GetFilesQuery>({
      query: (query) => {
        let url = `/files?projectId=${query.projectId}&sort=${query.sort}&sortDirection=${query.sortDirection}&offset=${query.offset}&limit=${query.limit}`;
        if (query.namePrefix) {
          url += `&namePrefix=${query.namePrefix}`;
        }
        return { url };
      },
      providesTags: (result) =>
        result?.files.length
          ? [
              ...result!.files.map(({ id }) => ({ type: 'File' as const, id })),
              { type: 'File', id: 'LIST' },
            ]
          : [{ type: 'File', id: 'LIST' }],
    }),
    getFile: builder.query<GreenRoomFile, string>({
      query: (id) => `/files/${id}`,
      providesTags: (result, error, id) => [{ type: 'File', id }],
    }),
    uploadFile: builder.mutation<GreenRoomFile, FormData>({
      query: (formData) => ({
        url: `/files`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: [{ type: 'File', id: 'LIST' }],
    }),
    getFileQuota: builder.query<GetFileQuota, string>({
      query: (projectId) => `/files/quota?projectId=${projectId}`,
      providesTags: ['FileQuota'],
    }),
    deleteFiles: builder.mutation<void, DeleteFilesRequest>({
      query: (request) => ({
        url: `/files`,
        method: 'DELETE',
        body: request,
      }),
      invalidatesTags: ['File', 'FileQuota'],
    }),
  }),
});

export const {
  useGetFilesQuery,
  useGetFileQuery,
  useLazyGetFileQuery,
  useUploadFileMutation,
  useGetFileQuotaQuery,
  useDeleteFilesMutation,
} = filesApiSlice;
