import { FinancialAccountTransaction } from '../api/project-financial-account-slice';

export const getFinancialAccountTransactionDisplayType = (
  transaction: FinancialAccountTransaction
): string => {
  switch (transaction.flowType) {
    case 'outbound_transfer':
    case 'inbound_transfer':
      return 'Transfer';
    case 'issuing_authorization':
      return 'Authorization';
    case 'outbound_payment':
      return 'Payment';
    case 'received_debit':
      return 'Debit';
    case 'received_credit':
      return 'Credit';
    default:
      return (
        transaction.flowType.charAt(0).toUpperCase() +
        transaction.flowType.slice(1).replace(/_/g, ' ')
      );
  }
};

export const getFinancialAccountTransactionPreposition = (
  transaction: FinancialAccountTransaction
): string => {
  switch (transaction.flowType) {
    case 'issuing_authorization':
      return 'for';
    case 'received_debit':
      return 'from';
    default:
      return transaction.amount > 0 ? 'from' : 'to';
  }
};
