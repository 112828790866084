import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React from 'react';

interface ServerErrorDisplayProps {
  error: FetchBaseQueryError | SerializedError;
  errorMessage: string;
}

interface ServerError {
  errors?: object;
  type?: string;
  title?: string;
  detail?: string;
}

function ServerErrorDisplay({ error, errorMessage }: ServerErrorDisplayProps) {
  const fetchBaseError = (error as FetchBaseQueryError) || null;
  const errorData = (fetchBaseError?.data as ServerError) || null;
  const type = (errorData?.type as string) || null;
  const title = (errorData?.title as string) || errorMessage;
  const detail = (errorData?.detail as string) || title;

  if (errorData?.errors && type === 'human_readable') {
    const errorMessages = Object.values(errorData.errors);
    if (errorMessages.length) {
      return (
        <div>
          <div>{detail}</div>
          <ul>
            {errorMessages.map((message) => (
              <li key={message}>{message}</li>
            ))}
          </ul>
        </div>
      );
    }
  }

  return <div>{detail}</div>;
}

export default ServerErrorDisplay;
