import React from 'react';
import { SelectChangeEvent, MenuItem, DialogActions } from '@mui/material';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import GreenRoomButton from '../GreenRoomButton';

/// //////////
// Types
export type SelectOptionsType = readonly { value: string; label: string }[];

/// //////////
// Select helpers
export const getSelectParamFromURL = (
  paramName: string,
  defaultValue: string,
  selectOptions: SelectOptionsType,
  searchParams: URLSearchParams
) => {
  const param = searchParams.get(paramName) || '';

  if (selectOptions.map((value) => value.value).includes(param)) return param;

  return defaultValue;
};

export const onSelectChange = (
  e: SelectChangeEvent,
  name: string,
  options: SelectOptionsType,
  onChange: any, // TODO: define type
  stagedChanges: any // TODO: define type
) => {
  const value = e.target.value as typeof options[number]['value'];

  onChange({
    ...stagedChanges,
    [name]: value,
  });
};

export const getSelectOptions = (options: SelectOptionsType) =>
  options.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));

/// //////////
// DatePicker helpers
export const formatDateForAPI = (momentDate: Moment) =>
  momentDate.format('YYYY-MM-DD');
export const formatDateForUI = (momentDate: Moment) =>
  momentDate.format('MM/DD/YY');

export const getDateParamFromURL = (
  paramName: string,
  defaultValue: string,
  searchParams: URLSearchParams
) => {
  const paramValue = searchParams.get(paramName) || '';

  // If param value is valid, set it
  if (paramValue && moment(paramValue).isValid())
    return moment(paramValue).format('YYYY-MM-DD');

  // If param value is invalid, but not null, then set it to the default value
  if (paramValue) return defaultValue;

  // Else, return null;
  return null;
};

export const getCustomActionBar = (
  { onClear, onAccept }: PickersActionBarProps,
  mobile: boolean
) => (
  <DialogActions>
    <GreenRoomButton onClick={onClear} type="destroy">
      Clear
    </GreenRoomButton>
    {mobile && (
      <GreenRoomButton onClick={onAccept} type="accept">
        Accept
      </GreenRoomButton>
    )}
  </DialogActions>
);
