import React, { useState } from 'react';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useGetLabelsQuery } from '../../app/api/labels-api-slice';
import CreateEditProjectLabelDialog from '../../pages/CreateEditProjectLabelDialog';
import './EditLabels.scss';
import {
  isSomeSelected,
  isAllSelected,
  isSomeButNotAllSelected,
  SELECT_MENU_PROPS,
} from '../Selects/select-helpers';
import GreenRoomTooltip from '../GreenRoomTooltip';
import Labels from './Labels';

/// //////////
// TODO: There is a lot of overlap in the use of the Select in EditLabels and FilterEvents
// First, refactor FilterEvents so there are fewer places to touch when adding new filters
// Then, if a MutliSelect is needed any additional places, create GreenRoomMultiSelect component to use in EditLabels, FilterEvents, and beyond
const INPUT_LABEL = 'Labels';

const SELECT_ALL_LABEL_VALUE = 'select-all-menu-item';
const CREATE_LABEL_VALUE = 'create-a-new-event-button';
const DISABLED_LABEL_VALUE = 'select-a-project-first-help-text';

interface EditLabelsProps {
  projectId: string | null;
  selectedLabelIds: string[];
  // eslint-disable-next-line no-unused-vars
  onChange: (labelNames: string[]) => void;
}

export default function EditLabels({
  projectId,
  selectedLabelIds,
  onChange,
}: EditLabelsProps) {
  const getLabels = useGetLabelsQuery(
    { projectId: projectId! },
    { skip: !projectId }
  );
  const labelOptions =
    getLabels.data?.map((label) => ({
      value: label.id,
      label: label.name,
    })) || [];

  type FilterEventOptions = typeof labelOptions;

  const [dialogOpen, setDialogOpen] = useState(false);

  const createNewDisabled = !projectId;

  const getMultiSelectOptions = (
    selectedValues: string[],
    options: FilterEventOptions
  ) => {
    const selectProjectFirstOption = (
      <MenuItem
        disabled
        key={DISABLED_LABEL_VALUE}
        value={DISABLED_LABEL_VALUE}
        style={{ whiteSpace: 'normal' }}
      >
        Select a project to add labels to your event
      </MenuItem>
    );

    if (createNewDisabled) {
      return selectProjectFirstOption;
    }

    const conditionalOptions = [];
    const toggleAllOption = (
      <MenuItem
        key="select-all-options"
        value={SELECT_ALL_LABEL_VALUE}
        className={`${
          (isSomeSelected(selectedValues) &&
            'green-room-multi-select-toggle-all-menu-item-selected') ||
          ''
        }`}
      >
        <ListItemIcon>
          <Checkbox
            checked={isAllSelected(selectedValues, options)}
            indeterminate={isSomeButNotAllSelected(selectedValues, options)}
          />
        </ListItemIcon>
        <ListItemText
          className="green-room-multi-select-toggle-all-text"
          primary={
            isSomeSelected(selectedValues) ? 'Deselect all' : 'Select all'
          }
        />
      </MenuItem>
    );

    if (options?.length) {
      conditionalOptions.push(toggleAllOption);
    }

    return [
      ...conditionalOptions,
      options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <ListItemIcon>
            <Checkbox
              checked={
                selectedValues.indexOf(option.value) > -1 ||
                isAllSelected(selectedValues, options)
              }
            />
          </ListItemIcon>
          <ListItemText primary={option.label} />
        </MenuItem>
      )),
      <MenuItem key={CREATE_LABEL_VALUE} value={CREATE_LABEL_VALUE}>
        <AddIcon className="project-label-option-icon" /> Create label...
      </MenuItem>,
    ];
  };

  return (
    <div className="edit-field edit-field-inline">
      <FormControl fullWidth>
        <InputLabel id="multiple-chip-label">{INPUT_LABEL}</InputLabel>
        <Select
          labelId="multiple-chip-label"
          label={INPUT_LABEL}
          multiple
          value={selectedLabelIds}
          onChange={(e) => {
            const { value } = e.target;

            if (value.includes(SELECT_ALL_LABEL_VALUE)) {
              const allLabelNames = labelOptions.map((option) => option.value);
              if (isSomeSelected(selectedLabelIds)) {
                onChange([]);
              } else {
                onChange(allLabelNames);
              }
            } else if (value.includes(CREATE_LABEL_VALUE)) {
              setDialogOpen(true);
            } else {
              onChange(typeof value === 'string' ? value.split(',') : value);
            }
          }}
          renderValue={(labelIds) => (
            <Labels labelIds={labelIds} projectId={projectId} />
          )}
          MenuProps={SELECT_MENU_PROPS}
        >
          {getMultiSelectOptions(selectedLabelIds, labelOptions)}
        </Select>
      </FormControl>
      {!createNewDisabled && (
        <CreateEditProjectLabelDialog
          open={dialogOpen}
          onClose={(newLabelId: string | null) => {
            setDialogOpen(false);
            if (newLabelId) {
              onChange([...selectedLabelIds, newLabelId]);
            }
          }}
          label={{ projectId }}
          labelNamesOnProject={getLabels.data?.map((l) => l.name)}
        />
      )}
      <GreenRoomTooltip title="Use labels to categorize or group your events for whatever you may need to filter for later. This could be used for unconfirmed dates, band configurations, tours, recordings or any other grouping you think about your shows with. These are visible and filterable on the index page." />
    </div>
  );
}
