export const LOCATION_TBD = 'Location: TBD';

export const getCityAndState = (
  city: string | null | undefined,
  state: string | null | undefined
) => {
  let cityAndState;

  if (city && state) {
    cityAndState = `${city}, ${state}`;
  } else if (city) {
    cityAndState = city;
  } else if (state) {
    cityAndState = state;
  } else {
    cityAndState = LOCATION_TBD;
  }

  return cityAndState;
};
