import emptySplitApi from './empty-api-slice';

interface PaymentTypeBody {
  name: string;
}

interface CreatePaymentType extends PaymentTypeBody {
  projectId: string;
}

interface UpdatePaymentType {
  id: string;
  body: PaymentTypeBody;
}

export interface PaymentType extends PaymentTypeBody {
  id: string;
  projectId: string;
}

export interface CreateEditPaymentType extends Omit<PaymentType, 'id'> {
  id: string | null;
}

export interface GetPaymentTypesQuery {
  projectId: string;
}

export const paymentTypesApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentTypesForAllProjects: builder.query<PaymentType[], void>({
      query: () => `/payment_types`,
      providesTags: ['PaymentType'],
    }),
    getPaymentTypes: builder.query<PaymentType[], GetPaymentTypesQuery>({
      query: (query) => `/payment_types?projectId=${query.projectId}`,
      providesTags: ['PaymentType'],
    }),
    createPaymentType: builder.mutation<string, CreatePaymentType>({
      query: (paymentType) => ({
        url: `/payment_types`,
        method: 'POST',
        body: paymentType,
      }),
      invalidatesTags: ['PaymentType'],
    }),
    updatePaymentType: builder.mutation<void, UpdatePaymentType>({
      query: (paymentType) => ({
        url: `/payment_types/${paymentType.id}`,
        method: 'PUT',
        body: JSON.stringify(paymentType.body),
        headers: {
          'Content-Type': 'application/merge-put+json',
        },
      }),
      invalidatesTags: ['PaymentType'],
    }),
    deletePaymentType: builder.mutation<void, string>({
      query: (id) => ({
        url: `/payment_types/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PaymentType'],
    }),
  }),
});

export const {
  useGetPaymentTypesForAllProjectsQuery,
  useGetPaymentTypesQuery,
  useCreatePaymentTypeMutation,
  useUpdatePaymentTypeMutation,
  useDeletePaymentTypeMutation,
} = paymentTypesApiSlice;
