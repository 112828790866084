import React, { useState, useEffect } from 'react';
import { Chip, InputLabel, FormControl, Select } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import GreenRoomDialog from '../../Dialog/GreenRoomDialog';
import GreenRoomButton from '../../GreenRoomButton';
import {
  getSelectParamFromURL,
  getSelectOptions,
  onSelectChange,
} from '../filter-helpers';

// Note: There is premature configuration for mulitple `sort by` options in this file
// Configuration that is close, but may not align with the eventual api is denoted by TODO: EVENTS SORT BY V2

/// //////////
// Constants: Sort
export const SORT_PARAM_NAME = 'sort';
const SORT_VALUE_SHOW_DATE = 'showDate'; // TODO: EVENTS SORT BY V2: make sure this matches api
const SORT_VALUE_SHOW_DATE_LABEL = 'Show date';
const SORT_VALUE_CREATED_DATE = 'createdDate'; // TODO: EVENTS SORT BY V2: make sure this matches api
const SORT_VALUE_CREATED_DATE_LABEL = 'Created date';
const SORT_VALUE_VENUE = 'venue'; // TODO: EVENTS SORT BY V2: make sure this matches api
const SORT_VALUE_VENUE_LABEL = 'Venue';
const SORT_OPTIONS_V2 = [
  {
    value: SORT_VALUE_SHOW_DATE,
    label: SORT_VALUE_SHOW_DATE_LABEL,
  },
  {
    value: SORT_VALUE_CREATED_DATE,
    label: SORT_VALUE_CREATED_DATE_LABEL,
  },
  {
    value: SORT_VALUE_VENUE,
    label: SORT_VALUE_VENUE_LABEL,
  },
] as const;
const SORT_OPTIONS = SORT_OPTIONS_V2.slice(0, 1); // TODO: EVENTS SORT BY V2: delete and rename SORT_OPTIONS_V2 as SORT_OPTIONS
export const SORT_DEFAULT_VALUE = SORT_OPTIONS[0].value;

/// //////////
// Constants: Sort direction
export const SORT_DIRECTION_PARAM_NAME = 'sortDirection';
const SORT_DIRECTION_VALUE_DESC = 'Desc'; // TODO: EVENTS SORT BY V2: make sure this matches api
const SORT_DIRECTION_VALUE_ASC = 'Asc'; // TODO: EVENTS SORT BY V2: make sure this matches api
const SORT_DIRECTION_OPTIONS = [
  {
    value: SORT_DIRECTION_VALUE_ASC,
    label: 'Ascending',
  },
  {
    value: SORT_DIRECTION_VALUE_DESC,
    label: 'Descending',
  },
] as const;
export const SORT_DIRECTION_DEFAULT_VALUE = SORT_DIRECTION_OPTIONS[0].value;

/// //////////
// Types
export interface SortEventsRequestArgs {
  sort:
    | typeof SORT_VALUE_SHOW_DATE
    | typeof SORT_VALUE_CREATED_DATE
    | typeof SORT_VALUE_VENUE;
  sortDirection:
    | typeof SORT_DIRECTION_VALUE_DESC
    | typeof SORT_DIRECTION_VALUE_ASC;
}

function SortEvents() {
  /// //////////
  // Navigation
  const [searchParams, setSearchParams] = useSearchParams();

  // //////////
  // Active filters
  const activeFilters = {
    sort: getSelectParamFromURL(
      SORT_PARAM_NAME,
      SORT_DEFAULT_VALUE,
      SORT_OPTIONS,
      searchParams
    ),
    sortDirection: getSelectParamFromURL(
      SORT_DIRECTION_PARAM_NAME,
      SORT_DIRECTION_DEFAULT_VALUE,
      SORT_DIRECTION_OPTIONS,
      searchParams
    ),
  } as SortEventsRequestArgs;

  /// //////////
  // Staged filters; Awaiting user to click "Approve"
  const [stagedFilters, setStagedFilters] = useState(
    activeFilters as SortEventsRequestArgs
  );

  // //////////
  // Chip component
  const getChipLabel = () => {
    // Sort by show date (Default)
    let label = SORT_VALUE_SHOW_DATE_LABEL;

    // Sort by created date
    if (activeFilters.sort === SORT_VALUE_CREATED_DATE) {
      label = SORT_VALUE_CREATED_DATE_LABEL;
      // Sort by venue name
    } else if (activeFilters.sort === SORT_VALUE_VENUE) {
      label = SORT_VALUE_VENUE_LABEL;
    }

    return label;
  };

  // //////////
  // Dialogue component
  const [sortDialogOpen, setSortDialogOpen] = useState<boolean>(false);

  // When the url changes, update the dialogue state; Important after clearing the filters via parent
  useEffect(() => {
    setStagedFilters(activeFilters);
  }, [searchParams]);

  // //////////
  // Helper to update the url when user clicks "Approve"
  const updateURL = () => {
    searchParams.set(SORT_PARAM_NAME, stagedFilters.sort);
    searchParams.set(SORT_DIRECTION_PARAM_NAME, stagedFilters.sortDirection);

    // DO save to history
    setSearchParams(searchParams);
  };

  // //////////
  // Filter component
  return (
    <>
      <Chip
        className="green-room-chip"
        icon={
          (activeFilters.sortDirection === SORT_DIRECTION_VALUE_DESC && (
            <ArrowDownwardIcon />
          )) || <ArrowUpwardIcon />
        }
        label={getChipLabel()}
        onClick={() => {
          setSortDialogOpen(true);
        }}
      />
      <GreenRoomDialog
        open={sortDialogOpen}
        title="Sort"
        onClose={() => {
          setStagedFilters(activeFilters);
          setSortDialogOpen(false);
        }}
        actions={
          <GreenRoomButton
            type="accept"
            onClick={() => {
              updateURL();
              setSortDialogOpen(false);
            }}
          >
            Apply
          </GreenRoomButton>
        }
      >
        <>
          <FormControl fullWidth>
            <InputLabel id="sort">Sort By</InputLabel>
            <Select
              disabled // TODO: Remove when more sorts available
              labelId="sort"
              label="Sort By"
              value={stagedFilters.sort}
              onChange={(e) =>
                onSelectChange(
                  e,
                  'sort',
                  SORT_OPTIONS,
                  setStagedFilters,
                  stagedFilters
                )
              }
            >
              {getSelectOptions(SORT_OPTIONS)}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="sort-direction">Sort Direction</InputLabel>
            <Select
              labelId="sort-direction"
              label="Sort Direction"
              value={stagedFilters.sortDirection}
              onChange={(e) =>
                onSelectChange(
                  e,
                  'sortDirection',
                  SORT_DIRECTION_OPTIONS,
                  setStagedFilters,
                  stagedFilters
                )
              }
            >
              {getSelectOptions(SORT_DIRECTION_OPTIONS)}
            </Select>
          </FormControl>
        </>
      </GreenRoomDialog>
    </>
  );
}

export default SortEvents;
