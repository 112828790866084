import emptySplitApi from './empty-api-slice';

export interface UserBody {
  legalFirstName: string | null;
  legalLastName: string | null;
  preferredName: string | null;
  phoneNumber: string | null;
  zipCode: string | null;
  timeZone: string | null;
  betaAccess: boolean;
}

export interface User extends UserBody {
  id: string;
  email: string;
  intercomHash: string | null;
  createdAt: string;
}

export interface UpdateUser extends UserBody {}

export interface UpdateUserWithReferrer extends UpdateUser {
  referrer: string;
  referrerDetails: string | null;
}

export interface UpdateUserRequest {
  user: UpdateUser;
  initialSignup: boolean;
}

export const userApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => `/user`,
      providesTags: ['User'],
    }),
    updateUser: builder.mutation<void, UpdateUserRequest>({
      query: (request) => ({
        url: `/user`,
        method: 'PATCH',
        body: JSON.stringify(request.user),
        headers: {
          'Content-Type': 'application/merge-patch+json',
          'Initial-Signup': request.initialSignup ? 'true' : 'false',
        },
      }),
      invalidatesTags: ['User', 'FeatureFlags'],
    }),
    deleteUser: builder.mutation<void, void>({
      query: () => ({
        url: `/user`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useGetUserQuery, useUpdateUserMutation, useDeleteUserMutation } =
  userApiSlice;
