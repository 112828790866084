import { Input, InputAdornment, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import getFormattedAmountWithCurrencySign from '../app/helpers/currency-helpers';

interface FinancialAccountTransferAmountInputProps {
  verb: string;
  amount: number;
  // eslint-disable-next-line no-unused-vars
  setAmount: (amount: number) => void;
  cashBalance: number;
  disabled: boolean;
}

function FinancialAccountTransferAmountInput({
  verb,
  amount,
  setAmount,
  cashBalance,
  disabled,
}: FinancialAccountTransferAmountInputProps) {
  /// //////////
  // Autofocus input
  const inputToAutofocus = useRef<HTMLLabelElement>(null);

  const getFormattedAmountWithoutCurrencySign = (amt: number) =>
    getFormattedAmountWithCurrencySign(amt).substring(1);

  /// //////////
  // Dynamic-width input
  const [widthOfAmount, setWidthOfAmount] = useState<string | 0>(0); // We can ascertain the appropriate width by measuring an invisible span of the desired value
  const widthOfAmountInput = useRef<HTMLSpanElement>(null); // Unusual looking input; important to autofocus

  // When the amount changes, update the width of the input to keep it centered
  useEffect(() => {
    if (widthOfAmountInput.current) {
      setWidthOfAmount(`${widthOfAmountInput.current.offsetWidth}px`);
    }
  }, [amount]);

  const insufficientFunds = amount > cashBalance;

  return (
    <div className="modal-with-actions-body-section-extra-padded">
      {/* Note: This is a special input that has dynamic width */}
      <div className="dynamic-width-input-wrapper">
        {/* Calculate the width of the hidden element with identical contents... */}
        <span
          className="dynamic-width-input-hidden-span"
          ref={widthOfAmountInput}
        >
          <InputAdornment position="start">$</InputAdornment>
          {/* Note: Without dollar sign since it's rendered via a separate component above */}
          {getFormattedAmountWithoutCurrencySign(amount)}
        </span>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label ref={inputToAutofocus}>
          {/* ...And use that calculation to set a fixed width on the visible input */}
          <NumericFormat
            id="outbound-transfer-amount"
            defaultValue={cashBalance}
            placeholder="0.00"
            disableUnderline
            style={{ width: widthOfAmount }}
            autoFocus
            customInput={Input}
            allowNegative={false}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            value={amount / 100}
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator
            onValueChange={(values) => {
              setAmount(Math.round((values.floatValue || 0) * 100) || 0);
            }}
            disabled={disabled}
          />
        </label>
      </div>
      <Typography
        variant="body2"
        className={`greenroom-subtitle ${
          (insufficientFunds && 'greenroom-subtitle-error') || ''
        }`}
      >{`${verb} up to ${getFormattedAmountWithCurrencySign(
        cashBalance
      )}`}</Typography>
    </div>
  );
}

export default FinancialAccountTransferAmountInput;
