import React from 'react';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import OnboardingPageCreateProject from './OnboardingPageCreateProject';

interface OnboardingPageProps {
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  openChange: (open: boolean, id?: string) => void;
}

function CreateProjectDialog({ open, openChange }: OnboardingPageProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <OnboardingPageCreateProject
        onBack={() => openChange(false)}
        onNext={({ id }) => openChange(false, id)}
        nextLabel="Save"
      />
    </Dialog>
  );
}

export default CreateProjectDialog;
