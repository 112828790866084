import React from 'react';
import {
  CardContent,
  FormControl,
  TextField,
  Autocomplete,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Grid,
  InputLabel,
  Alert,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { EventPerson, Transaction } from '../app/api/events-api-slice';
import { TeamMember, Role, EditEvent } from '../app/models/edit-event';
import { EventRoleType } from '../app/api/event-role-types-api-slice';
import { Person } from '../app/api/persons-api-slice';
import CreateEditEventRoleTypeDialog, {
  EditEventRoleType,
} from '../pages/CreateEditEventRoleTypeDialog';
import CreateEditProjectMemberDialog from '../pages/CreateEditProjectMemberDialog';
import EditEventTransactionCard from './EditEventTransactionCard';
import './EditEventRoleCard.scss';
import {
  AutocompleteValue,
  getAutocompleteOptionLabel,
  getSelectedName,
  getAutocompleteOptions,
} from '../app/helpers/autocomplete-helpers';
import { PaymentType } from '../app/api/payment-types-api-slice';
import { EditPerson, getSMSDisabled } from '../app/helpers/contact-helpers';
import SMSHelperText from './SMSHelperText';
import EmailHelperText from './EmailHelperText';
import { useGetProjectEntitlementsQuery } from '../app/api/projects-api-slice';
import PremiumFeatureChip from './PremiumFeatureChip';

interface EditEventRoleCardProps {
  editEvent: EditEvent;
  roles: EventRoleType[] | undefined;
  persons: Person[] | undefined;
  paymentTypes: PaymentType[] | undefined;
  eventPerson: EventPerson | null;
  // eslint-disable-next-line no-unused-vars
  onEditEventPerson: (eventPerson: EventPerson) => void;
  eventTransaction: Transaction | null;
  // eslint-disable-next-line no-unused-vars
  onEditEventTransaction: (eventTransaction: Transaction | null) => void;
}

function EditEventRoleCard({
  roles,
  persons,
  paymentTypes,
  editEvent,
  eventPerson,
  onEditEventPerson,
  eventTransaction,
  onEditEventTransaction,
}: EditEventRoleCardProps) {
  const getProjectEntitlements = useGetProjectEntitlementsQuery(
    editEvent.projectId!
  );

  /// //////////
  // Media queries
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  /// //////////
  // Create new role with Dialog
  const newRole = {
    id: null,
    name: '',
    projectId: editEvent.projectId,
  } as EditEventRoleType;

  const [newRoleDialogProps, setNewRoleDialogProps] = React.useState({
    open: false,
    role: newRole,
  });

  /// //////////
  // Find person associated with eventPerson
  const editPerson = persons?.find(
    (person) => person.id === eventPerson?.personId
  );

  /// //////////
  // Selected event persons's role
  const changeRole = (changedRole: Role) => {
    const newEventPerson: EventPerson = {
      id: eventPerson?.id || null,
      personId: eventPerson?.personId || null,
      isAttending: eventPerson?.isAttending || null,
      eventRoleTypeId: changedRole?.id || null,
      sendEmail: eventPerson?.sendEmail || false,
      sendText: eventPerson?.sendText || false,
    };

    onEditEventPerson(newEventPerson);
  };

  /// //////////
  // Create new team member with Dialog
  const newTeamMember = {
    id: null,
    name: '',
    email: null,
    projectId: editEvent.projectId,
  } as EditPerson;

  const [newTeamMemberDialogProps, setNewTeamMemberDialogProps] =
    React.useState({
      open: false,
      person: newTeamMember,
    });

  /// //////////
  // Selected event persons's team member
  const changeTeamMember = (changedTeamMember: TeamMember) => {
    const newEventPerson: EventPerson = {
      id: eventPerson?.id || null,
      personId: changedTeamMember?.id || null,
      isAttending: eventPerson?.isAttending || null,
      eventRoleTypeId: eventPerson?.eventRoleTypeId || null,
      sendEmail: changedTeamMember?.defaultSendEmail || false,
      sendText: changedTeamMember?.defaultSendText || false,
    };

    onEditEventPerson(newEventPerson);
  };

  return (
    <>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                value={getSelectedName(roles, eventPerson?.eventRoleTypeId)}
                onChange={(event, newValue: AutocompleteValue) => {
                  if (
                    newValue &&
                    (newValue.inputValue || newValue.inputValue === '')
                  ) {
                    // Create a new value from the user input
                    setNewRoleDialogProps({
                      open: true,
                      role: { ...newRole, name: newValue.inputValue },
                    });
                  } else {
                    changeRole(newValue);
                  }
                }}
                filterOptions={(options, params) =>
                  getAutocompleteOptions(options, params, 'role')
                }
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="person-role"
                options={roles!}
                getOptionLabel={(option) => getAutocompleteOptionLabel(option)}
                renderOption={(props, option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <li {...props}>{option.name}</li>
                )}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="standard"
                    label="Role"
                    required
                    className="gr-edit-event-edit-event-role-card-role"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                value={getSelectedName(persons, eventPerson?.personId)}
                onChange={(event, newValue: AutocompleteValue) => {
                  if (typeof newValue === 'string') {
                    changeTeamMember({
                      name: newValue,
                    });
                  } else if (
                    newValue &&
                    (newValue.inputValue || newValue.inputValue === '')
                  ) {
                    // Create a new value from the user input
                    setNewTeamMemberDialogProps({
                      open: true,
                      person: {
                        ...newTeamMember,
                        name: newValue.inputValue,
                      },
                    });
                  } else {
                    changeTeamMember(newValue);
                  }
                }}
                filterOptions={(options, params) =>
                  getAutocompleteOptions(options, params, 'person')
                }
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="person-name"
                options={persons!}
                getOptionLabel={(option) => getAutocompleteOptionLabel(option)}
                renderOption={(props, option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <li {...props}>{option.name}</li>
                )}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="standard"
                    label="Team Member"
                    className="gr-edit-event-edit-event-role-card-team-member"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <InputLabel htmlFor="event-person-attendance">
              Attendance
            </InputLabel>
            <ToggleButtonGroup
              exclusive
              id="event-person-attendance"
              value={String(eventPerson?.isAttending)}
              onChange={(e, value) => {
                onEditEventPerson({
                  id: eventPerson?.id || null,
                  personId: eventPerson?.personId || null,
                  isAttending: JSON.parse(value),
                  eventRoleTypeId: eventPerson?.eventRoleTypeId || null,
                  sendEmail: eventPerson?.sendEmail || false,
                  sendText: eventPerson?.sendText || false,
                });
              }}
              sx={{ width: '100%' }}
            >
              <ToggleButton className="event-person-toggle-button" value="null">
                <AccessTimeIcon color="disabled" />
                <Typography variant="body2">Pending</Typography>
              </ToggleButton>
              <ToggleButton className="event-person-toggle-button" value="true">
                <CheckCircleIcon color="success" />
                <Typography variant="body2">Attending</Typography>
              </ToggleButton>
              <ToggleButton
                className="event-person-toggle-button"
                value="false"
              >
                <CancelIcon color="error" />
                <Typography variant="body2">Declined</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(_, checked) => {
                    onEditEventPerson({
                      id: eventPerson?.id || null,
                      personId: eventPerson?.personId || null,
                      isAttending: eventPerson?.isAttending || null,
                      eventRoleTypeId: eventPerson?.eventRoleTypeId || null,
                      sendEmail: checked,
                      sendText: eventPerson?.sendText || false,
                    });
                  }}
                  checked={!!eventPerson?.sendEmail}
                  disabled={!editPerson?.email}
                  className="gr-edit-event-edit-event-role-card-send-email"
                />
              }
              label="Send Email Updates"
            />
            <EmailHelperText
              editPerson={editPerson}
              onClick={() =>
                editPerson &&
                setNewTeamMemberDialogProps({
                  open: true,
                  person: editPerson,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(_, checked) => {
                    onEditEventPerson({
                      id: eventPerson?.id || null,
                      personId: eventPerson?.personId || null,
                      isAttending: eventPerson?.isAttending || null,
                      eventRoleTypeId: eventPerson?.eventRoleTypeId || null,
                      sendEmail: eventPerson?.sendEmail || false,
                      sendText: checked,
                    });
                  }}
                  checked={!!eventPerson?.sendText}
                  disabled={getSMSDisabled(
                    editPerson,
                    !!eventPerson?.sendText,
                    false,
                    !!getProjectEntitlements.data?.sms
                  )}
                  className="gr-edit-event-edit-event-role-card-send-text"
                />
              }
              label="Send Text Updates"
            />
            {!getProjectEntitlements.data?.sms && (
              <PremiumFeatureChip
                projectId={editEvent.projectId!}
                size="small"
              />
            )}
            {getProjectEntitlements.data?.sms && (
              <SMSHelperText
                editPerson={editPerson}
                newPhoneNumber={false}
                onClick={() =>
                  editPerson &&
                  setNewTeamMemberDialogProps({
                    open: true,
                    person: editPerson,
                  })
                }
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  onChange={async () => {
                    if (!eventTransaction) {
                      const newTransaction: Transaction = {
                        id: uuid(),
                        type: 'Expense' as Transaction['type'],
                        transactor: null,
                        description: '',
                        amount: 0,
                        isPaid: false,
                        paidDate: null,
                        eventPersonId: eventPerson?.id || null,
                        paymentTypeId: null,
                        projectId: editEvent.projectId,
                        excludeFromReports: false,
                      };

                      onEditEventTransaction(newTransaction);
                    } else {
                      onEditEventTransaction(null);
                    }
                  }}
                  checked={!!eventTransaction}
                  className="gr-edit-event-edit-event-role-card-paid-role"
                />
              }
              label="Paid Role"
            />
          </Grid>
          {!!eventTransaction && (
            <>
              {' '}
              <Alert severity="info" sx={{ margin: '8px' }}>
                Only you and other project admins can see finances related to
                this role.
              </Alert>
              <EditEventTransactionCard
                transaction={eventTransaction}
                projectId={editEvent.projectId}
                paymentTypes={paymentTypes}
                onEditEventTransaction={onEditEventTransaction}
                embedded
              />
            </>
          )}
        </Grid>
      </CardContent>
      <CreateEditEventRoleTypeDialog
        role={newRoleDialogProps.role}
        fullScreen={fullScreen}
        open={newRoleDialogProps.open}
        onClose={(id) => {
          changeRole({
            name: getSelectedName(roles, id),
            id,
          });

          setNewRoleDialogProps({ ...newRoleDialogProps, open: false });
        }}
      />
      <CreateEditProjectMemberDialog
        person={newTeamMemberDialogProps.person}
        fullScreen={fullScreen}
        open={newTeamMemberDialogProps.open}
        onClose={(id) => {
          // If new member created
          if (id) {
            changeTeamMember({
              name: getSelectedName(persons, id),
              id,
            });
          }

          setNewTeamMemberDialogProps({
            ...newTeamMemberDialogProps,
            open: false,
          });
        }}
      />
    </>
  );
}

export default EditEventRoleCard;
