import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import React from 'react';
import { useGetEventRoleTypesQuery } from '../app/api/event-role-types-api-slice';
import { Project } from '../app/api/projects-api-slice';
import GreenRoomButton from '../components/GreenRoomButton';
import ProjectRoles from '../components/ProjectRoles';

export interface OnboardingPageProjectRolesProps {
  project: Project;
  onNext: () => void;
}

function OnboardingPageProjectRoles({
  project,
  onNext,
}: OnboardingPageProjectRolesProps) {
  const getEventRoleTypes = useGetEventRoleTypesQuery({
    projectId: project.id,
  });

  return (
    <>
      <DialogTitle>
        What do members do in <strong>{project.name}</strong>?
      </DialogTitle>
      <DialogContent>
        <div className="onboarding-content">
          <ProjectRoles projectId={project.id} hideTitle />
        </div>
      </DialogContent>
      <DialogActions>
        <GreenRoomButton type="cancel" onClick={onNext}>
          Skip
        </GreenRoomButton>
        <GreenRoomButton
          type="accept"
          onClick={() => {
            onNext();
          }}
          disabled={
            getEventRoleTypes.isLoading || getEventRoleTypes.data?.length === 0
          }
        >
          Next
        </GreenRoomButton>
      </DialogActions>
    </>
  );
}

export default OnboardingPageProjectRoles;
