import React, { RefObject, useState } from 'react';
import { TextField, Grid, FormHelperText } from '@mui/material';
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import './EditDatePicker.scss';

interface EditDatePickerProps {
  dateLabel: string;
  timeLabel: string;
  value: string | null;
  minDateTime?: string | null;
  maxDateTime?: string | null;
  // eslint-disable-next-line no-unused-vars
  onChange: (date: Moment | null) => void;
  dateRequired?: boolean;
  dateDisabled?: boolean;
  helperText?: React.ReactElement<any> | null;
  innerRef?: RefObject<any>;
}

function EditDatePicker({
  dateLabel,
  timeLabel,
  value,
  minDateTime,
  maxDateTime,
  onChange,
  dateRequired,
  dateDisabled,
  helperText,
  innerRef,
}: EditDatePickerProps) {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [timePickerOpen, setTimePickerOpen] = useState(false);

  // The minTime prop on TimePicker fails to account for if the event timestamp and minTime timestamp are on different days
  const getMinTimeTakingIntoAccountDate = () => {
    let enhancedMinDateTime;

    if (minDateTime) {
      const isSameOrBefore = moment(value).isSameOrBefore(minDateTime, 'day');

      if (isSameOrBefore) enhancedMinDateTime = moment(minDateTime);
    }

    return enhancedMinDateTime;
  };

  // The maxTime prop on TimePicker fails to account for if the event timestamp and minTime timestamp are on different days
  const getMaxTimeTakingIntoAccountDate = () => {
    let enhancedMaxDateTime;

    if (maxDateTime) {
      const isSameOrAfter = moment(value).isSameOrAfter(maxDateTime, 'day');

      if (isSameOrAfter) enhancedMaxDateTime = moment(maxDateTime);
    }

    return enhancedMaxDateTime;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label ref={innerRef}>
              <DatePicker
                value={value}
                onChange={onChange}
                open={datePickerOpen}
                onClose={() => setDatePickerOpen(false)}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    fullWidth
                    required={dateRequired}
                    autoComplete="off"
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                      onClick: () => setDatePickerOpen(true),
                      onKeyDown: (event) => {
                        if (event.key === 'Enter' || event.key === ' ')
                          setDatePickerOpen(true);
                      },
                    }}
                    className="edit-date-picker-input"
                  />
                )}
                disableOpenPicker
                label={dateLabel}
                minDate={moment(minDateTime)}
                maxDate={moment(maxDateTime)}
                className="edit-date-picker"
                disabled={dateDisabled}
                showToolbar
              />
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <TimePicker
              disabled={!value}
              value={value}
              onChange={onChange}
              open={timePickerOpen}
              onClose={() => setTimePickerOpen(false)}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  fullWidth
                  autoComplete="off"
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                    onClick: () => setTimePickerOpen(true),
                    onKeyDown: (event) => {
                      if (event.key === 'Enter' || event.key === ' ')
                        setTimePickerOpen(true);
                    },
                  }}
                  className="edit-time-picker-input"
                />
              )}
              disableOpenPicker
              minutesStep={5}
              label={timeLabel}
              minTime={getMinTimeTakingIntoAccountDate()}
              maxTime={getMaxTimeTakingIntoAccountDate()}
              showToolbar
            />
          </Grid>
        </Grid>
        <FormHelperText>{helperText}</FormHelperText>
      </>
    </LocalizationProvider>
  );
}

EditDatePicker.defaultProps = {
  minDateTime: null,
  maxDateTime: null,
  dateRequired: false,
  dateDisabled: false,
  helperText: null,
  innerRef: null,
};

export default EditDatePicker;
