import React, { useEffect, useRef } from 'react';
import { Divider, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  useCreateOutboundTransferRequestMutation,
  useGetExternalAccountsQuery,
} from '../../app/api/project-financial-account-slice';
import ExternalAccountSelect from '../../components/ExternalAccountSelect';
import ServerErrorDisplay from '../../components/ServerErrorDisplay';
import './FinancialAccountOutboundTransferDialog.scss';
import Loading from '../../components/Loading';
import GreenRoomDialog from '../../components/Dialog/GreenRoomDialog';
import GreenRoomButton from '../../components/GreenRoomButton';
import FinancialAccountTransferAmountInput from '../FinancialAccountTransferAmountInput';
import getFormattedAmountWithCurrencySign from '../../app/helpers/currency-helpers';

// TODO: Componentize dynamic-width input if needed elsewhere

interface FinancialAccountOutboundTransferDialogProps {
  open: boolean;
  onClose: () => void;
  projectId: string;
  cashBalance: number;
}

function FinancialAccountOutboundTransferDialog({
  open,
  onClose,
  projectId,
  cashBalance,
}: FinancialAccountOutboundTransferDialogProps) {
  /// //////////
  // Notifications
  const { enqueueSnackbar } = useSnackbar();

  /// //////////
  // Form states
  const getExternalAccountsQuery = useGetExternalAccountsQuery(projectId);
  const [externalAccountId, setExternalAccountId] = React.useState<
    string | null
  >(null);
  const [createOutboundTransferMutation, createOutboundTransferMutationStatus] =
    useCreateOutboundTransferRequestMutation();
  const [amount, setAmount] = React.useState(0);
  // Boolean to allow rendering first dialog state after a successful transfer
  const [formIsDirty, setFormIsDirty] = React.useState<boolean>(false);

  const insufficientFunds = amount > cashBalance;
  const formDisabled =
    !externalAccountId ||
    !amount ||
    createOutboundTransferMutationStatus.isLoading ||
    insufficientFunds;

  const handleClose = () => {
    if (createOutboundTransferMutationStatus.isLoading) {
      return;
    }
    onClose();
    setFormIsDirty(false);
    setAmount(cashBalance);
  };

  /// //////////
  // Form submission
  useEffect(() => {
    if (formIsDirty && createOutboundTransferMutationStatus.isSuccess) {
      enqueueSnackbar('Transfer was requested successfully.', {
        variant: 'success',
      });
    }
    if (formIsDirty && createOutboundTransferMutationStatus.isError) {
      const errorDisplay = (
        <ServerErrorDisplay
          error={createOutboundTransferMutationStatus.error}
          errorMessage="There was a problem requesting the transfer."
        />
      );
      enqueueSnackbar(errorDisplay, {
        variant: 'error',
      });
    }
  }, [createOutboundTransferMutationStatus]);

  /// //////////
  // Autofocus input
  const inputToAutofocus = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    setAmount(cashBalance);
  }, [cashBalance]);

  /// //////////
  // Autofocus input on open
  useEffect(() => {
    if (inputToAutofocus.current) {
      inputToAutofocus.current.focus();
    }
  }, [open]);

  /// //////////
  // Conditional Dialog content
  let title;
  let actions;
  let body;

  /// //////////
  // Set submitting state
  if (createOutboundTransferMutationStatus.isLoading) {
    title = 'Transfer Pending';
    body = (
      <div style={{ marginTop: '8px', overflow: 'hidden' }}>
        <Loading />
      </div>
    );
  }

  /// //////////
  // Set success state
  if (createOutboundTransferMutationStatus.isSuccess) {
    const transferredAmount = getFormattedAmountWithCurrencySign(
      createOutboundTransferMutationStatus.originalArgs?.payload.amount
    );

    const transferredAccountId =
      createOutboundTransferMutationStatus.originalArgs?.payload
        .externalAccountId;

    // Note: We can trust getExternalAccountsQuery.data is defined because it is required for previous modal states
    // Important: If this modal state is broken out, need to consider fetching and error states
    const transferredAccount = getExternalAccountsQuery.data?.find(
      (acct) => acct.id === transferredAccountId
    );
    const transferredAccountName = transferredAccount?.bankName;
    const transferredAccountPin = transferredAccount?.last4;

    title = 'Transfer Initiated';
    actions = (
      <GreenRoomButton type="accept" onClick={handleClose}>
        Ok
      </GreenRoomButton>
    );
    body = (
      <>
        <span
          style={{ marginTop: '16px' }}
          className="modal-with-actions-body-section-padded"
        >
          A funds transfer to <strong>{transferredAccountName}</strong> account
          ending in <strong>{transferredAccountPin}</strong> for{' '}
          <strong>{transferredAmount}</strong> has been initiated.
        </span>
        <Typography
          variant="caption"
          className="modal-with-actions-body-section-padded"
        >
          Bank transfers initiated before 6pm CST on business days will
          typically be available in your bank account the next business day, but
          it can take up to 3 business days before funds arrive.{' '}
          <a
            href="https://help.greenroom.app/ach-external-transfer-timeline"
            target="_blank"
            rel="noreferrer"
          >
            Learn more...
          </a>
        </Typography>
      </>
    );
  }

  /// //////////
  // Set initial form state
  if (!formIsDirty && !createOutboundTransferMutationStatus.isLoading) {
    title = 'Transfer';
    actions = (
      <GreenRoomButton
        type="transfer"
        onClick={() => {
          createOutboundTransferMutation({
            projectId,
            payload: { amount, externalAccountId: externalAccountId! },
          });
          setFormIsDirty(true);
        }}
        disabled={formDisabled}
      >
        {(formDisabled && 'Transfer') ||
          `Transfer ${getFormattedAmountWithCurrencySign(amount)}`}
      </GreenRoomButton>
    );
    body = (
      <>
        <FinancialAccountTransferAmountInput
          verb="Transfer"
          amount={amount}
          setAmount={setAmount}
          cashBalance={cashBalance}
          disabled={createOutboundTransferMutationStatus.isLoading}
        />
        <Divider className="modal-with-actions-divider" />
        <ExternalAccountSelect
          projectId={projectId}
          id={externalAccountId}
          onChange={setExternalAccountId}
          label="Transfer to"
          disabled={createOutboundTransferMutationStatus.isLoading}
          className="modal-with-actions-body-section-padded"
        />
        <Typography
          variant="caption"
          className="modal-with-actions-body-section-padded"
        >
          Transfer speeds vary and could take up to 3 business days to complete.{' '}
          <a
            href="https://help.greenroom.app/ach-external-transfer-timeline"
            target="_blank"
            rel="noreferrer"
          >
            Learn more...
          </a>
        </Typography>
      </>
    );
  }

  return (
    <GreenRoomDialog
      open={open}
      title={title}
      onClose={handleClose}
      onCloseDisabled={createOutboundTransferMutationStatus.isLoading}
      actions={actions}
    >
      {body}
    </GreenRoomDialog>
  );
}

export default FinancialAccountOutboundTransferDialog;
