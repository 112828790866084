import React from 'react';
import { useNavigate } from 'react-router-dom';
import GreenRoomButton from './GreenRoomButton';
import Heading from './Text/Heading';

function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <div>
      <Heading>Not Found</Heading>
      <p>The page could not be found.</p>
      <GreenRoomButton type="accept" onClick={() => navigate('/')}>
        Go home
      </GreenRoomButton>
    </div>
  );
}

export default NotFoundPage;
