import { Project } from '../api/projects-api-slice';

const getProjectCrumb = (
  projectId: string | null,
  projects: Project[] | [],
  // eslint-disable-next-line no-unused-vars
  onChange: (id: any) => void,
  redirectLinkIfNotFound: string,
  showPersonalProject: boolean = false
) => {
  const projectIdIsValid =
    (showPersonalProject && projectId === 'personal') ||
    projects.find((project: Project) => project.id === projectId);

  const projectSelectOptions = [{ name: 'Personal', id: 'personal' }].concat(
    projects.map((project: Project) => ({
      name: project.name,
      id: project.id,
    }))
  );

  if (!showPersonalProject) {
    projectSelectOptions.shift();
  }

  let projectCrumb: any;

  // If the project id in the url is not valid, render a reload link;
  if (projectId && !projectIdIsValid) {
    projectCrumb = {
      name: 'Project not found \u{21BB} Reload',
      to: redirectLinkIfNotFound,
    };
  } else if (projectIdIsValid) {
    // If one project exists, the project crumb is static;
    if (projectSelectOptions.length === 1) {
      projectCrumb = {
        name: projectSelectOptions[0].name,
      };
    }
    // If more than one project exists, the project crumb is a select;
    if (projectSelectOptions.length > 1) {
      projectCrumb = {
        name: 'Project',
        options: projectSelectOptions,
        value: projectId,
        onChange,
      };
    }
  }

  return projectCrumb;
};

export default getProjectCrumb;
