import emptySplitApi from './empty-api-slice';

export interface FeatureFlags extends Record<string, string | boolean> {}

export const featureFlagsApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<FeatureFlags, void>({
      query: () => `/feature_flags`,
      providesTags: ['FeatureFlags'],
    }),
  }),
});

export const { useGetFeatureFlagsQuery } = featureFlagsApiSlice;
