import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
import Swal from 'sweetalert2/dist/sweetalert2';
import { useSnackbar } from 'notistack';
import { Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SortTransactions, {
  SORT_PARAM_NAME,
  SORT_DEFAULT_VALUE,
  SORT_DIRECTION_PARAM_NAME,
  SORT_DIRECTION_DEFAULT_VALUE,
  SortTransactionsRequestArgs,
} from './SortTransactions';
import FilterTransactionsByDate, {
  DATE_FROM_PARAM_NAME,
  DATE_TO_PARAM_NAME,
  DATE_RANGE_PARAM_NAME,
  DATE_RANGE_DEFAULT_VALUE,
  DATE_RANGE_VALUE_ALL_TIME,
  DATE_RANGE_VALUE_CUSTOM,
  FilterTransactionsByDateRequestArgs,
} from './FilterTransactionsByDate';
import FilterTransactions, {
  FilterTransactionsRequestArgs,
  PAY_STATUS_PARAM_NAME,
  TYPE_PARAM_NAME,
  PAYMENT_TYPES_PARAM_NAME,
} from './FilterTransactions';
import shallowEquals from '../../../app/helpers/shallow-equals';
import {
  GetProjectTransactionsRequest,
  useExportProjectTransactionsMutation,
} from '../../../app/api/transactions-api-slice';

/// //////////
// Types
export type FilterRequestArgs = SortTransactionsRequestArgs &
  FilterTransactionsByDateRequestArgs &
  FilterTransactionsRequestArgs;
interface TransactionsFiltersProps {
  // eslint-disable-next-line no-unused-vars
  onUpdateFilterRequestArgs: (filterRequestArgs: FilterRequestArgs) => void;
  requestArgs: GetProjectTransactionsRequest;
}

function TransactionsFilters({
  onUpdateFilterRequestArgs,
  requestArgs,
}: TransactionsFiltersProps) {
  const [exportProjectTransactionsMutation] =
    useExportProjectTransactionsMutation();
  const snackbar = useSnackbar();
  /// //////////
  // Navigation
  const [searchParams, setSearchParams] = useSearchParams();

  /// //////////
  // Required filters; Optionally restored to default when clearing filters
  const requiredFilters = [
    {
      name: SORT_PARAM_NAME,
      defaultValue: SORT_DEFAULT_VALUE,
      resetOnClear: false,
    },
    {
      name: SORT_DIRECTION_PARAM_NAME,
      defaultValue: SORT_DIRECTION_DEFAULT_VALUE,
      resetOnClear: false,
    },
    {
      name: DATE_RANGE_PARAM_NAME,
      defaultValue: DATE_RANGE_DEFAULT_VALUE,
      resetOnClear: true,
    },
  ];

  /// //////////
  // Non-required filters; Deleted from URL when clearing filters
  const optionalFilters = [
    DATE_FROM_PARAM_NAME,
    DATE_TO_PARAM_NAME,
    TYPE_PARAM_NAME,
    PAY_STATUS_PARAM_NAME,
    PAYMENT_TYPES_PARAM_NAME,
  ];

  // //////////
  // Some filter params should be set to default values on load; Helper to track if they are set
  const isUnitialized = (urlParams: URLSearchParams) => {
    const unitializedFilters = requiredFilters.filter(
      (filter) => !urlParams.get(filter.name)
    );

    return !!unitializedFilters.length;
  };

  // //////////
  // Check if non-default filters are applied
  const isClearFiltersButtonVisible = (urlParams: URLSearchParams) => {
    const resettableRequiredFilters = requiredFilters.filter(
      (filter) =>
        filter.resetOnClear &&
        urlParams.get(filter.name) &&
        urlParams.get(filter.name) !== filter.defaultValue
    );

    const resettableOptionalFields = optionalFilters.filter((filterName) =>
      urlParams.get(filterName)
    );

    return (
      !!resettableRequiredFilters.length || !!resettableOptionalFields.length
    );
  };

  // //////////
  // On button click, reset filters to default and save url to history
  const clearFilters = () => {
    // Reset required filters to default values if flagged
    requiredFilters.forEach((filter) => {
      if (filter.resetOnClear)
        searchParams.set(filter.name, filter.defaultValue);
    });

    // Delete all optional filter parameters
    optionalFilters.forEach((filterName) => {
      searchParams.delete(filterName);
    });

    // DO save to history
    setSearchParams(searchParams, { replace: true });
  };

  // //////////
  // Save old filters to compare new filters against
  const [previousFilters, setPreviousFilters] = useState<
    FilterRequestArgs | {}
  >({});

  // //////////
  // When URL updates, add missing required defaults to URL or fire callback to refetch data
  useEffect(() => {
    // //////////
    // If component is unitialized, set the missing params first, which will cause a refire of this useEffect
    if (isUnitialized(searchParams)) {
      requiredFilters.forEach((filter) => {
        if (!searchParams.get(filter.name)) {
          searchParams.set(filter.name, filter.defaultValue);
        }
      });

      // DO NOT save to history
      setSearchParams(searchParams, { replace: true });
    } else {
      // //////////
      // Pluck or derive the from and to params
      const dateRange = searchParams.get(DATE_RANGE_PARAM_NAME);
      let from;
      let to;

      if (dateRange === DATE_RANGE_VALUE_CUSTOM) {
        from = searchParams.get(DATE_FROM_PARAM_NAME);
        to = searchParams.get(DATE_TO_PARAM_NAME);
      } else if (dateRange !== DATE_RANGE_VALUE_ALL_TIME) {
        from = moment()
          .subtract(Number(dateRange), 'days')
          .format('YYYY-MM-DD');
        to = moment().format('YYYY-MM-DD');
      }

      // //////////
      // New filter request args
      const newFilters = {
        sort: searchParams.get(SORT_PARAM_NAME),
        sortDirection: searchParams.get(SORT_DIRECTION_PARAM_NAME),
        from,
        to,
        type: searchParams.get(TYPE_PARAM_NAME),
        isPaid: searchParams.get(PAY_STATUS_PARAM_NAME),
        paymentTypeIds: searchParams.get(PAYMENT_TYPES_PARAM_NAME),
      } as FilterRequestArgs;

      // //////////
      // Fire callback with new filter request args
      // NOTE: The if condition is to avoid resetting the page number when irrelevant search params (like selected transactionId) change
      if (!shallowEquals(newFilters, previousFilters))
        onUpdateFilterRequestArgs(newFilters);

      setPreviousFilters(newFilters);
    }
  }, [searchParams]);

  // //////////
  // Filters wrapper component
  return (
    <div>
      <FilterTransactionsByDate />
      <FilterTransactions />
      <SortTransactions />
      <Chip
        className="green-room-chip"
        label="Export"
        color="info"
        icon={<FileDownloadIcon />}
        onClick={() => {
          Swal.fire({
            title: 'Export Transactions?',
            text: `We will generate a CSV file of your transactions based on your current filters and email it to you when it is ready. Would you like to proceed?`,
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
          }).then((result) => {
            if (result.isConfirmed) {
              exportProjectTransactionsMutation(requestArgs)
                .then(() => {
                  snackbar.enqueueSnackbar('Exporting Transactions...', {
                    variant: 'success',
                  });
                })
                .catch(() => {
                  snackbar.enqueueSnackbar('Error exporting transactions', {
                    variant: 'error',
                  });
                });
            }
          });
        }}
      />
      {isClearFiltersButtonVisible(searchParams) && (
        <Chip
          variant="outlined"
          onClick={clearFilters}
          onDelete={clearFilters}
          label="Clear filters"
          deleteIcon={<DeleteIcon />}
          color="secondary"
          className="green-room-chip"
        />
      )}
    </div>
  );
}

export default TransactionsFilters;
