import { Button } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import React from 'react';
import './SupportLinks.scss';

function SupportLinks() {
  return (
    <div className="support-links">
      <Button
        startIcon={<HelpIcon />}
        href="https://help.greenroom.app/"
        target="_blank"
        className="support-link"
      >
        Help & Support
      </Button>
      <Button
        startIcon={<BugReportOutlinedIcon />}
        href="mailto:support@greenroom.app"
        className="support-link"
      >
        Report A Bug
      </Button>
      <Button
        startIcon={<TipsAndUpdatesOutlinedIcon />}
        href="https://ideas.greenroom.app/ideas"
        target="_blank"
        className="support-link"
      >
        Submit An Idea
      </Button>
      <Button
        startIcon={<CalendarTodayIcon />}
        href="https://calendly.com/d/g35-6dy-j5f/chat-with-green-room"
        target="_blank"
        className="support-link"
      >
        Talk To Someone
      </Button>
      <Button
        startIcon={<AutoAwesomeOutlinedIcon />}
        href="https://changelog.greenroom.app/"
        target="_blank"
        className="support-link"
      >
        What&apos;s New
      </Button>
    </div>
  );
}

export default SupportLinks;
