import React, { useEffect, useRef } from 'react';
import { Divider, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCreateOutboundPaymentRequestMutation } from '../../app/api/project-financial-account-slice';
import ServerErrorDisplay from '../../components/ServerErrorDisplay';
import './FinancialAccountOutboundTransferDialog.scss';
import Loading from '../../components/Loading';
import GreenRoomDialog from '../../components/Dialog/GreenRoomDialog';
import GreenRoomButton from '../../components/GreenRoomButton';
import FinancialAccountTransferAmountInput from '../FinancialAccountTransferAmountInput';
import getFormattedAmountWithCurrencySign from '../../app/helpers/currency-helpers';

// TODO: Componentize dynamic-width input if needed elsewhere

interface FinancialAccountOutboundPaymentDialogProps {
  open: boolean;
  onClose: () => void;
  projectId: string;
  cashBalance: number;
}

function FinancialAccountOutboundPaymentDialog({
  open,
  onClose,
  projectId,
  cashBalance,
}: FinancialAccountOutboundPaymentDialogProps) {
  /// //////////
  // Notifications
  const { enqueueSnackbar } = useSnackbar();

  /// //////////
  // Form states
  const [destinationEmail, setDestinationEmail] = React.useState<string | null>(
    null
  );
  const [description, setDescription] = React.useState<string | null>(null);
  const [createOutboundTransferMutation, createOutboundTransferMutationStatus] =
    useCreateOutboundPaymentRequestMutation();
  const [amount, setAmount] = React.useState(0);
  // Boolean to allow rendering first dialog state after a successful transfer
  const [formIsDirty, setFormIsDirty] = React.useState<boolean>(false);

  const insufficientFunds = amount > cashBalance;
  const formDisabled =
    !destinationEmail ||
    !amount ||
    createOutboundTransferMutationStatus.isLoading ||
    insufficientFunds;

  const handleClose = () => {
    if (createOutboundTransferMutationStatus.isLoading) {
      return;
    }
    onClose();
    setFormIsDirty(false);
  };

  /// //////////
  // Form submission
  useEffect(() => {
    if (formIsDirty && createOutboundTransferMutationStatus.isSuccess) {
      enqueueSnackbar('Transfer was requested successfully.', {
        variant: 'success',
      });
    }
    if (formIsDirty && createOutboundTransferMutationStatus.isError) {
      const errorDisplay = (
        <ServerErrorDisplay
          error={createOutboundTransferMutationStatus.error}
          errorMessage="There was a problem requesting the transfer."
        />
      );
      enqueueSnackbar(errorDisplay, {
        variant: 'error',
      });
      setFormIsDirty(false);
    }
  }, [createOutboundTransferMutationStatus]);

  /// //////////
  // Autofocus input
  const inputToAutofocus = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    setAmount(0);
  }, [cashBalance]);

  /// //////////
  // Autofocus input on open
  useEffect(() => {
    if (inputToAutofocus.current) {
      inputToAutofocus.current.focus();
    }
  }, [open]);

  /// //////////
  // Conditional Dialog content
  let title;
  let actions;
  let body;

  /// //////////
  // Set submitting state
  if (createOutboundTransferMutationStatus.isLoading) {
    title = 'Transfer Pending';
    body = (
      <div style={{ marginTop: '8px', overflow: 'hidden' }}>
        <Loading />
      </div>
    );
  }

  /// //////////
  // Set success state
  if (createOutboundTransferMutationStatus.isSuccess) {
    const transferredAmount = getFormattedAmountWithCurrencySign(
      createOutboundTransferMutationStatus.originalArgs?.payload.amount
    );

    title = 'Transfer Initiated';
    actions = (
      <GreenRoomButton type="accept" onClick={handleClose}>
        Ok
      </GreenRoomButton>
    );
    body = (
      <>
        <span
          style={{ marginTop: '16px' }}
          className="modal-with-actions-body-section-padded"
        >
          A payment to{' '}
          <strong>
            {
              createOutboundTransferMutationStatus.originalArgs?.payload
                .destinationEmail
            }
          </strong>{' '}
          for <strong>{transferredAmount}</strong> has been initiated.
        </span>
        <Typography
          variant="caption"
          className="modal-with-actions-body-section-padded"
        >
          Payments are normally instant, but can take up to 3 business days.{' '}
          <a
            href="https://help.greenroom.app/pay-other-user-timeline"
            target="_blank"
            rel="noreferrer"
          >
            Learn more...
          </a>
        </Typography>
      </>
    );
  }

  /// //////////
  // Set initial form state
  if (!formIsDirty && !createOutboundTransferMutationStatus.isLoading) {
    title = 'Pay Other User';
    actions = (
      <GreenRoomButton
        type="transfer"
        onClick={() => {
          createOutboundTransferMutation({
            projectId,
            payload: {
              amount,
              destinationEmail: destinationEmail!,
              description,
            },
          });
          setFormIsDirty(true);
        }}
        disabled={formDisabled}
      >
        {(formDisabled && 'Transfer') ||
          `Transfer ${getFormattedAmountWithCurrencySign(amount)}`}
      </GreenRoomButton>
    );
    body = (
      <>
        <FinancialAccountTransferAmountInput
          verb="Send"
          amount={amount}
          setAmount={setAmount}
          cashBalance={cashBalance}
          disabled={createOutboundTransferMutationStatus.isLoading}
        />
        <Divider className="modal-with-actions-divider" />
        <TextField
          label="Send To Email Address"
          placeholder="user@example.com"
          required
          fullWidth
          value={destinationEmail}
          onChange={(e) => setDestinationEmail(e.target.value)}
        />
        <TextField
          label="Description"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Typography
          variant="caption"
          className="modal-with-actions-body-section-padded"
        >
          Payments are normally instant, but can take up to 3 business days.{' '}
          <a
            href="https://help.greenroom.app/pay-other-user-timeline"
            target="_blank"
            rel="noreferrer"
          >
            Learn more...
          </a>
        </Typography>
      </>
    );
  }

  return (
    <GreenRoomDialog
      open={open}
      title={title}
      onClose={handleClose}
      onCloseDisabled={createOutboundTransferMutationStatus.isLoading}
      actions={actions}
    >
      {body}
    </GreenRoomDialog>
  );
}

export default FinancialAccountOutboundPaymentDialog;
