import { MenuProps } from '@mui/material';

/// //////////
// Select Menu
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const SELECT_MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
} as Partial<MenuProps> | undefined;

export const isSomeSelected = (newSelected: string[]) => newSelected.length > 0;
export const isAllSelected = (newSelected: string[], selectOptions: any) =>
  isSomeSelected(newSelected) && newSelected.length === selectOptions?.length;
export const isSomeButNotAllSelected = (
  newSelected: string[],
  selectOptions: any
) => isSomeSelected(newSelected) && !isAllSelected(newSelected, selectOptions);
