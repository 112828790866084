import React from 'react';
import {
  Tooltip,
  ClickAwayListener,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

interface GreenRoomTooltipProps {
  title: React.ReactNode;
}

function GreenRoomTooltip({ title }: GreenRoomTooltipProps) {
  const [open, setOpen] = React.useState<boolean>(false);

  /// //////////
  // Responsiveness: Clickable on mobile, Hoverable on desktop
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <Tooltip
          open={open}
          onClose={isSmallScreen ? undefined : () => setOpen(false)}
          onOpen={() => setOpen(true)}
          title={title}
        >
          <IconButton
            aria-label="help"
            onClick={() => (!isSmallScreen ? undefined : setOpen(!open))}
          >
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}

export default GreenRoomTooltip;
